import React, { useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SearchStructureInput from './SearchStructureInput';
import { makeStyles, TablePagination, Button, Hidden, CircularProgress } from '@material-ui/core';
import CompositeStructureComponent from '../StructureTab/CompositeStructureComponent';
import MessageSnackbar from '../MainPage/MessageSnackbar';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
//import { getImageFromSmiles } from '../store/searchStructure/actions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { requestStructureSearch, downloadSearchResults } from '../store/searchStructure/actions';
import { SearchInProgress } from '../SearchBox/SearchMessages';
import SaveIcon from "@material-ui/icons/SaveAlt";
import { searchApiFromUrl } from '../store/search/utilities';
import { Server } from 'https';

const useStyles = makeStyles({
  paperRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  iframe: {
    minHeight: 485
  },
  marBottom: {
    marginBottom: "7px",
    fontSize: "1.5rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: "0em",
    textAlign: "center",
    borderColor: "lightgray",
    borderStyle: 'solid',
    borderWidth: '1px',
    wordBreak: "break-all",
  },
  left10: {
    marginTop: "10px",
    marginBottom: "30px",
    display: "inline-table",
  },
  molCell: {
    // display: "inline",
    float: "left",
    marginTop: "4px",
    marginBottom: "4px",
    marginLeft: "4px",
    marginRight: "24px",
    boxShadow: "2px 2px 8px #444",
    margin: "10px",
    backgroundColor: "transparent",
    transition: "transform 0.2s linear 0s",
    textAlign: "center",
  },
  tableBottom: {
    clear: "both",
    height: "20px",
    width: "100%",
  },
  molCellSpan: {
    float: "left",
    textAlign: "center"
  },
  root: {
    display: 'flex',
    boxShadow: "none",
    border: "1px solid lightgray",
    marginBottom: "10px",
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: "80%",
  },
  content: {
    flex: '1 0 auto',
    fontSize: "21px",
    //fontSize: "12px",
    fontFamily: "Roboto , Helvetica, Arial, sans-serif",
    fontWeight: 400,
    padding: "0px !important",
  },
  cover: {
    width: "20%",
    minHeight: 150,
    height: "auto",
    border: "1px solid lightgray",
    borderRadius: "4px",
    cursor: "pointer",
  },
  img: {
    width: 150,
    height: 150,
    cursor: "pointer",
  },
  flex: {
    display: "flex",
    border: "1px solid lightgray",
    borderRadius: "4px",
    minHeight: "37px",
    height: "auto",
  },
  title: {
    width: "20%",
    borderRight: "1px solid lightgray",
    paddingTop: "5px",
    paddingLeft: "5px",
  },
  value: {
    width: "80%",
    paddingTop: "5px",
    paddingLeft: "5px",
    wordBreak: "break-all",
  },
  container: {
    height: "136.5vh",
    overflow: "auto",
    borderRadius: "4px",
    border: "1px solid lightgray",
  },
  containerHead: {
    borderRadius: "4px",
    border: "1px solid lightgray",
  },
  menuItemMsg: {
    //position: "absolute",
    zIndex: 999,
    //width: 535,
    fontSize: "0.9rem",
    minHeight: "10px",
    //paddingTop: "5px",
    //paddingBottom: "5px",
    backgroundColor: "ghostwhite",
    paddingLeft: "15px",
    border: "1px solid lightgray",
    borderRadius: "4px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  loadmoreBtn: {
    height: "45px",
  },
  icon: {
    width: 16,
    height: 16
  },
  hide: {
    visibility: "hidden",
  },
  floatRight: {
    display: 'flex',
    justifyContent: "space-between",
  },
  resultTitle: {
    fontSize: "1.5rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: "0em",
    paddingLeft: '10px',
    paddingTop: '2px',
  }
});
const SearchStructureMain = () => {
  const classes = useStyles();
  const [SMILES, setSMILES] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [showMarvin, setShowMarvin] = React.useState(false);
  const [mrvVal, setMrvVal] = React.useState('');
  const [disableBtn, setdisableBtn] = React.useState(true);
  //const structureSearchResult = useSelector((state: any) => state.structureSearchReducer.imageData);
  const structureSearchResultData = useSelector((state: any) => state.structureSearchReducer.data);
  const structureSearchResultJchem = useSelector((state: any) => state.structureSearchReducer.jChemData);
  const show = localStorage.getItem('show');
  //const jChemData = structureSearchResultData !== undefined ? structureSearchResultData.jChemData : "";
  //let backboneIdentity = structureSearchResultData.backboneIdentity  ? structureSearchResultData.backboneIdentity : "";
  //new load more button changes
  const [value, setValue] = React.useState('');
  const [searchType, setSearchType] = React.useState('');
  const [sliderValue, setSliderValue] = React.useState(80);
  const [doubleBond, setDoubleBond] = React.useState(false);
  const [tetrahedralStereo, setTetrahedralStereo] = React.useState(false);
  const [minResults, setMin] = React.useState(0);
  const [maxResults, setMax] = React.useState(30);
  const selectedSMILESHolder = (smiles: any) => {
    setSMILES(smiles);
    setValue(smiles.value);
    setSearchType(smiles.searchType);
    setSliderValue(smiles.sliderValue);
    setDoubleBond(smiles.doubleBond);
    setTetrahedralStereo(smiles.tetrahedralStereo);
    setMin(smiles.minResults);
    setMax(smiles.maxResults);
    setOpen(smiles.open);
  }
  // index: boolean = true;
  const reset = (res: any) => {
    let resetVal = localStorage.getItem('r');
    if (res === 'reset') {
      if (resetVal) {
        if (resetVal === 'true') {
          localStorage.setItem('r', 'false');

        } else {
          localStorage.setItem('r', 'true');

        }

      } else {
        localStorage.setItem('r', 'true');
      }
      //index = !index
    }
    setSMILES(localStorage.getItem('r'));
  }
  const resetRightHandSide = (rhs: any) => {
    if (rhs === 'all') {
      setdisableBtn(true);
      setShowMarvin(false);
    }
  }
  React.useEffect(() => {
    if (show === 'true') {
      setShowMarvin(true);
      // setPage(0);
    }
  })
  const dispatch = useDispatch();
  const LoadMoreApiCAll = useCallback(
    (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, open: any, minResults: any, maxResults: any) => {
      //const Molecule = molecule;
      //const TetrahedralStereo = tetrahedralStereo;
      //const DoubleBond = doubleBond;
      //const SearchType = searchType;
      //const SliderValue = sliderValue;
      //const Open = open;
      //dispatch(loading(true));
      const newMin = maxResults;
      const newMax = newMin + 10;
      //setTimeout(function () {
      dispatch(requestStructureSearch(molecule, tetrahedralStereo, doubleBond, searchType, sliderValue, open, newMin, newMax))
      //}, 2000);
      setMin(newMin);
      setMax(newMax);
      //if (rowData !== undefined) {
      //  setShowList(true);
      //  setShowNote(false);
      //  props.getAPIData(rowData);
      //  //setOpen(false);
      //}
    },
    [dispatch, minResults, maxResults]
  );
  const structureSearchResult = useSelector((state: any) => state.structureSearchReducer);
  const Loading = structureSearchResult.loading;
  const hasMore = structureSearchResult.hasMore;
  const inProgress = structureSearchResult.inProgress;
  const isLoading = structureSearchResult.isLoading;
  const resultCount = structureSearchResult.resultCount;
  const dataLength = structureSearchResultData ? structureSearchResultData.length : "";
  //end of new laod more changes//
  const getImage = (source: any) => {
    // set image source for the existing hidden image tag and display it.
    setMrvVal(source);
    setdisableBtn(false);
    // $("#image").attr("src", source);
    // $("#imageContainer").css("display", "inline-block");
  }
  const refresh = localStorage.getItem('open');
  const applyDataUri = (data: any) => {
    if (data) {
      $("#imageContainer").empty();
      for (let i = 0; i < data.length; i++) {
        let dataUri = 'data:image/jpeg;base64,' + data[i].molConverterResult.jpegResp;
        var molCell = $('<div>', { class: "molCell", id: i });
        $("#imageContainer").append(molCell);
        molCell.append($('<div>', { class: "molCellSpan", text: (data[i].backboneIdentity) }));
        var img = $('<img>');
        img.attr('src', dataUri);
        img.attr('data-mol', escape(data[i].molConverterResult.jpegResp));
        molCell.append(img);
        $("#" + i).click(() => {
          //window.open('/' + data[i].backboneIdentity + '/Substance', '_blank');
          window.location.href = '/' + data[i].backboneIdentity + '/Substance';
        })
      }
      // $("#imageContainer").append($('<div>', { class: "tableBottom" }));
      //$("#imageContainer").css("display", "flex");
      //$("#imageContainer").css("overflow", "scroll");
      //$("#imageContainer").css("text-align", "center");
      //$("#imageContainer").css("cursor", "pointer");
    }
    // let dataUri = 'data:image/jpeg;base64,' + data;
    // $('#imageContainer').empty();
    // var img = $('<img>', { src: dataUri }).appendTo($("#imageContainer"));
    // $("#imageContainer").css("display", "inline-block");
  }
  if (structureSearchResultData) {
    applyDataUri(structureSearchResultData);
  }
  //const dispatch = useDispatch();
  const getAPIData = (item: any) => {
    // window.location.href = '/';
    // if (item) {
    // dispatch(getImageFromSmiles(item));
    // }
  }
  const navigate = (backboneIdentity: any) => {
   
    //dispatch<any>(searchApiFromUrl(backboneIdentity, 'Substance', true))
    //window.location.href = '/' + backboneIdentity.charCodeAt(0) + '/Substance';
    
    window.location.href = '/' + backboneIdentity.replace('+','&plus&').replace('/', '&backslash&') + '/Substance';
    // window.location.href = '/WATER/Substance';
  }
  let URI = 'data:image/jpeg;base64,';
  //pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const download = useCallback(
    (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, minResults: any, maxResults: any) => {
      dispatch(downloadSearchResults(molecule, tetrahedralStereo, doubleBond, searchType, sliderValue, minResults, maxResults))
    },
    [dispatch]
  );
  const resetData = (event: any) => {
    if (event === 'resetData') {
      setPage(0);
    }
  }
  return (
    <div>
      <MessageSnackbar />
      <Grid container spacing={1}>
        <Grid item lg={5} md={12} xs={12} sm={12}>
          <CompositeStructureComponent smilesfromsearch={SMILES} searchIndex={"true"} getImage={getImage} />
          <Paper elevation={0}>
            <Paper className={classes.paperRoot}>
              <SearchStructureInput resetData={resetData} selectedSMILES={selectedSMILESHolder} resetMarvin={reset} resetAll={resetRightHandSide} inputValMrv={mrvVal} disableBtn={disableBtn} getAPIData={getAPIData} />
            </Paper>
          </Paper>
        </Grid>
        {showMarvin ?
          <Grid item lg={7} md={12} xs={12} sm={12}>
            <div className={classes.containerHead}>
              <div className={classes.floatRight}>
                {
                  (searchType === 'subStructure' || searchType === 'similarity') ?
                    (refresh !== 'yes' && structureSearchResultData !== undefined) ?
                      <div className={classes.resultTitle}>SEARCH RESULTS: <span>(Total no. of records: {resultCount})</span> </div> :
                      <div className={classes.resultTitle}></div>

                    : (structureSearchResultJchem !== undefined || structureSearchResultData !== undefined) ? <div className={classes.resultTitle}>SEARCH RESULTS: <span>(Total no. of records: {dataLength})</span> </div> : <div className={classes.resultTitle}></div>}
                <Button type="button" size="small" variant="text" disabled={structureSearchResultJchem === undefined && structureSearchResultData === undefined}
                  onClick={(e) => download(value, tetrahedralStereo, doubleBond, searchType, sliderValue, minResults, maxResults)}
                >{inProgress ? <CircularProgress size={31} title="download in progress" /> : <span title="download search results in .csv format"><SaveIcon /></span>}</Button></div>
            </div>
            <div className={classes.container}>
              {(searchType === 'subStructure' || searchType === 'similarity') ?
                (refresh !== 'yes' || structureSearchResultData !== undefined) ? structureSearchResultJchem &&
                  structureSearchResultJchem.map((item: any, i: any) => (
                    <Card className={[classes.root, 'noFlex'].join(" ")} key={i}>
                      <div className={[classes.cover, 'centerAligned'].join(" ")}>
                        <img className={classes.img} alt="image" onClick={(e) => navigate(item.backboneIdentity)} src={URI + item.molConverterResult.jpegResp} /></div>
                      <div className={[classes.details, 'fullWidth'].join(" ")}>
                        <CardContent className={classes.content}>
                          <div className={classes.flex}><div className={classes.title}>Name:</div><div className={classes.value}>{item.backboneIdentity}</div></div>
                          <div className={classes.flex}><div className={classes.title}>Smiles:</div><div className={classes.value}>{item.smiles}</div></div>
                          {item.similarity ? <div className={classes.flex}><div className={classes.title}>Similarity:</div><div className={classes.value}>{(item.similarity) * 100}%</div></div> : null}
                          <div className={classes.flex}><div className={classes.title}>SIR Identity:</div><div className={classes.value}>{item.sirIdentity}</div></div>
                          <div className={classes.flex}><div className={classes.title}>Molecular Formula:</div><div className={classes.value}>{item.molecularFormula}</div></div>
                          <div className={classes.flex}><div className={classes.title}>Verification Status:</div><div className={classes.value}>{item.verificationStatus}</div></div>
                          <div className={classes.flex}><div className={classes.title}>Structure Type:</div><div className={classes.value}>{item.structureType}</div></div>
                        </CardContent>
                      </div>
                    </Card>
                  )) : null
                :
                structureSearchResultData &&
                structureSearchResultData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, i: any) => (
                  <Card className={[classes.root, 'noFlex'].join(" ")} key={i}>
                    <div className={[classes.cover, 'centerAligned'].join(" ")}>
                      <img className={classes.img} alt="image" onClick={(e) => navigate(item.backboneIdentity)} src={URI + item.molConverterResult.jpegResp} /></div>
                    <div className={[classes.details, 'fullWidth'].join(" ")}>
                      <CardContent className={classes.content}>
                        <div className={classes.flex}><div className={classes.title}>Name:</div><div className={classes.value}>{item.backboneIdentity}</div></div>
                        <div className={classes.flex}><div className={classes.title}>Smiles:</div><div className={classes.value}>{item.smiles}</div></div>
                        {item.similarity ? <div className={classes.flex}><div className={classes.title}>Similarity:</div><div className={classes.value}>{(item.similarity) * 100}%</div></div> : null}
                        <div className={classes.flex}><div className={classes.title}>SIR Identity:</div><div className={classes.value}>{item.sirIdentity}</div></div>
                        <div className={classes.flex}><div className={classes.title}>Molecular Formula:</div><div className={classes.value}>{item.molecularFormula}</div></div>
                        <div className={classes.flex}><div className={classes.title}>Verification Status:</div><div className={classes.value}>{item.verificationStatus}</div></div>
                        <div className={classes.flex}><div className={classes.title}>Structure Type:</div><div className={classes.value}>{item.structureType}</div></div>
                      </CardContent>
                    </div>
                  </Card>
                ))
              }
              {
                structureSearchResultData && (searchType === 'exact' || searchType === 'fullFragment') ?
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                    component="div"
                    count={structureSearchResultData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  : null
              }
              {(structureSearchResultData && hasMore) ?
                < Button className={classes.loadmoreBtn} onClick={(e) => LoadMoreApiCAll(value, tetrahedralStereo, doubleBond, searchType, sliderValue, true, minResults, maxResults)}>Load More...</Button> : null
              }
              {
                hasMore && (searchType === 'subStructure' || searchType === 'similarity') && Loading && refresh !== 'yes' ? <div className={classes.menuItemMsg}><SearchInProgress /></div> : null
              }
            </div>
          </Grid> : null}
      </Grid>
    </div>
  )
}
export default SearchStructureMain;
