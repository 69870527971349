import React, { useCallback } from 'react';
import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { useDispatch, useSelector } from 'react-redux';
import { requestHistory, requestVariantHistory, requestPolymerHistory } from '../store/history/actions';
import { AppState } from '../store';

const useStyles = makeStyles(theme => ({
  button: {
  },
  icon: {
    width: 16,
    height: 16
  },
}));

type Props = {
  name: string;
  type: string;
  tabName?: string;
  disable?: boolean;
  isVariant?: boolean;
  isPolymer?: boolean;
  isVariantRestricted?: boolean;
  isPolymerRestricted?: boolean;
};

export const HistoryButton = (props: Props) => {
  const { name, type, disable, tabName, isVariantRestricted, isPolymerRestricted } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const onShowHistory = useCallback(() => {
    if (props.tabName === 'ContributionfactorTab') {
      dispatch(requestHistory(name, 'SubComponent'));
    } else if (props.isVariant) {
      console.log('variant history api ====')
      dispatch(requestVariantHistory(name, type));
    } else if (props.isPolymer) {
      console.log('polymer history api ====')
      dispatch(requestPolymerHistory(name, type));
    } else {
      dispatch(requestHistory(name, type));
    }
  }, [dispatch, name, type]);
  const { loading } = useSelector((state: AppState) => state.itemHistory);
  //const showStructureHistory = () => {
  //  alert('clicked');
  //}

  console.log('isVariantRestricted ==>', isVariantRestricted)
  console.log('isPolymerRestricted ==', isPolymerRestricted)
  const title = props.isVariant ? "Variant History" : "View History";
  return (
    <span>
      <Button
        size="small"
        variant="text"
        className={classes.button}
        title={title}
        disabled={!name || !type || disable || isVariantRestricted || isPolymerRestricted}
        onClick={onShowHistory}
      >
        <HistoryIcon className={classes.icon} />
      </Button>
      {loading && <CircularProgress size={20} />}
    </span>
  );
}
export default HistoryButton;
