import { createReducer } from 'typesafe-actions';
import { ItemHistoryState } from "./types";
import * as actions from './actions';

const initialState: ItemHistoryState = {
  loading: false,
  name: "",
  itemHistory: [],
  linkHistory: [],
  variantHistory: [],
  polymerHistory: {     
    backboneIdentity: "",
    history: [],
    sirIdentity:""
  }
};

export const itemHistoryReducer = createReducer(initialState)
  .handleAction(actions.requestHistoryAction.request, (state, action) => ({ ...state, name: "", loading: true }))
  .handleAction(actions.requestHistoryAction.success, (state, action) => ({ ...action.payload, loading: false }))
  .handleAction(actions.requestHistoryAction.failure, (state, action) => ({ ...state, name: "", loading: false }))
  .handleAction(actions.closeHistoryViewAction, (state, action) => ({ ...state, name: "" }))
  .handleAction(actions.requestVariantHistoryAction.request, (state, action) => ({ ...state, name: "", loading: true }))
  .handleAction(actions.requestVariantHistoryAction.success, (state, action) => ({ ...state, variantHistory: action.payload, loading: false }))
  .handleAction(actions.requestVariantHistoryAction.failure, (state, action) => ({ ...state, name: "", loading: false }))
  .handleAction(actions.closeVariantHistoryViewAction, (state, action) => ({ ...state, variantHistory: [] }))

  .handleAction(actions.requestPolymerHistoryAction.request, (state, action) => ({
    ...state, polymerHistory: {
      backboneIdentity: "",
      history: [],
      sirIdentity: ""
    }, loading: true }))
  .handleAction(actions.requestPolymerHistoryAction.success, (state, action) => ({ ...state, polymerHistory: action.payload, loading: false }))
  .handleAction(actions.requestPolymerHistoryAction.failure, (state, action) => ({
    ...state, polymerHistory: { 
      backboneIdentity: "",
      history: [],
      sirIdentity: ""
    }, loading: false }))
  .handleAction(actions.closePolymerHistoryViewAction, (state, action) => ({
    ...state, polymerHistory: { 
      backboneIdentity: "",
      history: [],
      sirIdentity: ""
    }
  }))
  ;
