import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import HistoryStructureView from './HistoryStructureView';
import { closeHistoryViewStructure } from '../store/structurehistory/actions';

const HistoryDialogStructure = () => {
  const dispatch = useDispatch();
  //const structureHistory = useSelector((state: any) => {
  //  return state.structureHistoryReducer;
  //});
  const structureHistory = useSelector((state: any) => state.structureHistoryReducer);
  const onClose = useCallback(
    () => { dispatch(closeHistoryViewStructure()); },
    [dispatch]);

  const open = structureHistory.backboneIdentity !== "" ;
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg" >
      <MuiDialogTitle >
        <Grid justify="space-between" container spacing={6}>
          <Grid item>
            {`History for ${structureHistory.backboneIdentity}`}
          </Grid>
          <Grid item>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent>
        <HistoryStructureView historyStructureData={structureHistory} />
      </DialogContent>
    </Dialog>
  );
}

export default HistoryDialogStructure;
