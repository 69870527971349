import { NodeData } from "../store/hierarchy/types";

// A non-recursive implementation of tree walking.
// Use when parameter to make a predicate.
// Apply is called on nodes that match the predicate.
// It's up to the caller to ensure that what happens during mess with the walking (i.e. I'd advise against adding / deleting nodes using this function!)
export const walkTree = (rootNode: NodeData, when: (item: NodeData) => boolean, apply: (item: NodeData) => void): void => {
  const flat = [ rootNode ];
  while (flat.length) {
    const node = flat.pop();
    if (node) {
      if (when(node)) {
        apply(node);
      }

      const children = node.children;
      if (children) {
        flat.push(...children);
      }
    }
  }
}

export const removeNodes = (rootNode: NodeData, itemName: string): void => {
  if (rootNode.children) {
    rootNode.children = rootNode.children.filter(i => i.name !== itemName);

    rootNode.children.forEach(i => removeNodes(i, itemName));
  }
}

export const hasNode = (rootNode: NodeData, predicate: (item: NodeData) => boolean): boolean => {
  const flat = [ rootNode ];
  while (flat.length) {
    const node = flat.pop();
    if (node) {
      if (predicate(node)) {
        return true;
      }

      const children = node.children;
      if (children) {
        flat.push(...children);
      }
    }
  }

  return false;
}

export const findNode = (rootNode: NodeData, predicate: (item: NodeData) => boolean): NodeData | null => {
  const flat = [ rootNode ];
  while (flat.length) {
    const node = flat.pop();
    if (node) {
      if (predicate(node)) {
        return node;
      }

      const children = node.children;
      if (children) {
        flat.push(...children);
      }
    }
  }

  return null;
}

export const makeNodeKey = (node: NodeData): string => {
  return makeKey(node.name, node.type, node.subType, node.selected || false);
}

export const makeKey = (name: string, type: string, subType: string, selected: boolean): string => {
  return `${subType || type}-${name}`;
}

export const filterOutNames = (nodes: NodeData[]): NodeData[] => {
  return nodes.filter(n => n.type !== 'Substance name')
}

export const addChildNode = (parentNode: NodeData, childNode: NodeData): void => {
  if (parentNode.children) {
    parentNode.children.push(childNode);
  } else {
    parentNode.children = [ childNode ];
  }
}

export const findNodesInTreeByName = (nodeName: string, nodeType: string, rootNode: NodeData) : NodeData[] => {
  const results = [] as NodeData[];
  walkTree(rootNode,
     (i) => i.name === nodeName && i.type === nodeType,
     (i) => results.push(i));

  return results;
}

// At each node, we just compare the children arrays and add the whole set if the target (current) has no children,
// and if the target has children, we add new ones from other.
export const mergeTrees = (current: NodeData, other: NodeData) => {
  if (other.children) {
    if (!current.children || current.children.length === 0) {
      current.children = other.children;
    } else {
      current.status = other.status;
      current.subType = other.subType;
      other.children.forEach(i => {
        const original = current.children || [];
        const originalItem = original.find(c => c.name === i.name);
        if (originalItem) {
          mergeTrees(originalItem, i);
        } else {
          original.push(i);
          current.children = original.sort((a,b) => a.name.localeCompare(b.name));
        }
      });
    }
  }
}

export const setSelectedBranches = (node: NodeData, item:string): boolean => {
  node.selected = (node.name === item);

  if (!node.selected) {
    const found = node.children ? node.children.filter(n => setSelectedBranches(n, item)).length : 0;
    node.selected = found > 0;
  }

  return node.selected;
}

// For sorting node lists. e.g. nodes.sort(nodeComparer)
export const nodeComparer = (a: NodeData, b: NodeData) => {
  const typeCompare = (a.subType || '').localeCompare(b.subType || '');
  if (typeCompare === 0) {
    return a.name.localeCompare(b.name, 'en', { sensitivity: 'base', ignorePunctuation: true});
  }

  return typeCompare;
}
