import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import './TabStyle.css';
import DetailContainer from '../DetailPanel/DetailContainer';
import CommentComponent from './CommentComponent';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a22yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: 5,
    textAlign: 'center' as 'center',
    overflow: "auto",
    maxHeight: "70vh"
  },
  cell: {
    fontSize: 12,
    display: "flex",
    alignContent: "center"
  },
  none: {
    display: "none"
  }
}));
//let selected: string = '';
const TabForCompositeSubstance = () => {
  //const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  //if (selectedItem === null || selectedItem === undefined) {
  //  selected = '';
  //} else {
  //  selected = selectedItem.name;
  //}
  localStorage.setItem('parentTypeWhileEdit', 'Composite');
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="SUBSTANCE INFO" {...a22yProps(0)} />
          <Tab label="COMMENTS" {...a22yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DetailContainer isCompositeSub={true} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CommentComponent isCompositeSub={true} />
      </TabPanel>
    </div>
  );
}

export default TabForCompositeSubstance;
