import { FormikErrors } from "formik";
import { pickBy, isEmpty } from "lodash";
import { Entity, AddItemState } from "../store/addItem/types";
import { hasConsecutiveSpaces } from "../utils/checkConsecutiveSpaces";

export const warningMessage = (values: AddItemState): string => {
  if (values.newEntity.name && values.parentEntity.name) {
    if (values.newEntity.type === "Substance" && values.parentEntity.type === "Substance name") {
      return `Creating a Substance-Substance link between ${values.newEntity.name} and ${values.parentEntity.name}`;
    }
  }

  return "";
}

export const validateNewItem = async (values: AddItemState) => {
  if (values.newEntity.type === 'Composite substance') {
    const tempEntity = { name: "", type: "", status: "", subType: "" };
    values.parentEntity = tempEntity;
  }
  const errors: FormikErrors<AddItemState> = {};
  errors.newEntity = await validateNewEntity(values.newEntity, values.parentEntity);
  errors.linkDomains = values.parentEntity.name ? validateDomains(values.linkDomains) : undefined;
/*  if (values.newEntity.type === "Composite substance") {
    errors.linkDomains = validateDomains(values.linkDomains);
  } else {
    errors.linkDomains = validateDomains(values.linkDomains);
  }*/
  //As per SonarQube For both condition doing same errors.linkDomains
  errors.linkDomains = validateDomains(values.linkDomains);
  const errorCount = (errors.newEntity ? Object.keys(errors.newEntity).length : 0)
    + (errors.linkDomains ? errors.linkDomains.length : 0);

  if (errorCount) {
    throw clean(errors);
  }

  return;
}

export const validateNewEntity = async (values: Entity, parentEntity: Entity): Promise<FormikErrors<Entity> | undefined> => {
  const errors = await validateEntity(values);
  errors.type = validateEntityRelationship(values, parentEntity);

  return clean(errors);
}

const clean = (item: any): any => {
  const cleaned = pickBy(item, v => v !== undefined);
  return isEmpty(cleaned) ? undefined : cleaned;
}

const validateEntity = async (values: Entity): Promise<FormikErrors<Entity>> => {
  const errors = {} as FormikErrors<Entity>;
  errors.name = validateNotEmpty(values.name, "Name");
  //errors.hasSpace = validateNotSpace(values.name, "hasSpace")
  //errors.isUnique = validateNotUnique(values.name, "isUnique")
  //if (!errors.name) {
  //  errors.name = await validateEntityNameIsUnique(values.name);
  //}
  errors.subType = validateNotEmpty(values.subType, "Type");
  errors.status = validateNotEmpty(values.status, "Status");

  return errors;
}

const relationshipMap: { [id: string]: boolean; } = {
  "Classification-Classification": true,
  "Classification-Substance": false,
  "Classification-Substance name": false,
  "Substance-Classification": true,
  "Substance-Substance": true,
  "Substance-Substance name": false,
  "Substance name-Classification": false,
  "Substance name-Substance": true,
  "Substance name-Composite": true,
  "Substance name-Substance name": false,
  "Classification name-Substance": false,
  "Classification name-Substance name": false,
  "Classification name-Classification": true,
};

export const validateEntityRelationship = (childEntity: Entity, parentEntity: Entity): string | undefined => {
  if (!parentEntity || !parentEntity.name) {
    // That's fine - you can add anything to nothing!
    return undefined;
  }
  if (!relationshipMap[`${childEntity.type}-${parentEntity.type}`]) {
    return `Can't add a ${childEntity.type} to a ${parentEntity.type}`;
  }

  return undefined;
}

export const validateDomains = (domains: string[]): string[] | undefined => {
  return domains && domains.length > 0 ? undefined : ["Need to select at least one domain"];
}

const validateNotEmpty = (value: string, name: string): string | undefined => {
  return value ? undefined : `${name} cannot be empty`;
}
const validateNotSpace = (value: string, name: string): string | undefined => {
  //let reg = /^.*\s{2,}.*$/
  //SonarQube warning for Denail of Service issue

  // let reg = /(?!\S)\s{2}(?!$)/
  return hasConsecutiveSpaces(value) ? `Name contains more than one consecutive space or leading/trailing spaces - please correct` : undefined;
}
//const validateNotUnique = (value: string, name: string): string | undefined => {
//  console.log('unique ka')
//  return value ? undefined : `${name} cannot be empty`;
//}
