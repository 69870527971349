import { Dispatch } from 'redux';
import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import axios from 'axios';
import { MergeItemsSpec, MergeItemsFormState, MergeItemsPayload } from './types';
import { makeSuccessMessage, makeError } from '../error/actions';
import { locateItem } from '../hierarchy/actions';

export const showMergeAction = createStandardAction("SHOW_MERGE")<MergeItemsSpec>();
export const showMerge = (spec: MergeItemsSpec) => {
  return showMergeAction(spec);
};

export const closeMergeAction = createStandardAction("CLOSE_MERGE")<never>();
export const closeMerge = () => {
  return closeMergeAction();
};

export const saveMergeAction = createAsyncAction("SAVE_MERGE_START", "SAVE_MERGE_SUCCESS", "SAVE_MERGE_FAIL")<MergeItemsFormState, MergeItemsPayload, MergeItemsPayload>();

const convertToApiCreateItemPayload = (values: MergeItemsFormState): MergeItemsPayload => {
  return {
    sourceName: values.source.name,
    targetName: values.target.name,
    auditDescription: values.auditDescription,
    nodeType: values.defaultSelected
  }
}

export const saveMerge = (values: MergeItemsFormState) => {
   return async (dispatch: Dispatch) => {
    dispatch(saveMergeAction.request(values));
    const payload = convertToApiCreateItemPayload(values);

    axios.put('/api/v1/Entities/merge', payload)
      .then((response) => {
        dispatch(makeSuccessMessage("Items were successfully merged"));
        dispatch(saveMergeAction.success(payload));
        const itemToLocate = { name: payload.targetName, type: values.defaultSelected, subType: "", status: "", substanceId: "" };
        dispatch<any>(locateItem(itemToLocate, true));
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(saveMergeAction.failure(error.message));
      });
  }
}
