import React from 'react';
import { Route, Switch } from 'react-router';
import MainTab from './MainPage/MainTab';
import OverviewPage from './MainPage/OverviewPage';
//import OverviewPage from './MainPage/OverviewPage';
const Routes: React.SFC = () => (
  <Switch>
    <Route exact path="/" component={MainTab} />
    <Route path="/:substanceName/:type" component={MainTab} />
    <Route component={() => <div>No Results Found...Please enter exact Substance name and type combination in the address bar</div>} />
    </Switch>
)

export default Routes;
