import React from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { TextField, Select, Checkbox } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { AddLinkFormValues, AddLinkFormConfig } from '../store/entity/types';
import SearchField from '../SearchBox/SearchField';
import { Suggestion } from '../store/search/types';
import { getParentFilter, getChildFilter } from "../domain/FilterHelper";

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  searchFieldSpacer: {
    marginTop: "20px"
  },
});

type Props = {
  config: any
};

export const SwapSynonymSubstanceFormInner = (props: Props & FormikProps<any>) => {
  const classes = useStyles();
  const {
    childNameLabel,
    parentNameLabel,
    auditDescriptionLabel,
  } = props.config;
 
  return (
    <Form>
      <div>
        <InputLabel >{childNameLabel}</InputLabel>
        <SearchField
          name="childItem"
          filter="classification name,substance name" />
        <InputLabel className={classes.searchFieldSpacer}>{'Substance/Classification'}</InputLabel>
        <SearchField
          name="parentItem"
          filter="classification,substance" />

        <InputLabel className={classes.searchFieldSpacer} htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
        <Field
          className={classes.field}
          inputProps={{ id: 'audit-description-input' }}
          name="auditDescription"
          component={TextField}
          margin="dense"
          fullWidth />
        <Button type="submit" color="primary">
          Save
        </Button>
      </div>
    </Form>
  )
};

const emptySuggestion: Suggestion = {
  name: "",
  type: "",
  subType: "",
  status: "",
  substanceId: ""
};

const SwapSynonymSubstanceForm = withFormik<Props, any>({
  mapPropsToValues: (props) => ({ childItem: emptySuggestion, parentItem: emptySuggestion, auditDescription: ""}),
  handleSubmit: (formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  enableReinitialize: true,
})(SwapSynonymSubstanceFormInner);

export default SwapSynonymSubstanceForm;
