import React from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { AddFormValues, AddFormConfig } from '../store/entity/types';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  }
});

type Props = {
  config: AddFormConfig
};

export const AddFormInner = (props: Props & FormikProps<AddFormValues>) => {
  const classes = useStyles();
  const { nameLabel, typeLabel, typeMenuItems, statusLabel, statusMenuItems, auditDescriptionLabel } = props.config;
  return (
  <Form>
    <div>
      <InputLabel >{nameLabel}</InputLabel>
      <EnhancedTextInputField name="name" fullWidth margin="dense" className={classes.field} />

      <InputLabel htmlFor="classification-type-input">{ typeLabel }</InputLabel>
      <Field
        className={classes.field}
        name="type"
        component={Select}
        margin="dense"
        fullWidth
        inputProps={{ id: 'classification-type-input' }}>
        { typeMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
      </Field>
      <InputLabel htmlFor="status-input">{ statusLabel }</InputLabel>
      <Field
        className={classes.field}
        name="status"
        component={Select}
        margin="dense"
        fullWidth
        inputProps={{ id: 'status-input' }}>
        { statusMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
      </Field>
      <InputLabel htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
      <Field
        className={classes.field}
        inputProps={{ id: 'audit-description-input' }}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth/>
      <Button type="submit" color="primary">
        Add
      </Button>
    </div>
  </Form>
)};

const AddForm = withFormik<Props, AddFormValues>({
  mapPropsToValues: (props) => ({name: "", type:"", status: "A", auditDescription:""}),
  handleSubmit: ( formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  enableReinitialize: true,
})(AddFormInner);

export default AddForm;
