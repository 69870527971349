import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, IconButton, makeStyles, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green } from '@material-ui/core/colors';
import { AppState } from '../store';
import { closeMessageAction } from '../store/error/actions';
import { SirMessage, MessageSeverity } from '../store/error/types';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  }
}));

const getMessage = (messages: SirMessage[]): SirMessage => {
  if (messages.length > 0) {
    return messages[0];
  }

  return {
    severity: MessageSeverity.Info,
    message: "Yes SIR!",
    time: 0
  };
}

const getDelayForSeverity = (severity: MessageSeverity): number => {
  if (severity === MessageSeverity.Error) {
    return 30000;
  } else {
    return 5000;
  }

}
const getClassForSeverity = (severity: MessageSeverity, classes: ReturnType<typeof useStyles>): string => {
  switch (severity) {
    case MessageSeverity.Info:
      return classes.info;
    case MessageSeverity.Success:
          return classes.success;
    case MessageSeverity.Warning:
      return classes.warning;
    case MessageSeverity.Error:
      return classes.error;
    default:
      return classes.info;
    }
}

const renderIcon = (severity: MessageSeverity, className: string): JSX.Element => {
  switch (severity) {
    case MessageSeverity.Info:
      return (<InfoIcon className={className} />);
    case MessageSeverity.Success:
      return (<CheckCircleIcon className={className} />);
    case MessageSeverity.Warning:
      return (<WarningIcon className={className} />);
    case MessageSeverity.Error:
      return (<ErrorIcon className={className} />);
    default:
      return (<InfoIcon className={className} />);
    }
}

const MessageSnackbar = () => {
  const classes = useStyles();
  const messages = useSelector((state: AppState) => state.messages);
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(closeMessageAction()),
    [dispatch]
  );

  const message = getMessage(messages.messages);
  return (
    <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={messages.display}
    autoHideDuration={getDelayForSeverity(message.severity)}
    onClose={onClose}
    >
      <SnackbarContent
        className={getClassForSeverity(message.severity, classes)}
        aria-describedby="message-snackbar"
        message={
          <span id="message-snackbar" className={classes.message}>
            {renderIcon(message.severity, classes.icon)}
            {message.message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>);
}

export default MessageSnackbar;
