import React, { useState } from 'react';
import { Input, InputAdornment, Tooltip, makeStyles, TableHead, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import InformationIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { CharacterTranslation, translateText } from './textTranslation';
import axios from 'axios';
import { makeError } from '../store/error/actions';
import { useSelector, useDispatch } from "react-redux";
import { Formik } from 'formik';
import { hasConsecutiveSpaces } from '../utils/checkConsecutiveSpaces';

const useStyles = makeStyles({
  tooltip: {
    fontSize: "0.85rem"
  },
  table: {
    minWidth: "200px"
  },
  tableHeader: {
    padding: "0px",
    color: "White"
  },
  tableCell: {
    padding: "0px",
    borderBottom: "none",
    color: "White"
  },
  para: {
    marginTop: '-15px',
    marginBottom: '20px',
    color: 'red',
    //padding: '5px',
    fontSize: '1rem',
    fontWeight: 400,
    //paddingLeft: "15px"
  },
  para1: {
    marginTop: '-15px',
    marginBottom: '20px',
    color: 'red',
    //padding: '5px',
    fontSize: '0.91rem',
    fontWeight: 400,
    //paddingLeft: "15px"
  },
});

interface Props {
  translations: CharacterTranslation[];
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: any | undefined;
};

const SimpleTooltip = () => (
  <span>{"This input box is special. You can type \\a for example, to get an \u03B1. Click the icon for the full list."}</span>
)

interface TooltipProps {
  translations: CharacterTranslation[];
}

const FullTooltip = (props: TooltipProps) => {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeader}>Type this</TableCell>
          <TableCell className={classes.tableHeader}>To get this</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.translations.map(i =>
          <TableRow key={i.inputSequence}>
            <TableCell className={classes.tableCell}>{i.inputSequence}</TableCell>
            <TableCell className={classes.tableCell}>{i.translation}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

const EnhancedTextInput = (props: Props) => {
  const classes = useStyles();
  const { translations, value, inputProps } = props;
  const radioStatus = localStorage.getItem('radioItemSelected');
  const dispatch = useDispatch();
  const [translatedValue, setTranslatedValue] = useState("");
  const [listTranslations, setListTranslations] = useState(false);
  const val = localStorage.getItem('isSubVsClass');
  const toggleTooltipState = () => {
    setListTranslations(s => !s);
  }
  React.useEffect(() => {
    if (value === '') {
      setTranslatedValue("");
    }
    else {
      setTranslatedValue(value);
    }
  }, [value])
  const translate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const translation = translateText(e.currentTarget.value, translations);
    setTranslatedValue(translation);
    setIsUniqueVal(false);
    setHasExtraSpace(false);
    props.onChange({ ...e, currentTarget: { ...e.currentTarget, value: translation } });
    localStorage.setItem('nameComposite', e.currentTarget.value);
  };
  const [isUniqueVal, setIsUniqueVal] = useState(false);
  const [hasExtraSpace, setHasExtraSpace] = useState(false);
  //let isUniqueVal: boolean = false;



 const dataValidate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //var reg = /^.*\s{2,}.*$/
    //if ((e.currentTarget.value).match(reg)) {
    //  alert('space h')
    //} else {
    //  alert('space nahi h')
    //}
    if (e.currentTarget.value) {
      const name = await validateEntityNameIsUnique(e.currentTarget.value);
      if (name === 'Name is not unique') {
        localStorage.setItem('uniquename', 'false');
        //localStorage.removeItem('hasextraspace');
        dispatch(makeError('Please add an unique name'));
        setIsUniqueVal(true);
      }
      else if (name === 'Name contains more than one consecutive space or leading/trailing spaces - please correct') {
        //localStorage.setItem('uniquename', 'false');
        localStorage.removeItem('uniquename');
        //localStorage.setItem('hasextraspace', name);
        //dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
        //setHasExtraSpace(true);
      }
      else {
        localStorage.removeItem('uniquename');
        //localStorage.removeItem('hasextraspace');
        setIsUniqueVal(false);
      }
    }
  }
  //if (!errors.name) {
  //  errors.name = await validateEntityNameIsUnique(values.name);
  //}
  const validateEntityNameIsUnique = async (name: string): Promise<string | undefined> => {
    //let reg = /^.*\s{2,}.*$/
    //SonarQube warning for Denail of Service issue

    // let reg = /(?!\S)\s{2}(?!$)/
    const errMsg = `Name is not unique`;
    const spaceErr = `Name contains more than one consecutive space or leading/trailing spaces - please correct`;
    if (hasConsecutiveSpaces(name)) {
      setHasExtraSpace(true);
      //localStorage.setItem('uniquename', 'false');
      //localStorage.setItem('hasextraspace', spaceErr);
    } else {
      setHasExtraSpace(false);
      //localStorage.removeItem('uniquename');
      //localStorage.removeItem('hasextraspace');
    }
    try {
      const { data: { isUnique } } = await axios.get(`/api/v1/Entities/exists?name=${encodeURIComponent(name)}`);
      if (isUnique) {
        if (hasConsecutiveSpaces(name)) {
          //localStorage.setItem('hasextraspace', spaceErr);
          return spaceErr;
        }
        else {
          return undefined;
        }
      } else {
        return errMsg;
      }
      //return isUnique ? ((name).match(reg)) ? spaceErr : undefined : errMsg;
    } catch (e) {
      return errMsg;
    }
  }
  return (
    <>
      <div><Input
        id="inputForName"
        onChange={translate}
        value={translatedValue}
        onBlur={dataValidate}
        disabled={val === 'true'}
        {...inputProps}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip classes={{ tooltip: classes.tooltip }}
              title={listTranslations ? <FullTooltip translations={translations} /> : <SimpleTooltip />}
              interactive
            >
              <IconButton onClick={toggleTooltipState} size="small" tabIndex={-1}>
                <InformationIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        } />
      </div>
      {!isUniqueVal ? null : <div className={classes.para} >Name is not unique</div>}
      {!hasExtraSpace ? null : <div className={classes.para1} >Name contains more than one consecutive space or leading/trailing spaces - please correct</div>}
    </>
  );
}

export default  EnhancedTextInput ;
