import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import useStyles from './Styles';
import GlobalSearchBox from '../SearchBox/GlobalSearchBox';
import TreeContainer from '../TreeView/TreeContainer';
import ImportPanel from '../ImportExport/ImportPanel';
import MessageSnackbar from './MessageSnackbar';
import AllDialogs from '../AddDialog/AllDialogs';
import HistoryDialog from '../History/HistoryDialog';
import EditDialog from '../EditItems/EditDialog';
import StructureTabs from '../StructureTab/StructureTabComponent';
import HistoryDialogStructure from '../History/HistoryDialogStructure';
import FlagDialog from '../Flag/FlagDialog';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { requestSearchStructureWithInitial } from '../store/searchStructure/actions';
import EditSubComponentDialog from '../DetailPanel/EditSubComponentDialog';
import SwapDialog from '../SwapBackbone/SwapDialog';
import VariantHistoryDialog from '../History/VariantHistoryDialog';
import PolymerStructureHistoryDialog from '../History/PolymerStructureHistoryDialog';
const OverviewPage = (props: any) => {
  const substanceNameFromUrl = props.substanceNameFromUrl !== undefined ? props.substanceNameFromUrl : "";
  const typeFromUrl = props.typeFromUrl !== undefined ? props.typeFromUrl : '';
  const [open, setOpen] = React.useState(true);
  const openFromUtility = useSelector((state: any) => state.structureSearchReducer.isLoading);
  localStorage.removeItem('name');
  localStorage.removeItem('editComposite');
  localStorage.removeItem('r');
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestSearchStructureWithInitial.request(""));
    if (openFromUtility === false) {
      setOpen(false);
    }
    else if (substanceNameFromUrl === "" && typeFromUrl === "") {
      setOpen(false);
    }
  });
  return (
    <div className={classes.root}>
      <MessageSnackbar />
      <Grid container spacing={1}>
        <Grid item lg={5} xs={12} md={6} sm={12}>
          <Paper className={classes.paper} elevation={0}>
            <Paper className={classes.paperRoot}>
              <GlobalSearchBox />
            </Paper>
            <TreeContainer />
          </Paper>
        </Grid>
        <Grid item lg={7} xs={12} md={6} sm={12}>
          <Paper className={classes.paper} elevation={0}>
            <StructureTabs />
          </Paper>
        </Grid>
        <Grid item xs={6} lg={6} md={6} sm={12}>
          <Paper className={classes.paper} elevation={0}>
            <AllDialogs />
          </Paper>
        </Grid>
        <Grid item xs={6} lg={6} md={6} sm={12}>
          <Paper className={classes.paper} elevation={0}>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12} className="fileimprt">
          <Paper className={classes.paper} elevation={0}>
            <ImportPanel />
          </Paper>
        </Grid>
      </Grid>
      <HistoryDialog />
      <HistoryDialogStructure />
      <VariantHistoryDialog />
      <PolymerStructureHistoryDialog />
      <EditDialog />
      <FlagDialog />
      <SwapDialog />
      <EditSubComponentDialog />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default OverviewPage;
