import { createReducer } from "typesafe-actions";
import * as actions from './actions';

const initialState: any = {
  open: "",
  substanceName: "",
  substanceMolWeight: "",
  subComponentName: "",
  subComponentMolWeight: "",
  numberOfExpressedAs: "",
  contributionFactor: "",
  getSubComponentInfo: [],
  getMolWeightData: [],
  getSubcompForSearch: []
};

const combine = (state: any, action: any) => {
  return {
    ...state, getSubComponentInfo: action.payload, substanceMolWeight: action.payload.substanceMolWeight
  }
}
export const subComponentReducer = createReducer(initialState)
  .handleAction(actions.closeSubcompEditRequest, (state, action) => ({ ...state, open: "", getMolWeightData: [] }))
  .handleAction(actions.openSubCompEditRequest, (state, action) => ({ ...state, open: action.payload }))
  .handleAction(actions.getSubComponentRequest.request, (state, action) => ({ ...state,  }))
  .handleAction(actions.getSubComponentRequest.success, (state, action) => combine(state, action))
  .handleAction(actions.getSubComponentRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.savedSubCompEditAction.request, (state, action) => ({ ...state, open: ""}))
  .handleAction(actions.savedSubCompEditAction.success, (state, action) => ({ ...state, open: "" }))
  .handleAction(actions.savedSubCompEditAction.failure, (state, action) => ({ ...state, open: "" }))
  .handleAction(actions.getMolWeightRequest.request, (state, action) => ({ ...state, getMolWeightData: null }))
  .handleAction(actions.getMolWeightRequest.success, (state, action) => ({ ...state, getMolWeightData: action.payload }))
  .handleAction(actions.getSubComponentSearchRequest.request, (state, action) => ({ ...state, getSubcompForSearch: [] }))
  .handleAction(actions.getSubComponentSearchRequest.success, (state, action) => ({ ...state, getSubcompForSearch: action.payload }))
