import { createAsyncAction } from "typesafe-actions";
import { CommentState } from "./types";
import { Dispatch } from "redux";
import { AppState } from "..";
import axios from 'axios';
import { makeError } from "../error/actions";


export const commentRequest = createAsyncAction(
  "COMMENT_REQUEST_START",
  "COMMENT_REQUEST_SUCCESS",
  "COMMENTL_REQUEST_FAILURE")<string, string, string>();
export const getCommentRequest = createAsyncAction(
  "COMMENT_LIST_REQUEST_START",
  "COMMENT_LIST_REQUEST_SUCCESS",
  "COMMENTL_LIST_REQUEST_FAILURE")<string, CommentState, string>();



//post a comment
export const postComment = (name: string, commentType: string, description: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(commentRequest.request(name));
    axios.put('/api/v1/Comments/AddComments', { name: name, commentType: commentType, description: description })
      .then((response) => {
        //dispatch(makeSuccessMessage("Save complete"));
        //dispatch(commentRequest.success({ name: name, commentType: commentType, description: description }));
        setTimeout(function () {
          dispatch(getCommentRequest.request(name));
          axios.get(`/api/v1/Comments?Name=${encodeURIComponent(name)}&maxResults=100`)
            .then((response) => {
              dispatch(getCommentRequest.success({ backboneIdentity: response.data[0].backboneIdentity, comments: response.data[0].comments, sirIdentity: response.data[0].sirIdentity }));
            }).catch((error) => {
              dispatch(makeError(error));
              //dispatch(substanceDetailNodeRequest.failure(error.message));
            });
        }, 5000);
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(commentRequest.failure(error.message));
      });
  }
}

//get all comment
export const getCommentList = (name: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(commentRequest.request(name));
    axios.get(`/api/v1/Comments?Name=${encodeURIComponent(name)}&maxResults=100`)
      .then((response) => {
        dispatch(getCommentRequest.success({ backboneIdentity: response.data[0].backboneIdentity, comments: response.data[0].comments, sirIdentity: response.data[0].sirIdentity }));
      }).catch((error) => {
        //dispatch(makeError(error));
        //dispatch(substanceDetailNodeRequest.failure(error.message));
      });
  }
}

