import { Suggestion } from "../store/search/types";

export const getParentFilter = (childItem: Suggestion): string => {
  if (childItem && childItem.type === "Substance name") {
    return "Substance";
  }
  if (childItem && childItem.type === "Substance") {
    return "Classification,Substance";
  }
  if (childItem && childItem.type === "Classification") {
    return "Classification";
  }

  return "Classification,Substance";
}

export const getChildFilter = (parentItem: Suggestion):string => {
  if (parentItem && parentItem.type === "Substance name") {
    return "All";
  }
  if (parentItem && parentItem.type === "Substance") {
    return "Substance";
  }
  if (parentItem && parentItem.type === "Classification") {
    return "Classification,Substance";
  }

  return "Substance,Classification";
}
export const getParentFilterSynonym = (childItem: Suggestion): string => {
  return "Substance";
}
