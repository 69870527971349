import { initialState } from "./types";
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';

type SearchActionsType = ActionType<typeof actions>;

declare module "typesafe-actions" {
  export type RootAction = SearchActionsType;
}

export const searchReducer = createReducer(initialState)
.handleAction(actions.searchRequest.success, (state, action) => ({...state, searchTerm: state.searchTerm, matches: action.payload, loading: false, notEnoughCharacters: false }))
.handleAction(actions.searchRequest.request, (state, action) => ({...state, searchTerm: action.payload, loading: true, matches: [], notEnoughCharacters: false }))
.handleAction(actions.searchRequest.failure, (state, action) => ({ ...state, loading: false, matches: [] }))
.handleAction(actions.searchNotEnough, (state, action) => ({ ...state, loading: false, notEnoughCharacters: true }))
.handleAction(actions.searchStateChange, (state, action) => (action.payload))
.handleAction(actions.itemSelected, (state, action) => ({ ...state, selectedItem: action.payload }));

export default searchReducer;
