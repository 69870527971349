import React from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { TextField, Select, Checkbox } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { AddNameFormValues, AddNameFormConfig } from '../store/entity/types';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  }
});

type Props = {
  config: AddNameFormConfig
};

export const AddNameFormInner = (props: Props & FormikProps<AddNameFormValues>) => {
  const classes = useStyles();
  const {
    nameLabel,
    typeLabel,
    typeMenuItems,
    statusLabel,
    statusMenuItems,
    backboneIdentityLabel,
    auditDescriptionLabel,
    domainNameLabel,
    isPrimaryForDomainLabel,
    domainNameMenuItems
  } = props.config;
  return (
  <Form>
    <div>
      <InputLabel>{nameLabel}</InputLabel>
      <EnhancedTextInputField
        className={classes.field}
        name="name"
        autoFocus
        margin="dense"
        fullWidth
        />
      <InputLabel htmlFor="classification-type-input">{ typeLabel }</InputLabel>
      <Field
        className={classes.field}
        name="type"
        component={Select}
        margin="dense"
        fullWidth
        inputProps={{ id: 'classification-type-input' }}>
        { typeMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
      </Field>
      <InputLabel htmlFor="status-input">{ statusLabel }</InputLabel>
      <Field
        className={classes.field}
        name="status"
        component={Select}
        margin="dense"
        fullWidth
        inputProps={{ id: 'status-input' }}>
        { statusMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
      </Field>
      <InputLabel htmlFor="backbone-identity-input">{backboneIdentityLabel}</InputLabel>
      <Field
        className={classes.field}
        name="backboneIdentity"
        component={TextField}
        autoFocus
        margin="dense"
        fullWidth
        inputProps={{ id: 'backbone-identity-input' }}
        />
      <InputLabel htmlFor="domain-name-input">{ domainNameLabel }</InputLabel>
      <Field
        className={classes.field}
        name="domainName"
        component={Select}
        margin="dense"
        fullWidth
        inputProps={{ id: 'domain-name-input' }}>
        { domainNameMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
      </Field>
      <InputLabel htmlFor="is-primary-for-domain-input">{ isPrimaryForDomainLabel }</InputLabel>
      <Field
        className={classes.field}
        name="isPrimaryForDomain"
        component={Checkbox}
        margin="dense"
        inputProps={{ id: 'is-primary-for-domain-input' }}>
      </Field>
      <InputLabel htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
      <Field
        className={classes.field}
        inputProps={{ id: 'audit-description-input' }}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth/>
      <Button type="submit" color="primary">
        Add
      </Button>
    </div>
  </Form>
)};

const AddNameForm = withFormik<Props, AddNameFormValues>({
  handleSubmit: ( formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  mapPropsToValues: (props: Props) => ({
      name: "",
      type: "",
      status: "",
      backboneIdentity: props.config.defaultBackboneIdentity,
      domainName: "",
      isPrimaryForDomain: false,
      auditDescription: ""
  }),
  enableReinitialize: true,
})(AddNameFormInner);

export default AddNameForm;
