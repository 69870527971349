import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { closeVariantHistoryViewAction } from '../store/history/actions';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core';
import { formatDateAndTime } from '../domain/Format';

const useStyles = makeStyles({
  paper: {
    padding: 5,
    textAlign: 'center' as 'center',
    overflow: "auto",
    maxHeight: "70vh"
  }
});
const VariantHistoryDialog = () => {
  const dispatch = useDispatch();
  const itemHistory = useSelector((state: AppState) => {
    return state.itemHistory;
  });
  let variantHistoryArr: any[] = [];
  let variantName = '';

  console.log('itemHistory in variant ==', itemHistory)
  if (itemHistory && itemHistory.variantHistory) {
    if (itemHistory.variantHistory.length) {
      variantHistoryArr = itemHistory.variantHistory[0].history;
      variantName = itemHistory.variantHistory[0].backboneIdentity;
    }
  }
  const onClose = useCallback(
    () => { dispatch(closeVariantHistoryViewAction()); },
    [dispatch]);

  const open = variantHistoryArr.length > 0 ? true : false;
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg" >
      <MuiDialogTitle >
        <Grid justify="space-between" container spacing={6}>
          <Grid item>
            {`History for ${variantName}`}
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent>
        <Paper className={classes.paper} >
          <Typography variant="h4" >
            Variant History
          </Typography>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>When</TableCell>
                <TableCell>Who</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Change Type</TableCell>
                <TableCell>SOURCE ID</TableCell>
                <TableCell>MW</TableCell>
                <TableCell>MN</TableCell>
                <TableCell>PDI</TableCell>
                <TableCell>DP</TableCell>
                <TableCell>PRF Composition</TableCell>
                <TableCell>Source Composition</TableCell>
                <TableCell>Topology</TableCell>
                <TableCell>Chemical Microstructure</TableCell>
                <TableCell>Substituent Composite</TableCell>
                <TableCell>DS</TableCell>
                <TableCell>Change Description</TableCell>

              </TableRow>
            </TableHead>
            <TableBody className="namelistBody">
              {variantHistoryArr.map((item, i) => (
                <TableRow key={`item${i}`}>
                  <TableCell>{formatDateAndTime(item.historyDateTime)}</TableCell>
                  <TableCell>{item.userName}</TableCell>
                  <TableCell>{item.variantName}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.changeType}</TableCell>
                  <TableCell>{item.sourceID}</TableCell>
                  <TableCell>{item.weightAverageMolecularWeight}</TableCell>
                  <TableCell>{item.numberAvergeMolecularWeight}</TableCell>
                  <TableCell>{item.polydispersityIndex}</TableCell>
                  <TableCell>{item.averageDegreeOfPolymerisation}</TableCell>
                  <TableCell>{item.prfCompositionMoleFraction}</TableCell>
                  <TableCell>{item.sourceCompositionMoleFraction}</TableCell>
                  <TableCell>{item.topology}</TableCell>
                  <TableCell>{item.chemicalMicroStructure}</TableCell>
                  <TableCell>{item.substituentComposition}</TableCell>
                  <TableCell>{item.ds}</TableCell>
                  <TableCell>{item.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>      </DialogContent>
    </Dialog>
  );
}

export default VariantHistoryDialog;
