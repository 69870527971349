import { createAsyncAction, createStandardAction } from "typesafe-actions";
import { Dispatch } from "redux";
import { AppState } from "..";
import axios from 'axios';
import { makeSuccessMessage, makeError } from "../error/actions";
import { locateItem, queryNode } from "../hierarchy/actions";
import { nodeName } from "jquery";

export const closeSwapFlagRequest = createStandardAction("SWAP_FLAG_CLOSE_STRUCT")<void>();
export const openSwapFlagRequest = createStandardAction("SWAP_FLAG_OPEN_STRUCT")<any>();
export const swapSubstanceRequest = createAsyncAction(
  "SWAP_SUBSTANCE_REQUEST_START",
  "SWAP_SUBSTANCE_REQUEST_SUCCESS",
  "SWAP_SUBSTANCE_REQUEST_FAILURE")<string, any, string>();

export const closeSwapFlag = () => {
  return closeSwapFlagRequest();
}

export const openSwapFlag = () => {
  return openSwapFlagRequest("openSwapDialog");
}

export const swapSubstanceNameApiCall = (currentBackbone: string, name: string, description: string, type: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    swapSubstanceRequest.request(name);
    if (type == "Classification") {
      axios.put(`/api/v1/Classification/SwapClassificationBackboneName`, { oldBackboneName: currentBackbone, newBackboneName: name, auditDescription: description })
        .then((response) => {
          dispatch(swapSubstanceRequest.success(response.data));
          dispatch(makeSuccessMessage("Classification Swapped Successfully"));
          //dispatch<any>(locateItem({ name: "", type: "Classification", subType: "", status: "", substanceId: "" }, true, ""))
          dispatch<any>(locateItem({ name: name, type: type, subType: "", status: "A", auditDescription: "", substanceId: name }, true, currentBackbone))
          //dispatch<any>(queryNode(name,type))
          //dispatch<any>(getAllFlaggedInfo());
        })
        .catch((error: any) => {
          dispatch(makeError(error));
        });

    }
    else {
      axios.put(`/api/v1/Substances/SwapBackboneName`, { oldBackboneName: currentBackbone, newBackboneName: name, auditDescription: description })
        .then((response) => {
          dispatch(swapSubstanceRequest.success(response.data));
          dispatch(makeSuccessMessage("Substance Swapped Successfully"));
          //dispatch<any>(locateItem({ name: "", type: "Classification", subType: "", status: "", substanceId: "" }, true, ""))
          dispatch<any>(locateItem({ name: name, type: type, subType: "", status: "A", auditDescription: "", substanceId: name }, true, currentBackbone))
          //dispatch<any>(queryNode(name,type))
          //dispatch<any>(getAllFlaggedInfo());
        })
        .catch((error: any) => {
          dispatch(makeError(error));
        });
    }
  }
}
