import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { closeFlag, disableFlag, enableFlag } from '../store/flag/actions';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputLabel, TextField } from '@material-ui/core';
import { AppState } from '../store';
import { closeSubcompEdit, getSubComponentList, saveSubComponentEdit, getMolWeightInfo, getSubComponentSearchRequest } from '../store/subComponent/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchInputBox from '../SearchBox/SearchInputBox';
import { Suggestion } from '../store/search/types';
import { onItemSelectedPopUp } from '../store/search/actions';
import EditSubcomponentFormik from '../EditItems/EditSubComponentForm';
import { LookupState } from '../store/lookupData/types';
import { FormikActions, Field, Form } from 'formik';
const useStyles = makeStyles({
  subCompLabel: {
    marginBottom: "10px"
  },
  field: {
    marginBottom: "20px"
  },
  heightAdjust: {
    paddingBottom: "31px"
  },
  flexMain: {
    display: "flex",
    marginTop: "16px",
    marginBottom: "20px"
  },
  Child: {
    marginRight: "50px",
    width: "360px"
  },
  menuItem: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "0.9rem",
    minHeight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: "ghostwhite",
    paddingLeft: "15px",
    border: "1px solid lightgray",
    borderRadius: "4px",
    '&:hover': {
      textDecoration: "none",
      backgroundColor: "gainsboro",
      cursor: "pointer"
    },
  },
  menuContainer: {
    position: "absolute",
    zIndex: 999,
    //width: 552,
    maxHeight: 300,
    overflowY: "auto",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    //border: "1px solid lightgray",
    borderRadius: "4px",
    width: "96%",
    marginTop: "-20px",
  },
  nameColumn: {
    width: "50%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  typeColumn: {
    width: "50%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    //textAlign: "right"
  },
  red: {
    color: "red",
    display: "contents"
  },
  positionCF: {
    paddingTop: "8px",
  }
});

//interface FormProps {
//  data: any;
//  lookup: LookupState;
//  onSave: (values: any, formikActions: FormikActions<any>) => void;
//}
//const FormSelector = (props: FormProps) => {
//  const { data, lookup, onSave } = props;
//  return (
//    <div><EditSubcomponentFormik data={data[0]} lookup={lookup} onSave={onSave} /></div>
//  )

//}
const EditSubComponentDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { itemData } = useSelector((state: AppState) => state.editItem);
  const lookup = useSelector((state: AppState) => state.lookup);
  let index = localStorage.getItem('index');
  const subCompDetailsReducer = useSelector((state: any) => state.subComponentReducer);
  const subCompDetails = (subCompDetailsReducer.getSubComponentInfo.length > 0 && index) ? subCompDetailsReducer.getSubComponentInfo[index] : [];
  if (subCompDetailsReducer.getSubComponentInfo.length > 0) {
    localStorage.setItem('reducerData', JSON.stringify(subCompDetailsReducer.getSubComponentInfo));
  }
  let subcompName = (subCompDetailsReducer && subCompDetailsReducer.getSubComponentInfo.length > 0 && index) ? subCompDetailsReducer.getSubComponentInfo[index].subComponentName : "";
  if (subCompDetailsReducer.getSubcompForSearch.length > 0) {
    subcompName = subCompDetailsReducer.getSubcompForSearch[0].substanceName;
  }
  let parentWeight: any = (localStorage.getItem('parentMolWeight'));
  let isOpen = subCompDetailsReducer.open;
  const onclose = useCallback(
    () => {
      dispatch(closeSubcompEdit());
      dispatch(getSubComponentSearchRequest.request(""));
      localStorage.removeItem('index');
      localStorage.removeItem('parentMolWeight');
    },
    [dispatch]);
  const open = isOpen === "openSubCompEditDialog";

  //new changes added
  let object: Suggestion;
  const [objectData, setobjectData] = React.useState(subcompName);
  const [molWeightReq, setMolWeightReq] = React.useState(false);
  let [MolValue, setMolValue] = React.useState(subCompDetails.subComponentMolWeight);
  let [NoExp, setNoExp] = React.useState(subCompDetails.numberOfExpressedAs);
  let [Cf, setCf] = React.useState(subCompDetails.contributionFactor);
  //let NoExp = subCompDetails.numberOfExpressedAs;
  //let Cf = subCompDetails.contributionFactor;
  //const [molValue, setMolValue] = React.useState(subCompDetails.subComponentMolWeight);
  const dataSubstance = (item: Suggestion) => {
    object = item;
    setobjectData(item.name);
    setMolValue(item.type);
    if (item && NoExp) {
      setNoExp(subCompDetails.numberOfExpressedAs);
      setTimeout(() => {
        let valData: any = +((NoExp * +item.type) / +parentWeight);
        setCf(valData.toFixed(8));
      }, 700)
      //calculateCF(item.type, NoExp)
    }
    //setMolWeightReq(true);
  }
  //const calculateCF = (item: any, NoExp: any){

  //}
  const itemSelected = useCallback(
    (item: Suggestion) => { }
,      //dispatch(getMolWeightInfo(item.name)),
      //dispatch(getSubComponentList(item.name)),
    [dispatch]
  );
  let loadField: boolean = false;
  let [molWeightChange, setmolWeightChange] = React.useState();
  let molValChange: any = "";
  useEffect(() => {
    if (subCompDetailsReducer.getSubcompForSearch.length === 0) {
      loadField = true;
      setMolValue(subCompDetails.subComponentMolWeight);
      setNoExp(subCompDetails.numberOfExpressedAs);
      setCf(subCompDetails.contributionFactor);
      setobjectData(subcompName);
    } else {
      loadField = false
      // MolValue = "";
      // setmolWeightChange(subCompDetailsReducer.getSubcompForSearch[0].substanceMolWeight);
      //setMolValue(subCompDetailsReducer.getSubcompForSearch[0].substanceMolWeight);
      //setTimeout(() => {
      //  let valData: any = +((NoExp * +MolValue) / +parentWeight);
      //  setCf(valData.toFixed(8));
      //}, 700)
    }
  }, [subcompName]);

  const noChangeExp = (e: any) => {
    setNoExp(e); 
      setTimeout(() => {
        let valData: any = +((e * +MolValue) / parentWeight);
        setCf(valData.toFixed(8));
      }, 500);
  }
  //Function not in used hence commented
  /*const cfChange = (e: any) => {
    setCf(e.target.value);
  }*/
  const molWeightExpChange = (e: any) => {
    setMolValue(e);
    setTimeout(() => {
      let valData: any = +((NoExp * e) / parentWeight);
      setCf(valData.toFixed(8));
    }, 500);
  }
  //const onSaveSubComp = (subcompName: any, MolValue: any, NoExp: any, Cf: any) => {
  //  //const Name = name;
  //  //const CommentType = commentType;
  //  //const Description = description;
  //  //dispatch(saveSubComponentEdit(subcompName, MolValue, NoExp, Cf))
  //  dispatch<any>(getSubComponentList(subcompName))
  //}
  const putCommentApi = useCallback(
    (subcompName: any, MolValue: any, NoExp: any, Cf: any) => {
      //const Name = name;
      //const CommentType = commentType;
      //const Description = description;
      // dispatch(getSubComponentList(subcompName))
      dispatch(saveSubComponentEdit(subcompName, MolValue, NoExp, Cf))
    },
    [dispatch]
  );
  const onSaveSubComp = (subcompName: any, MolValue: any, NoExp: any, Cf: any) => {
    putCommentApi(subcompName, MolValue, NoExp, Cf);
  }
  return (
    <Dialog open={open} onClose={onclose} fullWidth={true} maxWidth="lg" >
      <MuiDialogTitle>
        <Grid justify="space-between" container spacing={6}>
          <Grid item>
            Edit Sub Component: {objectData}
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>

      <DialogContent >
        <Form>
          { //<FormSelector data={subCompDetails.getSubComponentInfo} lookup={lookup} onSave={onSave} />
          }
          <InputLabel className={classes.subCompLabel}>Sub-Component Expressed As</InputLabel>
          <SearchInputBox
            itemSelected={itemSelected}
            //className={classes.field}
            value={{ name: subcompName, type: "", subType: "", status: "", substanceId: "" }}
            filter="subcomponent"
            dataSubstance={dataSubstance}
          />
          <p className={classes.heightAdjust}></p>
          <div className={classes.flexMain}>
            <div className={classes.Child}>
              <InputLabel>Mol. Weight of Expressed As</InputLabel>
              { // <div> <input type="text" id="name1" value={MolValue} onChange={molWeightExpChange} /></div>
              }
              <TextField
                margin="dense"
                // component={TextField}
                type="number"
                fullWidth
                className={classes.field}
                name="subComponentMolWeight"
                //defaultValue={data.subComponentMolWeight}
                value={MolValue}
                required
                onChange={(e: any) => molWeightExpChange(e.target.value)}
              />
            </div>
            <div className={classes.Child}>
              <InputLabel>Number of Expressed As</InputLabel>
              <TextField
                type="number"
                margin="dense"
                name="numberOfExpressedAs"
                //onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                fullWidth
                //component={TextField}
                value={NoExp}
                required
                onChange={(e: any) => noChangeExp(e.target.value)}
                className={classes.field}
               // defaultValue={subCompDetails.numberOfExpressedAs}
              />
            </div>
            <div className={classes.Child}>
              <InputLabel>Contribution Factor </InputLabel>
              <div className={classes.positionCF} > {Cf}</div>

            </div>
          </div>
          <Button type="button" color="primary" disabled={objectData === ""} onClick={(e) => onSaveSubComp(objectData, MolValue, NoExp, Cf)}>
            Save
      </Button>
        </Form>
        <div style={{ color: 'red' }}>* All Fields are Mandatory</div>
      </DialogContent>
    </Dialog>
  );
}
export default EditSubComponentDialog;
