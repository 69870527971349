import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { closeMerge, saveMerge } from '../store/mergeItems/actions';
import MergeItemsForm from './MergeItemsForm';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  titleDiv: {
    marginBottom: "-36px"
  }
}) 

const AddNewItemDialog = () => {
  const mergeState = useSelector((state: AppState) => state.mergeItems);
  const dispatch = useDispatch();

  const onClose = useCallback(
    () => dispatch(closeMerge()),
    [dispatch]
  );

  const onSave = useCallback(
    (values) => dispatch(saveMerge(values)),
    [dispatch]
  );

  const open = mergeState.show;
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
        <Grid justify="space-between" container spacing={6} className={classes.titleDiv}>
          <Grid item>
            Merge Items
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent>
        <MergeItemsForm values={mergeState} onSave={onSave} />
      </DialogContent>
    </Dialog>
  );
}

export default AddNewItemDialog;
