import React from 'react';
import AddSubstanceToCompositePanel from './AddSubToComposite';
import { Select, MenuItem, TextField, Grid, FormControl, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppState } from '../store';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: "12px",
    padding: "0px",
  },
  mainDiv: {
    marginTop: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    borderWidth: "1px",
    borderColor: "lightgray",
    borderStyle: "solid",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  pad: {
    paddingTop: "25px",
    paddingLeft: "15px",
    paddingRight: "20px",
    paddingBottom: "15px",
    fontSize: '16px',
    fontWeight: 500,
    color: 'gray'
  },
  padEmail: {
    paddingTop: "25px",
    fontSize: '16px',
    fontWeight: 500,
    color: 'gray',
    paddingLeft: "15px",
    paddingBottom: "15px"
  },
  flex: {
    display: "flex"
  },
    textStrike:{
      marginTop:"20px",
      paddingLeft: "15px"
  },
  para: {
    margin: '0px',
    color: 'red',
    paddingLeft: "15px"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    
  },
  chip: {
    margin: 2,
    height: 26
  },
  nocursor: {
    pointerEvents: "none",
  }
}));

const AddComponentToCompositePanel = (props: any) => {
  const classes = useStyles();

  const [restrictedToError, setRestrictedToError] = React.useState(false);
  const getRestrictionData = useSelector((state: AppState) => state.editItem.restrictionData);
  const isEdit = localStorage.getItem('editComposite');
  const [Domains, setDomains] = React.useState(isEdit ? getRestrictionData.data.groupName : []);
  const [emailEdit, setEmailEdit] = React.useState((isEdit && getRestrictionData.data.email)? getRestrictionData.data.email.join(';') : '');
  const [ifDropDownSelected, setIfDropDownSelected] = React.useState(Domains.length > 0 ? true: false);
  const [ifEmailEntered, setIfEmailEntered] = React.useState(emailEdit ? true : false);
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const itemDetail = detailData.itemDetail;
  const dataHolder = (e: any) => {
    props.dataFromParent(e);
  }
  const DomainList = [
    'BW',
    'HC',
    'PC',
    'PLM',
    'Regulatory',
    'Safety',
  ];

  const handleChange = (event: any) => {
    setDomains(event.target.value);
    if (event.target.value.length > 0) {
      setIfDropDownSelected(true);
    } else {
      setIfDropDownSelected(false);
    }
    localStorage.setItem('restricteddomains', JSON.stringify(event.target.value));

  };
  const handleRestrictedToChange = (event: any) => {
    if (event.target.value === '') {
      setIfEmailEntered(false);
      setRestrictedToError(false);
    } else {
      setIfEmailEntered(true);
      setRestrictedToError(true);
    }
    var emails = event.target.value.split(";");
    let invalidEmails = [];
    for (let i = 0; i < emails.length; i++) {
      if (!validateMultipleEmails(emails[i].trim())) {
        invalidEmails.push(emails[i].trim())
      }
    }
    if (invalidEmails.length > 0 && event.target.value !== '') {
      setRestrictedToError(true);
    } else {
      localStorage.setItem('restrictredemails', JSON.stringify(emails));
      setRestrictedToError(false);
    }
  }
  const validateMultipleEmails = (e: string) => {
    var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(e);
  };
  return (
    <React.Fragment>

      <Grid className={(isEdit && itemDetail.compositeNames === null) ? [classes.mainDiv, classes.nocursor, "flex-div"].join(" ") : [classes.mainDiv, "flex-div"].join(" ")}>

        <Grid item lg={12} xs={12} sm={12} md={12}>
          <div className={classes.flex}>
          <div className={classes.pad} >
            Restricted Domains:
        </div>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={Domains}
              onChange={handleChange}
              className={[classes.selectEmpty,"set-width"].join(" ")}
              disabled={(isEdit && itemDetail.compositeNames === null) || ifEmailEntered}
              renderValue={(selected: any) => (
                <div className={classes.chips}>
                  {selected.map((value: any) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {DomainList.map((domain) => (
                <MenuItem key={domain} value={domain} >
                  {domain}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            </div>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <div className={classes.flex}>
          <div className={classes.padEmail} >
            Named list of users:
        </div>
          <div>
            <TextField
              type="text"
              placeholder="Enter the email address seperated by semicolon ';' e.g: username@unilever.com"
              disabled={ifDropDownSelected}
                className={[classes.textStrike,"set-width-email"].join(" ")}
              onChange={e => { setEmailEdit(e.target.value) }}
              onBlur={e => { handleRestrictedToChange(e) }}
              value={emailEdit}
            //label=" Id" variant="outlined"
            />
            {restrictedToError ? <p className={classes.para} >One or More Email Addresses are Invalid</p> :
              null}
            </div>
            </div>
        </Grid>
      </Grid>
      
      <AddSubstanceToCompositePanel data={dataHolder}/>
      </React.Fragment>
  )
}

export default AddComponentToCompositePanel;
