import React from 'react';
import { Field, FieldProps } from 'formik';
import { defaultTranslations } from './textTranslation';
import EnhancedTextInput from './EnhancedTextInput';

interface Props {
  name: string;
  [x: string]: any;
}

const EnhancedTextInputfield = (props: Props) => {
  const { name, ...rest } = props;
  return (
    <Field name={name}>
      {(formik: FieldProps) => {
        const { field, form } = formik;
        const name = localStorage.getItem('nameComposite')
        return (
          <EnhancedTextInput
            value={field.value}
            onChange={(e) => { form.setFieldValue(field.name, e.currentTarget.value); form.setFieldTouched(field.name); }}
            translations={defaultTranslations}
            inputProps={{ name, ...rest }} />
        )
      }}
    </Field>
  )
}

export default EnhancedTextInputfield;
