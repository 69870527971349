import { createReducer } from 'typesafe-actions';
import { ImportExportState } from "./types";
import * as actions from './actions';
import { substanceDetailNodeRequest } from '../itemDetail/actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: ImportExportState = {
  importFileName: "",
  importFile: null,
  importResult: null,
  inProgress: false,
  activeNode: null,
  renderKey: "import"
};

export const importExportReducer = createReducer(initialState)
.handleAction(actions.importRequestAction.request, (state, action) => ({...state, inProgress: true }))
  .handleAction(actions.importRequestAction.success, (state, action) => ({ ...state, inProgress: false, renderKey: `import-${uuidv4()}`, importResult: action.payload, importFileName: "" }))
  .handleAction(actions.importRequestAction.failure, (state, action) => ({ ...state, inProgress: false, renderKey: `import-${uuidv4()}` }))
.handleAction(actions.exportRequestAction.request, (state, action) => ({...state, inProgress: true }))
.handleAction(actions.exportRequestAction.success, (state, action) => ({...state, inProgress: false }))
.handleAction(actions.exportRequestAction.failure, (state, action) => ({...state, inProgress: false }))
.handleAction(substanceDetailNodeRequest.success, (state, action) => ({...state, activeNode: { name: action.payload.substanceName, type: action.payload.type }}))
.handleAction(actions.importFileDrop, (state, action) => ({...state, inProgress: false, importFileName: action.payload ? action.payload.name : "", importFile: action.payload, importResult: null }))
;
