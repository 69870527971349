import { AuthenticationActions } from 'react-aad-msal';
import {
  UserState,
} from "./types";
import { AnyAction } from 'redux';
import { userRequest, userDomain, userDomainRequest } from './actions';
import { getType } from 'typesafe-actions';


const initialState: UserState = {
  userName: "",
  isAdmin: false,
  userDomain: ['', ''],
};

export function userReducer(state = initialState, action: AnyAction): UserState {
  switch (action.type) {
    case getType(userRequest.success):
      return {
        ...state,
        userName: action.payload.userName,
        isAdmin: action.payload.isAdmin,
        userDomain: action.payload.userDomain
      };
    case getType(userDomainRequest.success):
      return {
        ...state,
        userDomain: action.payload.userDomain
      }
    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        userName: action.payload.account.userName,
      };
    case AuthenticationActions.LoginError:
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        userName: "Login error",
      };
    default:
      return state;
  }
}
