import { createAsyncAction, createStandardAction } from "typesafe-actions";
import { Dispatch } from "redux";
import axios from 'axios';
import { AppState } from "..";
import { makeSuccessMessage } from "../error/actions";

export const getAllFlagRequest = createAsyncAction(
  "GETALLFLAG_REQUEST_START",
  "GETALLFLAG_REQUEST_SUCCESS",
  "GETALLFLAG_REQUEST_FAILURE")<'', any, string>();

export const nameFlagRequest = createAsyncAction(
  "NAME_FLAG_REQUEST_START",
  "NAME_FLAG_REQUEST_SUCCESS",
  "NAME_FLAG_REQUEST_FAILURE")<string, any, string>();

export const enableFlagRequest = createAsyncAction(
  "ENABLE_FLAG_REQUEST_START",
  "ENABLE_FLAG_REQUEST_SUCCESS",
  "ENABLE_FLAG_REQUEST_FAILURE")<string, any, string>();

export const disableFlagRequest = createAsyncAction(
  "DISABLE_FLAG_REQUEST_START",
  "DISABLE_FLAG_REQUEST_SUCCESS",
  "DISABLE_FLAG_REQUEST_FAILURE")<string, any, string>();

export const closeFlagRequest = createStandardAction("FLAG_CLOSE_STRUCT")<void>();
export const openFlagRequest = createStandardAction("FLAG_OPEN_STRUCT")<any>();

//export const closeSubcompEditRequest = createStandardAction("SUBCOMP_CLOSE_STRUCT")<void>();
//export const openSubCompEditRequest = createStandardAction("SUBCOMP_OPEN_STRUCT")<any>();

//get all flagged details api call
export const getAllFlaggedInfo = () => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const name = localStorage.getItem('name');
    dispatch(getAllFlagRequest.request(''));
    axios.get('/api/v1/Flag')
      .then((response) => {
        dispatch(getAllFlagRequest.success(response.data));
        if (name !== null) {
          dispatch<any>(getNameFlag(name));
        }
      })
      .catch((error: any) => {

      });
  }
}
//get flagged/notflagged response for composite/substance api call
export const getNameFlag = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(nameFlagRequest.request(""));
    axios.get(`/api/v1/Flag/NameFlag?Name=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(nameFlagRequest.success(response.data));
      })
      .catch((error: any) => {

      });
  }
}
//disable flag api call
export const disableFlag = (name: string, description: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    disableFlagRequest.request(name);
    axios.put(`/api/v1/Flag/DisableFlag`, {name: name, description: description})
      .then((response) => {
        dispatch(disableFlagRequest.success(response.data));
        dispatch(makeSuccessMessage("flag Item reviewed and disabled"));
        dispatch<any>(getAllFlaggedInfo());
      })
      .catch((error: any) => {

      });
  }
}
//enable flag api call
export const enableFlag = (name: string, description: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    enableFlagRequest.request(name);
    axios.put(`/api/v1/Flag/EnableFlag`, { name: name, description: description })
      .then((response) => {
        dispatch(enableFlagRequest.success(response.data));
        dispatch(makeSuccessMessage("item Flagged for review"));
        dispatch<any>(getAllFlaggedInfo());
      })
      .catch((error: any) => {

      });
  }
}

export const closeFlag = () => {
  return closeFlagRequest();
}

export const openFlag = () => {
  return openFlagRequest("openDialog");
}


//export const closeSubcompEdit = () => {
//  return closeSubcompEditRequest();
//}

//export const openSubCompEdit = () => {
//  return openSubCompEditRequest("openSubCompEditDialog");
//}
