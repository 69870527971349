import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import SaveIcon from "@material-ui/icons/SaveAlt";
import { AppState } from '../store';
import { Button, CircularProgress } from '@material-ui/core';
import { exportNode } from '../store/importExport/actions';

const useStyles = makeStyles(theme => ({
  button: {
  },
  icon: {
    width: 16,
    height: 16
  },
}));

interface Props  {
  type: string;
  name: string;
  disableExport?: boolean;
};

const ExportButton = (props: Props) => {
  const classes=useStyles();
  const { name, type, disableExport } = props;
  const { inProgress } = useSelector((state: AppState) => state.importExport)
  const dispatch = useDispatch();
  const onExport = useCallback( () => {
      dispatch(exportNode(name, type));
  }, [dispatch, name, type]);

  return (
    <span>
      <Button size="small" variant="text" className={classes.button} onClick={onExport} title="Export to CSV" disabled={!name || !type || disableExport} >
        <SaveIcon className={classes.icon} />
      </Button>
      {inProgress && <CircularProgress size={20} /> }
    </span>
  )
}

export default ExportButton;
