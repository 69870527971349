import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import {DropzoneArea} from 'material-ui-dropzone';
import { Paper, Grid, Button, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Theme, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import SaveIcon from "@material-ui/icons/SaveAlt";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fileDrop, importFile, exportReport } from '../store/importExport/actions';

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    width: "300px",
    borderRadius: 5,
    borderWidth: 2,
    padding: "10px",
    minHeight: "0px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: "500px"
  },
  button: {},
  icon: {
    width: 16,
    height: 16
  },
}));

const ImportPanel = () => {
  const classes=useStyles();
  const importExportData = useSelector((state: AppState) => state.importExport);
  const user = useSelector((state: AppState) => state.user);
  const reportUrl = importExportData.importResult ? importExportData.importResult.reportUrl : null;
  const dispatch = useDispatch();

  const onDropChange = useCallback( (files: any) => {
    dispatch(fileDrop(files));
  }, [dispatch]);

  const onImport = useCallback( () => {
    dispatch(importFile());
  }, [dispatch]);

  const onReportExport = useCallback( () => {
    if (reportUrl) {
      dispatch(exportReport(reportUrl));
    }
  }, [dispatch, reportUrl]);

  return (
    <Paper>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>File Import</Typography>
        </ExpansionPanelSummary>
      <ExpansionPanelDetails >
        {user.isAdmin ?
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <DropzoneArea dropzoneClass={classes.dropZone}
                onChange={onDropChange}
                key={importExportData.renderKey}
                filesLimit={1}
                acceptedFiles={['text/csv', 'application/vnd.ms-excel']}
                  dropzoneText={`Drop an import file here or click to browse for files`}
                showAlerts={false}
                showFileNamesInPreview={true}>
              </DropzoneArea>
          </Grid>
          <Grid item>
            <div>{importExportData.importFileName || "No file"}</div>
            <Button onClick={onImport} variant="contained" disabled={!importExportData.importFileName} >Import</Button>
          </Grid>
          <Grid item>
            {importExportData.inProgress && importExportData.importFileName &&
              <div>Loading...
                <CircularProgress />
              </div>
            }
          </Grid>
          <Grid item>
            {importExportData.importResult &&
            <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow key="name">
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">{importExportData.importResult.name}</TableCell>
              </TableRow>
              <TableRow key="errorCount">
                <TableCell align="right">Errors</TableCell>
                <TableCell align="right">{importExportData.importResult.errorCount}</TableCell>
              </TableRow>
              <TableRow key="substanceCount">
                <TableCell align="right">Substances</TableCell>
                <TableCell align="right">{importExportData.importResult.substanceCount}</TableCell>
              </TableRow>
              <TableRow key="nameCount">
                <TableCell align="right">Names</TableCell>
                <TableCell align="right">{importExportData.importResult.nameCount}</TableCell>
              </TableRow>
              <TableRow key="classificationCount">
                <TableCell align="right">Classifications</TableCell>
                <TableCell align="right">{importExportData.importResult.classificationCount}</TableCell>
              </TableRow>
              <TableRow key="linkCount">
                <TableCell align="right">Links</TableCell>
                <TableCell align="right">{importExportData.importResult.linkCount}</TableCell>
              </TableRow>
              <TableRow key="reportDownload">
                <TableCell align="right">Report Download</TableCell>
                <TableCell align="right">
                  <Button size="small" variant="text" className={classes.button} onClick={onReportExport} title="Download a row-by-row report" >
                    CSV Report
                    <SaveIcon className={classes.icon} />
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
            </Table>
                }
          </Grid>
        </Grid> : "Import is only available to Admin users" }
      </ExpansionPanelDetails>
    </ExpansionPanel>
    </Paper>
  );
}

export default ImportPanel;
