import React, { } from 'react';
import MaterialTable from 'material-table';
import SearchInputBox from '../SearchBox/SearchInputBox';
import { useDispatch } from 'react-redux';
import { Suggestion } from '../store/search/types';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles({

  subCompLabel: {
    marginBottom: "10px"
  },
  field: {
    marginBottom: "20px"
  },
  flexMain: {
    display: "flex",
    marginTop: "20px"
  },
  Child: {
    marginRight: "50px",
    width: "360px"
  },
  menuItem: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "0.9rem",
    minHeight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: "ghostwhite",
    paddingLeft: "15px",
    border: "1px solid lightgray",
    borderRadius: "4px",
    '&:hover': {
      textDecoration: "none",
      backgroundColor: "gainsboro",
      cursor: "pointer"
    },
  },
  menuContainer: {
    position: "absolute",
    zIndex: 999,
    //width: 552,
    maxHeight: 300,
    overflowY: "auto",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    //border: "1px solid lightgray",
    borderRadius: "4px",
    width: "96%",
    marginTop: "-20px",
  },
  nameColumn: {
    width: "50%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  typeColumn: {
    width: "50%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    //textAlign: "right"
  },
  red: {
    color: "red",
    display: "contents"
  },
  searchboxWidth: {
    minWidth: 396
  }
});

export default function AddContributionTable(props: any) {
  //const classes = useStyles();
  const [state, setState] = React.useState({ data: props.dataLoad });
  const [nameReq, setNameReq] = React.useState(false);
  const [cFactor, setCFactor] = React.useState(false);
  const [numberOfReq, setNumberOfReq] = React.useState(false);
  const [molWeightReq, setMolWeightReq] = React.useState(false);
  const dispatch = useDispatch();
  const [objectData, setobjectData] = React.useState<any>();
  let [cfval, setCfVal] = React.useState<any>();
  const [showEmptyMsg, setshowEmptyMsg] = React.useState(false);
  const [noexpVal, setNoExpVal] = React.useState('');
  const itemSelected = (item: any) => {
  }
  const dataSubstance = (item: Suggestion) => {
    setobjectData(item);
    setMolWeightReq(true);
    setNameReq(false);
    if (noexpVal) {
      calculateValOnChangeName(noexpVal,item);
    }
  }
  const calculateValOnChangeName = (val: any, item: any) => {
    //clearTimeout(typingTimer);
    //typingTimer = setTimeout(() => {
      setNoExpVal(val);
      setCFactor(false);
      if (item && props.molWeightParent && props.parentName.name) {
        let valData: any = (+(val * (+item.type)) / (+props.molWeightParent));
        setCfVal(valData.toFixed(8));
        setshowEmptyMsg(false);
      } else {
        setshowEmptyMsg(true);
      }
    //}, 2000);
  }
  props.selectedItem(state.data);
  let typingTimer: any = null;

  const calculateVal = (val: any) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      setNoExpVal(val);
      setCFactor(false);
      if (objectData && props.molWeightParent && props.parentName.name) {
        let valData: any = (+(val * (+objectData.type)) / (+props.molWeightParent));
        setCfVal(valData.toFixed(8));
        setshowEmptyMsg(false);
      } else {
        setshowEmptyMsg(true);
      }
    }, 1500);
  }
  return (
    <div>
      {showEmptyMsg ? <p className='validationErrorMsg'>Please fill parent entity details before saving contribution factors.</p> : null}
      <MaterialTable
        title="Add Contribution Factors with Sub-components"
        options={{
          filtering: false
        }}
        columns={[
          {
            title: 'Name of the substance or sub-components expressed as', field: 'name',
            editComponent: (props) => (
              <div className="search-box-cf">
                {nameReq ? <div><SearchInputBox
                  itemSelected={itemSelected}
                  value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                  filter="subcomponent"
                  dataSubstance={dataSubstance} /><p className='validationErrorMsg'>* Required</p></div> :
                  !molWeightReq ? <SearchInputBox
                    itemSelected={itemSelected}
                    value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                    filter="subcomponent"
                    dataSubstance={dataSubstance} />
                    : <SearchInputBox
                      itemSelected={itemSelected}
                      value={{ name: objectData.name ? objectData.name : props.value, type: "", subType: "", status: "", substanceId: "" }}
                      filter="subcomponent"
                      dataSubstance={dataSubstance}
                    />}

              </div>
            )
          },
          {
            title: 'Mol. Weight of Expressed as', field: 'molweight',
            editComponent: (props) => (
              molWeightReq ? <div>{objectData.type}</div> : <div>{props.value}</div>
            )
          },
          {
            title: 'Number of  Expressed as', field: 'numberof',
            editComponent: (props) => (
              numberOfReq ? <div> < TextField
                type="number"
                placeholder='Number of'
                value={props.value ? props.value : ''}
                onChange={e => { props.onChange(+e.target.value); calculateVal(+e.target.value) }}
              /><p className='validationErrorMsg'>* Enter a positive number</p></div> : < TextField
                  type="number"
                  placeholder='Number of'
                  value={props.value ? props.value : ''}
                  onChange={e => { props.onChange(+e.target.value); calculateVal(+e.target.value) }}
                />
            )
          },
          {
            title: 'Contribution Factor', field: 'contributionFactor',
            editComponent: (props) => (
              cFactor ? <div className='validationErrorMsg'>Please fill parent and subcomponent required details</div> :
                <div>{cfval}</div>
            )
          }
        ]}
        data={state.data}
        editable={{
          onRowUpdateCancelled: (rowData) => {
            setNumberOfReq(false);
            setNameReq(false);
            setCFactor(false);
            setMolWeightReq(false);
            localStorage.removeItem('searchType');
            setshowEmptyMsg(false);
            setNoExpVal('');
          },
          onRowAddCancelled: (rowData) => {
            setNumberOfReq(false);
            setNameReq(false);
            setCFactor(false);
            setMolWeightReq(false);
            localStorage.removeItem('searchType');
            setshowEmptyMsg(false);
            setNoExpVal('');
          },
          onRowAdd: (newData: any) =>
            new Promise<void>((resolve, reject) => {
              if (objectData) {
                newData.name = objectData.name
                newData.molweight = objectData.type;
                newData.contributionFactor = cfval;
              }
              setNumberOfReq(false);
              setNameReq(false);
              setCFactor(false);
              setMolWeightReq(false);
              setNoExpVal('');
              if (newData.name === '' || newData.name === undefined || newData.contributionFactor === '' || newData.contributionFactor === undefined
                || newData.contributionFactor <= 0 || newData.numberof === '' || newData.numberof === undefined || newData.numberof <= 0 || props.parentName.name === "") {
                if (newData.name === '' || newData.name === undefined) {
                  setNameReq(true);
                }
                if (newData.contributionFactor === '' || newData.contributionFactor === undefined || newData.contributionFactor <= 0) {
                  setCFactor(true);
                }
                if (newData.numberof === '' || newData.numberof === undefined || newData.numberof <= 0) {
                  setNumberOfReq(true);
                }
                if (props.parentName.name === "") {
                  setshowEmptyMsg(true);
                }
                reject();
                return;
              }
              localStorage.removeItem('searchType');

              resolve();
              setState((prevState: any) => {
                setCfVal("");
                setobjectData("");
                setMolWeightReq(false);
                setshowEmptyMsg(false);
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }),

          onRowDelete: (oldData: any) =>
            new Promise<void>((resolve) => {
              resolve();
              setNoExpVal('');
              setState((prevState: any) => {
                localStorage.removeItem('searchType');
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            })
        }}
      />
    </div>
  )


}
