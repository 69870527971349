//import { makeStyles } from "@material-ui/styles";
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  backdrop: {
    zIndex: 0,
    color: '#fff',
  },
    root: {
      flexGrow: 1,
      marginTop: 15
    },
    paper: {
      //padding: 5,
      //textAlign: 'center' as 'center'
    },
    paperSize: {
        marginTop: 10,
        height: 400,
    },
    input: {
      marginLeft: 5,
      flex: 1,
    },
    paperRoot: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center"
    },
});

export const useButtonStyles = makeStyles({
    rightIcon: {
        marginLeft: 5,
    },
    button: {
        margin: "5px!important",  // Shame to have to use "important" but when I removed all buttons from overview page (so they are only in child components), they ended up having their margin overridden by buttonbase. I don't understand why that happened, but this brings it back up.
    },
  });

  export default useStyles;
