import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddForm from './AddForm';
import AddNameForm from './AddNameForm';
import AddLinkForm from './AddLinkForm'
import UnlinkItemsForm from './UnlinkItemsForm';
import { FormConfig, AddFormConfig, AddNameFormConfig, AddLinkFormConfig } from '../store/entity/types';
import { Grid } from '@material-ui/core';
import SwapSynonymSubstanceForm from './swapsynonymSubstance';

type Props = {
  open: boolean,
  config: FormConfig
}

const AddDialog = (props: Props) => {
  const { open, config } = props;
  return (
    <Dialog open={open} onClose={config.onClose} fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
      <Grid justify="space-between" container spacing={6}>
      <Grid item>
        {config.title}
        </Grid>
        <Grid item>
        {config.onClose ? (
          <IconButton aria-label="close" onClick={config.onClose}>
            <CloseIcon />
          </IconButton>
          )
          : null}
        </Grid>
      </Grid>
      </MuiDialogTitle>
      <DialogContent>
        {(config.formType === "AddClassification" || config.formType === "AddSubstance") ?
          (<AddForm config ={config as AddFormConfig} />) : null}
        {(config.formType === "AddSubstanceName") ?
          (<AddNameForm config ={config as AddNameFormConfig}/>) : null}
        {(config.formType === "AddLink") ?
          (<AddLinkForm config ={config as AddLinkFormConfig}/>) : null}
        {(config.formType === "Unlink") ?
          (<UnlinkItemsForm config={config as AddLinkFormConfig} />) : null}
        {(config.formType === "Swap") ?
          (<SwapSynonymSubstanceForm config={config as any} />) : null}
      </DialogContent>
    </Dialog>
  );
}

export default AddDialog;
