import React from 'react';
import './App.css';
import MainAppBar from './AppBar/MainAppBar';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme/SIRtheme';
import { createBrowserHistory } from 'history';
import configureStore from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';
import AuthWrapper from './AuthWrapper';

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
console.log(history.location, history)
const store = configureStore(history);

class App extends React.Component {

  render() {
    return (
        <div>
        <AuthWrapper store={store} >
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={theme}>
                <MainAppBar />
                <Routes />
              </ThemeProvider>
            </ConnectedRouter>
          </Provider>
        </AuthWrapper>
        </div>
    );
  }
}

export default App;
