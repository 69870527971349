import React from 'react';
import { Field, FieldProps } from 'formik';
import DomainEdit, { DomainItemEditState } from './DomainEdit';
import { AppState } from '../store';
import { useSelector } from 'react-redux';

interface Props {
  name: string;
  allDomains: string[];
  selectedVal?: any | undefined | null;
  values?: any | undefined | null;
}

const makeDomainEditItems = (allDomains: string[], selectedDomains: string[]): DomainItemEditState[] => {
  localStorage.setItem('selectedDomainsForEdit', JSON.stringify(selectedDomains));
  return allDomains.map(i => ({ name: i, selected: selectedDomains.includes(i) }))
}

export const updateSelectedItems = (selectedDomains: string[], changed: DomainItemEditState): string[] => {
  return changed.selected ?
    [...selectedDomains, changed.name] :
    selectedDomains.filter(i => i !== changed.name);
}

const DomainEditField = (props: Props) => {
  const { name, allDomains, values } = props;
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const itemDetail = detailData.itemDetail;
  const value = localStorage.getItem('editComposite');
  const value1 = localStorage.getItem('editSubstance');
  const substanceName = localStorage.getItem('editSubName');

  let data = (value: any) => {
      props.selectedVal(value);   
  }

  let domainsPreSelected: any = []; 
  if (value === 'true' || value1 === 'true') {
    //domainsPreSelected = itemDetail.names[1].domains;
    if (value === 'true') {
      for (let i = 0; i < itemDetail.names.length; i++) {
        if (itemDetail.names[i].name === itemDetail.backboneIdentity) {
          domainsPreSelected = itemDetail.names[i].domains;
          break;
        }
      }
    }
    if (value1 === 'true') {
      for (let i = 0; i < itemDetail.names.length; i++) {
        if (itemDetail.names[i].name === substanceName) {
          domainsPreSelected = itemDetail.names[i].domains;
          break;
        }
      }
    }

  return (
    <Field
      name={name}
      render={(props: FieldProps) => {
        const { field, form } = props;
        return (
          <DomainEdit
            title="Link in domains:"
            items={makeDomainEditItems(allDomains, field.value || domainsPreSelected)}
            onChange={(item) => {
              form.setFieldValue(name, updateSelectedItems(field.value || domainsPreSelected , item));
              form.setFieldTouched(name);          
            }}
          />
        )
      }
      }
    />
  )
} else {
    return (
      <Field
        name={name}
        render={(props: FieldProps) => {
          const { field, form } = props;
          return (
            <DomainEdit
              title="Link in domains:"
              items={makeDomainEditItems(allDomains, field.value || [])}
              onChange={(item) => {
                form.setFieldValue(name, updateSelectedItems(field.value || [], item));
                form.setFieldTouched(name);
                data(item);
              }}
            />
          )
        }
        }
      />
    )
}
};

export default DomainEditField;
