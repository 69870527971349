import React from 'react';
import { Store, AnyAction} from 'redux';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './authProvider';
import { fakeUser } from './store/user/actions';

interface AuthWrapperProps {
  store: Store<any, AnyAction>,
  children: any;
}

const AuthWrapper = (props: AuthWrapperProps) => {
  const { store, children } = props;
  if (authProvider) {
    return (
      <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
        {children}
      </AzureAD>);
  }
  else {
    fakeUser()(store.dispatch);
    return children;
  }
}

export default AuthWrapper;
