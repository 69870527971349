import React, { useCallback } from 'react';
import { makeStyles } from "@material-ui/styles";
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { openSwapFlag } from '../store/swapBackbone/actions';

const useStyles = makeStyles(theme => ({
  button: {
  },
  icon: {
    width: 24,
    height: 24
  }
}));
type Props = {
  name: string;
  type: string;
  status: string;
};
export const SwapButton = (props: Props) => {
  const {name, type, status } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);

  //const showFlagDialog = () => {
  //  localStorage.setItem('openDialog', 'open');
  //}
  const showSwapDialog = useCallback(() => {
    dispatch(openSwapFlag());
  }, [dispatch]);
  return (
    <span>
      <Button
        size="small"
        variant="text"
        className={classes.button}
        title="Swap Backbone Name"
        //disabled={user.isAdmin ? (name === 'Loading...' || !type || !name) : (isNameFlag && isNameFlag.flagStatus === "A" || name === 'Loading...' || !type || !name)}
        disabled={((status === "I" || !user.isAdmin)) || (!name || !type)}
        onClick={showSwapDialog}
      >
        <SwapHorizIcon className={classes.icon} />
      </Button>
    </span>
  );
}
export default SwapButton;


