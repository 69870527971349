import axios from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import {
  AddFormValues,
  AddNameFormValues,
  AddLinkFormValues,
  AddClassificationPayload,
  ConvertFormValuesToClassification,
  AddSubstancePayload,
  ConvertFormValuesToSubstance,
  AddSubstanceNamePayload,
  ConvertFormValuesToSubstanceName,
  AddClassificationLinkPayload,
  ConvertFormValuesToClassificationLink,
  AddSubstanceLinkPayload,
  ConvertFormValuesToSubstanceLink,
  AddSubstanceClassificationLinkPayload,
  ConvertFormValuesToSubstanceClassificationLink,
  AddSubstanceNameLinkPayload,
  ConvertFormValuesToSubstanceNameLink,
  ConvertFormValuesToSubstanceLinkDel,
} from './types';
import {
  ADD_CLASSIFICATION_REQUEST_START, ADD_CLASSIFICATION_REQUEST_SUCCESS, ADD_CLASSIFICATION_REQUEST_FAILURE,
  ADD_SUBSTANCE_REQUEST_START, ADD_SUBSTANCE_REQUEST_SUCCESS, ADD_SUBSTANCE_REQUEST_FAILURE,
  ADD_SUBSTANCE_NAME_REQUEST_START, ADD_SUBSTANCE_NAME_REQUEST_SUCCESS, ADD_SUBSTANCE_NAME_REQUEST_FAILURE,
  ADD_CLASSIFICATION_LINK_REQUEST_START, ADD_CLASSIFICATION_LINK_REQUEST_SUCCESS, ADD_CLASSIFICATION_LINK_REQUEST_FAILURE,
  ADD_SUBSTANCE_LINK_REQUEST_START, ADD_SUBSTANCE_LINK_REQUEST_SUCCESS, ADD_SUBSTANCE_LINK_REQUEST_FAILURE,
  ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START, ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS, ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE,
  ADD_SUBSTANCE_NAME_LINK_REQUEST_START, ADD_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS, ADD_SUBSTANCE_NAME_LINK_REQUEST_FAILURE,
  REMOVE_CLASSIFICATION_LINK_REQUEST_START,
  REMOVE_CLASSIFICATION_LINK_REQUEST_SUCCESS,
  REMOVE_CLASSIFICATION_LINK_REQUEST_FAILURE,
  REMOVE_SUBSTANCE_LINK_REQUEST_START,
  REMOVE_SUBSTANCE_LINK_REQUEST_SUCCESS,
  REMOVE_SUBSTANCE_LINK_REQUEST_FAILURE,
  REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START,
  REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS,
  REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE,
  REMOVE_SUBSTANCE_NAME_LINK_REQUEST_START,
  REMOVE_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS,
  REMOVE_SUBSTANCE_NAME_LINK_REQUEST_FAILURE
} from './types';
import { makeError, makeSuccessMessage } from '../error/actions';
import { Dispatch } from 'redux';
import { AppState } from '..';
import { locateItem } from '../hierarchy/actions';

export const addClassificationRequest = createAsyncAction(ADD_CLASSIFICATION_REQUEST_START, ADD_CLASSIFICATION_REQUEST_SUCCESS, ADD_CLASSIFICATION_REQUEST_FAILURE)
  <string, AddClassificationPayload, string>();

export const addSubstanceRequest = createAsyncAction(ADD_SUBSTANCE_REQUEST_START, ADD_SUBSTANCE_REQUEST_SUCCESS, ADD_SUBSTANCE_REQUEST_FAILURE)
  <string, AddSubstancePayload, string>();

export const addSubstanceNameRequest = createAsyncAction(ADD_SUBSTANCE_NAME_REQUEST_START, ADD_SUBSTANCE_NAME_REQUEST_SUCCESS, ADD_SUBSTANCE_NAME_REQUEST_FAILURE)
  <string, AddSubstanceNamePayload, string>();

export const addClassificationLinkRequest = createAsyncAction(ADD_CLASSIFICATION_LINK_REQUEST_START, ADD_CLASSIFICATION_LINK_REQUEST_SUCCESS, ADD_CLASSIFICATION_LINK_REQUEST_FAILURE,)
  <string, AddClassificationLinkPayload, string>();

export const addSubstanceLinkRequest = createAsyncAction(ADD_SUBSTANCE_LINK_REQUEST_START, ADD_SUBSTANCE_LINK_REQUEST_SUCCESS, ADD_SUBSTANCE_LINK_REQUEST_FAILURE)
  <string, AddSubstanceLinkPayload, string>();

export const addSubstanceClassificationLinkRequest = createAsyncAction( ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START, ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS, ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE)
  <string, AddSubstanceClassificationLinkPayload, string>();

export const addSubstanceNameLinkRequest = createAsyncAction(ADD_SUBSTANCE_NAME_LINK_REQUEST_START, ADD_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS, ADD_SUBSTANCE_NAME_LINK_REQUEST_FAILURE)
  <string, AddSubstanceNameLinkPayload, string>();

  export const removeClassificationLinkRequest = createAsyncAction(REMOVE_CLASSIFICATION_LINK_REQUEST_START, REMOVE_CLASSIFICATION_LINK_REQUEST_SUCCESS, REMOVE_CLASSIFICATION_LINK_REQUEST_FAILURE,)
  <string, AddClassificationLinkPayload, string>();

export const removeSubstanceLinkRequest = createAsyncAction(REMOVE_SUBSTANCE_LINK_REQUEST_START, REMOVE_SUBSTANCE_LINK_REQUEST_SUCCESS, REMOVE_SUBSTANCE_LINK_REQUEST_FAILURE)
  <string, AddSubstanceLinkPayload, string>();

export const removeSubstanceClassificationLinkRequest = createAsyncAction( REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START, REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS, REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE)
  <string, AddSubstanceClassificationLinkPayload, string>();

export const removedSubstanceNameLinkRequest = createAsyncAction(REMOVE_SUBSTANCE_NAME_LINK_REQUEST_START, REMOVE_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS, REMOVE_SUBSTANCE_NAME_LINK_REQUEST_FAILURE)
  <string, AddSubstanceNameLinkPayload, string>();

export const AddClassification = (formValues: AddFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newClassification = ConvertFormValuesToClassification(formValues);
    addClassificationRequest.request(newClassification.name);
    axios.put('/api/v1/Classification/ClassificationName', newClassification)
      .then((response) => {
        dispatch(makeSuccessMessage("Classification successfully added"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({ name: newClassification.name, type: "Classification", subType: newClassification.classificationType, status: newClassification.status, substanceId: ""}))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addClassificationRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const AddSubstance = (formValues: AddFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newSubstance= ConvertFormValuesToSubstance(formValues);
    addSubstanceRequest.request(newSubstance.backboneIdentity);
    axios.put('/api/v1/Substances', newSubstance)
      .then((response) => {
        dispatch(makeSuccessMessage("Substance successfully added"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({ name: newSubstance.backboneIdentity, type: "Substance", subType: "", status: newSubstance.status, substanceId: newSubstance.backboneIdentity}))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const AddSubstanceName = (formValues: AddNameFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newSubstanceName = ConvertFormValuesToSubstanceName(formValues);
    addSubstanceNameRequest.request(newSubstanceName.name);
    axios.put('/api/v1/SubstanceName/AddSubstanceName', newSubstanceName)
      .then((response) => {
        dispatch(makeSuccessMessage("Substance name successfully added"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({ name: newSubstanceName.name, type: "Substance name", subType: newSubstanceName.nameType, status: newSubstanceName.status, substanceId: newSubstanceName.backboneIdentity}))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceNameRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const AddLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  if (formValues.childItem.type === "Classification" && formValues.parentItem.type === "Classification" && formValues.linkDomain)
  {
    return AddClassificationLink(formValues, doClose, setSubmitting);
  }
  else if (formValues.childItem.type === "Substance" && formValues.parentItem.type === "Substance" && formValues.linkDomain){
    return AddSubstanceLink(formValues, doClose, setSubmitting);
  }
  else if (formValues.childItem.type === "Substance" && formValues.parentItem.type === "Classification" && formValues.linkDomain){
    return AddSubstanceClassificationLink(formValues, doClose, setSubmitting);
  }
  else if (formValues.childItem.type === "Substance name" && formValues.parentItem.type === "Substance" && formValues.linkDomain){
    return AddSubstanceNameLink(formValues, doClose, setSubmitting);
  }
  else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      if (formValues.linkDomain == "") {
        dispatch(makeError("Cannot link. Please select Requestor's Domain value"));

      } else {
        if (!formValues.childItem.type) {
          dispatch(makeError(`Cannot link. Please select Child value.`));
        } else if (!formValues.parentItem.type) {
          dispatch(makeError(`Cannot link. Please select Parent value.`));
        } else {
          dispatch(makeError(`Cannot link ${formValues.childItem.type} to ${formValues.parentItem.type}`));
        }
      }
      setSubmitting(false);
    }
  }
}

export const AddClassificationLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToClassificationLink(formValues);
    addClassificationLinkRequest.request(newLink.parentClassificationName + " > " + newLink.childClassificationName);
    axios.put('/api/v1/Classification/ClassificationLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Classification link successfully added"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.parentClassificationName, type: "Classification", subType: "", status: "", substanceId: ""}, true, newLink.childClassificationName))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addClassificationLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const AddSubstanceLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToSubstanceLink(formValues);
    addSubstanceLinkRequest.request(newLink.parentSubstance + " > " + newLink.childSubstance);
    axios.put('/api/v1/Substances/AddSubstanceToSubstanceLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Substance link successfully added"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.parentSubstance, type: "Substance", subType: "", status: "", substanceId: ""}, true, newLink.childSubstance))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const AddSubstanceClassificationLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToSubstanceClassificationLink(formValues);
    addSubstanceClassificationLinkRequest.request(newLink.classificationName + " > " + newLink.substance);
    axios.put('/api/v1/Substances/NewSubstanceClassificationLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Parent-Child link successfully added"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.classificationName, type: "Classification", subType: "", status: "", substanceId: ""}, true, newLink.substance))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceClassificationLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const AddSubstanceNameLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToSubstanceNameLink(formValues);
    addSubstanceNameLinkRequest.request(newLink.backboneIdentity + " > " + newLink.substanceName);
    axios.put('/api/v1/SubstanceName/AddSubstanceNameLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Substance name link successfully added"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.backboneIdentity, type: "Substance", subType: "", status: "", substanceId: ""}, true, newLink.substanceName))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceNameLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const RemoveLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  if (formValues.childItem.type === "Classification" && formValues.parentItem.type === "Classification" && formValues.linkDomain)
  {
    return RemoveClassificationLink(formValues, doClose, setSubmitting);
  }
  else if (formValues.childItem.type === "Substance" && formValues.parentItem.type === "Substance" && formValues.linkDomain) {
    return RemoveSubstanceLink(formValues, doClose, setSubmitting);
  }
  else if (formValues.childItem.type === "Substance" && formValues.parentItem.type === "Classification" && formValues.linkDomain){
    return RemoveSubstanceClassificationLink(formValues, doClose, setSubmitting);
  }
  else if (formValues.childItem.type === "Substance name" && formValues.parentItem.type === "Substance" && formValues.linkDomain){
    return RemoveSubstanceNameLink(formValues, doClose, setSubmitting);
  }
  else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      if (formValues.linkDomain == "") {
        dispatch(makeError("Cannot link. Please select Requestor's Domain value"));

      } else {
        if (!formValues.childItem.type) {
          dispatch(makeError(`Cannot link. Please select Child value.`));
        } else if (!formValues.parentItem.type) {
          dispatch(makeError(`Cannot link. Please select Parent value.`));
        } else {
          dispatch(makeError(`Cannot link ${formValues.childItem.type} to ${formValues.parentItem.type}`));
        }
      }
      setSubmitting(false);
    }
  }
}

export const RemoveClassificationLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToClassificationLink(formValues);
    addClassificationLinkRequest.request(newLink.parentClassificationName + " > " + newLink.childClassificationName);
    axios.post('/api/v1/Classification/RemoveClassificationLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Classification link successfully removed"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.childClassificationName, type: "Classification", subType: "", status: "", substanceId: ""}, true, newLink.childClassificationName))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addClassificationLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const RemoveSubstanceLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToSubstanceLinkDel(formValues);
    addSubstanceLinkRequest.request(newLink.parentSubstanceName + " > " + newLink.childSubstanceName);
    axios.post('/api/v1/Substances/DeleteSubstanceLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Substance link successfully removed"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.childSubstanceName, type: "Substance", subType: "", status: "", substanceId: ""}, true, newLink.childSubstanceName))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const RemoveSubstanceClassificationLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToSubstanceClassificationLink(formValues);
    addSubstanceClassificationLinkRequest.request(newLink.classificationName + " > " + newLink.substance);
    axios.post('/api/v1/Substances/DeleteSubstanceClassificationLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Parent-Child link successfully removed"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.substance, type: "Substance", subType: "", status: "", substanceId: ""}, true, newLink.substance))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceClassificationLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}

export const RemoveSubstanceNameLink = (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const newLink = ConvertFormValuesToSubstanceNameLink(formValues);
    addSubstanceNameLinkRequest.request(newLink.backboneIdentity + " > " + newLink.substanceName);
    axios.put('/api/v1/SubstanceName/DeleteSubstanceNameLink', newLink)
      .then((response) => {
        dispatch(makeSuccessMessage("Substance name link successfully removed"));
        setSubmitting(false);
        doClose();
        dispatch<any>(locateItem({name: newLink.backboneIdentity, type: "Substance", subType: "", status: "", substanceId: ""}, true, newLink.backboneIdentity))
      })
      .catch((error: any) => {
        dispatch(makeError(error));
        dispatch(addSubstanceNameLinkRequest.failure(error.message));
        setSubmitting(false);
    });
  };
}
export const AddContributionFactor = (formValues: any, doClose: Function, setSubmitting: Function, val: boolean) => {
  let data: any = localStorage.getItem('addTableData');
  let dataVal: any;
  if (data) {
    dataVal = JSON.parse(data);
  }
  if (formValues.parentItem.name === "") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Cannot Save data. Please add Parent entity details'));
      setSubmitting(false);
    }
  } else if (formValues.moleculeWeight === "") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Cannot Save data. Mol. Weight of the selected substance doesn’t exist in SIR, please be sure to add accurate value'));
      setSubmitting(false);
    }
  } else if (data === null || data === '[]') {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Cannot Save data. Please add contribution factors'));
     setSubmitting(false);
    }
  } else {
    let componentData: any = [];
    return (dispatch: Dispatch, getState: () => AppState) => {
      for (let i = 0; i < dataVal.length; i++) {
        let obj: any = {
          subComponentName: dataVal[i].name,
          subComponentMolWeight: dataVal[i].molweight.toString() ? dataVal[i].molweight.toString() : 0,
          numberOfExpressedAs: dataVal[i].numberof.toString(),
          contributionFactor: dataVal[i].contributionFactor.toString(),
          status: "A"
        }
        componentData.push(obj);
      }
         const newLink = {
           substanceName: formValues.parentItem.name,
           substanceMolWeight: formValues.moleculeWeight,
           status: formValues.linkStatus,
           component: componentData,
           auditDescription: formValues.auditDescription
      }
      if (val) {
           axios.post('/api/v1/ContributionFactor/EditSubComponent', newLink)
          .then((response) => {
            dispatch(makeSuccessMessage("Contribution factors are updated successfully"));
            setSubmitting(false);
            doClose();
            localStorage.removeItem('addTableData');
            dispatch<any>(locateItem({ name: newLink.substanceName, type: "Substance", subType: "", status: "", substanceId: "" }, true, newLink.substanceName))
          })
          .catch((error: any) => {
            dispatch(makeError(error));
            dispatch(addSubstanceLinkRequest.failure(error.message));
            setSubmitting(false);
          });
      } else {
        axios.put('/api/v1/ContributionFactor/AddSubComponent', newLink)
          .then((response) => {
            dispatch(makeSuccessMessage("Contribution factors are added successfully"));
            setSubmitting(false);
            doClose();
            localStorage.removeItem('addTableData');
            dispatch<any>(locateItem({ name: newLink.substanceName, type: "Substance", subType: "", status: "", substanceId: "" }, true, newLink.substanceName))
          })
          .catch((error: any) => {
            dispatch(makeError(error));
            dispatch(addSubstanceLinkRequest.failure(error.message));
            setSubmitting(false);
          });
      }
       
  }
  };
}
export const SwapSynonym = (formValues: any, doClose: Function, setSubmitting: Function) => {
  if (formValues.childItem.name === '') {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Cannot relocate the synonyms. Please select a Synonyms'));
      setSubmitting(false);
    }
  } else if (formValues.parentItem.name === '') {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Cannot relocate the synonyms. Please select a Subtance'));
      setSubmitting(false);
    }
  } else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      let object: any = {
        "substanceName": formValues.childItem.name,
        "parentName": formValues.parentItem.name,
        "domainName": null,
        "auditDescription": formValues.auditDescription
      }
      // const newLink = ConvertFormValuesToSubstanceLinkDel(formValues);
      // addSubstanceLinkRequest.request(newLink.parentSubstanceName + " > " + newLink.childSubstanceName);
      axios.put('/api/v1/SubstanceName/SwapSubstanceName', object)
        .then((response) => {
          dispatch(makeSuccessMessage("Synonyms relocated successfully."));
          setSubmitting(false);
          doClose();
          dispatch<any>(locateItem({ name: formValues.parentItem.name, type: formValues.parentItem.type, subType: "", status: "", substanceId: "" }, true, ""))
        })
        .catch((error: any) => {
          dispatch(makeError(error));
          dispatch(addSubstanceLinkRequest.failure(error.message));
          setSubmitting(false);
        });
    };
  }
}
