import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { LookupState } from './types';

const initalState: LookupState = {
  status: [],
  classificationType: [],
  nameType: [],
  domain: [],
  license: "",
  molWeight: "",
};

export const lookupReducer = createReducer(initalState)
  .handleAction(actions.lookupStatusRequest.success, (state, action) => ({ ...state, status: action.payload, }))
  .handleAction(actions.lookupStatusRequest.request, (state, action) => ({ ...state }))
  .handleAction(actions.lookupStatusRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.lookupClassificationTypeRequest.success, (state, action) => ({ ...state, classificationType: action.payload }))
  .handleAction(actions.lookupClassificationTypeRequest.request, (state, action) => ({ ...state }))
  .handleAction(actions.lookupClassificationTypeRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.lookupNameTypeRequest.success, (state, action) => ({ ...state, nameType: action.payload }))
  .handleAction(actions.lookupNameTypeRequest.request, (state, action) => ({ ...state }))
  .handleAction(actions.lookupNameTypeRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.lookupDomainRequest.success, (state, action) => ({ ...state, domain: action.payload }))
  .handleAction(actions.lookupDomainRequest.request, (state, action) => ({ ...state }))
  .handleAction(actions.lookupDomainRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.licenseRequest.success, (state, action) => ({ ...state, license: action.payload.data }));


export default lookupReducer;
