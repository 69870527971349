import React from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { AppState } from '../store';
import NameDetailList from './NameDetailList';
import DetailTitle from './DetailTitle';
import PathDisplay from './PathDisplay';
import CompositionDetailList from './CompositionDetailList';

const useStyles = makeStyles({
  paper: {
    padding: 5,
    textAlign: 'center' as 'center',
    //overflow: "auto",
    //minHeight: "70vh",
    //maxHeight: "100vh"
  },
});

type Props = {
  isCompositeSub?: boolean;
};

const DetailContainer = (props: Props) => {
  const { isCompositeSub } = props;

  const classes = useStyles();
  localStorage.removeItem('name');
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const parentVariantDetails = useSelector((state: AppState) => state.polymersData.ParentVariantDetails);
  const variantFlag = useSelector((state: AppState) => state.variant.variantFlag);
  const polymerFlag = useSelector((state: AppState) => state.polymersData.polymerFlag);

  // const treeData = useSelector((state: AppState) => state.hierarchy.tree);
  //const commentList = useSelector((state: any) => state.commentReducer);
  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  const nameFlag = useSelector((state: any) => state.flagReducer);
  const isNameFlag = nameFlag.flagDetails;
  const node = detailData.selectedNode;
  const itemDetail = detailData.itemDetail;
  localStorage.setItem('name', itemDetail.backboneIdentity);
  let csvDataArray: any = [];
  let itemDetailDataArray: any = [];
  let parentVariantDataArray: any = [];
  const namesArray = itemDetail.names !== undefined ? detailData.itemDetail.names : "";
  const classificationsArray = itemDetail.classifications !== undefined ? itemDetail.classifications : "";
  if (namesArray.length > 0) {
    for (let i = 0; i < namesArray.length; i++) {
      let obj: any = {
        Name: namesArray[i].name ? namesArray[i].name : "",
        Type: namesArray[i].nameType ? namesArray[i].nameType : "",
        Domains: namesArray[i].domains ? namesArray[i].domains : "",
        Status: namesArray[i].status ? namesArray[i].status : "",
      }
      csvDataArray.push(obj);
    }
  }
  if (classificationsArray.length > 0) {
    for (let i = 0; i < classificationsArray.length; i++) {
      let obj1: any = {
        Name: classificationsArray[i].name ? classificationsArray[i].name : "",
        Type: classificationsArray[i].classificationType ? classificationsArray[i].classificationType : "",
        Domains: classificationsArray[i].domain ? classificationsArray[i].domain : "",
        Status: classificationsArray[i].status ? classificationsArray[i].status : "",
      }
      csvDataArray.push(obj1);
    }
  }
  if (namesArray.length > 0) {
    for (let i = 0; i < namesArray.length; i++) {
      if (itemDetail.names[i].nameType !== 'Component') {
        let obj: any = {
          name: namesArray[i].name ? namesArray[i].name : "",
          nameType: namesArray[i].nameType ? namesArray[i].nameType : "",
          domains: namesArray[i].domains ? namesArray[i].domains : "",
          status: namesArray[i].status ? namesArray[i].status : "",
          isPrimaryForDomains: namesArray[i].isPrimaryForDomains ? namesArray[i].isPrimaryForDomains : ""
        }
        itemDetailDataArray.push(obj);
      }
    }
  }

  if (parentVariantDetails != null) {
    //for (let i = 0; i < parentVariantDetails.length; i++) {      
    //parentVariantDetails.map((item: any, index: any) => {      
    var parentVariantDetailsNames = parentVariantDetails.names;

    for (let i = 0; i < parentVariantDetailsNames.length; i++) {
      //alert("parentVariantDetailsNames[] -- " + JSON.stringify(parentVariantDetailsNames[i]));

      let obj: any = {
        Name: parentVariantDetailsNames[i].name ? parentVariantDetailsNames[i].name : "",
        Type: parentVariantDetailsNames[i].nameType ? parentVariantDetailsNames[i].nameType : "",
        Domains: parentVariantDetailsNames[i].domains ? parentVariantDetailsNames[i].domains : "",
        Status: parentVariantDetailsNames[i].status ? parentVariantDetailsNames[i].status : "",
        isPrimaryForDomains: parentVariantDetailsNames[i].isPrimaryForDomains ? parentVariantDetailsNames[i].isPrimaryForDomains : ""
      }
      parentVariantDataArray.push(obj);
    }
  }
  const variantscreen = useSelector((state: AppState) => state.variant.variantStrcutureInfo);

  const isVRestricted = (variantscreen && variantscreen.isVariantRestricted != null ? variantscreen.isVariantRestricted : false);

  let displayUI = false;;
  if (selectedItem === null) {
    displayUI = false;
  } else {
    displayUI = true;
  }
  if (displayUI) {
    return (
      <Paper className={classes.paper} >
        {node && <DetailTitle name={itemDetail.backboneIdentity} csvData={csvDataArray} type={detailData.type} subType={node.subType} status={node.status} isNameFlag={isNameFlag} tabName={'substancetab'} isVariantRestricted={isVRestricted} isCompositeSub={isCompositeSub} />}

        {itemDetail &&
          itemDetail.classificationPaths &&
          <React.Fragment>
            {detailData.type !== "Composite" ?
              <PathDisplay paths={itemDetail.classificationPaths} /> : null
            }
            <NameDetailList data={itemDetail} type={detailData.type} highligted={selectedItem} itemDetailDataArray={itemDetailDataArray} itemDetailParentVariantArray={parentVariantDataArray} variantFlag={variantFlag} polymerFlag={polymerFlag} />
            {detailData.type === "Composite" && itemDetail.compositeNames ? <CompositionDetailList data={itemDetail} type={detailData.type} highligted={selectedItem} /> : null}
          </React.Fragment>
        }
      </Paper>
    );
  } else {
    return (
      <div className={classes.paper} ></div>
    );
  }

}

export default DetailContainer;
