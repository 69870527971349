import { Dispatch } from 'redux';
import axios from 'axios';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { SubstanceDetailResult, CompositeTabDetailResult } from './types';
import { AppState } from '../index';
import { makeError } from '../error/actions';
import { NodeData } from '../hierarchy/types';
import { getCommentList } from '../comment/actions';
import { getStructureList, classificationNodeRequest } from '../hierarchy/actions';
import { itemSelected } from '../search/actions';
import { filterOutNames } from '../../domain/tree';
import { transformHierarchyResult } from '../hierarchy/utilities';
import { getCompositeRestrictionsList, getStructureRestrictionsList } from '../editItem/actions';
import { getSubComponentInfo, getMolWeightInfo } from '../subComponent/actions';
//import {getVariantList} from '../variant/actions';
import { getPolymerFlag, getParentVariantDetails} from '../polymer/actions';
import { getVariantFlag, getStructureVariantInfo} from '../variant/actions';
 
export const substanceDetailNodeRequest = createAsyncAction(
  "SUBSTANCE_DETAIL_REQUEST_START",
  "SUBSTANCE_DETAIL_REQUEST_SUCCESS",
  "SUBSTANCE_DETAIL_REQUEST_FAILURE")<string, SubstanceDetailResult, string>();

export const compositeTabContentRequest = createAsyncAction(
  "COMPOSITE_TAB_CONTENT_REQUEST_START",
  "COMPOSITE_TAB_CONTENT_REQUEST_SUCCESS",
  "COMPOSITE_TAB_CONTENT_REQUEST_FAILURE")<string, CompositeTabDetailResult, string>();

//export const nameFlagRequest = createAsyncAction(
//  "NAME_FLAG_REQUEST_START",
//  "NAME_FLAG_REQUEST_SUCCESS",
//  "NAME_FLAG_REQUEST_FAILURE")<string, any, string>();


export const selectedNodeAction = createStandardAction("NODE_SELECTED")<NodeData>();

// Get full substance detail
export const showDetail = (nodeData: NodeData) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(selectedNodeAction(nodeData));
    const nodeName = nodeData.name;
    const nodeType = nodeData.type;
    localStorage.setItem('nodename', nodeName);
    localStorage.setItem('defaultTabIndex', 'zero');
    var current = getState().search.selectedItem;
    if (current && current.name === nodeName && current.substanceId === nodeType) {
      // Already selected.
      return;
    }
    console.log('nodeType == >',nodeType);
    if (nodeType === "Substance" || nodeType === "Classification" || nodeType == "Structure Variant") {
      let item = {
        name: nodeName,
        type: nodeType,
        subType: nodeData.subType,
        status: nodeData.status,
        substanceId: "",
        auditDescription: "",
      }
      dispatch(itemSelected(item));
      dispatch<any>(getMolWeightInfo(nodeName));
      dispatch<any>(getStructureList(nodeName));
      dispatch<any>(getSubComponentInfo(nodeName));
      dispatch(substanceDetailNodeRequest.request(nodeName));
      //dispatch<any>(getVariantList(nodeName, 0));
      //dispatch<any>(getPolymerStructureList(nodeName));
      dispatch<any>(getPolymerFlag(nodeName));
      dispatch<any>(getVariantFlag(nodeName));      
      dispatch<any>(getParentVariantDetails(nodeName));
      
      let showInactiveValues = localStorage.getItem('showInactiveSynonyms') === 'true' ? true: false;
      if (showInactiveValues) {
        axios.get(`/api/v1/substances/name?name=${encodeURIComponent(nodeName)}&button=${encodeURIComponent(showInactiveValues)}`)
          .then((response) => {
            dispatch(substanceDetailNodeRequest.success({ substanceName: nodeName, data: response.data, type: nodeType === "Substance" ? nodeType : "Classification" }));
          })
          .catch((error) => {
            if (nodeType === "Classification") {
              dispatch(substanceDetailNodeRequest.success({ substanceName: nodeName, data: { backboneIdentity: nodeName }, type: "Classification" }));
            }
            else {
              dispatch(makeError(error));
              dispatch(substanceDetailNodeRequest.failure(error.message));
            }
          });
      } else {
        axios.get(`/api/v1/substances/name?name=${encodeURIComponent(nodeName)}`)
          .then((response) => {
            dispatch(substanceDetailNodeRequest.success({ substanceName: nodeName, data: response.data, type: nodeType === "Substance" ? nodeType : "Classification" }));
          })
          .catch((error) => {
            if (nodeType === "Classification") {
              dispatch(substanceDetailNodeRequest.success({ substanceName: nodeName, data: { backboneIdentity: nodeName }, type: "Classification" }));
            }
            else {
              dispatch(makeError(error));
              dispatch(substanceDetailNodeRequest.failure(error.message));
            }
          });
      }
      dispatch(compositeTabContentRequest.request(nodeName));
      axios.get(`/api/v1/CompositeSubstances/componentName?componentName=${encodeURIComponent(nodeName)}`)
        .then((response) => {
          dispatch(compositeTabContentRequest.success({ data: response.data[0] }));
          //alert('data for composite tab successfully fetched');
        }).catch((error) => {
          //dispatch(makeError(error));
          // dispatch(substanceDetailNodeRequest.failure(error.message));
        });
      dispatch<any>(getCommentList(nodeName));
      dispatch<any>(getStructureRestrictionsList(nodeName));
      //alert("in itemdetails action ..")
      //dispatch<any>(getNameFlag(nodeName));
    }
    //else if (nodeType === "Classification") {
    //  let item = {
    //    name: nodeName,
    //    type: nodeType,
    //    subType: nodeData.subType,
    //    status: nodeData.status,
    //    substanceId: "",
    //    auditDescription: "",
    //  }
    //  dispatch(itemSelected(item));
    //  dispatch(compositeTabContentRequest.request(nodeName));
    //  dispatch(substanceDetailNodeRequest.success({ substanceName: nodeName, data: { backboneIdentity: nodeName }, type: "Classification" }));
    //}
    else if (nodeType === "Substance name") {
      let item = {
        name: nodeName,
        type: nodeType,
        subType: nodeData.subType,
        status: nodeData.status,
        substanceId: "",
        auditDescription: "",
      }
      dispatch(itemSelected(item));
      dispatch(compositeTabContentRequest.request(nodeName));
      dispatch(substanceDetailNodeRequest.success({ substanceName: nodeName, data: { backboneIdentity: nodeName }, type: "Substance name" }));
    }
    else if (nodeType === "Composite" || nodeType === "Composite substance Name" || nodeType === "Composite name") {
      dispatch(classificationNodeRequest.request(nodeName));
      axios.get(`/api/v1/entities/parentageandchildren?nodeName=&nodeType=Classification`)
        .then((response) => {
          dispatch(classificationNodeRequest.success({ parentName: '', nodeType: "Classification", data: filterOutNames(transformHierarchyResult(response.data)) }));
        })
        .catch((error) => {
          dispatch(makeError(error));
          dispatch(classificationNodeRequest.failure(error.message));
        });
      let item = {
        name: nodeName,
        type: nodeType,
        subType: nodeData.subType,
        status: nodeData.status,
        substanceId: "",
        auditDescription: "",
      }
      dispatch(itemSelected(item));
      localStorage.removeItem('restrictredemails');
      localStorage.removeItem('restricteddomains');
      localStorage.removeItem('tableEditData');
      localStorage.removeItem('selectedDomainsForEdit');
      dispatch(substanceDetailNodeRequest.request(nodeName));
      axios.get(`/api/v1/CompositeSubstances/GetCompositeSubstanceDetails/${encodeURIComponent(nodeName)}`)
        .then((response) => {
          dispatch<any>(getVariantFlag(nodeName));   
          dispatch(substanceDetailNodeRequest.success({ substanceName: nodeName, data: response.data, type: "Composite" }));
        })
        .catch((error) => {
          dispatch(makeError(error));
          dispatch(substanceDetailNodeRequest.failure(error.message));
        });
      dispatch<any>(getCommentList(nodeName));
      dispatch<any>(getCompositeRestrictionsList(nodeName));
      //dispatch<any>(getNameFlag(nodeName));
    }
    else {
      console.log('else of showDetail ============= ')
    }
  }
}
