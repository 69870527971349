import { Grid, Paper, Typography, Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useState } from 'react';
import { validateNewItem, warningMessage } from '../domain/AddItemValidation';
import { AddItemState } from '../store/addItem/types';
import { LookupState } from '../store/lookupData/types';
import NameFormPanel from './NameFormPanel';
import AddComponentToCompositePanel from './AddCompToComposite';
import ParentLinkFormPanel from './ParentLinkFormPanel';
import ValidationError from './ValidationError';
import { useSelector, useDispatch } from "react-redux";
import { makeError } from '../store/error/actions';
import { hasConsecutiveSpaces } from '../utils/checkConsecutiveSpaces';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px",
  },
  paper: {
    padding: "5px",
    height: "100%"
  },
  msgColorRed: {
    color: 'red',
    fontSize: '14px'
  },
  msgColorGreen: {
    color: 'green',
    fontSize: '14px'
  },
  noaction:{
    color: "rgba(0, 0, 0, 0.26)",
    cursor: 'no-drop',
    pointerEvents: 'none'
  },

});

const getSubTypesForType = (type: string, lookup: LookupState) => {
  if (type === 'Classification') {
    return lookup.classificationType;
  }
  return lookup.nameType;
}


interface LookupProps {
  lookup: LookupState;
  onSaveComp: any;
  //a1?: any;
  //b1?: any;
  //datatoValidate : DatatoValidate;
}



type AddNewItemProps = LookupProps & FormikProps<AddItemState>;

const AddNewItemForm = (props: AddNewItemProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { lookup, values, isValid, dirty, errors, isSubmitting} = props;
  const message = warningMessage(values);
  const [showAvgMsg, setShowAvgMsg] = React.useState(false);
  const [showMinMsg, setShowMinMsg] = React.useState(false);
  //const [showMaxMsg, setShowMaxMsg] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState(false);
  const validationMessageAvg = 'sum of Average value should not be greater than 100';
  const validationMessageMin = 'sum of Minimum value should not be greater than 100';
  //const validationMessageMax = 'sum of Maximum value should not be greater than 100';
  const validationSuccessMsg = "valid composition";
  const errorValidationOnload = "Please fill composite data before validating";
  //let validateClick: boolean = false;
  const [validateClick, setValidateClick] = React.useState(false);
  const enableBtn = values.newEntity.type === 'Composite substance' ? true : false;
  //const addState = useSelector((state: DatatoValidateState) => state.data);
  const [levelData, setlevelData] = React.useState([])
  const [, setSubData]: any = React.useState([]);
  localStorage.setItem('status', 'A');
 // localStorage.setItem('typeSelected', 'Synonym');
  let name = localStorage.getItem('nameComposite');
  const [uniqueNameDisable, setUniqueNameDisable] = React.useState(false);
  const [hasExtraSpaceDisable, setHasExtraSpaceDisable] = React.useState(false);
  //let uniqueNameDisable = ((localStorage.getItem('uniquename')) === 'false' ? true : false);
  //let hasExtraSpaceDisable = ((localStorage.getItem('hasextraspace')) === 'false' ? true : false);
  const parsedDomain = localStorage.getItem('selectedDomainsForEdit');
  let domainWhileADD: any = [];
  if (parsedDomain) {
    domainWhileADD = JSON.parse(parsedDomain);
  }
  let disableIfEmptyDomain = domainWhileADD.length === 0 ? true : false;
  let disableIfEmptyName = name === '' ? true : false;
  // let uniqueNameDisable = localStorage.getItem('uniquename') === 'false' ? true : false;
  //let hasExtraSpaceDisable = localStorage.getItem('hasextraspace') === 'Name contains more than one consecutive space or leading/trailing spaces - please correct' ? true : false;
  //React.useEffect(() => {
  //  let a1 = localStorage.getItem('uniquename');
  //  let b1 = localStorage.getItem('hasextraspace');
  //  //const [uniqueNameDisable, setUniqueNameDisable] = React.useState(false);
  //  if (a1 === 'false') {
  //    setUniqueNameDisable(true);
  //  }
  //  else if (b1 === 'Name contains more than one consecutive space or leading/trailing spaces - please correct') {
  //    setHasExtraSpaceDisable(true);
  //  }
  //  else {
  //    setHasExtraSpaceDisable(false);
  //    setUniqueNameDisable(false);
  //  }
  //  //let hasExtraSpaceDisable = localStorage.getItem('hasextraspace') === 'false' ? true : false;
  //  //console.log('cond', uniqueNameDisable, hasExtraSpaceDisable, a1, b1, values);
  //}, [values.newEntity.name])
  //console.log('cond', a1, b1);

  const ValidateComposition = (e: any) => {
    //const name = localStorage.getItem('nameComposite');
    const plm = localStorage.getItem('plm');
    const safety = localStorage.getItem('safety');
    const regulatory = localStorage.getItem('regulatory');
    const fr = localStorage.getItem('fr');
    const HC = localStorage.getItem('HC');
    const BW = localStorage.getItem('BW');
    const PC = localStorage.getItem('PC');
    const type = localStorage.getItem('typeSelected') === null ? 'Synonym' : localStorage.getItem('typeSelected');
    localStorage.removeItem('isAddCompositeSave');
    if ((name === null) || ((plm === null) && (safety === null) && (regulatory === null) && (fr === null) && (HC === null) && (BW === null) && (PC === null)) || (type === null)) {
      setSuccessMsg(false);
    }
    else {
      if (e.length === 0) {
        setSuccessMsg(false);
        setValidateClick(true);
      } else {
        //   if (e.length > 0) {
        var avgTotal = 0;
        var minTotal = 0;
       // var maxTotal = 0;
        for (let i = 0; i < e.length; i++) {
          avgTotal += e[i].avglevel ? e[i].avglevel : 0  ;
          minTotal += e[i].minlevel ? e[i].minlevel : 0;
         // maxTotal += e[i].maxlevel;
        }
        if (avgTotal > 100) {
          setShowAvgMsg(true);
        } else {
          setShowAvgMsg(false);
        }
        if (minTotal > 100) {
          setShowMinMsg(true);
        } else {
          setShowMinMsg(false);
        }
        //if (maxTotal > 100) {
        //  setShowMaxMsg(true);
        //} else {
        //  setShowMaxMsg(false);
        //}
        if (((avgTotal <= 100) || (Number.isNaN(avgTotal))) && ((minTotal <= 100) || (Number.isNaN(minTotal))) ) {
          setSuccessMsg(true);
        } else {
          setSuccessMsg(false);
        }
      }
    }
  }
  const dataHolderFunction = (levelDataComp: any) => {
    const getEnableSaveBtn = localStorage.getItem('isAddCompositeSave');
    setlevelData(levelDataComp);
    //setValidateClick(false);
    if (levelDataComp.length === 0 && getEnableSaveBtn === 'true') {
      setSuccessMsg(false);
      setValidateClick(false);
      setShowAvgMsg(false);
      setShowMinMsg(false);
      //setShowMaxMsg(false);
    } else {
      if (levelDataComp.length > 0 && getEnableSaveBtn === 'true') {
        setSuccessMsg(false);
        setValidateClick(false);
        setShowAvgMsg(false);
        setShowMinMsg(false);
       // setShowMaxMsg(false);
      }
    }
  }
  const substanceData = (data: any) => {
    setSubData(data);
  }
  const selectedVal = (value: any) => {
    if (value.name === 'PLM') {
      if (value.selected) {
        localStorage.setItem('plm', value.name)
      } else {
        localStorage.removeItem('plm')
      }
    }
    if (value.name === 'Safety') {
      if (value.selected) {
        localStorage.setItem('safety', value.name)
      } else {

        localStorage.removeItem('safety')
      }
    }
    if (value.name === 'Regulatory') {
      if (value.selected) {
        localStorage.setItem('regulatory', value.name)
      } else {
        localStorage.removeItem('regulatory')
      }
    }
    if (value.name === 'F&R') {
      if (value.selected) {
        localStorage.setItem('fr', value.name)
      } else {
        localStorage.removeItem('fr')
      }
    }
    if (value.name === 'HC') {
      if (value.selected) {
        localStorage.setItem('HC', value.name)
      } else {
        localStorage.removeItem('HC')
      }
    }
    if (value.name === 'BW') {
      if (value.selected) {
        localStorage.setItem('BW', value.name)
      } else {
        localStorage.removeItem('BW')
      }
    }
    if (value.name === 'PC') {
      if (value.selected) {
        localStorage.setItem('PC', value.name)
      } else {
        localStorage.removeItem('PC')
      }
    }
  }
  const handleAudit = (val: any) => {
    localStorage.setItem('audit', val.target.value);
  }
  //const namedata = (val: any) => {
  //  localStorage.setItem('nameComposite', val);
  //}
  const statusSelected = (val: any) => {
    localStorage.setItem('status', val);
  }
  const typeSelected = (val: any) => {
    localStorage.setItem('typeSelected', val);
  }

  const onSaveComp = () => {
    const domainSelected: any = [];

    if (localStorage.getItem('fr') !== null) {
      domainSelected.push(localStorage.getItem('fr'))
    }
    if (localStorage.getItem('plm') !== null) {
      domainSelected.push(localStorage.getItem('plm'))
    }
    if (localStorage.getItem('safety') !== null) {
      domainSelected.push(localStorage.getItem('safety'))
    }
    if (localStorage.getItem('regulatory') !== null) {
      domainSelected.push(localStorage.getItem('regulatory'))
    }
    if (localStorage.getItem('HC') !== null) {
      domainSelected.push(localStorage.getItem('HC'))
    }
    if (localStorage.getItem('PC') !== null) {
      domainSelected.push(localStorage.getItem('PC'))
    }
    if (localStorage.getItem('BW') !== null) {
      domainSelected.push(localStorage.getItem('BW'))
    }
    //localStorage.setItem('compositepage', "true");
    // levelData.pop();
    let componentsData: any = [];
    let objLevelData: any = levelData;
    for (let i = 0; i < objLevelData.length; i++) {
      let obj: any = {
        name: objLevelData[i].name,
        avg: objLevelData[i].avglevel ? objLevelData[i].avglevel.toString() : "",
        min: objLevelData[i].minlevel ? objLevelData[i].minlevel.toString() : "",
        max: objLevelData[i].maxlevel ? objLevelData[i].maxlevel.toString() : ""
      }
      componentsData.push(obj)
    }
    let data = {
      name: localStorage.getItem('nameComposite'),
      type: 'Composite substance Name',
      subType: localStorage.getItem('typeSelected') === null ? 'Synonym' : localStorage.getItem('typeSelected'),
      status: localStorage.getItem('status'),
      linkDomains: domainSelected,
      auditDescription: localStorage.getItem('audit'),
      components: componentsData
    }
    if ((localStorage.getItem('nameComposite') !== undefined) || (domainSelected.length > 0)) {
      props.onSaveComp(data);
    }
    //let reg = /^(.*\s{2,}.*)$/
    //SonarQube warning for Denail of Service issue
    // let reg = /(?!\S)\s{2}(?!$)/
    if (values.newEntity.name !== "" && hasConsecutiveSpaces(values.newEntity.name)) {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      console.log('name contains spaces');
    }
    //ValidateComposition(levelData);
  }
  //const [mol, setMol] = useState(null);
  //const [molVal, setMolVal] = useState(false);
  //const molWeightChange = (val: any) => {
  //  setMol(val.target.value);
  //  if (val.target.value !== null) {
  //    setMolVal(false);
  //  }
  //}
  const dataValidateHasSpace = () => {
    //let reg = /^.*\s{2,}.*$/
    //SonarQube warning for Denail of Service issue
    // let reg = /(?!\S)\s{2}(?!$)/
    if (values.newEntity.name !== "" && hasConsecutiveSpaces(values.newEntity.name)) {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
    } else if (values.newEntity.name !== "" && localStorage.getItem('uniquename') === 'false') {
      dispatch(makeError('Please add an unique name'));
    }
    else if (values.newEntity.type === "Substance name" && values.parentEntity.name === undefined) {
      localStorage.setItem('parentRequired', 'true');
    }
  }
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <ValidationError name="newEntity.type" />
            <NameFormPanel propertyPrefix="newEntity." parentType={values.parentEntity.type} type={values.newEntity.type} availableTypes={getSubTypesForType(values.newEntity.type, lookup)} availableStatuses={lookup.status} status={statusSelected} typeSelected={typeSelected} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <ParentLinkFormPanel allDomains={lookup.domain.map(i => i.display)} values={values} selectedVal={selectedVal} />
          </Paper>

        </Grid>
        {values.newEntity.type === "Substance" ? < Grid item xs={12}>
          <Paper className={classes.paper} >
            <InputLabel>Mol. Weight</InputLabel>
            <Field
              type="number"
              className={classes.field}
              name="molweight"
              component={TextField}
              margin="dense"
              fullWidth              
            />
          </Paper>
        </Grid>: null}
        <Grid item xs={12}>
          <Paper className={classes.paper} >
            <InputLabel>Audit Description</InputLabel>
            {enableBtn ? <Input className="auditDesc" name="auditDescription" onBlur={(item: any) => handleAudit(item)} /> : <Field
              className={classes.field}
              name="auditDescription"
              component={TextField}
              margin="dense"
              fullWidth
            />}
          </Paper>
        </Grid>
        {enableBtn ? <Grid item xs={12}>
          <Paper className={classes.paper} >
            <AddComponentToCompositePanel {...lookup} dataFromParent={dataHolderFunction} substanceData={substanceData} />
          </Paper>
        </Grid> : null}
        <Grid item xs={8}>
          {enableBtn ?
            <Button type="button" color="primary" onClick={() => ValidateComposition(levelData)}>
              Validate Composition
          </Button>
            : null}
          {enableBtn ?
            <Button type="submit" color="primary" onClick={onSaveComp} disabled={!successMsg || disableIfEmptyDomain || disableIfEmptyName}>
              Save
          </Button> :
            <Button type="submit" color="primary" onClick={dataValidateHasSpace}>
              Save
          </Button>}
          {showAvgMsg ?
            <Typography variant="caption" className={classes.msgColorRed} >
              {validationMessageAvg}</Typography>
            :
            showMinMsg ?
              <Typography variant="caption" className={classes.msgColorRed} >
                {validationMessageMin}</Typography>
              :
                successMsg ?
                  <Typography variant="caption" className={classes.msgColorGreen} >
                    {validationSuccessMsg}</Typography>
                  : validateClick ?
                    <Typography variant="caption" className={classes.msgColorRed} >
                      {errorValidationOnload}</Typography> :
                    null
          }
          <Typography variant="caption" >{message}</Typography>
        </Grid>
      </Grid>
    </Form>
  )
}

interface FormProps {
  data: AddItemState;
  lookup: LookupState;
  onSave: (values: AddItemState, formikActions: FormikActions<AddItemState>) => void;
  onSaveComp: any;
}

const AddNewItemFormik = (props: FormProps) => {

  const { data, lookup } = props;
  const onSaveComp = (val: any) => {
    props.onSaveComp(val);
  }
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={props.onSave}
        validate={validateNewItem}
        render={(fp) => (
          <AddNewItemForm lookup={lookup} {...fp} onSaveComp={onSaveComp} />
        )}
      />
    </div>
  );
};

export default AddNewItemFormik;
