import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { addEditPolymerStrcuture, getSourceCheckFixer, getSRUCheckFixer } from '../store/polymer/actions';
import { makeError, makeSuccessMessage } from '../store/error/actions';
import { Select, MenuItem, TextField, Grid, FormControl, CircularProgress } from '@material-ui/core';
import { addEditPolymerStrcut } from '../store/polymer/types';
import { closeEditPolymer, showConfirmBox } from "../store/polymer/actions";
import { closeConfirmBox } from "../store/polymer/actions";
import WarningIcon from '@material-ui/icons/Warning';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface Props {
  MRVchanged?: (mrv: string) => void;
  mrv?: string | null;
  smilesfromsearch?: string;
  searchIndex?: string;
  getImage?: any | null;
}



interface MarvinInstance {
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: (() => void) | null) => void;
  exportStructure: (format: string, settings?: any) => Promise<string>;
  importStructure: (format: string, data: any) => void;
}

interface Props {
  MRVchanged?: (mrv: string) => void;
  mrv?: string | null;
  smilesfromsearch?: string;
  searchIndex?: string;
  getImage?: any | null;
}

const useStyles = makeStyles((theme) => ({
  iframe: {
    minHeight: 519,
    width: 580
  },
  child1: {
    position: "relative",
    top: "29px",
    marginLeft: "12px"
  },
  btn: {
    top: "-65px",
    marginLeft: "10px"
  },
  parent: {
    // display: "flex",
  },
  textarea: {
    width: "465px",
    marginTop: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    borderWidth: "1px",
    borderColor: "lightgray",
    borderStyle: "solid",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  outerDivL: {
    display: "flex",
    float: "left",
    marginBottom: "30px"
  },
  saveBtn: {
    display: "flex",
    float: "left",
    marginBottom: "30px",
    marginLeft: "50%"
  },
  displayFlex: {
    display: "flex",
  },
  floatR: {
    float: "right"
  },
  floatL: {
    float: "left"
  },
  textRight: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
    position: "fixed",
    left: "50%",
    bottom: "20px",
    transform: "translate(-50 %, -50 %)",
    margin: "0 auto"
  },
  textLeft: {
    textAlign: "left"
  },
  svBtn: {
    top: "2px"
  },
  lableTxt: {
    "font-size": "20px",
    "font-weight": "500",
    marginRight: "10px"
  },
  error: {
    color: "red",
    marginTop: "0px",
    marginBottom: "0px",
  },
  subText: {
    width: "390px"
  },
  molFormulaText: {
    width: "375px",
    marginRight: "30px"
  },
  prfText: {
    width: "464px",
  },
  seperator: {
    marginBottom: "80px",
  },
  parentText: {
    width: "426px",
    marginRight: "31px"
  },
  parentNameText: {
    width: "426px",
    marginRight: "30px"
  },
  selectEmpty1: {
    marginTop: "2px",
    width: 175,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  centerText: {
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 600,
    marginBottom: '30px'
  },
  topRight: {
    position: "absolute",
    top: "8px",
    right: "16px"
  },
  yes: {
    marginLeft: '5px',
    marginRight: '57px'

  },
  a: {
    fontSize: '21px',
    fontWeight: 600,
    marginBottom: '25px',
    marginTop: '20px'
  },
  b: {
    fontSize: '21px',
    fontWeight: 600,
    marginBottom: '10px'
  },
  c: {
    fontSize: '21px',
    fontWeight: 600,
    marginBottom: '30px',
    marginTop: '27px'
  },
  bullet: {
    fontSize: '21px',
    fontWeight: 600,
    marginBottom: '30px'
  },
  k: {
    marginBottom: "30px",
  }
}));

export const EditPolymerDialog = (props: Props) => {
  const { editPolymer } = useSelector((state: AppState) => state.polymersData);
  const structureData = useSelector((state: AppState) => state.polymersData.ParentPolymerDetails);

  // const polymerDt = polymersData.PolymerData;
  const sruCheckerFixer = useSelector((state: AppState) => state.polymersData.sruCheckerFixerData);
  const sourceCheckerFixer = useSelector((state: AppState) => state.polymersData.sourceCheckerFixerData);
  const user = useSelector((state: AppState) => state.user);

  const [sruErrFlag, setSruErrFlag] = React.useState<any>(false);
  const [SourceErrFlag, setSourceErrFlag] = React.useState<any>(false);
  const [isSaving, setisSaving] = React.useState<any>(false);



  const dispatch = useDispatch();
  const classes = useStyles();
  const licenseData = useSelector((state: AppState) => state.lookup.license);

  const [marvin, setMarvin] = React.useState<MarvinInstance>();
  const [marvin2, setMarvin2] = React.useState<MarvinInstance>();

  const [dataSmile, setDataSmile] = React.useState<any>();
  const [dataSmile2, setDataSmile2] = React.useState<any>();

  const [mol1, setMol1] = React.useState<any>();
  const [mol2, setMol2] = React.useState<any>();

  const [sdf1, setSdf1] = React.useState<any>();
  const [sdf2, setSdf2] = React.useState<any>();
  const [DisableRestrictPanel, setDisableRestrictPanel] = React.useState(false);
  const [enableValidateBtn1, setEnableValidateBtn1] = React.useState(true);
  const [enableValidateBtn1Restrict, setenableValidateBtn1Restrict] = React.useState(true);
  const [enableValidateBtn2, setEnableValidateBtn2] = React.useState(true);
  const [enableValidateBtn2Restrict, setenableValidateBtn2Restrict] = React.useState(true);


  const detailData = useSelector((state: AppState) => state.itemDetail);
  const itemDetail = detailData.itemDetail;
  const [prfSmiles, setprfSmiles] = React.useState(structureData && structureData.prfSmiles ? structureData.prfSmiles : "");
  const [substituentSmiles, setsubstituentSmiles] = React.useState(structureData && structureData.substituentSmiles ? structureData.substituentSmiles : "");
  const [verificationStatus, setVerificationStatus] = React.useState(structureData && structureData.verification ? structureData.verification.toUpperCase() : '');
  const [auditDescription, setAuditDescription] = React.useState(structureData && structureData.auditDescription ? structureData.auditDescription : '');
  const [molFormula, setMolFormula] = React.useState(structureData && structureData.molecularFormula ? structureData.molecularFormula : '');
  const getRestrictionData = useSelector((state: AppState) => state.editItem.structurerestrictionData);

  const onClose = useCallback(
    () => {
      dispatch(closeEditPolymer())
      setDataSmile("");
      setDataSmile2("");
    },
    [dispatch]
  );
  //function is not in use hence commented
  /*const onSave =
    (values: any) => {

    }*/

  React.useEffect(() => {

    if (dataSmile == '') {
      console.log('working v1')
      setEnableValidateBtn1(true)
    }

    if (dataSmile2 == '') {
      console.log('working v2')
      setEnableValidateBtn2(true)
    }

  }, [dataSmile, dataSmile2]);

  React.useEffect(() => {
    console.log('popup testng here')
    setupSketcher();
    setupSketcher2();
    // getData();
  }, []);

  async function setupSketcher() {
    try {
      console.log('inside setup 1')
      //@ts-ignore
      const marvinNameSpace = await MarvinJSUtil.getPackage("#sketch");
      const m = marvinNameSpace.sketcherInstance as MarvinInstance;
      marvinNameSpace.Sketch.license("data:text/xml," + licenseData);
      setMarvin(m);
      console.log('now marvin =', marvin);
      // initControl();
    } catch (e) {
      console.warn("Couldn't load", e);
    }
  }


  async function setupSketcher2() {
    try {
      console.log('inside setup2 test here')
      //@ts-ignore
      const marvinNameSpace = await MarvinJSUtil.getPackage("#sketch2");
      const m = marvinNameSpace.sketcherInstance as MarvinInstance;
      marvinNameSpace.Sketch.license("data:text/xml," + licenseData);
      setMarvin2(m);
      console.log('now marvin2 =', marvin2, marvinNameSpace, m);
      // initControl();
    } catch (e) {
      console.warn("Couldn't load", e);
    }
  }


  const open = (editPolymer != null);
  const action = editPolymer;
  console.log('action === ', action)


  const verificationStatusList = [
    'VERIFIED',
    'UNVERIFIED'
  ]

  const handleVerificationStatus = (event: any) => {
    setVerificationStatus(event.target.value);
  }
  //function is not in use hence commented
    /*const handleAudit = (val: any) => {
    setAuditDescription(val.target.value);
  }*/


  React.useEffect(() => {
    console.log('use effect of dialog')
    if (!marvin) {
      console.log('inside if >.>>>>>')
      return;
    }

    if (structureData != null && structureData.sruMolcontent != null && structureData.sruSmiles != null) {
      marvin.importStructure('mol', structureData.sruMolcontent);
      setDataSmile(structureData.sruSmiles)
      setMol1(structureData.sruMolcontent);
      console.log('setting marvin1')
      setEnableValidateBtn1(false);
    } else {
      setEnableValidateBtn1(true);
    }

    marvin.off("molchange", null);

    marvin.on("molchange", () => {
      marvin.exportStructure("smiles").then(
        (smile) => {
          setDataSmile(smile);
          if (smile == "") {
            setEnableValidateBtn1(true);
          } else {
            setEnableValidateBtn1(false);
          }
          console.log('smile 1 =', smile)
        }, (err) => { console.log('error here ==', err) })
      marvin.exportStructure("sdf").then(
        (sdf) => {
          setSdf1(sdf);
          // console.log('sdf 1 =', sdf)
        })
      marvin.exportStructure("mol").then(
        (mol) => {
          setMol1(mol);
          dispatch(getSRUCheckFixer(mol));
          //  console.log('mol 1=', mol)
        })
    })

  }, [props.MRVchanged, marvin, open]);



  React.useEffect(() => {
    console.log('use effect of dialog marvin 2', marvin2)
    if (!marvin2) {
      console.log('inside if marvin 2 >.>>>>>')
      return;
    }

    console.log('marvin 2 working')
    if (structureData != null && structureData.sourceMolcontent != null && structureData.sourceSmiles != null) {
      marvin2.importStructure('mol', structureData.sourceMolcontent);
      setDataSmile2(structureData.sourceSmiles);
      setMol2(structureData.sourceMolcontent);
      console.log('setting marvin2')
      setEnableValidateBtn2(false);
    } else {
      console.log('empty smiles =====')
      setEnableValidateBtn2(true);
    }
    console.log('enableValidateBtn2 ==', enableValidateBtn2)
    marvin2.off("molchange", null);
    marvin2.on("molchange", () => {
      marvin2.exportStructure("smiles").then(
        (smile) => {
          if (smile == "") {
            setEnableValidateBtn2(true);
            console.log('google =====')
          } else {
            console.log('google =====')
            setEnableValidateBtn2(false);
          }
          setDataSmile2(smile);
          console.log('smile 2=', smile)
        })
      marvin2.exportStructure("sdf").then(
        (sdf) => {
          setSdf2(sdf);
          console.log('sdf 2=', sdf)
        })
      marvin2.exportStructure("mol").then(
        (mol) => {
          setMol2(mol);
          dispatch(getSourceCheckFixer(mol));
          console.log('mol 2=', mol)
        })
    })

  }, [props.MRVchanged, marvin2, open]);


  React.useEffect(() => {
    const restrictData = getRestrictionData ? getRestrictionData.data : '';
    if (restrictData.email.length > 0 || restrictData.groupName.length > 0) {
      if (structureData != null && structureData.sruSmiles == null && structureData.sourceSmiles == null) { // for restricted domains even if structures r present shld return null for other domain's api call
        setDisableRestrictPanel(true);
      } else {
        setDisableRestrictPanel(false);
      }
    } else {
      setDisableRestrictPanel(false);
    }


    if (restrictData.email.length > 0 || restrictData.groupName.length > 0) {
      setenableValidateBtn1Restrict(true);
      setenableValidateBtn2Restrict(true);
      console.log('google =====')

    } else {
      setenableValidateBtn1Restrict(false);
      console.log('google =====')
      setenableValidateBtn2Restrict(false);
    }



  }, [props.MRVchanged, marvin, marvin2])


  const saveStructure = () => {
    console.log('values == dataSmile=', dataSmile, ' dataSmile2=', dataSmile2, ' molFormula=', molFormula, 'prfSmiles =', prfSmiles, 'substituentSmiles =', substituentSmiles)
    console.log('flag =', dataSmile == undefined, 'd2', dataSmile2 == undefined, 'mf=', molFormula == '', 'prf =', prfSmiles == '', 'sub =', substituentSmiles == '')
    console.log('structureData =', structureData, structureData.sruSmiles, structureData.sruSmiles == null, structureData.sruSmiles == '', typeof structureData.sruSmiles)
    // delete = prev full current empty
    // add = prev empty current empty
    // if (structureData != null && (structureData.sruSmiles != null && (dataSmile == undefined || dataSmile == '')) && (structureData.sourceSmiles != null && (dataSmile2 == undefined || dataSmile2 == '')) &&
    // (structureData.molecularFormula != null && molFormula == '') && (structureData.prfSmiles != null && prfSmiles == '' )


    if ((dataSmile == undefined || dataSmile == '') && (dataSmile2 == undefined || dataSmile2 == '') && molFormula == '' && prfSmiles == '' && substituentSmiles == '') {
      dispatch(showConfirmBox({ 's': 'some text' }));
    } else if (structureData != null && structureData.sruSmiles == dataSmile && structureData.sourceSmiles == dataSmile2 && structureData.prfSmiles == prfSmiles && structureData.substituentSmiles == substituentSmiles && verificationStatus == structureData.verification && molFormula == structureData.molecularFormula) {
      console.log('No Change *********************')
      onClose();
    } else {
      saveStructureAPI();
    }
  }

  const saveStructureAPI = () => {
    console.log('save api tsx *********************')
    var obj: addEditPolymerStrcut = {
      //name: structureData.name,
      name: itemDetail.backboneIdentity,
      sruMolecule: (dataSmile == undefined) ? "" : dataSmile,
      sourceMolecule: (dataSmile2 == undefined) ? "" : dataSmile2,
      molecularFormula: molFormula,
      sruMolContent: (mol1 == undefined || dataSmile == "") ? "" : mol1,
      sourceMolContent: (mol2 == undefined || dataSmile2 == "") ? "" : mol2,
      prfSmiles: prfSmiles,
      substituentSmiles: substituentSmiles,
      isPolymer: true,
      verification: verificationStatus,
      status: "A",
      auditDescription: auditDescription
    }

    console.log('structure obj  ==', obj)
    //console.log('structureData ==', structureData)
    dispatch(addEditPolymerStrcuture(obj));
    setisSaving(true);
    setDataSmile("");
    setDataSmile2("");
  }

  const inputSmiles = (event: any) => {
    // setEnableValidateBtn(true);
    localStorage.setItem('sourceSmiles', event.target.value);
    if (marvin) {
      marvin.importStructure('smiles', event.target.value);
    }
  }

  const inputSmiles2 = (event: any) => {
    // setEnableValidateBtn(true);
    localStorage.setItem('sourceSmiles2', event.target.value);
    if (marvin2) {
      marvin2.importStructure('smiles', event.target.value);
    }
  }



  if (open) {
    console.log('open flag true')
    if (!marvin) {
      console.log('marvn null call seup')
      setTimeout(() => {
        setupSketcher();
      }, 1000)
    }


    if (!marvin2) {
      console.log('marvin 2 null call seup')
      setTimeout(() => {
        setupSketcher2();
      }, 1000)
    }

  }


  function getJson() {
    let sdf1Str = "";
    if (sdf1 != undefined) {
      let arr1 = sdf1.split("SMT");
      if (arr1.length) {
        arr1.map((i: any) => {
          let o = i.split('\n')[0].split(" ");
          o = o[o.length - 1];
          if (o.length > 0) {
            sdf1Str = sdf1Str + ' ' + o;
          }
        })
      }
    }


    let sdf2Str = "";
    if (sdf2 != undefined) {
      let arr1 = sdf2.split("SMT");
      if (arr1.length) {
        arr1.map((i: any) => {
          let o = i.split('\n')[0].split(" ");
          o = o[o.length - 1];
          if (o.length > 0) {
            sdf2Str = sdf2Str + ' ' + o;
          }
        })
      }
    }


    let obj = {
      smile1: dataSmile,
      sdf1: sdf1,
      mol1: mol1,
      dp1: sdf1Str,
      smile2: dataSmile2,
      sdf2: sdf2,
      mol2: mol2,
      dp2: sdf2Str
    }

    console.log('Here =', obj);
  }

  const [sruErrorData, setsruErrorData] = React.useState([]);
  let v1: any = [];
  const sruValidate = () => {
    setTimeout(() => {
      if (sruCheckerFixer !== null) {
        if (sruCheckerFixer.data.steps !== null) {
          for (let i = 0; i < sruCheckerFixer.data.steps.length; i++) {
            if (sruCheckerFixer.data.steps[i].description !== null) {
              let d: any = 'SRU_Smiles :' + sruCheckerFixer.data.steps[i].description;
              setSruErrFlag(true);
              v1.push(d);
              dispatch(makeError("This structure has the possibility to be enantiomeric. It is vital to specify enantionmers if they are known. Please review the structure and modify as appropriate. If it is a racemic mixture e.g., as is the case for many polymers, or it is unknown, then you can save as the racemic structure."));
            }
          }
        }
        setsruErrorData(v1);
        if (v1.length === 0) {
          setSruErrFlag(false);
          dispatch(makeSuccessMessage("SRU Structure is valid as per Checker Fixer Validation."));
        }

        if (marvin && sruCheckerFixer.data != null && sruCheckerFixer.data.result != null) {

          marvin.importStructure("sdf", sruCheckerFixer.data.result);
        }
      }
    }, 1000);
  }


  const [errorData, setErrorData] = React.useState([]);
  let v2: any = [];
  const sourceValidate = () => {
    setTimeout(() => {
      if (sourceCheckerFixer !== null) {
        if (sourceCheckerFixer.data.steps !== null) {
          for (let i = 0; i < sourceCheckerFixer.data.steps.length; i++) {
            if (sourceCheckerFixer.data.steps[i].description !== null) {
              let d: any = 'Source_Smiles :' + sourceCheckerFixer.data.steps[i].description;
              setSourceErrFlag(true);
              v2.push(d);
              dispatch(makeError("This structure has the possibility to be enantiomeric. It is vital to specify enantionmers if they are known. Please review the structure and modify as appropriate. If it is a racemic mixture e.g., as is the case for many polymers, or it is unknown, then you can save as the racemic structure."));
            }
          }
        }
        setErrorData(v2);
        if (v2.length === 0) {
          setSourceErrFlag(false);
          dispatch(makeSuccessMessage("Source Structure is valid as per Checker Fixer Validation."));
        }

        if (marvin2 && sourceCheckerFixer.data != null && sourceCheckerFixer.data.result != null) {
          //Issue fixed for export mol Code change from Smiles to SDF format 
          marvin2.importStructure("sdf", sourceCheckerFixer.data.result);
        }
      }
    }, 1000);
  }



  const saveValidation = () => {
    //if (DisableRestrictPanel) { NO need to consider this restriction because its already habled in edit n view icon /button 
    //  console.log(' intestr restrict panel ==')
    //  return true;
    //} else
    if (isSaving) {
      console.log(' intestr err flags ==')
      return true;
    } else if ((dataSmile == undefined || dataSmile == "") && (dataSmile2 == undefined || dataSmile2 == "")) {
      console.log(' intestr smiles ==')
      return true;
    }
    //  else if (prfSmiles == !structureData.prfSmiles || substituentSmiles != structureData.substituentSmiles) {
    //  console.log(' intestr prf ==')
    //  return false;
    //}
    else {
      console.log(' intestr outer else ==')
      return false
    }


    //  return (DisableRestrictPanel || sruErrFlag || SourceErrFlag || isSaving || (structureData.sruSmiles == dataSmile &&
    //  structureData.sourceSmiles == dataSmile2) && (prfSmiles == structureData.prfSmiles && substituentSmiles == structureData.substituentSmiles &&
    //verificationStatus == structureData.verification && auditDescription == structureData.auditDescription &&
    //molFormula == structureData.molecularFormula))  
  };
  const { confirm } = useSelector((state: AppState) => state.polymersData);

  const onCloseBox = useCallback(
    () => {
      dispatch(closeConfirmBox())
    }, [dispatch]
  );

  const openBox = (confirm != null);

  console.log('openBox ==', openBox)
  console.log('openEdit =', open)
  const yesClicked = () => {
    saveStructureAPI();
    onCloseBox();
  }

  //const { inProgress } = useSelector((state: AppState) => state.importExport)
  const inProgress = true



  return (
    <><Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg" disableEscapeKeyDown disableBackdropClick>
      <MuiDialogTitle>
        <Grid justify="space-between" container spacing={6}>
          <Grid item>
            {action && action.action ? action.action : ''} Parent Polymer
          </Grid>
          <Grid>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      {/*{dataSmile}*/}
      {/*<Button type="button" variant="contained" onClick={ getJson} color="primary" className={classes.marLeft}>*/}
      {/*  SAVE*/}
      {/*</Button>*/}
      {/*Editor 1*/}

      <DialogContent>
        <div className={classes.outerDivL}>

          <div className={classes.lableTxt}>Parent SIR ID:</div>
          <div>
            <TextField
              type="text"
              value={(structureData != null && structureData.sirIdentity) ? structureData.sirIdentity : "NA"}
              //value={itemDetail.sirIdentity}
              disabled={action.action == 'Edit' || action.action == 'View'}
              className={classes.parentText} />
          </div>
        </div>
        <div className={classes.outerDivL}>

          <div className={classes.lableTxt}>PRF Smiles:</div>
          <div>
            <TextField
              type="text"
              placeholder="PRFSmiles"
              disabled={action.action == 'View'}
              onChange={e => { setprfSmiles(e.target.value); }}
              value={prfSmiles}
              className={classes.prfText} />
          </div>
        </div>
        <div className={classes.outerDivL}>
          <div className={classes.lableTxt}>Parent Name:</div>
          <div>
            <TextField
              type="text"
              value={(structureData != null && structureData.name) ? structureData.name : "NA"}
              // value={itemDetail.backboneIdentity}
              disabled={action.action == 'Edit' || action.action == 'View'}
              className={classes.parentNameText} />
          </div>
        </div>

        <div className={classes.outerDivL}>
          <div className={classes.lableTxt}>Substituent Smiles:</div>
          <div>
            <TextField
              type="text"
              placeholder="substituentSmiles"
              disabled={action.action == 'View'}
              onChange={e => { setsubstituentSmiles(e.target.value); }}
              value={substituentSmiles}
              className={classes.subText} />
          </div>
        </div>
        <div className={classes.outerDivL}>
          <div className={classes.lableTxt}>Molecular Formula:</div>
          <div>
            <TextField
              type="text"
              disabled={action.action == 'View'}
              onChange={e => { setMolFormula(e.target.value); }}
              placeholder="MolFormula"
              value={molFormula}
              className={classes.molFormulaText} />
          </div>
        </div>
        <div className={classes.outerDivL}>
          <div className={classes.lableTxt}>Verification Status:</div>
          <div>
            <FormControl>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                //multiple
                value={verificationStatus}
                onChange={handleVerificationStatus}
                className={classes.selectEmpty1}
                disabled={action.action == 'View'}
              >
                {verificationStatusList && verificationStatusList.map((verificationStatus) => (
                  <MenuItem key={verificationStatus} value={verificationStatus}>
                    {verificationStatus}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.outerDivL}>
          <div className={classes.lableTxt}>Audit Description:</div>
          <div>
            <TextField
              type="text"
              placeholder="AuditDescription"
              disabled={action.action == 'View'}
              onChange={e => { setAuditDescription(e.target.value); }}
              value={auditDescription}
              className={classes.subText} />
          </div>
        </div>
        <div className={classes.seperator}></div>
        <div className={classes.outerDivL}>
          <div id={"placeHolderDiv1"}>
            <span className={classes.lableTxt}>SRU STRUCTURE and SMILES: </span><br /><br />
            <iframe className={classes.iframe} title={`MarvinJS`} id="sketch" data-toolbars="reporting" sandbox="allow-same-origin allow-scripts allow-forms" src="/marvinjs/editorws.html" />
            <textarea name="message" id="structure" value={dataSmile} className={classes.textarea} rows={7} cols={89.5} placeholder="Type here..." onChange={e => { setDataSmile(e.target.value); inputSmiles(e); }} disabled={action.action == 'View'} />
            {action && action.action == 'Edit' && user.isAdmin && <Button type="button" variant="contained" name="Validate" color="primary" className={classes.btn} onClick={sruValidate} disabled={enableValidateBtn1}>
              Validate
            </Button>}
            {action && action.action == 'Edit' && !user.isAdmin && <Button type="button" variant="contained" name="Validate" color="primary" className={classes.btn} onClick={sruValidate} disabled={!user.isAdmin}>
              Validate
            </Button>}
            {sruErrFlag ? <div className={classes.textLeft}>Error Description: {sruErrorData.map((error: any, index: any) => (
              <p className={classes.error} key={index}>
                {error}
              </p>
            ))} </div> : null}
          </div>

          {/*Editor 2*/}
          <div id={"placeHolderDiv2"}>
            <span className={classes.lableTxt}>SOURCE STRUCTURE and SMILES: </span><br /><br />
            <iframe className={classes.iframe} title={`MarvinJS`} id="sketch2" data-toolbars="reporting" sandbox="allow-same-origin allow-scripts allow-forms" src="/marvinjs/editorws.html" />
            <textarea name="message" id="structure" value={dataSmile2} className={classes.textarea} rows={7} cols={89.5} placeholder="Type here..." onChange={e => { setDataSmile2(e.target.value); inputSmiles2(e); }} disabled={action.action == 'View'} />
            {action && action.action == 'Edit' && user.isAdmin && <Button type="button" variant="contained" name="Validate" color="primary" className={classes.btn} onClick={sourceValidate} disabled={enableValidateBtn2}>
              Validate
            </Button>}
            {action && action.action == 'Edit' && !user.isAdmin && <Button type="button" variant="contained" name="Validate" color="primary" className={classes.btn} onClick={sourceValidate} disabled={!user.isAdmin}>
              Validate
            </Button>}
            {(SourceErrFlag) ? <div className={classes.textLeft}>Error Description: {errorData.map((error: any, index: any) => (
              <div className={classes.error} key={index}>
                {error}
              </div>
            ))} </div> : null}

          </div>
        </div>




        {user.isAdmin ? <div className={classes.saveBtn}>
          {action && action.action == 'Edit' && <Button type="button" variant="contained" onClick={saveStructure} color="primary" className={classes.svBtn}>
            {/*disabled={saveValidation()}*/}
            SAVE

            {/*{inProgress && <CircularProgress size={20} color="secondary" />}*/}
          </Button>}


        </div> : <div className={classes.saveBtn}>
          {action && action.action == 'Edit' && <Button type="button" variant="contained" onClick={saveStructure} color="primary" className={classes.svBtn} disabled={!user.isAdmin}>
            SAVE
          </Button>}
        </div>}
      </DialogContent>
    </Dialog>

      <Dialog open={openBox} onClose={onCloseBox} maxWidth="lg">
        <MuiDialogTitle>


          <Grid justify="space-between" container spacing={6}>
            <Grid item>

            </Grid>
            <Grid item>
              <div className={classes.topRight}>

                <IconButton aria-label="close" onClick={onCloseBox}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </MuiDialogTitle>

        <DialogContent>
          <div>
            <div className={classes.a}>You are trying to save the Parent Polymer Substance with NO STRUCTURE INFO. </div>
            <div className={classes.b}>  If you are able to, please add at least one of the following fields: </div>
            <FiberManualRecordIcon /> <span className={classes.bullet}>PRF Smiles</span> <FiberManualRecordIcon /> <span className={classes.bullet}> Substituent Smiles </span> <FiberManualRecordIcon /> <span className={classes.bullet}> SRU Smiles </span> <FiberManualRecordIcon /> <span className={classes.bullet}> Source Smiles </span> <FiberManualRecordIcon /> <span className={classes.bullet}>Molecular Formula </span>
            <div className={classes.c}><WarningIcon /> Are you sure ?</div> </div>
          <div className={classes.k}>
            <Button type="button" variant="contained" color="primary" onClick={yesClicked} size='large' className={classes.yes}>  YES  </Button>
            <Button type="button" variant="contained" color="primary" size='large' onClick={onCloseBox} className={classes.yes}>  NO  </Button>
          </div>
        </DialogContent>
      </Dialog></>

  );
}


//export  const ConfirmBoxDialog=(props: any)=> {
//  const classes = useStyles();
//  const { confirm } = useSelector((state: AppState) => state.polymersData);
//  const dispatch = useDispatch();

//  const onClose = useCallback(
//    () => {
//      dispatch(closeConfirmBox())
//    }, [dispatch]
//  );

//  const open = (confirm != null);

//  const yesClicked = () => {
//  //  saveStructure()
//  }



//  console.log('here ==', open)
//  return (
//    <Dialog open={open} onClose={onClose} maxWidth="sm">
//      <MuiDialogTitle>


//        <Grid justify="space-between" container spacing={6}>
//          <Grid item>

//          </Grid>
//          <Grid item>
//            <div className={classes.topRight}>

//              <IconButton aria-label="close" onClick={onClose}>
//                <CloseIcon />
//              </IconButton>
//            </div>
//          </Grid>
//        </Grid>
//      </MuiDialogTitle>

//      <DialogContent>
//        <div className={classes.centerText}>
//          You are saving this parent polymer substance with no STUCTURE INFO <br></br>
//          Please add at least one field (PRF or Substituent or SRU or Source Smiles or Molecular Formula) if you can. <br></br>
//          Are you sure you want to save? </div>
//        <div className={classes.saveBtn}>
//          <Button type="button" variant="contained" color="primary" onClick={yesClicked} className={classes.yes}>  YES  </Button>
//          <Button type="button" variant="contained" color="primary" onClick={onClose} >  NO  </Button>
//        </div>
//      </DialogContent>
//    </Dialog>
//  );
//}
