import { useState, useCallback } from 'react';
import { SearchState, initialState } from '../store/search/types';
import { searchOnlyPolymerApi } from '../store/search/utilities';

const useOnlyPolymerSearchReactState: () => [SearchState, (inputValue: string, maxResults: number, filter: string | undefined, removeForMerge?: string | undefined) => void, () => void] =
  () => {
    const [searchState, setSearchState] = useState(initialState);
  
    const onSearchTermChanged = useCallback(
      (inputValue: string, maxResults: number, filter: string | undefined, removeForMerge?: string | undefined) => searchOnlyPolymerApi(inputValue, searchState, setSearchState, "", maxResults, filter, removeForMerge),
      [searchState, setSearchState]
    );

    const clearResults = useCallback(() => setSearchState(initialState), []);

    return [searchState, onSearchTermChanged, clearResults];
  }

export default useOnlyPolymerSearchReactState;
