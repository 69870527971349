import React, { useCallback, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { Button, ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
//import { showAddItem } from '../store/addItem/actions';
import { NodeData } from '../store/hierarchy/types';
import { ItemDetailState } from '../store/itemDetail/types';
//import AddDialog from '../AddDialog/AddDialog';
//import { AddLinkFormValues } from '../store/entity/types';
import AddDialogContributionFactor from './AddDialogContributionFactor';
import { LookupState } from '../store/lookupData/types';
import { AddContributionFactor } from '../store/entity/actions';
//import AddEditDialogContributionFactor from './AddEditDialogContributionFactor';
import { closeSubcompEdit, getSubComponentSearchRequest } from '../store/subComponent/actions';
//import { locateItem } from '../store/hierarchy/actions';
//import { showDetail } from '../store/itemDetail/actions';
import { itemSelected } from '../store/search/actions';

const useStyles = makeStyles(theme => ({
  button: {
    margin: "5px",
  },
  icon: {
    width: 16,
    height: 16
  },
}));

const defaultLookup: { [id: string]: string; } = {
  "Edit Contribution": "Edit Contribution",
  "Add Contribution": "Add Contribution"
};

const AddNewBtnCFactor = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: AppState) => state.user);
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const dispatch = useDispatch();
  const [openAddCF, setOpenAddCF] = useState(false);
  const onAddLinkDialogClose = useCallback(() => {
    setOpenAddCF(false); localStorage.removeItem('addTableData');
    localStorage.removeItem('searchType');
    let item = {
      name: "",
      type: "",
      subType: "",
      status: "",
      substanceId: "",
      auditDescription: "",
    }
    dispatch(itemSelected(item));
    //dispatch<any>(locateItem({ name: "", type: "", subType: "", status: "", substanceId: "" }, true, ""));
    //dispatch<any>(showDetail({ name: "", type: "", subType:"", status: "" }));
    dispatch(closeSubcompEdit());
  }, [setOpenAddCF]);
  const lookupData: LookupState = useSelector((state: AppState) => state.lookup);
  const getSubComponentreducer = useSelector((state: any) => state.subComponentReducer);

  const onDropdown = useCallback(() => {
    setOpen(s => !s);
  }, [setOpen]);

  const onClose = () => {
    setOpen(false);
  }
  const onAddContributionFactor = () => {
    dispatch(closeSubcompEdit());
    dispatch(getSubComponentSearchRequest.request(""));
    setOpenAddCF(true);
  }

  let selectedItemVal = (val: any) => {
      let data = JSON.stringify(val);
      localStorage.setItem('addTableData', data);
  }

  const onSubmitAddLink = useCallback(
    (formValues: any, doClose: Function, setSubmitting: Function, ) => {     
        dispatch(AddContributionFactor(formValues, doClose, setSubmitting, false))
    },
    [dispatch]
  );
  const addLinkFormConfig: any = {
    formType: "AddContributionFactor",
    title: "Add Contribution Factor",
    moleculeWeightLabel: "Mol. Weight",
   parentNameLabel: "Parent",
    statusLabel: "Status",
    statusMenuItems: lookupData.status,
    parentEntity: null,
    auditDescriptionLabel: "Audit Description",
    submitAdd: onSubmitAddLink,
    onClose: onAddLinkDialogClose,
  };


  return (
    <div>
    <span>
      <ButtonGroup variant="contained" color="primary" disabled={!user.isAdmin} className={classes.button} ref={anchorRef} >
          <Button onClick={onAddContributionFactor} variant="contained" color="primary">
         Contribution Factors
        </Button>
        <Button
          color="primary"
          size="small"
          onClick={onDropdown}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>

                <MenuList id="split-button-menu">
                    <MenuItem key="Add Contribution" onClick={() => onAddContributionFactor()}>
                    Add Contribution Factor
                    </MenuItem>
                   
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>    </span>
    <AddDialogContributionFactor
        open={openAddCF}
        config={addLinkFormConfig}
        selectedItem={selectedItemVal}
    />
    
      </div>
  )
}

export default AddNewBtnCFactor;
