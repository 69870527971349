import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import UnileverLogo from '../theme/UnileverLogo.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { authenticatedUser } from '../store/user/actions';

const useStyles = makeStyles({
  main: {
    flexGrow: 1
  },
  Appbar: {
    backgroundColor: '#fff',
    height: 60
  },
  logo: {
    height: "50px",
    padding: "5px"
  }
});

const MainAppBar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticatedUser());
  }, [dispatch]);

  const classes = useStyles();
  const user = useSelector((state: AppState) => state.user);
  localStorage.removeItem('showInactiveSynonyms');
  return (
    <div className={classes.main}>
      <div className="header">
        <div className="head">
          <img alt="Unilever logo" className={classes.logo} src={UnileverLogo} />
          <div className="title">Substance Identity Repository</div>
        </div>
        <div className="userDetails">
          <div className="loginLogout"><div>Hello,</div>&nbsp;<a href='/.auth/login/aad?post_login_redirect=%2F'>{user.userName}</a></div>
          {user.isAdmin ? <div className="role">SIR Administrator</div> : <div className="role">SIR Viewer</div>}
        </div>
      </div>
    </div>
  );
};

export default MainAppBar;
