import React from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { AddLinkFormValues, AddLinkFormConfig } from '../store/entity/types';
import SearchField from '../SearchBox/SearchField';
import { Suggestion } from '../store/search/types';
import {getParentFilter, getChildFilter} from "../domain/FilterHelper";

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  searchFieldSpacer: {
    marginTop: "20px"
  }
});

type Props = {
  config: AddLinkFormConfig
};

export const UnlinkItemsFormInner = (props: Props & FormikProps<AddLinkFormValues>) => {
  const classes = useStyles();
  const {
    childNameLabel,
    parentNameLabel,
    domainNameLabel,
    domainNameMenuItems,
    auditDescriptionLabel,
  } = props.config;
  const { values } = props;
  const childFilter = getChildFilter(values.parentItem);
  const parentFilter = getParentFilter(values.childItem);

  return (
  <Form>
    <div>
    <InputLabel className={classes.searchFieldSpacer}>{childNameLabel}</InputLabel>
    <SearchField
        name="childItem"
        filter={childFilter}
        />
    <InputLabel className={classes.searchFieldSpacer}>{parentNameLabel}</InputLabel>
    <SearchField
      name="parentItem"
      filter={parentFilter}
      />
    <InputLabel className={classes.searchFieldSpacer} htmlFor="domain-name-input">{ domainNameLabel }</InputLabel>
    <Field
      className={classes.field}
      name="linkDomain"
      component={Select}
      margin="dense"
      fullWidth
      inputProps={{ id: 'domain-name-input' }}>
      { domainNameMenuItems.map((item) =>
        <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
      )}
    </Field>
    <InputLabel htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
    <Field
      className={classes.field}
      inputProps={{ id: 'audit-description-input' }}
      name="auditDescription"
      component={TextField}
      margin="dense"
      fullWidth/>
    <Button type="submit" color="primary">
      Remove
    </Button>
    </div>
  </Form>
)};

const emptySuggestion: Suggestion = {
  name: "",
  type: "",
  subType: "",
  status: "",
  substanceId: ""
};

const UnlinkItemsForm = withFormik<Props, AddLinkFormValues>({
  mapPropsToValues: (props) => ({childItem: emptySuggestion, parentItem:emptySuggestion, status: "A", linkDomain: "", isPrimaryForDomain: false, linkStatus: "A", auditDescription:""}),
  handleSubmit: ( formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  enableReinitialize: true,
})(UnlinkItemsFormInner);

export default UnlinkItemsForm;
