import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PathDisplay from '../DetailPanel/PathDisplay';
import { Button, TablePagination } from '@material-ui/core';
import DetailTitle from '../DetailPanel/DetailTitle';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import MuiAlert from '@material-ui/lab/Alert';
import { postComment } from '../store/comment/actions';

const useStyles = makeStyles({
  table: {
    minWidth: 630,
  },
  msgBlock: {
    display: "flex"
  },
  msgBlockMain: {
    display: "flex",
    justifyContent: "space-between"
  },
  username: {
    color: "blue",
    fontSize: "14px",
    fontWeight: 500,
    paddingRight: "5px"
  },
  timestamp: {
    fontSize: "14px",
    fontWeight: 500,
    color: "gray",
    paddingRight: "5px"

  },
  admin: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#800000",
    paddingRight: "5px"

  },
  public: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#008000",
    paddingRight: "5px"

  },
  description: {
    fontSize: "14px",
    paddingTop: "10px",
    textAlign: "unset",
  },
  msgList: {
    padding: "10px",
    borderRadius: "5px",
    borderWidth: "1px",
    borderColor: "lightgray",
    borderStyle: "solid",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    marginBottom: "10px",
  },
  paper: {
    padding: 5,
    //textAlign: 'center' as 'center',
    overflow: "auto",
    height: "969px",
  },
  textarea: {
    width: "99%",
    marginTop: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    borderWidth: "1px",
    borderColor: "lightgray",
    borderStyle: "solid",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  btnGrp: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  marLeft: {
    marginLeft: "5px",
  },
  marBottom: {
    marginBottom: "10px",
    paddingRight: "5px",
    paddingLeft: "5px"
  }
});

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


type Props = {
  isCompositeSub?: boolean;
};

export default function CommentComponent(props: Props) {
  const { isCompositeSub } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const node = detailData.selectedNode;
  const itemDetail = detailData.itemDetail;
  const [comment, setComment] = React.useState('');
  const [commentType, setCommentType] = React.useState('');
  const [showAdmin, setShowAdmin] = React.useState(false);
  const [showPublic, setShowPublic] = React.useState(false);
  const commentList = useSelector((state: any) => state.commentReducer);
  const nameFlag = useSelector((state: any) => state.flagReducer);
  const isNameFlag = nameFlag.flagDetails;
  const userdetails = useSelector((state: AppState) => state.user);
  let isAdmin = userdetails.isAdmin;
  let substancename = localStorage.getItem('nodename');
  const handleChange = (e: any) => {
    setComment(e.target.value);
  }
  const dispatch = useDispatch();
  const putCommentApi = useCallback(
    (name: string, commentType: string, description: string) => {
      const Name = name;
      const CommentType = commentType;
      const Description = description;
      dispatch(postComment(Name, CommentType, Description))
    },
    [dispatch]
  );
  const adminComment = (substancename: any, commentType: any, comment: any) => {
    (document.getElementById('comment') as any).value = '';
    if (comment !== '') {
      setShowAdmin(true);
      setCommentType('Admin');
      putCommentApi(substancename, commentType, comment);
    }
    setTimeout(function () {
      setShowAdmin(false);
      setComment('');
      setCommentType('');
    }, 5000);
  }
  const publicComment = (substancename: any, commentType: any, comment: any) => {
    (document.getElementById('comment') as any).value = '';
    if (comment !== '') {
      setShowPublic(true);
      setCommentType('Public');
      putCommentApi(substancename, commentType, comment);
    }
    setTimeout(function () {
      setShowPublic(false);
      setComment('');
      setCommentType('');
    }, 5000);
  }

  let UpdatedResponse: any = [];
  if (commentList.backboneIdentity !== undefined) {
    (commentList.comments as any).forEach((e: any) => {
      const dateTime = e.commentDateTime;
      const modifiedDateTime = dateTime.split('T');
      const date = modifiedDateTime[0].split("-");
      const finalDate = date[2] + "-" + date[1] + "-" + date[0];
      const time = modifiedDateTime[1].split(":");
      const finalTime = time[0] + ":" + time[1];
      const finalDateTime = (finalDate + " " + finalTime);
      let object = {};
      object = {
        commentDateTime: finalDateTime,
        commentType: e.commentType,
        commentedUserName: e.commentedUserName,
        description: e.description,
      };
      UpdatedResponse.push(object);
    });
  }
  return (
    <Paper className={classes.paper} >
      {node && <DetailTitle name={itemDetail.backboneIdentity} type={detailData.type} subType={node.subType} isNameFlag={isNameFlag} status={node.status} tabName={'commenttab'} isCompositeSub={isCompositeSub} />}
      {itemDetail &&
        itemDetail.classificationPaths &&
        <React.Fragment>
          <PathDisplay paths={itemDetail.classificationPaths} />
        </React.Fragment>
      }
      <textarea name="message" id="comment" className={classes.textarea} rows={7} cols={89.5} placeholder="Type here..." onChange={handleChange} ></textarea>
      <div className={classes.btnGrp}>
        {isAdmin ? < Button type="button" variant="contained" name="Admin" color="primary" onClick={(e) => adminComment(substancename, 'Admin', comment)}>
          Admin Comment
        </Button> : null}
        <Button type="button" variant="contained" name="user" color="primary" onClick={(e) => publicComment(substancename, 'Public', comment)} className={classes.marLeft}>
          Public Comment
        </Button>
      </div>
      {showAdmin ? <div className={classes.marBottom} >< Alert severity="success">"{comment}" Successfully added as an {commentType} Comment</Alert></div> : null}
      {showPublic ? <div className={classes.marBottom} >< Alert severity="success">"{comment}" Successfully added as a {commentType} Comment</Alert></div> : null
      }
      {commentList.backboneIdentity !== undefined ?
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody className="compositetabcontentBody">
                {UpdatedResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
                  <TableRow key={row.description}>
                    <div className={classes.msgList} >
                      <div className={classes.msgBlockMain}>
                        <div className={classes.msgBlock}>
                          <div className={classes.username}> {row.commentedUserName}</div>
                          <div className={classes.timestamp}>added a comment -</div>
                          <div className={classes.timestamp}>{row.commentDateTime}</div>
                        </div>
                        <div className={row.commentType === "Admin" ? classes.admin : classes.public}>
                          <i className="fa fa-comment"></i>&nbsp;
                          {row.commentType}</div>
                      </div>
                      <div className={classes.description}>
                        {row.description}
                      </div>
                    </div>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            component="div"
            count={UpdatedResponse.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        : null}
    </Paper>
  );
}
