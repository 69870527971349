import React, { useCallback, useState } from 'react';
import { SirSearch } from './SirSearch';
import { debounce } from 'lodash';
import { Suggestion } from '../store/search/types';
import useSearchReactState from './useSearchReactState';
import { ControllerStateAndHelpers } from 'downshift';
import { useSelector } from 'react-redux';
import usePolymerSearchReactState from './usePolymerSearchReactState';
import useOnlyPolymerSearchReactState from './useOnlyPolymerSearchReactState';

import { AppState } from '../store';

const InitialMax = 20;
const Multiplier = 10;

const triggerSearch = (target: (inputValue: string,  state: ControllerStateAndHelpers<Suggestion>) => void) => debounce(target, 1000);

interface Props {
  value: Suggestion;
  filter?: string | undefined;
  itemSelected: (item: Suggestion) => void;
  dataSubstance?: (item: Suggestion) => void;
  removeForMerge?: string | undefined;
  isVariant?: string | undefined;
}

const SearchInputBox = (props: Props) => {
  const { value, filter, itemSelected, removeForMerge, isVariant } = props;
 // props.value = "abc";
 let searchData:any;let onSearchTermChanged :any;let clearResults:any;
 const polymerFlag = useSelector((state: AppState) => state.polymersData.polymerFlag);
 let polymerResult = usePolymerSearchReactState();
 let Searchresult = useSearchReactState();
 let onlyPolymerResult = useOnlyPolymerSearchReactState();
  console.log('box ==', document.getElementById('addNewFormDlg'))
  console.log('google ==', document.getElementById('compSearch1'), document.getElementById('compSearch2'), document.getElementById('compSearch3'), document.getElementById('compSearch4'))
  if (isVariant === 'Yes') { // add/edit variant's search box [api returns only parent polymers]
    console.log('only polymer searching ==')
    searchData = onlyPolymerResult[0];
    onSearchTermChanged = onlyPolymerResult[1];
    clearResults = onlyPolymerResult[2];
  }
  //else if (polymerFlag == true && document.getElementById('addNewFormDlg') != null) {
  else if (document.getElementById('addNewFormDlg') != null) { // add new item form's search box [api returns parent polymer + other substance omit variants]
  console.log('polymer searching ==')
   searchData = polymerResult[0];
   onSearchTermChanged = polymerResult[1];
   clearResults = polymerResult[2];
  }
  else if (document.getElementById('compSearch4') != null) { //search box for composite substance form
    console.log('composites component searching ==')
    searchData = Searchresult[0];
    onSearchTermChanged = Searchresult[1];
    clearResults = Searchresult[2];
  }
  else { // global search box [api returns all substances]
   console.log('normal searching ==')
    searchData = Searchresult[0];
    onSearchTermChanged = Searchresult[1];
    clearResults = Searchresult[2];
  //const [searchData, onSearchTermChanged, clearResults] = useSearchReactState();//useSearchReduxState();
 }
  const [maxResults, setMax] = useState(InitialMax);

  const fetchSome = useCallback(
    (name: string, state: ControllerStateAndHelpers<Suggestion>) => {
      if (name.localeCompare(state.selectedItem ? state.selectedItem.name: "") !== 0) {
        setMax(InitialMax);
        if (filter === 'subcomponent') {
          localStorage.setItem('searchType', 'subcomponent');
        } else {
          localStorage.removeItem('searchType');
        }
        onSearchTermChanged(name, InitialMax, filter, removeForMerge);
      }

      // User cleared selection - set selectedItem to empty.
      if (!name && ((state.selectedItem && state.selectedItem.name) || (value && value.name))) {
        state.selectItem({ name: "", type: "", subType: "", status: "", substanceId: ""});
      }
    },
    [onSearchTermChanged, filter, value, removeForMerge]);

    const fetchAll = useCallback(
    (name: string) => {
      const newMax = maxResults * Multiplier
        onSearchTermChanged(name, newMax, filter, removeForMerge);
      setMax(newMax);
    },
      [onSearchTermChanged, maxResults, filter, removeForMerge]);

    const onItemSelected = useCallback(
      (item: Suggestion) => {
        itemSelected(item);
        if (props.dataSubstance) {
          props.dataSubstance(item);
        }
      clearResults();
    },
    [clearResults, itemSelected]);
  return (
    <SirSearch
      matches={searchData.matches}
      notEnoughCharacters={searchData.notEnoughCharacters}
      loading={searchData.loading}
      valueChanged={triggerSearch(fetchSome)}
      fetchAll={fetchAll}
      itemSelected={onItemSelected}
      value={value.name}
      hasMore={searchData.hasMore}
      filter={filter}
      />
  )
};

export default SearchInputBox;
