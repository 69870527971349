import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const initialState: any = {
  backboneIdentity: "",
  sirIdentity: "DEV-000-036-398",
  history: []
};
const combine = (state: any, action: any) => {
  return { ...state, backboneIdentity: action.payload.backboneIdentity, history: action.payload.history, sirIdentity: action.payload.sirIdentity }
}
export const structureHistoryReducer = createReducer(initialState)
  .handleAction(actions.requestHistoryStructure.success, (state, action) => combine(state, action))
  .handleAction(actions.requestHistoryStructure.failure, (state, action) => ({ ...state }))
  .handleAction(actions.closeHistoryViewActionStruct, (state, action) => ({ ...state, backboneIdentity: "" }));
