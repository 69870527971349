import { SirMessageState, SirMessage } from "./types";
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

// We keep a few old messages after adding a new one. Even if we don't have a way of displaying,
// this can be handy in debug to be able to go to redux state and see previous messages.
const RETAIN_AGE_MILLIS = 3600000;
const RETAIN_COUNT = 10;

const initialState: SirMessageState = {
    display: false,
    messages: []
  };

const shouldKeep = (newMessage: SirMessage, baseTime: number, index: number) => {
  return ((newMessage.time - baseTime) < RETAIN_AGE_MILLIS) &&
   (index+2 <= RETAIN_COUNT);
}

const addMessageWithPurge = (newMessage: SirMessage, messageList: SirMessage[]) : SirMessage[] => {
  // Insert new message at the top, and purge the existing.
  return [newMessage, ...messageList.filter((m, i) => shouldKeep(newMessage, m.time, i))];
};

export const messageReducer = createReducer(initialState)
.handleAction(actions.messageAction, (state, action) => ({...state, display: true, messages: addMessageWithPurge(action.payload, state.messages) }))
.handleAction(actions.closeMessageAction, (state, action) => ({...state, display: false }));
