import React from 'react';
import { Select, TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field, Form, FormikActions } from 'formik';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';
import { ItemData } from '../store/editItem/types';
import { LookupState } from '../store/lookupData/types';
import DomainEditField from '../DomainSelector/DomainEditField';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { Paper, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
//import { useSelector } from 'react-redux';
//import { AppState } from '../store';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px",
  },
  msgColorRed: {
    color: 'red',
    fontSize: '14px'
  },
  paper: {
    padding: 2,
    margin: 8
  },
  flex: {
    display: "flex"
  },
  font: {
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    color: "rgba(0, 0, 0, 0.87)",
  },
  width: {
    width: "100%"
  },
  removeMar: {
    margin: "0px !important"
  }
});

interface LookupProps {
  lookup: LookupState;
  data?: any;
}

const EditNameForm = (props: LookupProps) => {
  const classes = useStyles();
  const { lookup, data } = props;
  let compositeTypesData: any[] = [];
  const detailData = useSelector((state: AppState) => state.itemDetail);
  // const treeData = useSelector((state: AppState) => state.hierarchy.tree);
  //const commentList = useSelector((state: any) => state.commentReducer);
  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  const enableBtn = (selectedItem && (selectedItem.type === 'Composite' || selectedItem.type === 'Composite substance' || selectedItem.type === 'Composite name')) ? true : false;
  const itemDetail = detailData.itemDetail;
  const backboneIdentity = itemDetail['backboneIdentity'];
  const [showFNR, setShowFNR] = React.useState(false);
  const [showPLM, setShowPLM] = React.useState(false);
  const [showRegulatory, setShowRegulatory] = React.useState(false);
  const [showSafety, setShowSafety] = React.useState(false);
  //const detailData = useSelector((state: AppState) => state.itemDetail);
  //const dataRow = detailData.itemDetail;
  const [showDomainError, setShowDomainError] = React.useState(false);
  const [disableField, setDisableField] = React.useState(false);
  const [fnrChecked, setFnrChecked] = React.useState(data.primaryDomains.includes('F&R') ? true : false);
  const [plmChecked, setPlmChecked] = React.useState(data.primaryDomains.includes('PLM') ? true : false);
  const [regulatoryChecked, setRegulatoryChecked] = React.useState(data.primaryDomains.includes('Regulatory') ? true : false);
  const [safetyChecked, setSafetyChecked] = React.useState(data.primaryDomains.includes('Safety') ? true : false);
  const [showHC, setShowHC] = React.useState(false);
  const [hcChecked, setHcChecked] = React.useState(data.primaryDomains.includes('HC') ? true : false);
  const [showBW, setShowBW] = React.useState(false);
  const [bwChecked, setBwChecked] = React.useState(data.primaryDomains.includes('BW') ? true : false);
  const [showPC, setShowPC] = React.useState(false);
  const [pcChecked, setPcChecked] = React.useState(data.primaryDomains.includes('PC') ? true : false);


  if (selectedItem && (selectedItem.type === 'Composite' || selectedItem.type === 'Composite substance' || selectedItem.type === 'Composite name')) {
    compositeTypesData.push({
      key: 'PLM ING',
      display: 'PLM ING'
    });
    compositeTypesData.push({
      key: 'Synonym',
      display: 'Synonym'
    });
    compositeTypesData.push({
      key: 'Trade Name',
      display: 'Trade Name'
    });
    compositeTypesData.push({
      key: 'PLM Pure',
      display: 'PLM Pure'
    });
  }
  for (let i = 0; i < lookup.nameType.length; i++) {
    if (lookup.nameType[i].display === "Component") {
      lookup.nameType.splice(i, 1);
    }
    if (lookup.nameType[i].display === "Sub Component") {
      lookup.nameType.splice(i, 1);
    }
    if (lookup.nameType[i].display === "PLM ING") {
      lookup.nameType.splice(i, 1);
    }
    if (lookup.nameType[i].display === "Trade Name") {
      lookup.nameType.splice(i, 1);
      break;
    }
  }
  React.useEffect(() => {
    if (backboneIdentity === data.name) {
      setDisableField(true);
    }

    //if (plmChecked === true) {
    //  data.primaryDomains.push('PLM');
    //}
    //if (regulatoryChecked === true) {
    //  data.primaryDomains.push('Regulatory');
    //}
    //if (safetyChecked === true) {
    //  data.primaryDomains.push('Safety');
    //}
  })

  setTimeout(() => {
    const parsedDomain = localStorage.getItem('selectedDomainsForEdit');
    let domainWhileEdit: any = [];
    if (parsedDomain) {
      domainWhileEdit = JSON.parse(parsedDomain);
    }
    if (domainWhileEdit.length <= 0) {
      setShowPLM(false);
      setShowRegulatory(false);
      setShowSafety(false);
    } else {
      // domainWhileEdit.includes('F&R') ? setShowFNR(true) : setShowFNR(false);

      if (domainWhileEdit.includes('F&R')) {
        setShowFNR(true);
      } else {
        setShowFNR(false);
        for (var i = 0; i < data.primaryDomains.length; i++) {
          if (data.primaryDomains[i] === 'F&R') {
            data.primaryDomains.splice(i, 1);
            i--;
            setFnrChecked(false);
          }
        }
      }
      if (domainWhileEdit.includes('PLM')) {
        setShowPLM(true);
      } else {
        setShowPLM(false);
        for (var i = 0; i < data.primaryDomains.length; i++) {
          if (data.primaryDomains[i] === 'PLM') {
            data.primaryDomains.splice(i, 1);
            i--;
            setPlmChecked(false);
          }
        }
      }
      if (domainWhileEdit.includes('Regulatory')) {
        setShowRegulatory(true);
      } else {
        setShowRegulatory(false);
        for (var i = 0; i < data.primaryDomains.length; i++) {
          if (data.primaryDomains[i] === 'Regulatory') {
            data.primaryDomains.splice(i, 1);
            i--;
            setRegulatoryChecked(false);
          }
        }
      }
      if (domainWhileEdit.includes('Safety')) {
        setShowSafety(true);
      } else {
        setShowSafety(false);
        for (var i = 0; i < data.primaryDomains.length; i++) {
          if (data.primaryDomains[i] === 'Safety') {
            data.primaryDomains.splice(i, 1);
            i--;
            setSafetyChecked(false);
          }
        }
      }
      if (domainWhileEdit.includes('HC')) {
        setShowHC(true);
      } else {
        setShowHC(false);
        for (var i = 0; i < data.primaryDomains.length; i++) {
          if (data.primaryDomains[i] === 'HC') {
            data.primaryDomains.splice(i, 1);
            i--;
            setHcChecked(false);
          }
        }
      }
      if (domainWhileEdit.includes('BW')) {
        setShowBW(true);
      } else {
        setShowBW(false);
        for (var i = 0; i < data.primaryDomains.length; i++) {
          if (data.primaryDomains[i] === 'BW') {
            data.primaryDomains.splice(i, 1);
            i--;
            setBwChecked(false);
          }
        }
      }
      if (domainWhileEdit.includes('PC')) {
        setShowPC(true);
      } else {
        setShowPC(false);
        for (var i = 0; i < data.primaryDomains.length; i++) {
          if (data.primaryDomains[i] === 'PC') {
            data.primaryDomains.splice(i, 1);
            i--;
            setPcChecked(false);
          }
        }
      }
      // }
      //if (!showFNR) {
      //  for (var i = 0; i < data.primaryDomains.length; i++) {
      //    if (data.primaryDomains[i] === 'F&R') {
      //      data.primaryDomains.splice(i, 1);
      //      i--;
      //    }
      //  }
      //}
      // domainWhileEdit.includes('HBPC') ? setShowHBPC(true) : setShowHBPC(false);
      // domainWhileEdit.includes("PLM") ? setShowPLM(true) : setShowPLM(false);
      // domainWhileEdit.includes("Regulatory") ? setShowRegulatory(true) : setShowRegulatory(false);
      // domainWhileEdit.includes("Safety") ? setShowSafety(true) : setShowSafety(false);
      //  if (!domainWhileEdit.includes('F&R')) {
      //    for (var i = 0; i < data.primaryDomains.length; i++) {

      //      if (data.primaryDomains[i] === 'F&R') {
      //        data.primaryDomains.splice(i, 1);
      //        i--;
      //      }
      //    }
      //  }
      //  else if (!domainWhileEdit.includes('HBPC')) {
      //    for (var i = 0; i < data.primaryDomains.length; i++) {

      //      if (data.primaryDomains[i] === 'HBPC') {
      //        data.primaryDomains.splice(i, 1);
      //        i--;
      //      }
      //    }
      //  }

      //  else if (!domainWhileEdit.includes('PLM')) {
      //    for (var i = 0; i < data.primaryDomains.length; i++) {

      //      if (data.primaryDomains[i] === 'PLM') {
      //        data.primaryDomains.splice(i, 1);
      //        i--;
      //      }
      //    }
      //  }

      //  else if (!domainWhileEdit.includes('Regulatory')) {
      //    for (var i = 0; i < data.primaryDomains.length; i++) {

      //      if (data.primaryDomains[i] === 'Regulatory') {
      //        data.primaryDomains.splice(i, 1);
      //        i--;
      //      }
      //    }
      //  }

      //  else if (!domainWhileEdit.includes('Safety')) {
      //    for (var i = 0; i < data.primaryDomains.length; i++) {

      //      if (data.primaryDomains[i] === 'Safety') {
      //        data.primaryDomains.splice(i, 1);
      //        i--;
      //      }
      //    }
      //  }
    }
    setShowDomainError(domainWhileEdit.length <= 0 ? true : false)
  }, 500)

  //isprimary changes
  const handleChange1 = (event: any) => {
    setFnrChecked(!fnrChecked);
    event.target.checked === true ?
      data.primaryDomains.push('F&R') : data.primaryDomains.splice(data.primaryDomains.indexOf('F&R'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < data.primaryDomains.length; i++) {

        if (data.primaryDomains[i] === 'F&R') {
          data.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }

  };
  const handleChange3 = (event: any) => {
    setPlmChecked(!plmChecked);
    event.target.checked === true ?
      data.primaryDomains.push('PLM') : data.primaryDomains.splice(data.primaryDomains.indexOf('PLM'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < data.primaryDomains.length; i++) {

        if (data.primaryDomains[i] === 'PLM') {
          data.primaryDomains.splice(i, 1);
          i--;
        }
      }

    };
  }
  const handleChange4 = (event: any) => {
    setRegulatoryChecked(!regulatoryChecked);
    event.target.checked === true ?
      data.primaryDomains.push('Regulatory') : data.primaryDomains.splice(data.primaryDomains.indexOf('Regulatory'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < data.primaryDomains.length; i++) {

        if (data.primaryDomains[i] === 'Regulatory') {
          data.primaryDomains.splice(i, 1);
          i--;
        }
      }
    };
  }
  const handleChange5 = (event: any) => {
    setSafetyChecked(!safetyChecked);
    event.target.checked === true ?
      data.primaryDomains.push('Safety') : data.primaryDomains.splice(data.primaryDomains.indexOf('Safety'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < data.primaryDomains.length; i++) {

        if (data.primaryDomains[i] === 'Safety') {
          data.primaryDomains.splice(i, 1);
          i--;
        }
      }
    };
  }
  const handleChange6 = (event: any) => {
    setHcChecked(!hcChecked);
    event.target.checked === true ?
      data.primaryDomains.push('HC') : data.primaryDomains.splice(data.primaryDomains.indexOf('HC'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < data.primaryDomains.length; i++) {

        if (data.primaryDomains[i] === 'HC') {
          data.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const handleChange7 = (event: any) => {
    setBwChecked(!bwChecked);
    event.target.checked === true ?
      data.primaryDomains.push('BW') : data.primaryDomains.splice(data.primaryDomains.indexOf('BW'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < data.primaryDomains.length; i++) {

        if (data.primaryDomains[i] === 'BW') {
          data.primaryDomains.splice(i, 1);
          i--;
        }
      }
    };
  }
  const handleChange8 = (event: any) => {
    setPcChecked(!pcChecked);
    event.target.checked === true ?
      data.primaryDomains.push('PC') : data.primaryDomains.splice(data.primaryDomains.indexOf('PC'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < data.primaryDomains.length; i++) {

        if (data.primaryDomains[i] === 'PC') {
          data.primaryDomains.splice(i, 1);
          i--;
        }
      }
    };
  }
  return (
    <Form>
      <InputLabel>Name</InputLabel>
      <EnhancedTextInputField name="name" fullWidth margin="dense" disabled={disableField} className={classes.field} />

      <InputLabel >Type</InputLabel>
      <Field
        className={classes.field}
        name="subType"
        component={Select}
        margin="dense"
        fullWidth
      >
        {enableBtn ? compositeTypesData.map((item) =>
          <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
        ) :
          lookup.nameType.map((item) =>
            <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
          )}
      </Field>
      <div className={classes.flex}>
        <DomainEditField name="linkDomains" allDomains={lookup.domain.map(i => i.display)} />
        <Paper className={[classes.paper, classes.width].join(" ")} >
          <InputLabel className={classes.font}>Make this name Primary for:</InputLabel>
          <FormGroup aria-label="position" style={{ marginTop: "2px" }}>
            {showBW && <FormControlLabel
              id="domain"
              value="BW"
              control={<Checkbox onChange={handleChange7} checked={bwChecked} />}
              label="BW"
              labelPlacement="end"
              className={classes.removeMar}
            />
            }
            {showFNR && <FormControlLabel
              id="domain"
              value="F&R"
              control={<Checkbox onChange={handleChange1} checked={fnrChecked} />}
              label="F&R"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showHC && <FormControlLabel
              id="domain"
              value="HC"
              control={<Checkbox onChange={handleChange6} checked={hcChecked} />}
              label="HC"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showPC && <FormControlLabel
              id="domain"
              value="PC"
              control={<Checkbox onChange={handleChange8} checked={pcChecked} />}
              label="PC"
              labelPlacement="end"
              className={classes.removeMar}
            />
            }
            {showPLM && <FormControlLabel
              id="domain"
              value="PLM"
              control={<Checkbox onChange={handleChange3} checked={plmChecked} />}
              label="PLM"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showRegulatory && <FormControlLabel
              id="domain"
              value="Regulatory"
              control={<Checkbox onChange={handleChange4} checked={regulatoryChecked} />}
              label="Regulatory"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showSafety && <FormControlLabel
              id="domain"
              value="Safety"
              control={<Checkbox onChange={handleChange5} checked={safetyChecked} />}
              label="Safety"
              labelPlacement="end"
              className={classes.removeMar}
            />
            }

          </FormGroup>
        </Paper>
      </div>
      {showDomainError ? <div className={classes.msgColorRed} > Please select atleast one domain</div> : null
      }
      <InputLabel>Status</InputLabel>
      <Field
        className={classes.field}
        name="status"
        component={Select}
        margin="dense"
        fullWidth
        disabled={disableField}
      >
        {lookup.status.map((item) =>
          <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
        )}
      </Field>

      <InputLabel>Audit Description</InputLabel>
      <Field
        className={classes.field}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth />

      <Button type="submit" color="primary" disabled={showDomainError}>
        Save
      </Button>
    </Form>
  )
}

interface FormProps {
  data: ItemData;
  lookup: LookupState;
  onSave: (values: ItemData, formikActions: FormikActions<ItemData>) => void;
}

const EditNameFormik = (props: FormProps) => {
  const { data, lookup } = props;
  localStorage.setItem('editSubName', data.name);
  localStorage.setItem('editSubstance', 'true')
  return (
    <div>
      <Formik
        initialValues={data}
        onSubmit={props.onSave}
        render={() => (
          <EditNameForm lookup={lookup} data={data} />
        )}
      />
    </div>
  );
};

export default EditNameFormik;
