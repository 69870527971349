import { AddItemState, Entity } from "./types";
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const emptyEntity: Entity = {
  name: "",
  status: "A",
  type: "Synonym",
  subType: "Synonym"
}
const emptyEntityName: Entity = {
  name: "",
  status: "A",
  type: "",
  subType: ""
}
const initialState: AddItemState = {
  newEntity: emptyEntity,
  parentEntity: emptyEntity,
  linkDomains: [],
  primaryDomains: [],
    auditDescription: "",
    saving: false,
    show: false
};

const combine = (state: any, action: any) => {
  return {
    ...state, parentEntity: action.payload.parentEntity,
    newEntity: action.payload.newEntity,
    parentName: action.payload.parentName,
    name: action.payload.name,
    primaryDomains: action.payload.primaryDomains,
    status: action.payload.status,
    subType: action.payload.subType,
    substanceMolWeight: action.payload.substanceMolWeight,
    linkDomains: action.payload.linkDomains,
    auditDescription: action.payload.auditDescription,
    saving:false
  }
}

export const addItemReducer = createReducer(initialState)
  .handleAction(actions.addItemAction, (state, action) => ({ ...state, parentEntity: action.payload.parentEntity, newEntity: { ...emptyEntity, type: action.payload.targetType }, linkDomains: [], primaryDomains: [], auditDescription: "", saving: false, show: true }))
  .handleAction(actions.addItemNameAction, (state, action) => ({ ...state, parentEntity: action.payload.parentEntity, newEntity: { ...emptyEntityName, type: action.payload.targetType }, linkDomains: [], primaryDomains: [], auditDescription: "", saving: false, show: true }))

  .handleAction(actions.saveAddAction.request, (state, action) => ({ ...state, saving: true }))
.handleAction(actions.saveAddAction.success, (state, action) => (initialState))
  .handleAction(actions.saveAddAction.failure, (state, action) => combine(state, action))
.handleAction(actions.closeAddAction, (state, action) => (initialState));
