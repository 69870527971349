import { MenuItem } from './types';

export const transformLookupStatusResult = (data: any) : MenuItem[] => {
  return data.map((i: any) => {
    const x: MenuItem = { key: i.statusCode, display: i.description };
    return x;
  });
}

export const transformLookupKeyResult = (data: any) : MenuItem[] => {
  return data.map((i: any) => {
    const x: MenuItem = { key: i, display: i };
    return x;
  });
}
