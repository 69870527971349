import { EditItemState } from "./types";
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const initialState: EditItemState = {
  itemData: null,
  saving: false,
  restrictionData: null,
  structurerestrictionData: null
   };


export const editItemReducer = createReducer(initialState)
.handleAction(actions.editItemAction, (state, action) => ({...state, itemData: action.payload, saving: false }))
.handleAction(actions.savedEditAction.request, (state, action) => ({...state, saving: true }))
.handleAction(actions.savedEditAction.success, (state, action) => ({...state, itemData: null, saving: false }))
.handleAction(actions.savedEditAction.failure, (state, action) => ({...state, saving: false }))
.handleAction(actions.closeEditAction, (state, action) => ({ ...state, itemData: null, saving: false }))
  .handleAction(actions.editCompositeRestrictionRequest.success, (state, action) => ({ ...state, restrictionData: action.payload }))
  .handleAction(actions.editStructureRestrictionRequest.success, (state, action) => ({ ...state, structurerestrictionData: action.payload }))
     
  
 ;
