import { Dispatch } from 'redux';
import {createStandardAction} from 'typesafe-actions';
import { SirMessage, MessageSeverity, SirError} from './types';
import {AppState} from '../index';

export const messageAction = createStandardAction("SIR_MESSAGE")<SirMessage>();
export const closeMessageAction = createStandardAction("SIR_MESSAGE_CLOSE")<void>();

export const sirMessage = (messageText: string, severity: MessageSeverity) => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    dispatch(messageAction({ message: messageText, severity: severity, time: Date.now()}));
  }
};

export const hideMessage = () => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    dispatch(closeMessageAction());
  }
};

export const makeError = (error: any) => {
  if (error.response && error.response.data && error.response.data.message) {
    const payload: SirError = error.response.data;
    return messageAction({ message: payload.message, severity: MessageSeverity.Error, time: Date.now()});
  }

  if (error.message) {
    return messageAction({ message: error.message, severity: MessageSeverity.Error, time: Date.now()});
  }

  if (typeof error === "string") {
    return messageAction({ message: error, severity: MessageSeverity.Error, time: Date.now()});
  }

  return messageAction({ message: JSON.stringify(error), severity: MessageSeverity.Error, time: Date.now()});
}

export const makeSuccessMessage = (messageText: string) => {
  return messageAction({ message: messageText, severity: MessageSeverity.Success, time: Date.now()});
}
