import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { closeAdd, saveAdd, saveAddComposite, saveAddSubComponent } from '../store/addItem/actions';
import AddNewItemFormik from './AddNewItemForm';
import AddNewSubItemFormik from './AddNewSubCompoentn';


const AddNewItemDialog = () => {
  const addState = useSelector((state: AppState) => state.addItem);
  const addStateName = useSelector((state: AppState) => state.addItemName);
  const lookup = useSelector((state: AppState) => state.lookup);
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(closeAdd()),
    [dispatch]
  );
  const onSave = useCallback(
    (values, formik) => dispatch(saveAdd(values, formik)),
    [dispatch]
  )
  const onSaveComp = useCallback(
    (values, formik) => dispatch(saveAddComposite(values, formik)),
    [dispatch]
  )
  const onSaveSub = useCallback(
    (values, formik) => dispatch(saveAddSubComponent(values, formik)),
    [dispatch]
  )
  
  const showUI = (addState.parentEntity && (addState.parentEntity.name === '' || addState.parentEntity.name === undefined)) ? true : false;
  const open = addState.show;
  const showStatusNote = (addState.parentEntity && addState.parentEntity.status === 'I') ? true : false;
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
        <Grid justify="space-between" container spacing={6}>
          {showUI ? <Grid item>
            Add new {addState.newEntity.type}
          </Grid> :
            showStatusNote ?
              <Grid item>
                Add new {addState.newEntity.type} to {addState.parentEntity.name}
                {(addState.newEntity.type === 'Substance name') ? < div className="note">Substance {addState.parentEntity.name} is already INACTIVE. Any new names added to the substance will be set to INACTIVE status by default.</div> : null}
              </Grid> :
            <Grid item>
                {(addState.newEntity.type === 'Composite substance') ? <div>Add new {addState.newEntity.type}</div> : <div>Add new {addState.newEntity.type} to {addState.parentEntity.name}</div>}
            </Grid>}
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      {addState.newEntity.type !== 'Sub component' ? <DialogContent>
        {addState.newEntity.type === 'Substance name' || addState.newEntity.type === 'Classification' ? <AddNewItemFormik data={addStateName} lookup={lookup} onSave={onSave} onSaveComp={onSaveComp} /> :
          <AddNewItemFormik data={addState} lookup={lookup} onSave={onSave} onSaveComp={onSaveComp} />}
      </DialogContent> :
        <DialogContent>
          <AddNewSubItemFormik data={addStateName} lookup={lookup} onSave={onSaveSub}  /> 
        </DialogContent>}
    </Dialog> 
  );
}

export default AddNewItemDialog;
