export interface AuthConfig {
  authority: string,
  clientId: string
  scope: string
}

export interface SirConfig {
  auth: AuthConfig,
  instrumentationKey: string
}

declare global {
  interface Window { config: SirConfig; }
}

export default function sirConfig(): SirConfig {
  return window.config;
}
