import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core';
import ItemIcon from '../CommonComponents/ItemIcon';
import { Suggestion } from "../store/search/types";

const useStyles = makeStyles({
  cell: {
    fontSize: 12,
    display: "flex",
    alignContent: "center"
  },
  tableheight: {
    paddingTop: 2,
    overflow: "auto",
    //minHeight: "70vh",
    maxHeight: "70vh",
  }
});

interface NameDetailListProps {
  data: any;
  highligted?: Suggestion | null;
  type?: any;
}

const CompositionDetailList = (props: NameDetailListProps) => {
  const classes = useStyles();
  return (props.data && props.data.compositeNames &&
    (
    <div className={classes.tableheight}> <Table stickyHeader aria-label="sticky table" >
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Domains</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Min Level</TableCell>
            <TableCell>Avg Level</TableCell>           
            <TableCell>Max Level</TableCell>
            {//<TableCell></TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody className="compositelistBody">
          {props.data.compositeNames.map((item: any) => (
            <TableRow key={item.name}
           // {...shouldHighlight && item.name === highlighted.name ? { ref: highlightedRef, selected: true } : {}}
            >
              <TableCell>
                <Typography className={classes.cell}>
                  <ItemIcon type="Substance" subType={item.nameType} />
                  {item.nameType}
                </Typography>
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.domains?item.domains.join(','):''}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.min}</TableCell>
              <TableCell>{item.avg}</TableCell>             
              <TableCell>{item.max}</TableCell>
              {//<TableCell>{item.nameType !== "SIR ID" && <EditButton itemData={substanceNameDetailToEditItem(item)} />}</TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
    </Table>
      </div>
  )
  );
}

export default CompositionDetailList;
