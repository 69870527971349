import React, { useCallback } from 'react';
import { makeStyles } from "@material-ui/styles";
import { Button } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { useDispatch } from 'react-redux';
import { requestStructureHistory } from '../store/structurehistory/actions';

const useStyles = makeStyles(theme => ({
  button: {
  },
  icon: {
    width: 16,
    height: 16
  },
}));

type Props = {
  name: string;
  type: string;
  tabName?: string;
  disableHistory?: boolean;
};

export const HistoryButtonStructure = (props: Props) => {
  const { name, type, disableHistory } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const showStructureHistory = useCallback(() => {
    dispatch(requestStructureHistory(name));
  }, [dispatch, name]);
  //const showStructureHistory = () => {
  //  alert('clicked');
  //}
  return (
    <span>
      <Button
        size="small"
        variant="text"
        className={classes.button}
        title="View structure history"
        disabled={!name || !type || disableHistory}
        onClick={showStructureHistory}
      >
        <HistoryIcon className={classes.icon} />
      </Button>
    </span>
  );
}
export default HistoryButtonStructure;
