import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputLabel, TextField, Select, MenuItem } from '@material-ui/core';
import { AppState } from '../store';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { closeSwapFlag, swapSubstanceNameApiCall } from '../store/swapBackbone/actions';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  fontweight: {
    fontWeight: 400
  },
  content: {
    paddingTop: "0px",
  },
  flex: {
    display: "flex"
  },
  font: {
    fontSize: "1.25rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.6,
    letterSpacing: "0.0075em"
  },
  text: {
    fontSize: "1.25rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 200,
    lineHeight: 1.6,
    letterSpacing: "0.0075em"
  },
  pad: {
    padding:"24px 24px 20px 24px !important"
  }
})

const SwapDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let itemDetailDataArray: any = [];
  const [name, setName] = React.useState('');
  const swapDetails = useSelector((state: any) => state.swapReducer);
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const itemDetail = detailData.itemDetail;
  const currentBackbone = itemDetail ? itemDetail.backboneIdentity : "";
  const [description, setDescription] = React.useState('');
  const namesArray = itemDetail.names !== undefined ? detailData.itemDetail.names : "";
    if (namesArray.length > 0) {
      for (let i = 0; i < namesArray.length; i++) {
        if ((itemDetail.names[i].nameType !== 'SIR ID' && itemDetail.names[i].nameType !== 'Component') && itemDetail.names[i].status !== 'I') {
          let obj: any = {
            name: namesArray[i].name ? namesArray[i].name : null,
            nameType: namesArray[i].nameType ? namesArray[i].nameType : "",
            domains: namesArray[i].domains ? namesArray[i].domains : "",
            status: namesArray[i].status ? namesArray[i].status : "",
            isPrimaryForDomains: namesArray[i].isPrimaryForDomains ? namesArray[i].isPrimaryForDomains : ""
          }
          if (namesArray[i].name !== itemDetail.backboneIdentity && namesArray[i].nameType !== 'CLS ID') { 
          itemDetailDataArray.push(obj);
          }
        }
      }
    }
  let isOpen = swapDetails ? swapDetails.open : "";
  let type = detailData ? detailData.type : ""; 
  React.useEffect(() => {
    if (isOpen === "") {
      setName('');
    }
  })
  
  const onclose = useCallback(
    () => { dispatch(closeSwapFlag()); },
    [dispatch]);
  const SwapAPICall = useCallback(
    (currentBackbone: string, name: string, description: string, type: string) => {
      dispatch(swapSubstanceNameApiCall(currentBackbone, name, description, type))
    },
    [dispatch]
  );
  const nameChange = (e: any) => {
    setName(e.target.value);
  }
  const DescVal = (e: any) => {
    setDescription(e.target.value);
  }
  const open = isOpen === "openSwapDialog";
  return (
    <Dialog open={open} onClose={onclose} fullWidth={true} maxWidth="lg" >
      <MuiDialogTitle>
        <Grid justify="space-between" container spacing={6}>
          <Grid item className={classes.pad}>
            <Grid item>
              Edit {detailData.type} Backbone Name (select one from existing names):
              </Grid>
            {
              //<Grid item className={ classes.fontweight}>
              //Current Backbone Name: {currentBackbone}
              //</Grid>
            }
           </Grid>
          <Grid item className={classes.pad}>
             <IconButton aria-label="close" onClick={onclose}>
              <CloseIcon />
             </IconButton>
           </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent className={classes.content}>
        {
          //<div className={classes.field}>Current Backbone Name: <span>{currentBackbone}</span></div>
        }
        <div className={[classes.flex, classes.field].join(" ")}><div className={classes.font}>Current Backbone Name:</div>&nbsp;&nbsp;<div className={classes.text}>{currentBackbone}</div></div>
        <InputLabel style={{marginBottom:'10px'}} >New Name</InputLabel>
        <Select
          className={classes.field}
          name="nameSwap"
          onChange={nameChange}
          value={name}
         // placeholder="--Please Select--"
          displayEmpty
          //component={Select}
          margin="dense"
          fullWidth
          renderValue={(value) => value === '' ? '--Please Select--' : `${value}`}
          //inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="" disabled>
            <em>--please select--</em>
          </MenuItem>
          {itemDetailDataArray.map((item: any) =>
            <MenuItem key={item.name} value={item.name}
              title={`Name: ${item.name}\nType: ${item.nameType}\nDomains: ${item.domains.join(",")}`}
            >
              <span style={{ width: "45%" }}>{item.name}</span>
              <span style={{ width: "25%" }}>{item.nameType}</span>
              <span style={{ width: "30%" }}>{ item.domains.join(",") }</span>
            </MenuItem>
          )}
        </Select>
        <InputLabel>Audit Description</InputLabel>
        <TextField
          className={classes.field}
          name="Description"
          //component={TextField}
          margin="dense"
          fullWidth
          onBlur={DescVal} />
        <Button type="button" name="swap" disabled={name === ""}color="primary" onClick={(e) => SwapAPICall(currentBackbone, name, description, type)}>
          Save
         </Button>
      </DialogContent>
    </Dialog>
    );
}
export default SwapDialog;
