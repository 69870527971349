import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FormConfig, AddFormConfig, AddNameFormConfig, AddLinkFormConfig } from '../store/entity/types';
import { Grid, makeStyles, Paper, InputLabel } from '@material-ui/core';
import SearchField from '../SearchBox/SearchField';
import { Form } from 'formik';
import AddLinkContributionForm from '../AddContributionFactor/AddLinkForm';

type Props = {
  open: boolean,
  config: any,
  selectedItem: any
}
const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  domainPanel: {
    padding: "8px"
  },
  title: {
    textAlign: "center"
  },
  paper: {
    padding: 2,
    margin: 8
  }
});

const AddDialog = (props: Props) => {
  const { open, config } = props;
  const classes = useStyles();
  const selectedItem = (val: any) => {
    props.selectedItem(val);
  }

  return (
    <Dialog open={open} onClose={config.onClose} fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
      <Grid justify="space-between" container spacing={6}>
      <Grid item>
        {config.title}
        </Grid>
        <Grid item>
        {config.onClose ? (
          <IconButton aria-label="close" onClick={config.onClose}>
            <CloseIcon />
          </IconButton>
          )
          : null}
        </Grid>
      </Grid>
      </MuiDialogTitle>
      <DialogContent>
      <AddLinkContributionForm config={config as any} selectedItem={selectedItem}/>
      </DialogContent>
    </Dialog>
  );
}

export default AddDialog;
