import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { onItemSelected } from '../store/search/actions';
import { Suggestion } from '../store/search/types';
import SearchInputBox from './SearchInputBox';

const GlobalSearchBox = () => {
  const dispatch = useDispatch();
  const itemSelected = useCallback(
    (item: Suggestion) => dispatch(onItemSelected(item)),
    [dispatch]
  );
  return (
    <SearchInputBox
      itemSelected={itemSelected}
      value={{ name: "", type: "", subType: "", status: "", substanceId: "" }}
      />
  )
};

export default GlobalSearchBox;
