import axios from 'axios';
import {Suggestion, SearchState} from './types';
import { locateItem } from '../hierarchy/actions';
import { Dispatch } from 'redux';
import { AppState } from '../index';
import { getNameFlag } from '../flag/actions';
import MainTab from '../../MainPage/MainTab';
import { makeError } from '../error/actions';
import OverviewPage from '../../MainPage/OverviewPage';
import { loading } from '../searchStructure/actions';
import { showDetail } from '../itemDetail/actions';
export const transformSearchResult = (data: any) : Suggestion[] => {
  return data.map((i: any) => {
    const x = { name: i.entityName, type: i.entityType, subType: i.entitySubType, status: i.entityStatus, substanceId: i.backboneName } as Suggestion;
    return x;
  });
}
export const transformSearchResultForMerge = (data: any): Suggestion[] => {
  //return data.map((i: any) => {
  //  const x = i.entityStatus !== 'I' ? { name: i.entityName, type: i.entityType, subType: i.entitySubType, status: i.entityStatus, substanceId: i.backboneName }  as Suggestion : null;
  //  console.log('matchARR', x);
  //    return x;
  //});
  const matchArr = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].entityStatus !== "I") {
      matchArr.push(data[i]);
    }
  }
  return matchArr.map((i: any) => {
    const x = { name: i.entityName, type: i.entityType, subType: i.entitySubType, status: i.entityStatus, substanceId: i.backboneName } as Suggestion;
    return x;
  })
  //return matchArr;
}
export const transformSearchResultSub = (data: any): Suggestion[] => {
  return data.map((i: any) => {
    const x = { name: i.substanceName, type: i.substanceMolWeight, subType: i.type, status: i.status, substanceId: i.substanceMolWeight } as Suggestion;
    return x;
  });
}

export const searchApi = (inputValue: string, currentState: SearchState, setState: (state: SearchState) => void, host: string, maxResults: number = 20, filter: string = "All", removeForMerge?: string | undefined) => {
  if (!inputValue || inputValue.length < 3) {
    setState({ ...currentState, notEnoughCharacters: true, searchTerm: inputValue, hasMore: false, context: "NOT ENOUGH" });
    return;
  }

  setState({ ...currentState, loading: true, searchTerm: inputValue, notEnoughCharacters: false, context: "LOADING START" });
  if (filter === 'subcomponent') {
    axios.get(`/api/v1/ContributionFactor/SearchSubComponent?substanceName=${encodeURIComponent(inputValue)}`)
      .then((response) => {
        //  const { matches, } = response.data;
        setState({ ...currentState, matches: transformSearchResultSub(response.data), searchTerm: inputValue, notEnoughCharacters: false, loading: false, hasMore: false, context: "LOADING DONE" });
      })
      .catch(function (error) {
        setState({ ...currentState, loading: false, hasMore: false, context: "ERROR" })
      });
  }
  else if (filter === 'RestrictVariantINsubstance') {

    axios.get(`${host}/api/v1/entities?name=${encodeURIComponent(inputValue)}&maxResults=${maxResults}&nameSearchType=Substance`, { headers: { 'Restrictvariant': true } })

      .then((response) => {

        const { matches, hasMore, searchTerm } = response.data;

        if (removeForMerge === 'Inactive') {

          setState({ ...currentState, matches: transformSearchResultForMerge(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });

        } else {

          setState({ ...currentState, matches: transformSearchResult(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });

        }

      })

      .catch(function (error) {

        setState({ ...currentState, loading: false, hasMore: false, context: "ERROR" })

      });

  } else {
    axios.get(`${host}/api/v1/entities?name=${encodeURIComponent(inputValue)}&maxResults=${maxResults}&nameSearchType=${filter}`)
      .then((response) => {
        const { matches, hasMore, searchTerm } = response.data;
        if (removeForMerge === 'Inactive'){
          setState({ ...currentState, matches: transformSearchResultForMerge(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });
          }else {
        setState({ ...currentState, matches: transformSearchResult(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });
      }
      })
      .catch(function (error) {
        setState({ ...currentState, loading: false, hasMore: false, context: "ERROR" })
      });
  }
 
}

export const searchApiFromUrl = (inputValue: string, type: string, open: boolean) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    //localStorage.removeItem('compositepage');
    //dispatch<any>(getNameFlag(item.name));
    //locateItem(item)(dispatch, getState);
    //dispatch(itemSelected(item));
    axios.get(`/api/v1/entities?name=${encodeURIComponent(inputValue.replace('&plus&', '+').replace('&backslash&', '/'))}&maxResults=20&nameSearchType=All`)
      .then((response) => {
        const data = response.data.matches;
        let showError: boolean = false;
        if (data.length == 1) {
          if (data[0].entityName.toLowerCase() === inputValue.toLowerCase() && data[0].entityType === type) {
            let item1: Suggestion = {
              name: data[0].backboneName,
              type: data[0].entityType,
              subType: data[0].entitySubType,
              status: data[0].entityStatus,
              substanceId: data[0].backboneName,
            }

            showError = true;
            localStorage.removeItem('url');
            open = false;
            dispatch<any>(getNameFlag(data[0].backboneName));
            locateItem(item1)(dispatch, getState);
            dispatch<any>(loading(open));
          }
        } else {
          for (let i = 0; i <= data.length; i++) {
            if (data[i].backboneName.toLowerCase() === inputValue.replace('&plus&', '+').replace('&backslash&', '/').toLowerCase() && data[i].entityType === type) {
              let item: Suggestion = {
                name: data[i].backboneName,
                type: data[i].entityType,
                subType: data[i].entitySubType,
                status: data[i].entityStatus,
                substanceId: data[i].backboneName,
              }
              showError = true;
              localStorage.removeItem('url');
              open = false;
              dispatch<any>(getNameFlag(item.name));
              locateItem(item)(dispatch, getState);
              dispatch<any>(loading(open));
              break;
              // dispatch<any>(locateItem(item));
              // dispatch(itemSelected(item));
            }
            if (showError === false && i === data.length - 1) {
              dispatch(makeError('Invalid URL. Please enter exact Substance name and type combination in the address bar'));
              const open = false;
              dispatch<any>(loading(open));
            }
          }
        }
      })
      .catch(function (error) {
        const open = false;
        dispatch<any>(loading(open));
        //dispatch(makeError(error));
      });
  }
}
export const reloadSubstanceDetails = (value: any, object: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    if (object && object.name !== '') {
      dispatch<any>(showDetail({ name: object.name, type: 'Substance', subType: 'Substance', status: object.status }));
    }
  }
}

export const searchPolymerApi = (inputValue: string, currentState: SearchState, setState: (state: SearchState) => void, host: string, maxResults: number = 20, filter: string = "All", removeForMerge?: string | undefined) => {
  if (!inputValue || inputValue.length < 3) {
    setState({ ...currentState, notEnoughCharacters: true, searchTerm: inputValue, hasMore: false, context: "NOT ENOUGH" });
    return;
  }
  setState({ ...currentState, loading: true, searchTerm: inputValue, notEnoughCharacters: false, context: "LOADING START" });
    console.log('searching polymers now ========== ')
    axios.get(`${host}/api/v1/Entities/GetSubatnceWithOutVariant?name=${encodeURIComponent(inputValue)}&maxResults=${maxResults}&nameSearchType=All`)
      .then((response) => {
        const { matches, hasMore, searchTerm } = response.data;
        if (removeForMerge === 'Inactive'){
          setState({ ...currentState, matches: transformSearchResultForMerge(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });
          }else {
        setState({ ...currentState, matches: transformSearchResult(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });
      }
      })
      .catch(function (error) {
        setState({ ...currentState, loading: false, hasMore: false, context: "ERROR" })
      });    
}

// API use in add/edit variant screen for seaching parent polymers , this API will return ONLY polymer substance
export const searchOnlyPolymerApi = (inputValue: string, currentState: SearchState, setState: (state: SearchState) => void, host: string, maxResults: number = 20, filter: string = "All", removeForMerge?: string | undefined) => {
  if (!inputValue || inputValue.length < 3) {
    setState({ ...currentState, notEnoughCharacters: true, searchTerm: inputValue, hasMore: false, context: "NOT ENOUGH" });
    return;
  }
  setState({ ...currentState, loading: true, searchTerm: inputValue, notEnoughCharacters: false, context: "LOADING START" });
    console.log('searching ONLY polymers now ========== ')
    axios.get(`${host}/api/v1/Entities/GetPolymerSubstanceWithOutVariant?name=${encodeURIComponent(inputValue)}&maxResults=${maxResults}&nameSearchType=All`)
      .then((response) => {
        const { matches, hasMore, searchTerm } = response.data;
        if (removeForMerge === 'Inactive'){
          setState({ ...currentState, matches: transformSearchResultForMerge(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });
          }else {
        setState({ ...currentState, matches: transformSearchResult(matches), searchTerm: searchTerm, notEnoughCharacters: false, loading: false, hasMore: hasMore, context: "LOADING DONE" });
      }
      })
      .catch(function (error) {
        setState({ ...currentState, loading: false, hasMore: false, context: "ERROR" })
      });    
}
