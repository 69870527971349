import { Grid, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { warningMessage, validateNewItem } from '../domain/AddItemValidation';
import { LookupState } from '../store/lookupData/types';
import NameFormPanel from './NameFormPanel';
import ValidationError from './ValidationError';
const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  paper: {
    padding: "5px",
    height: "100%"
  },
  msgColorRed: {
    color: 'red',
    fontSize: '14px'
  },
  msgColorGreen: {
    color: 'green',
    fontSize: '14px'
  },
  molweight: {
    marginTop: "0px",
    fontSize: "17px",
    color: "red",
    fontWeight: 400,
    fontFamily:"Roboto"
  },
  nameVal: {
    position: "relative",
    top: "-83px",
    marginTop: "0px",
    fontSize: "17px",
    color: "red",
    fontWeight: 400,
    fontFamily: "Roboto"
  }

});

const getSubTypesForType = (type: string, lookup: LookupState) => {
  if (type === 'Classification') {
    return lookup.classificationType;
  }
  return lookup.nameType;
}


interface LookupProps {
  lookup: LookupState;
 // onSaveComp: any;
  //datatoValidate : DatatoValidate;
}

const AddNewItemForm = (props: any) => {
  const classes = useStyles();
  const { lookup, values } = props;
  const message = warningMessage(values);
  const [mol, setMol] = useState(null);
  const [molVal, setMolVal] = useState(false);
  if (props.setNameVal) {

  }
  const statusSelected = (val: any) => {
    localStorage.setItem('status', val);
  }
  const typeSelected = (val: any) => {
    localStorage.setItem('typeSelected', val);
  }
  const saveClick = (val: any) => {
    if (mol === null) {
      setMolVal(true);
    } else {
      setMolVal(false);
    }

  }
  const molWeightChange = (val: any) => {
    setMol(val.target.value);
    if (val.target.value !== null) {
      setMolVal(false);
    }
  }
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <ValidationError name="newEntity.type" />
            <NameFormPanel propertyPrefix="newEntity." parentType={values.parentEntity.type} type={values.newEntity.type} availableTypes={getSubTypesForType(values.newEntity.type, lookup)} availableStatuses={lookup.status} status={statusSelected} typeSelected={typeSelected} />
          
          </Paper>

        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} >
            <InputLabel>Mol. Weight</InputLabel>
            <Field
              className={classes.field}
              name="molweight"
              component={TextField}
              margin="dense"
              fullWidth
              //type="number"
              onKeyDown={(e: any) => /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?><,-\s]*$/i.test(e.key) && e.which !== 8 && e.preventDefault()}
             InputProps={{ onBlur: molWeightChange }}
            />
           

          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} >
            <InputLabel>Audit Description</InputLabel>
             <Field
              className={classes.field}
              name="auditDescription"
              component={TextField}
              margin="dense"
              fullWidth
            />
          </Paper>
        </Grid>
        <Button type="submit" color="primary" onClick={saveClick}>
              Save
          </Button>
          <Typography variant="caption" >{message}</Typography>
        
      </Grid>
    </Form>
  )
}

interface FormProps {
  data: any;
  lookup: LookupState;
  onSave: (values: any, formikActions: FormikActions<any>) => void;
}

const AddNewSubItemFormik = (props: FormProps) => {
  const { data, lookup } = props;
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={props.onSave}
        //validate={validateNewItem}
        render={(fp) => (
          <AddNewItemForm lookup={lookup}  {...fp} />
        )}
      />
    </div>
  );
};

export default AddNewSubItemFormik;
