import React, { useCallback } from 'react';
import { Formik, FormikProps, Form, Field, FormikActions, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import SearchField from '../SearchBox/SearchField';
import { MergeItemsFormState } from "../store/mergeItems/types";
import { validateMerge } from "./MergeItemsValidation";
import { RadioGroup } from 'formik-material-ui';
import { FormControlLabel, Radio } from '@material-ui/core';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  errorText: {
    padding: 5,
    color: "Red"
  },
  searchFieldSpacer: {
    marginTop: "20px"
  },
  swapBtn: {
    float: "right",
    margin: "20px 10px -30px auto",
    zIndex: 1
  },
  mergeBtn: {
    marginTop: "20px"
  },
  group: {
    flexDirection: "row",
    marginBottom: "10px",
    marginTop: "-18px"
},
});

const MergeItemsForm = (props: FormikProps<MergeItemsFormState>) => {
  const classes = useStyles();
  const { values, setValues } = props;

  const onSwap = useCallback(() => {
    setValues({
      ...values,
      source: values.target,
      target: values.source
    });
  }, [values, setValues]);

  const substanceFilter = "Substance";

  console.log('values.defaultSelected ==', values.defaultSelected)

  const defaultFilterValue = (values.defaultSelected != undefined ? (values.defaultSelected == 'Substance' ? 'RestrictVariantINsubstance' : values.defaultSelected) : "RestrictVariantINsubstance");

  console.log('defaultFilterValue ==', defaultFilterValue);

  const [filter, setFilter] = React.useState<any>(defaultFilterValue);

  const selectRadio = useCallback( (e: any) => {
    let nullSuggestion = {
      name: "",
      type: "",
      subType: "",
      status: " ",
      substanceId: null,
      auditDescription: null
      } 
    setValues({
      ...values,
      source: nullSuggestion,
      target: nullSuggestion
    });
     if (e.target.value) {
      if (e.target.value == 'Substance') {
        setFilter("RestrictVariantINsubstance");
      } else if (e.target.value == 'Classification') {
         setFilter("classification");
      } else {
        console.log('error selection====');
      }
    }
  }, [values])

   return (
    <Form>

       <Field name="defaultSelected" label="some text" component={RadioGroup} className={classes.group}>
        <FormControlLabel
          value="Substance"
          control={<Radio />}
          label="Substance"
          onClick={selectRadio}
        />
        <FormControlLabel
          value="Classification"
          control={<Radio />}
          label="Classification"
          onClick={selectRadio}
        />
      </Field>

      <InputLabel>Source</InputLabel>
      <SearchField
         name="source"
         filter={filter}
         removeForMerge='Inactive'
       />
      <ErrorMessage
        name="source.name"
        render={msg => <Typography className={classes.errorText} variant="body1">{msg}</Typography>}
      />
      <Tooltip className={classes.swapBtn} title="Swap" placement="left">
        <IconButton onClick={onSwap} tabIndex={-1}><SwapVertIcon /></IconButton>
      </Tooltip>
      <InputLabel className={classes.searchFieldSpacer}>Target</InputLabel>
      <SearchField
        name="target"
        filter={filter}
        removeForMerge='Inactive'
      />
      <ErrorMessage
        name="target.name"
        render={msg => <Typography className={classes.errorText} variant="body1">{msg}</Typography>}
      />
      <InputLabel className={classes.searchFieldSpacer} htmlFor="audit-description-input">Audit Description</InputLabel>
      <Field
        className={classes.field}
        inputProps={{ id: 'audit-description-input' }}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth />
      <span>The Source identity will be removed and all of its classifications and names will be added to the Target identity.</span>
      <br />
      <span>Please note, this operation can't be reverted.</span>
      <br />
      <Button className={classes.mergeBtn} type="submit" color="primary">Merge</Button>
    </Form>
  )
}

interface FormProps {
  values: MergeItemsFormState;
  onSave: (values: MergeItemsFormState, formikActions: FormikActions<MergeItemsFormState>) => void;
}

const MergeItemsFormik = (props: FormProps) => {
  const { values, onSave } = props;

  return (
    <div>
      <Formik
        initialValues={values}
        onSubmit={onSave}
        validate={validateMerge}
        render={(fp) => (
          <MergeItemsForm {...fp} />
        )}
      />
    </div>
  );
};

export default MergeItemsFormik;
