import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { closeEdit, saveEdit } from '../store/editItem/actions';
import { AppState } from '../store';
import EditNameFormik from './EditNameForm';
import EditSubstanceFormik from './EditSubstanceForm';
import EditClassificationFormik from './EditClassificationForm';
import { ItemData } from '../store/editItem/types';
import { LookupState } from '../store/lookupData/types';
import { FormikActions } from 'formik';
import EditCompositeNameFormik from './EditNameForm - Copy';

interface FormProps {
  data: ItemData;
  lookup: LookupState;
  onSave: (values: ItemData, formikActions: FormikActions<ItemData>) => void;
}

const FormSelector = (props: FormProps) => {
  const { data, lookup, onSave } = props;
  const val = localStorage.getItem('isSubVsClass');
  if (val === 'true') {
    if (data.status == null) {
      data.status = 'A';
    }
    if (data.subType == null || data.subType == undefined || data.subType == '') {
      data.subType = 'Chemical Class';
    }     
  }
  return (
    <div>
      {data.type === "Substance name" && val === 'false' && <EditNameFormik data={data} lookup={lookup} onSave={onSave} />}
      {data.type === "Substance" && val === 'false' && <EditSubstanceFormik data={data} lookup={lookup} onSave={onSave} />}
      {data.type === "Classification" && val === 'false' && <EditClassificationFormik data={data} lookup={lookup} onSave={onSave} />}
      {data.type === "Composite" && val === 'false' && <EditCompositeNameFormik data={data} lookup={lookup} onSave={onSave} />}
      {data.type === "Classification name" && val === 'false' && <EditNameFormik data={data} lookup={lookup} onSave={onSave} />}
      {val === 'true' && <EditClassificationFormik data={data} lookup={lookup} onSave={onSave} />}



    </div>
  )
}


const EditDialog = () => {
  const {itemData} = useSelector((state: AppState) => state.editItem);
  const lookup = useSelector((state: AppState) => state.lookup);
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(closeEdit()),
    [dispatch]
  );
  const onSave = useCallback(
    (values: ItemData, formik: any) => dispatch(saveEdit(values, formik)),
    [dispatch]
  );
  const open = (itemData != null);
  const val = localStorage.getItem('isSubVsClass');
  return (
    <Dialog open={open} onClose={onClose}  fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
            {itemData && val === 'false' && <span>Edit {itemData.type}: {itemData.name}</span>}
            {itemData && val === 'true' && <span>Swap SubstanceVSClassification {itemData.type}: {itemData.name}</span>}
        </Grid>
        <Grid item>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      </MuiDialogTitle>
      <DialogContent>
        {itemData && <FormSelector data={itemData} lookup={lookup} onSave={onSave} />}
       
      </DialogContent>
    </Dialog>
  );
}

export default EditDialog;
