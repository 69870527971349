import { MenuItem } from "../lookupData/types";
import { Suggestion } from "../search/types";

/* For classifications and substances */
export type AddFormValues = {
  name: string,
  type: string,
  status: string,
  auditDescription: string
};

export type SubmitAdd = {
  (formValues: AddFormValues, doClose: Function, setSubmitting: Function) : void;
}

export type AddClassificationPayload = {
  name: string;
  classificationType: string;
  status: string;
  auditDescription: string;
}

export const ConvertFormValuesToClassification = (formValues: AddFormValues) : AddClassificationPayload => ({
  name: formValues.name,
  classificationType: formValues.type,
  status: formValues.status,
  auditDescription: formValues.auditDescription
});

export type AddSubstancePayload = {
  backboneIdentity: string;
  nameType: string;
  status: string;
  auditDescription: string;
}

export const ConvertFormValuesToSubstance = (formValues: AddFormValues) : AddSubstancePayload => ({
  backboneIdentity: formValues.name,
  nameType: formValues.type,
  status: formValues.status,
  auditDescription: formValues.auditDescription
});

/* For substance names */
export type AddNameFormValues = AddFormValues & {
  backboneIdentity: string,
  domainName: string,
  isPrimaryForDomain: boolean,
};

export type SubmitAddName = {
  (formValues: AddNameFormValues, doClose: Function, setSubmitting: Function) : void;
}

export type AddSubstanceNamePayload = AddSubstancePayload & {
  name: string,
  domainName: string,
  isPrimaryForDomain: boolean,
};

export const ConvertFormValuesToSubstanceName = (formValues: AddNameFormValues) : AddSubstanceNamePayload => ({
  name: formValues.name,
  nameType: formValues.type,
  status: formValues.status,
  backboneIdentity: formValues.backboneIdentity,
  domainName: formValues.domainName,
  isPrimaryForDomain: formValues.isPrimaryForDomain,
  auditDescription: formValues.auditDescription
});

/* For links */
export type AddLinkFormValues = {
  childItem: Suggestion,
  parentItem: Suggestion,
  linkDomain: string,
  isPrimaryForDomain: boolean, /* only used for linking substance name */
  linkStatus: string,
  auditDescription: string,
};

export type SubmitAddLink = {
  (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) : void;
}

export type AddClassificationLinkPayload = {
  parentClassificationName: string,
  childClassificationName: string,
  status: string,
  domainName: string,
  auditDescription: string
}

export const ConvertFormValuesToClassificationLink = (formValues: AddLinkFormValues) : AddClassificationLinkPayload => {
  if (formValues.childItem.type === "Classification" && formValues.parentItem.type === "Classification")
  {
    return {
      parentClassificationName: formValues.parentItem.name,
      childClassificationName: formValues.childItem.name,
      status: formValues.linkStatus,
      domainName: formValues.linkDomain,
      auditDescription: formValues.auditDescription
    }
  }
  return {
    parentClassificationName: "",
    childClassificationName: "",
    status: "",
    domainName: "",
    auditDescription: ""
  }
};

export type AddSubstanceLinkPayload = {
  parentSubstance: string,
  childSubstance: string,
  status?: string,
  domainName: string,
  auditDescription: string
}

export const ConvertFormValuesToSubstanceLink = (formValues: AddLinkFormValues) : AddSubstanceLinkPayload => {
  if (formValues.childItem.type === "Substance" && formValues.parentItem.type === "Substance")
  {
    return {
      parentSubstance: formValues.parentItem.name,
      childSubstance: formValues.childItem.name,
      status: formValues.linkStatus,
      domainName: formValues.linkDomain,
      auditDescription: formValues.auditDescription
    }
  }
  return {
    parentSubstance: "",
    childSubstance: "",
    status: "",
    domainName: "",
    auditDescription: ""
  }
};

export type DelSubstanceLinkPayload = {
  parentSubstanceName: string,
  childSubstanceName: string,
  domainName: string,
  auditDescription: string
}

export const ConvertFormValuesToSubstanceLinkDel = (formValues: AddLinkFormValues): DelSubstanceLinkPayload => {
  if (formValues.childItem.type === "Substance" && formValues.parentItem.type === "Substance") {
    return {
      parentSubstanceName: formValues.parentItem.name,
      childSubstanceName: formValues.childItem.name,
      //status: formValues.linkStatus,
      domainName: formValues.linkDomain,
      auditDescription: formValues.auditDescription
    }
  }
  return {
    parentSubstanceName: "",
    childSubstanceName: "",
    //status: "",
    domainName: "",
    auditDescription: ""
  }
};
export type AddSubstanceClassificationLinkPayload = {
    substance: string,
    classificationName: string,
    domainName: string,
    status: string,
    auditDescription: string
}

export const ConvertFormValuesToSubstanceClassificationLink = (formValues: AddLinkFormValues) : AddSubstanceClassificationLinkPayload => {
  if (formValues.childItem.type === "Substance" && formValues.parentItem.type === "Classification")
  {
    return {
      classificationName: formValues.parentItem.name,
      substance: formValues.childItem.name,
      status: formValues.linkStatus,
      domainName: formValues.linkDomain,
      auditDescription: formValues.auditDescription
    }
  }
  return {
    classificationName: "",
    substance: "",
    status: "",
    domainName: "",
    auditDescription: ""
  }
};

export type AddSubstanceNameLinkPayload = {
  substanceName: string,
  status: string,
  backboneIdentity: string,
  domainName: string,
  isPrimaryForDomain: boolean,
  auditDescription: string
}

export const ConvertFormValuesToSubstanceNameLink = (formValues: AddLinkFormValues) : AddSubstanceNameLinkPayload => {
  if (formValues.childItem.type === "Substance name" && formValues.parentItem.type === "Substance")
  {
    return {
      backboneIdentity: formValues.parentItem.name,
      substanceName: formValues.childItem.name,
      status: formValues.linkStatus,
      domainName: formValues.linkDomain,
      isPrimaryForDomain: formValues.isPrimaryForDomain,
      auditDescription: formValues.auditDescription
    }
  }
  return {
    backboneIdentity: "",
    substanceName: "",
    status: "",
    domainName: "",
    isPrimaryForDomain: false,
    auditDescription: ""
  }
};

/* Form configurations */
export type BaseFormConfig = {
  formType: string,
  title: string,
  nameLabel: string,
  typeLabel: string,
  typeMenuItems: MenuItem[],
  statusLabel: string,
  statusMenuItems: MenuItem[],
  auditDescriptionLabel: string,
  onClose: () => void,
};

/* This can be used for forms adding classification and substance*/
export type AddFormConfig = BaseFormConfig & {
  submitAdd: SubmitAdd,
};

/* This is used for add substance name form */
export type AddNameFormConfig = BaseFormConfig & {
  submitAdd: SubmitAddName,
  backboneIdentityLabel: string,
  defaultBackboneIdentity: string,
  domainNameLabel: string,
  domainNameMenuItems: MenuItem[],
  isPrimaryForDomainLabel: string,
};

/* This is used for add link form */
export type AddLinkFormConfig = {
  formType: string,
  title: string,
  childNameLabel: string,
  parentNameLabel: string,
  statusLabel: string,
  statusMenuItems: MenuItem[],
  domainNameLabel: string,
  domainNameMenuItems: MenuItem[],
  isPrimaryForDomainLabel: string,
  auditDescriptionLabel: string,
  submitAdd: SubmitAddLink,
  onClose: () => void,
};

export type FormConfig = AddFormConfig | AddNameFormConfig | AddLinkFormConfig;

export const ADD_CLASSIFICATION_REQUEST_START = "ADD_CLASSIFICATION_REQUEST_START";
export const ADD_CLASSIFICATION_REQUEST_SUCCESS = "ADD_CLASSIFICATION_REQUEST_SUCCESS";
export const ADD_CLASSIFICATION_REQUEST_FAILURE = "ADD_CLASSIFICATION_REQUEST_FAILURE";

export const ADD_SUBSTANCE_REQUEST_START = "ADD_SUBSTANCE_REQUEST_START";
export const ADD_SUBSTANCE_REQUEST_SUCCESS = "ADD_SUBSTANCE_REQUEST_SUCCESS";
export const ADD_SUBSTANCE_REQUEST_FAILURE = "ADD_SUBSTANCE_REQUEST_FAILURE";

export const ADD_SUBSTANCE_NAME_REQUEST_START = "ADD_SUBSTANCE_NAME_REQUEST_START";
export const ADD_SUBSTANCE_NAME_REQUEST_SUCCESS = "ADD_SUBSTANCE_NAME_REQUEST_SUCCESS";
export const ADD_SUBSTANCE_NAME_REQUEST_FAILURE = "ADD_SUBSTANCE_NAME_REQUEST_FAILURE";

export const ADD_CLASSIFICATION_LINK_REQUEST_START = "ADD_CLASSIFICATION_LINK_REQUEST_START";
export const ADD_CLASSIFICATION_LINK_REQUEST_SUCCESS = "ADD_CLASSIFICATION_LINK_REQUEST_SUCCESS";
export const ADD_CLASSIFICATION_LINK_REQUEST_FAILURE = "ADD_CLASSIFICATION_LINK_REQUEST_FAILURE";

export const ADD_SUBSTANCE_LINK_REQUEST_START = "ADD_SUBSTANCE_LINK_REQUEST_START";
export const ADD_SUBSTANCE_LINK_REQUEST_SUCCESS = "ADD_SUBSTANCE_LINK_REQUEST_SUCCESS";
export const ADD_SUBSTANCE_LINK_REQUEST_FAILURE = "ADD_SUBSTANCE_LINK_REQUEST_FAILURE";

export const ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START = "ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START";
export const ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS = "ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS";
export const ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE = "ADD_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE";

export const ADD_SUBSTANCE_NAME_LINK_REQUEST_START = "ADD_SUBSTANCE_NAME_LINK_REQUEST_START";
export const ADD_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS = "ADD_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS";
export const ADD_SUBSTANCE_NAME_LINK_REQUEST_FAILURE = "ADD_SUBSTANCE_NAME_LINK_REQUEST_FAILURE";

export const REMOVE_CLASSIFICATION_LINK_REQUEST_START = "REMOVE_CLASSIFICATION_LINK_REQUEST_START";
export const REMOVE_CLASSIFICATION_LINK_REQUEST_SUCCESS = "REMOVE_CLASSIFICATION_LINK_REQUEST_SUCCESS";
export const REMOVE_CLASSIFICATION_LINK_REQUEST_FAILURE = "REMOVE_CLASSIFICATION_LINK_REQUEST_FAILURE";

export const REMOVE_SUBSTANCE_LINK_REQUEST_START = "REMOVE_SUBSTANCE_LINK_REQUEST_START";
export const REMOVE_SUBSTANCE_LINK_REQUEST_SUCCESS = "REMOVE_SUBSTANCE_LINK_REQUEST_SUCCESS";
export const REMOVE_SUBSTANCE_LINK_REQUEST_FAILURE = "REMOVE_SUBSTANCE_LINK_REQUEST_FAILURE";

export const REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START = "REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_START";
export const REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS = "REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_SUCCESS";
export const REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE = "REMOVE_SUBSTANCE_CLASSIFICATION_LINK_REQUEST_FAILURE";

export const REMOVE_SUBSTANCE_NAME_LINK_REQUEST_START = "REMOVE_SUBSTANCE_NAME_LINK_REQUEST_START";
export const REMOVE_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS = "REMOVE_SUBSTANCE_NAME_LINK_REQUEST_SUCCESS";
export const REMOVE_SUBSTANCE_NAME_LINK_REQUEST_FAILURE = "REMOVE_SUBSTANCE_NAME_LINK_REQUEST_FAILURE";
