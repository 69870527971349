import { createReducer } from "typesafe-actions";
import * as actions from './actions';
import { AddVariantState } from "./types";
import { Entity } from '../addItem/types';

const emptyEntity: Entity = {
  name: "",
  status: "A",
  type: "Synonym",
  subType: "Synonym"
}

const initialState: AddVariantState = {
  itemData: null,
  show: false,
  saving: false,
  topologyList: [""],
  chemicalMicrostructuresList: [""],
  variantFlag: null,
  variantStrcutureInfo: null,
  indexOfVariant: null,
  
  viewVariantShow: false,  
  
  parentEntity: emptyEntity,
  isEdit: false,
  showAddVariant: false,
  variantRerestrictionData: {
    "variantName": "",
    "groupName": [],
    "email": []
  }

}; 

export const variantReducer = createReducer(initialState)
  
  .handleAction(actions.editVariantDialogAction, (state, action) => ({ ...state, itemData: null, saving: false, show: true, indexOfVariant: action.payload.variantName, parentEntity: action.payload.parentEntity, isEdit: true }))
  .handleAction(actions.viewVariantDialogAction, (state, action) => ({ ...state, itemData: null, viewVariantShow: true, indexOfVariant: action.payload.variantName, parentEntity: action.payload.parentEntity }))
  .handleAction(actions.closeVariantDialogAction, (state, action) => ({ ...state, show: false }))
  .handleAction(actions.closeViewVariantDialogAction, (state, action) => ({ ...state, viewVariantShow: false })) 
  .handleAction(actions.addVariantDialogAction, (state, action) => ({ ...state, showAddVariant: true, parentEntity: action.payload.parentEntity }))
  .handleAction(actions.closeAddVariantDialogAction, (state, action) => ({...state, showAddVariant:false}))
  
  //.handleAction(actions.closeVariantDialogAction, (state, action) => ({...state, show:false}))
  .handleAction(actions.getTopologyRequest.request, (state, action) => ({ ...state }))
  .handleAction(actions.getTopologyRequest.success, (state, action) => ({ ...state, topologyList: action.payload }))
  .handleAction(actions.getTopologyRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.getChemicalMicrostructuresRequest.request, (state, action) => ({ ...state }))
  .handleAction(actions.getChemicalMicrostructuresRequest.success, (state, action) => ({ ...state, chemicalMicrostructuresList: action.payload }))
  .handleAction(actions.getChemicalMicrostructuresRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.getVariantFlagAction.request, (state, action) => ({ ...state, variantFlag: null }))
  .handleAction(actions.getVariantFlagAction.success, (state, action) => ({ ...state, variantFlag: action.payload }))
  .handleAction(actions.getVariantFlagAction.failure, (state, action) => ({ ...state, variantFlag: null }))
  .handleAction(actions.getStructureVariantAction.request, (state, action) => ({ ...state, variantStrcutureInfo: null }))
  .handleAction(actions.getStructureVariantAction.success, (state, action) => ({ ...state, variantStrcutureInfo: action.payload }))
  .handleAction(actions.getStructureVariantAction.failure, (state, action) => ({ ...state, variantStrcutureInfo: null }))
  .handleAction(actions.addStructureAction.request, (state, action) => ({ ...state, variantStrcutureInfo: null }))
  .handleAction(actions.addStructureAction.success, (state, action) => ({ ...state, variantStrcutureInfo: action.payload }))
  .handleAction(actions.addStructureAction.failure, (state, action) => ({ ...state, variantStrcutureInfo: null }))
  .handleAction(actions.editStructureAction.request, (state, action) => ({ ...state, variantStrcutureInfo: null }))
  .handleAction(actions.editStructureAction.success, (state, action) => ({ ...state, variantStrcutureInfo: action.payload }))
  .handleAction(actions.editStructureAction.failure, (state, action) => ({ ...state, variantStrcutureInfo: null }))
  .handleAction(actions.getVariantRestrictionAction.success, (state, action) => ({...state,variantRerestrictionData:action.payload}))
  //.handleAction(actions.variantRequest.request, (state, action) => combine(state, action))
  //.handleAction(actions.variantRequest.success, (state, action) => combine(state, action))
  //.handleAction(actions.variantRequest.failure, (state, action) => ({ ...state }))
  //.handleAction(actions.getVariantRequest.request, (state, action) => ({ ...state, variants:[],isAdd:false  }))
  //.handleAction(actions.getVariantRequest.success, (state, action) =>combine(state, action))
  //.handleAction(actions.getVariantRequest.failure, (state, action) => ({ ...state }))





