import { createAsyncAction, createStandardAction } from "typesafe-actions";
import { Dispatch } from "redux";
import { AppState } from "..";
import axios from 'axios';
import { addEditPolymerStrcut, PolymerData } from './types';
import { makeSuccessMessage, makeError } from "../error/actions";
import { getVariantRestrictions } from '../variant/actions';
export const getPolymerstructureAction = createAsyncAction("GET_POLYMERSTRUCTURE_START", "GET_POLYMERSTRUCTURE_SUCCESS", "GET_POLYMERSTRUCTURE_FAIL")<string, any, string>();

  
export const getPolymerStructureList = (name: string) => {
  console.log('name ==',name);
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getPolymerstructureAction.request("started here"));
    axios.get(`api/v1/Structure/GetStructurePolymer?substanceName=${encodeURIComponent(name)}`)
      .then((response) => {
        console.log('response ==', response); 
        dispatch(getPolymerstructureAction.success(response.data));
      })
      .catch((error: any) => {
        console.log('error 1 =', error)
        dispatch(getPolymerstructureAction.failure(error.message));
      });
  }
}

export const SRUCheckerFixerAction = createAsyncAction("SRU_CHECKERFIXER_START", "SRU_CHECKERFIXER_SUCCESS", "SRU_CHECKERFIXER_FAIL")<string, any, string>();

export const getSRUCheckFixer = (smile:string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(SRUCheckerFixerAction.request("sru call"));
    console.log('sru checker fixer calling')
    let structureSource = smile;
    axios.post('/api/v1/Structure/CheckerFixer', null, { params: { structureSource } })
      .then((response) => {
        dispatch(SRUCheckerFixerAction.success(response));
      })
      .catch((error: any) => {
        //  localStorage.removeItem('marvinSmiles');
        dispatch(SRUCheckerFixerAction.failure(error.message));
      });
  };
}


export const sourceCheckerFixerAction = createAsyncAction("SOURCE_CHECKERFIXER_START", "SOURCE_CHECKERFIXER_SUCCESS", "SOURCE_CHECKERFIXER_FAIL")<string, any, string>();

export const getSourceCheckFixer = (smile: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(sourceCheckerFixerAction.request("source call"));
    console.log('source checker fixer calling')
    let structureSource = smile;
    axios.post('/api/v1/Structure/CheckerFixer', null, { params: { structureSource } })
      .then((response) => {
        dispatch(sourceCheckerFixerAction.success(response));
      })
      .catch((error: any) => {
        //  localStorage.removeItem('marvinSmiles');
        dispatch(sourceCheckerFixerAction.failure(error.message));
      });
  };
}

export const getParentPolymerAction = createAsyncAction("GET_PARENTPOLYMER_START", "GET_PARENTPOLYMER_SUCCESS", "GET_PARENTPOLYMER_FAIL")<string, any, string>();

export const getParentPolymer = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getParentPolymerAction.request("started"));
    axios.get(`/api/v1/Structure/GetParentPolymerDetails?substanceName=${encodeURIComponent(name)}`)
      .then((response) => {
        console.log('response ==', response)
        dispatch(getParentPolymerAction.success(response.data));
      }).catch((error) => {
        dispatch(getParentPolymerAction.failure(error));
      });

  }
}

export const getParentVariantDetailsAction = createAsyncAction("GET_PARENT_VARIANT_START", "GET_PARENT_VARIANT_SUCCESS", "GET_PARENT_VARIANT_FAIL")<string, any, string>();

export const getParentVariantDetails = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getParentVariantDetailsAction.request("started"));
    axios.get(`/api/v1/Substances/VariantParentDetails?variantName=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(getParentVariantDetailsAction.success(response.data));
       // dispatch<any>(getVariantRestrictions(name));        
      }).catch((error) => {
        dispatch(getParentVariantDetailsAction.failure(error));
      });

  }
}

export const getPolymerFlagAction = createAsyncAction("GET_POLYMERFLAG_START", "GET_POLYMERFLAG_SUCCESS", "GET_POLYMERFLAG_FAIL")<string, any, string>();

export const getPolymerFlag = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getPolymerFlagAction.request("started"));
    axios.get(`/api/v1/Entities/GetPolymerFlag?name=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(getPolymerFlagAction.success(response.data));
        if (response.data == true) {
          console.log('getting polymer details since its a polymer')
          dispatch<any>(getParentPolymer(name));
        }
      }).catch((error) => {
        dispatch(getPolymerFlagAction.failure(error));
      });

  }
}

export const AddEditPolymerStructureAction = createAsyncAction("ADDEDIT_POLYMERSTRUCTURE_START", "ADDEDIT_POLYMERSTRUCTURE_SUCCESS", "ADDEDIT_POLYMERSTRUCTURE_FAIL")<string, any, string>();

export const addEditPolymerStrcuture = (obj: addEditPolymerStrcut) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(AddEditPolymerStructureAction.request("Loading .."));
     axios.put('/api/v1/Structure/AddEditPolymerStructure', obj)
      .then((response:any) => {
        console.log('response here ==',response);
        dispatch(AddEditPolymerStructureAction.success(response));
        dispatch<any>(getParentPolymer(obj.name));
        let msg="";
        if(response.data.action=='AddStructure'){
          msg ="Parent Polymer Added Successfully !"
        }else if(response.data.action=='UpdateStructure'){
          msg ="Parent Polymer Updated Successfully !"
        }else if(response.data.action=='DeleteStructure'){
          msg="Parent Polymer Deleted Successfully !"
        }else {
          msg="Parent Polymer changed Successfully !"
        }
        dispatch(makeSuccessMessage(msg));
        dispatch(closeEditPolymer())
      })
       .catch((error) => {
         console.log('error in add structure ==',error,error.response.data.message)
         dispatch(makeError(error.response.data.message));
         dispatch(closeEditPolymer());
         dispatch(AddEditPolymerStructureAction.failure(error.response.data.message));
      });
  };
}


export const closeEditPolymerAction = createStandardAction("CLOSE_EDITPOLYMER")<void>();

export const closeEditPolymer = () => {
  return closeEditPolymerAction();
};

export const closeConfirmBoxAction = createStandardAction("CLOSE_CONFIRMBOX")<void>();

export const closeConfirmBox = () => {
  return closeConfirmBoxAction();
};

export const showConfirmBoxAction = createStandardAction("SHOW_CONFIRMBOX")<any>();
export const showConfirmBox = (itemData: any) => {
  return showConfirmBoxAction(itemData);
};

export const showEditPolymerAction = createStandardAction("EDIT_Polymer")<any>();
export const showEditPolymer = (itemData: any) => {
  return showEditPolymerAction(itemData);
};

export const getParentPolymerActionCOPY = createAsyncAction("GET_PARENTPOLYMERCOPY_START", "GET_PARENTPOLYMERCOPY_SUCCESS", "GET_PARENTPOLYMERCOPY_FAIL")<string, any, string>();

export const getParentPolymerCOPY = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getParentPolymerActionCOPY.request("started"));
    axios.get(`/api/v1/Structure/GetParentPolymerDetails?substanceName=${encodeURIComponent(name)}`)
      .then((response) => {
        console.log('response ==', response)
        dispatch(getParentPolymerActionCOPY.success(response.data));
      }).catch((error) => {
        dispatch(getParentPolymerActionCOPY.failure(error));
      });

  }
}
