import { uniqBy } from 'lodash';
import { NodeData, ClassificationResponseNode } from './types';
import { makeKey, nodeComparer, addChildNode } from '../../domain/tree';

const isOrphan = (data: ClassificationResponseNode[]) : boolean => {
  if (data.length > 0) {
    let topClassifications = ['CHEMICALS', 'COLOURS', 'CROSS CATEGORY', 'F&R', 'HBPC', 'HYDROCARBON BLENDS', 'NATURAL INORGANICS', 'NATURAL PRODUCTS', 'OIL AND FAT', 'PERFUMES', 'POLYMERS', 'SURFACTANT'];
    let p = data.filter(i => { return topClassifications.indexOf(i['entityName']) > -1 })
    console.log('p ==', p)
    if (p.length > 0) {
      return false
    } else {
      return true
    }
    // If level of first item is 0, then it MIGHT be an orphan...
    // if (!data[0].level) {
    //   // ...but it could also be a top level class.
    //   if (data[0].entityType === 'Classification' && data.length > 1) {
    //     return false;
    //   }
    //   return true;
    // }
  }

  return false;
}
export const transformHierarchyResult = (data: any) : NodeData[] => {
  return uniqBy(data, (i: any) => i.entityName).filter((i: any) => i.level > 0 ).map((i: any) => {
    const x = { name: i.entityName, type: i.entityType, subType: i.entitySubType, status: i.entityStatus, key: makeKey(i.entityName, i.entityType, i.entitySubType, false), partial: true } as NodeData;
    return x;
  }).sort(nodeComparer);
}

export const buildTreeNodeData = (data: ClassificationResponseNode[]) : NodeData => {
  const orphan = isOrphan(data);
  const rootNode : NodeData = {
    name: orphan ? 'Orphans' : 'Classification',
    type: 'Classification',
    subType: '',
    status: 'A',
    key: 'Top'
  }

  const treeNodes = orphan ? data : data.filter((i) => i.entityType !== 'Substance name');
  const uniqueNodes = uniqBy(treeNodes, (i) => i.entityName + i.entitySubType + i.parentName);

  //Top level nodes are parents that don't also appear as a node.
  const topNodes = uniqueNodes.filter((i) => !i.parentName).map((i) => {
    const x = { name: i.entityName, type: i.entityType, subType: i.entitySubType, status: i.entityStatus, children: [] } as NodeData;
    return x;
  });

  rootNode.children = topNodes

  const allNodes = [...topNodes];
  const topNames = topNodes.map(n => n.name);
  const remainingNodes = uniqueNodes.filter(n => !topNames.includes(n.entityName));
  // Relying on always finding parents before children here. That's fine as source data was returned from API that way.
  remainingNodes.forEach((s) => {
    const node = { key: makeKey(s.entityName, s.entityType, s.entitySubType, true), name: s.entityName, type: s.entityType, subType: s.entitySubType, status: s.entityStatus, children: [], partial: true } as NodeData;
    allNodes.push(node);
    const parents = allNodes.filter(i => i.name === s.parentName && i.subType === s.parentSubType);
    parents.forEach(p => addChildNode(p, node));
  });

  return  {
    name: 'All',
    type: 'Classification',
    subType: '',
    status: 'A',
    key: 'Root',
    children: [ rootNode ]
  }
}
