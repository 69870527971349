import { createReducer } from "typesafe-actions";
import * as actions from './actions';

const initialState: any = {
  oldBackboneName:"",
  open: "",
  newBackboneName: "",
  auditDescription: "",
  //domainName:""
};

const combine = (state: any, action: any) => {
  return { ...state, open: "" }
}
export const swapReducer = createReducer(initialState)
  .handleAction(actions.closeSwapFlagRequest, (state, action) => ({ ...state, open: "" }))
  .handleAction(actions.openSwapFlagRequest, (state, action) => ({ ...state, open: action.payload }))
  .handleAction(actions.swapSubstanceRequest.request, (state, action) => combine(state, action))
  .handleAction(actions.swapSubstanceRequest.success, (state, action) => combine(state, action))
  .handleAction(actions.swapSubstanceRequest.failure, (state, action) => ({ ...state }))
