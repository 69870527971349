import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from "@material-ui/icons/Edit";
import { AppState } from '../store';
import { Button } from '@material-ui/core';
import { showEditItem } from '../store/editItem/actions';
import { ItemData } from '../store/editItem/types';

const useStyles = makeStyles(theme => ({
  button: {
  },
  icon: {
    width: 16,
    height: 16
  },
}));

interface Props  {
  itemData: ItemData
};

const EditButton = (props: Props) => {
  const classes=useStyles();
  const { itemData } = props;
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  const onEditItem = useCallback(() => {

    localStorage.setItem('isSubVsClass', 'false');

    const newDetails = localStorage.getItem('allSubNameDetails');
    let primaryDomainForEdit: any = [];
    if (newDetails) {

      let nameData: any = [];
      nameData = JSON.parse(newDetails);
      for (var i = 0; i < nameData.length; i++) {
        if (nameData[i].name === itemData.name) {
          itemData.primaryDomains = nameData[i].isPrimaryForDomains;
        }
      }
    }
      dispatch(showEditItem(itemData));
  }, [dispatch,itemData]);

  return (
    <span>
      <Button size="small" variant="text" className={classes.button} onClick={onEditItem} title="Edit..." disabled={!user.isAdmin} >
        <EditIcon className={classes.icon} />
      </Button>
    </span>
  )
}

export default EditButton;
