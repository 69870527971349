import { Button } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store'; 
 
const useStyles = makeStyles(theme => ({
    button: {
    },
    icon: {
      width: 16,
      height: 16
    },
  }));
   

const EditButton = (props: any) => {
    const classes=useStyles();
     const dispatch = useDispatch();
    
  
    return (
      <span>
        <Button size="small" variant="text" className={classes.button} title="Edit">
          <EditIcon className={classes.icon} />
        </Button>
      </span>
    )
  }
  
  export default EditButton;
