import axios from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { AppState } from '..';
import { makeError } from '../error/actions';
import { MenuItem } from './types';
import { transformLookupStatusResult, transformLookupKeyResult } from './utilities';

export const lookupStatusRequest = createAsyncAction("LOOKUP_STATUS_REQUEST_START", "LOOKUP_STATUS_REQUEST_SUCCESS", "LOOKUP_STATUS_REQUEST_FAILURE")<null, MenuItem[], string>();
export const lookupClassificationTypeRequest = createAsyncAction("LOOKUP_CLASSIFICATION_TYPE_REQUEST_START", "LOOKUP_CLASSIFICATION_TYPE_REQUEST_SUCCESS", "LOOKUP_CLASSIFICATION_TYPE_REQUEST_FAILURE")<null, MenuItem[], string>();
export const lookupNameTypeRequest = createAsyncAction("LOOKUP_NAME_TYPE_REQUEST_START", "LOOKUP_NAME_TYPE_REQUEST_SUCCESS", "LOOKUP_NAME_TYPE_REQUEST_FAILURE")<null, MenuItem[], string>();
export const lookupDomainRequest = createAsyncAction("LOOKUP_DOMAIN_REQUEST_START", "LOOKUP_DOMAIN_REQUEST_SUCCESS", "LOOKUP_DOMAIN_REQUEST_FAILURE")<null, MenuItem[], string>();

export const licenseRequest = createAsyncAction(
  "LICENSE_REQUEST_START",
  "LICENSE_DETAIL_REQUEST_SUCCESS",
  "LICENSE_DETAIL_REQUEST_FAILURE")<string, any, string>();
export const lookupStatus = () => {
  return (dispatch: Dispatch, getState: () => AppState) => {

    var servername: any = "";
    var baseurl: any = "";
    if (window.location.origin.includes('https://sir-dev')) {                         //Development
      servername = "https://marvinjs-dev.unilever.com/marvin4js-license.cxl";
      baseurl = 'https://marvinjs-dev.unilever.com';
    } else if (window.location.origin.includes('https://sir-qa')) {                  // QA
      servername = "https://marvinjs-qa.unilever.com/marvin4js-license.cxl";
      baseurl = 'https://marvinjs-qa.unilever.com';
    } else if (window.location.origin.includes('https://sir.unilever.com')) {       //production 
      servername = "https://marvinjs.unilever.com/marvin4js-license.cxl";
      baseurl = 'https://marvinjs.unilever.com';
    } else {                                                                       //localhost
      servername = "https://marvinjs-dev.unilever.com/marvin4js-license.cxl";
      baseurl = 'https://marvinjs-dev.unilever.com';
    }
    localStorage.setItem('baseUrl', baseurl)
    axios.get(`/api/v1/CompositeSubstances/ChemexonLicense?ChemexonLicenseURL=${encodeURIComponent(servername)}`)
      .then((response: any) => {
        //let xml = response.data;
        dispatch(licenseRequest.success({ data: response.data }));

      }).catch((error) => {
      });
    dispatch(lookupStatusRequest.request());
    axios.get(`/api/v1/lookup/status`)
      .then((response) => {
        dispatch(lookupStatusRequest.success(transformLookupStatusResult(response.data)));
      })
     .catch((error) => {
       dispatch(makeError(error));
       dispatch(lookupStatusRequest.failure(error.message));
     });
  }
}

export const lookupClassificationType = () => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    dispatch(lookupClassificationTypeRequest.request());
    axios.get(`/api/v1/lookup/classificationtypes`)
      .then((response) => {
        dispatch(lookupClassificationTypeRequest.success(transformLookupKeyResult(response.data)));
      })
     .catch((error) => {
       dispatch(makeError(error));
       dispatch(lookupClassificationTypeRequest.failure(error.message));
     });
  }
}

export const lookupNameType = () => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    dispatch(lookupClassificationTypeRequest.request());
    axios.get(`/api/v1/lookup/nametypes`)
      .then((response) => {
        dispatch(lookupNameTypeRequest.success(transformLookupKeyResult(response.data)));
      })
     .catch((error) => {
       dispatch(makeError(error));
       dispatch(lookupClassificationTypeRequest.failure(error.message));
     });
  }
}

export const lookupDomain = () => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    dispatch(lookupDomainRequest.request());
    axios.get(`/api/v1/lookup/domain`)
      .then((response) => {
        dispatch(lookupDomainRequest.success(transformLookupKeyResult(response.data)));
      })
     .catch((error) => {
       dispatch(makeError(error));
       dispatch(lookupDomainRequest.failure(error.message));
     });
  }
}
