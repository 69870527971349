import { Dispatch } from 'redux';
import axios from 'axios';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ItemHistoryState } from './types';
import { AppState } from '../index';
import { makeError } from '../error/actions';

export const requestHistoryAction = createAsyncAction(
  "HISTORY_REQUEST_START",
  "HISTORY_REQUEST_SUCCESS",
  "HISTORY_REQUEST_FAILURE")<string, ItemHistoryState, string>();

export const closeHistoryViewAction = createStandardAction("HISTORY_CLOSE")<void>();

export const requestHistory = (nodeName: string, nodeType: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(requestHistoryAction.request(nodeName));
    axios.get(`/api/v1/entities/history?nodeName=${encodeURIComponent(nodeName)}&nodeType=${encodeURIComponent(nodeType)}`)
      .then((response) => {
        dispatch(requestHistoryAction.success(response.data));
      })
      .catch((error) => {
        dispatch(makeError(error));
        dispatch(requestHistoryAction.failure(error.message));
      });
  }
}
export const closeHistoryView = () => {
  return closeHistoryViewAction();
}

export const requestVariantHistoryAction = createAsyncAction(
  "VARIANT_HISTORY_REQUEST_START",
  "VARIANT_HISTORY_REQUEST_SUCCESS",
  "VARIANT_HISTORY_REQUEST_FAILURE")<string, ItemHistoryState, string>();

export const closeVariantHistoryViewAction = createStandardAction("VARIANT_HISTORY_CLOSE")<void>();

export const requestVariantHistory = (nodeName: string, nodeType: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(requestVariantHistoryAction.request(nodeName));
    axios.get(`/api/v1/Substances/GetVariantHistory?substanceName=${encodeURIComponent(nodeName)}`)
      .then((response) => {
        console.log('response.data ==', response.data)
        dispatch(requestVariantHistoryAction.success(response.data));
      })
      .catch((error) => {
        dispatch(makeError(error));
        dispatch(requestVariantHistoryAction.failure(error.message));
      });
  }
}

export const closeVariantHistoryView = () => {
  return closeVariantHistoryViewAction();
}

export const requestPolymerHistoryAction = createAsyncAction(
  "POLYMER_HISTORY_REQUEST_START",
  "POLYMER_HISTORY_REQUEST_SUCCESS",
  "POLYMER_HISTORY_REQUEST_FAILURE")<string, ItemHistoryState, string>();

export const closePolymerHistoryViewAction = createStandardAction("POLYMER_HISTORY_CLOSE")<void>();

export const requestPolymerHistory = (nodeName: string, nodeType: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(requestPolymerHistoryAction.request(nodeName));
    axios.get(`/api/v1/Structure/GetPolymerStructureHistory?substanceName=${encodeURIComponent(nodeName)}`)
      .then((response) => {
        console.log('polymer history ==', response.data[0])
        dispatch(requestPolymerHistoryAction.success(response.data[0]));
      })
      .catch((error) => {
        dispatch(makeError('No history data available'));
        dispatch(requestPolymerHistoryAction.failure(error.message));
      });
  }
}
export const closePolymerHistoryView = () => {
  return closePolymerHistoryViewAction();
}
