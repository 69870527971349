import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core';
import { ItemHistoryState } from '../store/history/types';
import { formatDateAndTime } from '../domain/Format';

const useStyles = makeStyles({
  paper: {
      padding: 5,
      textAlign: 'center' as 'center',
      overflow: "auto",
      maxHeight: "70vh"
    }
});

interface Props {
  historyData: ItemHistoryState;
}

const HistoryView = (props: Props) => {
  const classes = useStyles();
  const { historyData} = props;

  return (
    <Paper className={classes.paper} >
      <Typography variant="h4" >
        Item history
      </Typography>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell>When</TableCell>
            <TableCell>Who</TableCell>
            <TableCell>ChangeType</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Change Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="namelistBody">
          {historyData.itemHistory.map((item, i) => (
            <TableRow key={`item${i}`}>
              <TableCell>{formatDateAndTime(item.changeTime)}</TableCell>
              <TableCell>{item.userName}</TableCell>
              <TableCell>{item.changeType}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.nameType}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="h4" >
        Link history
      </Typography>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell>When</TableCell>
            <TableCell>Who</TableCell>
            <TableCell>ChangeType</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Change Description</TableCell>
            <TableCell>Domain Description</TableCell>
            <TableCell>Removed On</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="namelistBody">
          {historyData.linkHistory.map((item, i) => (
            <TableRow key={`item${i}`}>
              <TableCell>{formatDateAndTime(item.changeRecordTime)}</TableCell>
              <TableCell>{item.userName}</TableCell>
              <TableCell>{item.changeType}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.linkType}</TableCell>
              <TableCell>{item.domainName}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.domainDescription}</TableCell>
              <TableCell>{formatDateAndTime(item.removedOn)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default HistoryView;
