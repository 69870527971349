import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import SearchField from '../SearchBox/SearchField';
import DomainEditField from '../DomainSelector/DomainEditField';
import { Typography, Paper, FormControlLabel, FormGroup } from '@material-ui/core';
import ValidationError from './ValidationError';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  domainPanel: {
    padding: "8px"
  },
  title: {
    textAlign: "center"
  },
  paper: {
    padding: 2,
    margin: 8
  },
  flex: {
    display: "flex"
  },
  font: {
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    color: "rgba(0, 0, 0, 0.87)",
  },
  width: {
    width: "100%"
  },
  removeMar: {
    margin: "0px !important"
  }
});

interface ParentLinkFormPanelProps {
  allDomains: string[];
  values: any;
  selectedVal?: any | undefined | null;
}

const ParentLinkFormPanel = (props: ParentLinkFormPanelProps) => {
  const classes = useStyles();
  const { allDomains, values } = props;
  const [showFnR, setShowFnR] = React.useState(false);
  const [showPLM, setShowPLM] = React.useState(false);
  const [showRegulatory, setShowRegulatory] = React.useState(false);
  const [showSafety, setShowSafety] = React.useState(false);
  const [showHC, setShowHC] = React.useState(false);
  const [showPC, setShowPC] = React.useState(false);
  const [showBW, setShowBW] = React.useState(false);
  const [fnrChecked, setFnrChecked] = React.useState(false);
  const [plmChecked, setPlmChecked] = React.useState(false);
  const [regulatoryChecked, setRegulatoryChecked] = React.useState(false);
  const [safetyChecked, setSafetyChecked] = React.useState(false);
  const [hcChecked, setHcChecked] = React.useState(false);
  const [pcChecked, setPcChecked] = React.useState(false);
  const [bwChecked, setBwChecked] = React.useState(false);

  React.useEffect(() => {
    //if (values.linkDomains === []) {
    //  values.primaryDomains.splice(0, values.primaryDomains.length);
    //}
    //if (values.linkDomains === []) {
    //  setShowPLM(false);  A.splice(0,A.length)
    //  setShowRegulatory(false);
    //  setShowSafety(false);
    //}
  })
  const selectedVal = (value: any) => {
    props.selectedVal(value);
    if (value.name === 'F&R') {
      value.selected === true ? setShowFnR(true) : setShowFnR(false);
      if (value.selected === false) {
        for (var i = 0; i < values.primaryDomains.length; i++) {

          if (values.primaryDomains[i] === 'F&R') {
            values.primaryDomains.splice(i, 1);
            i--;
          }
        }
      }
    }
    else if (value.name === 'PLM') {
      value.selected === true ? setShowPLM(true) : setShowPLM(false);
      if (value.selected === false) {
        for (var i = 0; i < values.primaryDomains.length; i++) {

          if (values.primaryDomains[i] === 'PLM') {
            values.primaryDomains.splice(i, 1);
            i--;
          }
        }
      }
    }
    else if (value.name === 'Regulatory') {
      value.selected === true ? setShowRegulatory(true) : setShowRegulatory(false);
      if (value.selected === false) {
        for (var i = 0; i < values.primaryDomains.length; i++) {

          if (values.primaryDomains[i] === 'Regulatory') {
            values.primaryDomains.splice(i, 1);
            i--;
          }
        }
      }
    }
    else if (value.name === 'Safety') {
      value.selected === true ? setShowSafety(true) : setShowSafety(false);
      if (value.selected === false) {
        for (var i = 0; i < values.primaryDomains.length; i++) {

          if (values.primaryDomains[i] === 'Safety') {
            values.primaryDomains.splice(i, 1);
            i--;
          }
        }
      }
    } else if (value.name === 'HC') {
      value.selected === true ? setShowHC(true) : setShowHC(false);
      if (value.selected === false) {
        for (var i = 0; i < values.primaryDomains.length; i++) {

          if (values.primaryDomains[i] === 'HC') {
            values.primaryDomains.splice(i, 1);
            i--;
          }
        }
      }
    } else if (value.name === 'BW') {
      value.selected === true ? setShowBW(true) : setShowBW(false);
      if (value.selected === false) {
        for (var i = 0; i < values.primaryDomains.length; i++) {

          if (values.primaryDomains[i] === 'BW') {
            values.primaryDomains.splice(i, 1);
            i--;
          }
        }
      }
    } else if (value.name === 'PC') {
      value.selected === true ? setShowPC(true) : setShowPC(false);
      if (value.selected === false) {
        for (var i = 0; i < values.primaryDomains.length; i++) {

          if (values.primaryDomains[i] === 'PC') {
            values.primaryDomains.splice(i, 1);
            i--;
          }
        }
      }
    } else {
      //setShowPLM(false);
      //setShowRegulatory(false);
      //setShowSafety(false);
    }
    //console.log(props.selectedVal, allDomains, value);
  }
  const handleChange1 = (event: any) => {
    setFnrChecked(!fnrChecked);
    event.target.checked === true ?
      values.primaryDomains.push('F&R') : values.primaryDomains.splice(values.primaryDomains.indexOf('F&R'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < values.primaryDomains.length; i++) {

        if (values.primaryDomains[i] === 'F&R') {
          values.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const handleChange3 = (event: any) => {
    setPlmChecked(!plmChecked);
    event.target.checked === true ?
      values.primaryDomains.push('PLM') : values.primaryDomains.splice(values.primaryDomains.indexOf('PLM'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < values.primaryDomains.length; i++) {

        if (values.primaryDomains[i] === 'PLM') {
          values.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const handleChange4 = (event: any) => {
    setRegulatoryChecked(!regulatoryChecked);
    event.target.checked === true ?
      values.primaryDomains.push('Regulatory') : values.primaryDomains.splice(values.primaryDomains.indexOf('Regulatory'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < values.primaryDomains.length; i++) {

        if (values.primaryDomains[i] === 'Regulatory') {
          values.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const handleChange5 = (event: any) => {
    setSafetyChecked(!safetyChecked);
    event.target.checked === true ?
      values.primaryDomains.push('Safety') : values.primaryDomains.splice(values.primaryDomains.indexOf('Safety'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < values.primaryDomains.length; i++) {

        if (values.primaryDomains[i] === 'Safety') {
          values.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const handleChange6 = (event: any) => {
    setHcChecked(!hcChecked);
    event.target.checked === true ?
      values.primaryDomains.push('HC') : values.primaryDomains.splice(values.primaryDomains.indexOf('HC'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < values.primaryDomains.length; i++) {

        if (values.primaryDomains[i] === 'HC') {
          values.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const handleChange7 = (event: any) => {
    setBwChecked(!bwChecked);
    event.target.checked === true ?
      values.primaryDomains.push('BW') : values.primaryDomains.splice(values.primaryDomains.indexOf('BW'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < values.primaryDomains.length; i++) {

        if (values.primaryDomains[i] === 'BW') {
          values.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const handleChange8 = (event: any) => {
    setPcChecked(!pcChecked);
    event.target.checked === true ?
      values.primaryDomains.push('PC') : values.primaryDomains.splice(values.primaryDomains.indexOf('PC'), 1);
    if (event.target.checked === false) {
      for (var i = 0; i < values.primaryDomains.length; i++) {

        if (values.primaryDomains[i] === 'PC') {
          values.primaryDomains.splice(i, 1);
          i--;
        }
      }
    }
  };
  const LinkData = () => (
    <DomainEditField name="linkDomains" allDomains={allDomains} values={values} selectedVal={selectedVal} />
  )
  const ValidationData = () => (
    <ValidationError name="linkDomains" />
  )
  const enableBtn = values.newEntity.type === 'Composite substance' ? false : true;
  const showPrimaryForSubstanceName = values.newEntity.type === 'Substance name' || values.newEntity.type === 'Classification name' ? true : false;
  const LinkDataSearch = () => (
    <Paper className={classes.paper} >
      {enableBtn ? <InputLabel id='addNewFormDlg'>Parent</InputLabel> : null}
      <SearchField name="parentEntity" type={values.newEntity.type} values={values} />
    </Paper>
  )
  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.title}>Link to:</Typography>
      <LinkDataSearch />
      <div className={classes.flex}>
        <LinkData />
        {showPrimaryForSubstanceName && <Paper className={[classes.paper, classes.width].join(" ")} >
          <InputLabel className={classes.font}>Make this name Primary for:</InputLabel>
          <FormGroup aria-label="position" style={{ marginTop: "2px" }}>
          {showBW && <FormControlLabel
              id="domain"
              value="BW"
              control={<Checkbox onChange={handleChange7} checked={bwChecked} />}
              label="BW"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showFnR && <FormControlLabel
              id="domain"
              value="F&R"
              control={<Checkbox onChange={handleChange1} checked={fnrChecked} />}
              label="F&R"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showHC && <FormControlLabel
              id="domain"
              value="HC"
              control={<Checkbox onChange={handleChange6} checked={hcChecked} />}
              label="HC"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showPC && <FormControlLabel
              id="domain"
              value="PC"
              control={<Checkbox onChange={handleChange8} checked={pcChecked} />}
              label="PC"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showPLM && <FormControlLabel
              id="domain"
              value="PLM"
              control={<Checkbox onChange={handleChange3} checked={plmChecked} />}
              label="PLM"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showRegulatory && <FormControlLabel
              id="domain"
              value="Regulatory"
              control={<Checkbox onChange={handleChange4} checked={regulatoryChecked} />}
              label="Regulatory"
              labelPlacement="end"
              className={classes.removeMar}
            />}
            {showSafety && <FormControlLabel
              id="domain"
              value="Safety"
              control={<Checkbox onChange={handleChange5} checked={safetyChecked} />}
              label="Safety"
              labelPlacement="end"
              className={classes.removeMar}
            />
            }
          </FormGroup>
        </Paper>}
      </div>
      {
        // (values.newEntity.type === 'Substance' || values.newEntity.type === 'Composite substance') &&
        //<div className={classes.paper} > NOTE: The name of the substance being added now will be set as Primary name for the domain(s) selected. It is recommended to select one domain</div>
      }
      <ValidationData />
    </React.Fragment>
  )
}

export default ParentLinkFormPanel;
