import { MergeItemsState } from "./types";
import { Suggestion } from "../search/types";
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const emptyEntity: Suggestion = {
  name: "",
  type: "",
  subType: "",
  status: "",
  substanceId: null
}

const initialState: MergeItemsState = {
  source: emptyEntity,
  target: emptyEntity,
  auditDescription: "",
  saving: false,
  show: false,
  defaultSelected: ""
};

export const mergeItemsReducer = createReducer(initialState)
  .handleAction(actions.showMergeAction, (state, action) => ({ ...state, source: action.payload.source, target: { ...emptyEntity }, auditDescription: "", saving: false, show: true ,defaultSelected:action.payload.defaultSelected!=undefined?action.payload.defaultSelected:"Substance"}))
  .handleAction(actions.closeMergeAction, () => initialState)
  .handleAction(actions.saveMergeAction.request, (state) => ({ ...state, saving: true }))
  .handleAction(actions.saveMergeAction.success, () => initialState)
  .handleAction(actions.saveMergeAction.failure, (state) => ({ ...state, saving: false }));
