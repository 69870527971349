import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel, Configuration, AuthenticationParameters } from 'msal';
import sirConfig from './config';
import axios from 'axios';



export const useAuth = process.env.REACT_APP_USE_AUTH === "true";



function loggerCallback(logLevel: any, message: string, containsPii: any) {
  console.log(message, logLevel);
}
const environmentConfig = sirConfig();



const config: Configuration = {
  auth: {
    authority: environmentConfig.auth.authority,
    clientId: environmentConfig.auth.clientId,
    /*authority: 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
    clientId: '1fb2c867-ac02-4fc1-8080-a11cb68b213d',*/
    navigateToLoginRequestUrl: true,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: process.env.NODE_ENV === 'production' ? undefined :
    {
      logger: new Logger(
        loggerCallback, {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
        correlationId: '1234'
      }
      )
    }
};



const authenticationParameters: AuthenticationParameters = {
  scopes: [
    environmentConfig.auth.scope
  ]
  /*scopes: ["openid"]*/
}

export const getToken = async (authProvider: MsalAuthProvider): Promise<string> => {
  //const tokenResponse = await authProvider.getIdToken();
  //if (tokenResponse.idToken) {
  //  return tokenResponse.idToken.rawIdToken;
  //}

  //return Promise.resolve("");

  // Or for access token
   const tokenResponse = await authProvider.getAccessToken(authenticationParameters);
   if (tokenResponse.accessToken) {
     return tokenResponse.accessToken;
   }

   return Promise.resolve("");
}



axios.interceptors.request.use(async function (config) {
  console.log('get token to be called', authProvider)
  if (authProvider) {
    const token = await getToken(authProvider);

    console.log('Token:' + token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export const authProvider = useAuth ? new MsalAuthProvider(config, authenticationParameters, { loginType: LoginType.Redirect }) : null;
