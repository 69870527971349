import { ItemData } from "../store/editItem/types";

export const substanceNameDetailToEditItem = (nameDetail: any): ItemData => {
  return {
    name: nameDetail.name,
    type: "Substance name",
    subType: nameDetail.nameType,
    status: nameDetail.status,
    isPrimary: false,
    auditDescription: "",
    molWeight: "",
    primaryDomains: nameDetail.isPrimaryForDomains
  };
}

export const classificationNameDetailToEditItem = (nameDetail: any): ItemData => {
  return {
    name: nameDetail.name,
    type: "Classification name",
    subType: nameDetail.nameType,
    status: nameDetail.status,
    isPrimary: false,
    auditDescription: "",
    molWeight: "",
    primaryDomains: nameDetail.isPrimaryForDomains
  };
}
