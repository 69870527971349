export interface Suggestion {
  name: string;
  type: string;
  subType: string;
  status: string;
  substanceId: string | undefined | null;
  auditDescription?: string | undefined | null;
}

export interface SearchState {
    searchTerm: string;
    matches: Suggestion[];
    loading: boolean;
    notEnoughCharacters: boolean;
    selectedItem: Suggestion | null | undefined;
    context: string;
    hasMore: boolean;
}

export const initialState: SearchState = {
  searchTerm: "",
  matches: [],
  loading: false,
  notEnoughCharacters: true,
  selectedItem: null,
  context: "",
  hasMore: false
};
