import React from 'react';
import ChemicalClassIcon from "@material-ui/icons/Class";
import FunctionClassIcon from "@material-ui/icons/ClassOutlined";
import SubstanceIcon from "@material-ui/icons/Opacity";
import TradeNameIcon from "@material-ui/icons/Shop";
import SynonymIcon from "@material-ui/icons/Repeat";
import IdentifierIcon from "@material-ui/icons/Label";
import { BackBoneIcon } from './BackBoneIcon';

interface Props {
  type: string;
  subType: string;
  isBackbone?: boolean;
  isCompositeSub?: boolean;
};

const iconMap: { [index: string]: JSX.Element } = {
  'Chemical Class': <ChemicalClassIcon fontSize='inherit' />,
  'Chemical class': <ChemicalClassIcon fontSize='inherit' />,
  'Function': <FunctionClassIcon fontSize='inherit' />,
  'Synonym': <SynonymIcon fontSize='inherit' />,
  'Trade Name': <TradeNameIcon fontSize='inherit' />,
  'Substance': <SubstanceIcon fontSize='inherit' />,
  'Default': <IdentifierIcon fontSize='inherit' />,
  'BackBone': <BackBoneIcon />
};

export const ItemIcon = (props: Props) => {
  const { type, subType, isBackbone, isCompositeSub } = props;
  const lookupType = subType || type;
  //console.log('props ==', props)
  //console.log('lookupType ==', lookupType)
  //const isPrimaryForDomainsLength = isPrimaryForDomains ? isPrimaryForDomains.length : 0;
  //const icon = iconMap[lookupType] || iconMap['Default'];
  let icon =undefined;
  if (isCompositeSub == true) {
    console.log('composite sub here ==')
    icon = iconMap['Default']
  } else {
    icon= isBackbone ? iconMap['BackBone'] : (iconMap[lookupType] || iconMap['Default'])

  }
  //console.log('icon ==', icon)
  return (<span style={{ margin: "2px" }} >{icon}</span>);
}

export default ItemIcon;
