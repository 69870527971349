import { Dispatch } from 'redux';
import axios from 'axios';
import { saveAs } from "file-saver";
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ImportResult } from './types';
import {AppState} from '../index';
import { makeError, makeSuccessMessage } from '../error/actions';
import { locateItem, queryNode } from '../hierarchy/actions';

export const importRequestAction = createAsyncAction(
  "IMPORT_REQUEST_START",
  "IMPORT_REQUEST_SUCCESS",
  "IMPORT_REQUEST_FAILURE")<string, ImportResult, string>();

export const exportRequestAction = createAsyncAction(
  "EXPORT_REQUEST_START",
  "EXPORT_REQUEST_SUCCESS",
  "EXPORT_REQUEST_FAILURE")<string, string, string>();

export const exportReportAction = createAsyncAction(
  "EXPORT_REPORT_START",
  "EXPORT_REPORT_SUCCESS",
  "EXPORT_REPORT_FAILURE")<void, string, string>();

export const importFileDrop = createStandardAction("IMPORT_FILE_DROP")<File | null>();

export const exportNode = (nodeName: string, nodeType: string) => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    dispatch(exportRequestAction.request(nodeName));
    axios.get(`/api/v1/file?nodeName=${encodeURIComponent(nodeName)}&nodeType=${encodeURIComponent(nodeType)}&includeParents=true`)
      .then((response) => {
        dispatch(exportRequestAction.success('Done'));
        saveAs(new Blob([response.data]), "SIR-export.csv");
      })
      .catch((error) => {
        dispatch(makeError(error));
        dispatch(importRequestAction.failure(error.message));
      });
  }
}

export const exportReport = (reportUrl: string) => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    dispatch(exportReportAction.request());
    axios.get(`${reportUrl}`)
      .then((response) => {
        dispatch(exportReportAction.success('Done'));
        saveAs(new Blob([response.data]), "SIR-import-report.csv");
      })
      .catch((error) => {
        dispatch(makeError(error));
        dispatch(exportReportAction.failure(error.message));
      });
  }
}

export const importFile = () => {
  return (dispatch: Dispatch, getState: () => AppState ) => {
    const state=getState().importExport;
    const file = state.importFile;
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      dispatch(importRequestAction.request(state.importFileName));
      axios.put('/api/v1/file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          const result: ImportResult = {...response.data, name: file.name };
          dispatch(importRequestAction.success(result));
          dispatch(makeSuccessMessage(`Import complete: ${result.errorCount} errors, ${result.nameCount} names, ${result.substanceCount} substances, ${result.classificationCount} classification, ${result.linkCount} links`));
          dispatch<any>(queryNode('Orphans', 'Classification'));
          if (result.importRoot) {
            dispatch<any>(locateItem({ name: result.importRoot, type: "", subType: "", status: "", substanceId: "" }, true, result.importRoot));
          }
        })
        .catch((error) => {
          dispatch(makeError(error));
          dispatch(importRequestAction.failure(error.message));
      });
    }
  }
}

export const fileDrop = (files: File[]) => {
  if (files.length > 0) {
    return importFileDrop(files[0]);
  } else {
    return importFileDrop(null);
  }
}
