import { Dispatch } from 'redux';
import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import axios from 'axios';
import { AddItemState, AddItemSpec, AddItemPayload } from './types';
import { AppState } from '../index';
import { makeSuccessMessage, makeError } from '../error/actions';
import { locateItem } from '../hierarchy/actions';
import { showDetail } from '../itemDetail/actions';
import { getVariantFlag } from '../variant/actions';
import { hasConsecutiveSpaces } from '../../utils/checkConsecutiveSpaces';

export const addItemAction = createStandardAction("ADD_ITEM")<AddItemSpec>();
export const addItemNameAction = createStandardAction("ADD_ITEM_NAME")<AddItemSpec>();

export const showAddItem = (spec: AddItemSpec) => {
    return addItemAction(spec);
  
};
export const showAddItemName = (spec: AddItemSpec) => {
    return addItemNameAction(spec);
  
};

export const closeAddAction = createStandardAction("CLOSE_ADD")<void>();

export const closeAdd = () => {
  localStorage.removeItem('nameComposite');
  localStorage.removeItem('status');
  localStorage.removeItem('typeSelected');
  localStorage.removeItem('plm');
  localStorage.removeItem('safety');
  localStorage.removeItem('regulatory');
  localStorage.removeItem('fr');
  localStorage.removeItem('BW');
  localStorage.removeItem('PC');
  localStorage.removeItem('HC');
  localStorage.removeItem('audit');
  localStorage.removeItem('restricteddomains');
  localStorage.removeItem('restrictredemails');
  localStorage.removeItem('isAddCompositeSave');
  localStorage.removeItem('uniquename');
  localStorage.removeItem('parentRequired');
  //localStorage.removeItem('hasextraspace');
  return closeAddAction();
};

export const saveAddAction = createAsyncAction("SAVE_ADD_START", "SAVE_ADD_SUCCESS", "SAVE_ADD_FAIL")<string, AddItemState, any>();

const convertToApiCreateItemPayload = (values: AddItemState): AddItemPayload => {
  return {
    name: values.newEntity.name,
    type: values.parentEntity.type === "Composite" ? "Composite name" : values.newEntity.type,
    subType: values.newEntity.subType,
    status: values.newEntity.status,
    parentName: values.parentEntity.name,
    parentType: values.parentEntity.type,
    linkDomains: values.linkDomains,
    auditDescription: values.auditDescription
  }
}

const convertToApiCreateItemPayloadSubs = (values: any): any => {
  return {
    name: values.newEntity.name,
    type: values.parentEntity.type === "Composite" ? "Composite name" : values.newEntity.type,
    subType: values.newEntity.subType,
    status: values.newEntity.status,
    parentName: values.parentEntity.name,
    parentType: values.parentEntity.type,
    substanceMolWeight: values.molweight? values.molweight.toString(): '',
    linkDomains: values.linkDomains,
    primaryDomains: values.primaryDomains,
    auditDescription: values.auditDescription

  }
}

export const saveAdd = (values: AddItemState, formik: any) => {
  //let reg = /^.*\s{2,}.*$/
  //SonarQube warning for Denail of Service issue
  // let reg = /(?!\S)\s{2}(?!$)/
  if (hasConsecutiveSpaces(values.newEntity.name)) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      formik.setSubmitting(false);
    }
  }
  else if (values.newEntity.type === 'Substance name' && values.parentEntity.name === undefined) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      //dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      formik.setSubmitting(false);
    }
  }else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      let uniqueNameDisable = ((localStorage.getItem('uniquename')) === 'false' ? true : false);
      if (uniqueNameDisable) {
        dispatch(makeError('Please add an unique name'));
        formik.setSubmitting(false);
        //localStorage.removeItem('hasextraspace');
      }
      else {
        let payload = convertToApiCreateItemPayloadSubs(values);
        if (values.parentEntity.type !== 'Composite substance Name' && values.parentEntity.type) {
          // const payload = convertToApiCreateItemPayload(values);
          dispatch(saveAddAction.request(payload.name));
          axios.put('/api/v1/Entities', payload)
            .then((response) => {
              dispatch(makeSuccessMessage("New " + payload.type + " successfully added"));
              dispatch(saveAddAction.success(values));
              // if (payload.parentType !== "Composite") {
              //    dispatch<any>(locateItem({ name: payload.name, type: payload.type, subType: payload.subType, status: payload.status, substanceId: payload.name }));
              // }
              // dispatch<any>(showDetail({ name: payload.name, type: payload.parentType, subType: payload.subType, status: payload.status }));

              if (payload.type === 'Substance name' || payload.type === 'Classification name') {
                localStorage.removeItem('parentRequired');
                dispatch<any>(locateItem({ name: payload.parentName, type: payload.parentType, subType: payload.parentType, status: payload.status, substanceId: payload.parentName, auditDescription: payload.auditDescription }, true, payload.parentName));
              } else if (payload.type === 'Substance' && payload.parentType && payload.parentName) {
                showDetail({ name: payload.name, type: payload.type, subType: payload.subType, status: payload.status })(dispatch, getState);
              } else {
                dispatch<any>(locateItem({ name: payload.name, type: payload.type, subType: payload.subType, status: payload.status, substanceId: payload.name, auditDescription: payload.auditDescription }, true, payload.name));
              }
            })
            .catch((error: any) => {
              dispatch(makeError(error));
              dispatch(saveAddAction.failure(values));
              formik.setSubmitting(false);
              localStorage.removeItem('uniquename');
            });
        } else {
          if ((values.parentEntity.name === "" || values.parentEntity.name === undefined) && payload.type === 'Substance name') {
            dispatch(makeError('Parent field cannot be empty while adding Substance name'));
            formik.setSubmitting(false);
          }
          else if ((values.parentEntity.name === "" || values.parentEntity.name === undefined) && payload.type === 'Classification name') {
            dispatch(makeError('Parent field cannot be empty while adding Classification name'));
            formik.setSubmitting(false);
          
          } else {
            let payloadData = convertToApiCreateItemPayloadSubs(values);
            dispatch(saveAddAction.request(payloadData.name));
            axios.put('/api/v1/Entities', payloadData)
              .then((response) => {
                dispatch(makeSuccessMessage("New " + payload.type + " successfully added"));
                dispatch(saveAddAction.success(values));
                dispatch<any>(locateItem({ name: payloadData.name, type: payloadData.type, subType: payloadData.type.subType, status: payloadData.status, substanceId: payloadData.name, auditDescription: payloadData.auditDescription }, true, payloadData.name));

                // dispatch<any>(locateItem({ name: payloadData.name, type: payloadData.type, subType: payloadData.subType, status: payloadData.status, substanceId: payloadData.parentName }));
                // dispatch<any>(showDetail({ name: payloadData.name, type: payloadData.parentType, subType: payloadData.subType, status: payloadData.status }));
              })
              .catch((error: any) => {
                dispatch(makeError(error));
                dispatch(saveAddAction.failure(error.message));
              });
          }
        }
      }

    }
  }
}
export const saveAddComposite = (values: any, formik: any) => {
  let hasExtraSpaceDisable = ((localStorage.getItem('hasextraspace')) === 'Name contains more than one consecutive space or leading/trailing spaces - please correct' ? true : false);
  //let reg = /^.*\s{2,}.*$/
  //SonarQube warning for Denail of Service issue
  // let reg = /(?!\S)\s{2}(?!$)/
  if (hasConsecutiveSpaces(values.name)) {
   return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      /*formik.setSubmitting(false);*/
    }
  } else { 
  return (dispatch: Dispatch, getState: () => AppState) => {
    let uniqueNameDisable = ((localStorage.getItem('uniquename')) === 'false' ? true : false);
    if (uniqueNameDisable) {
      dispatch(makeError('Please add an unique name'));
      // formik.setSubmitting(false);
    }
    else if (hasExtraSpaceDisable) {
      //dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      //formik.setSubmitting(false);
    } else {
      //  const payload = convertToApiCreateItemPayload(values);
      dispatch(saveAddAction.request(values.name));
      localStorage.removeItem('isAddCompositeSave');
      axios.put('/api/v1/Entities', values)
        .then((response) => {
          //  dispatch(makeSuccessMessage(""));
          localStorage.removeItem('nameComposite');
          localStorage.removeItem('status');
          localStorage.removeItem('typeSelected');
          localStorage.removeItem('plm');
          localStorage.removeItem('safety');
          localStorage.removeItem('regulatory');
          localStorage.removeItem('fr');
          localStorage.removeItem('BW');
          localStorage.removeItem('PC');
          localStorage.removeItem('HC');
          localStorage.removeItem('audit');
          dispatch(saveAddAction.success(values));
          dispatch<any>(getVariantFlag(values.name));   
          const restDomains = localStorage.getItem('restricteddomains');
          const restEmail = localStorage.getItem('restrictredemails');
          let restrictionData: any = {
            compositeSubstanceName: values.name,
            domains: restDomains ? JSON.parse(restDomains) : null,
            mailIds: restEmail ? JSON.parse(restEmail) : null
          }
          axios.put('/api/v1/CompositeSubstances/Restrictions', restrictionData).then((response) => {
            if (restrictionData.domains !== null || restrictionData.mailIds !== null) {
              dispatch(makeSuccessMessage("New item with restrictions is added successfully."));
            } else {
              dispatch(makeSuccessMessage("New Composite substance successfully added"));
            }
            localStorage.removeItem('restricteddomains');
            localStorage.removeItem('restrictredemails');
          }).catch((error: any) => {
            dispatch(makeError(error));
          });
          dispatch<any>(showDetail({ name: values.name, type: "Composite", subType: values.subType, status: values.status }));
        })
        .catch((error: any) => {
          dispatch(makeError(error));
          dispatch(saveAddAction.failure(error.message));

        });
    }
  }
}
}
export const saveAddSubComponent = (values: any, formik: any) => {
  //let reg = /^.*\s{2,}.*$/
  //SonarQube warning for Denail of Service issue
  // let reg = /(?!\S)\s{2}(?!$)/
  let uniqueNameDisable = ((localStorage.getItem('uniquename')) === 'false' ? true : false);
  let hasExtraSpaceDisable = ((localStorage.getItem('hasextraspace')) === 'Name contains more than one consecutive space or leading/trailing spaces - please correct' ? true : false);
  if (uniqueNameDisable) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please add an unique name'));
      formik.setSubmitting(false);
    }
  } else if (hasExtraSpaceDisable) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      formik.setSubmitting(false);
    }
  } else {
    if (values.newEntity.name === "") {
      return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(makeError('Please enter Name'));
        formik.setSubmitting(false);
      }
    } else if (values.molweight === undefined || values.molweight === null || values.molweight === "") {
      return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(makeError('Please enter Molar Weight'));
        formik.setSubmitting(false);
      }
    }
    else if (hasConsecutiveSpaces(values.newEntity.name)) {
      return (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
        formik.setSubmitting(false);
      }
    }
    else {
      return (dispatch: Dispatch, getState: () => AppState) => {
        let object: any = {
          substanceName: values.newEntity.name,
          substanceMolWeight: values.molweight,
          status: values.newEntity.status,
          auditDescription: values.auditDescription

        }
        axios.put('/api/v1/ContributionFactor/AddSubComponent', object).then((response) => {
          dispatch(makeSuccessMessage("New subcomponent added successfully."));
          formik.setSubmitting(true);
          dispatch(closeAdd());
        }).catch((error: any) => {
          dispatch(makeError(error));
          formik.setSubmitting(false);
        });

      }
    }
  } 
}
