import React from 'react';
import MaterialTable from 'material-table';
import SearchInputBox from '../SearchBox/SearchInputBox';
import { useDispatch } from 'react-redux';
import { Suggestion } from '../store/search/types';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core';

export default function EditContributionTable(props: any) {
  const [state, setState] = React.useState({ data: props.dataLoad });
  const [nameReq, setNameReq] = React.useState(false);
  const [cFactor, setCFactor] = React.useState(false);
  const [numberOfReq, setNumberOfReq] = React.useState(false);
  const [molWeightReq, setMolWeightReq] = React.useState(false);
  const [objectData, setobjectData] = React.useState <any>();
  let [cfval, setCfVal] = React.useState<any>();
  let [CFNew, setCFNew] = React.useState(false);
  const [noexpVal, setNoExpVal] = React.useState('');

  const itemSelected = (item: any) => {
  }
  const dataSubstance = (item: Suggestion) => {
    setobjectData(item);
    setCfVal("")
    setMolWeightReq(true);
    setNameReq(false);
    if (noexpVal) {
      calculateValOnChangeName(noexpVal, item);
    }
  }
  const calculateValOnChangeName = (val: any, item: any) => {
    //clearTimeout(typingTimer);
    //typingTimer = setTimeout(() => {
    setNoExpVal(val);
    setCFactor(false);
    if (item && props.molWeightParent) {
      let valData: any = (+(val * (+item.type)) / (+props.molWeightParent));
      setCfVal(valData.toFixed(8));
    }
  }
  props.selectedItem(state.data);
  let typingTimer: any = null;
  const calculateVal = (val: any) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      setNoExpVal(val);
      setCFactor(false);
      if (objectData && props.molWeightParent) {
        let valData: any = (+(val * (+objectData.type)) / (+props.molWeightParent));
        setCfVal(valData.toFixed(8));
        setCFNew(true);
      }
    }, 1500);
  }
  //const calculateVal = (val: any) => {
  //  setTimeout(() => {
  //    setCFactor(false);
  //    if (objectData && props.molWeightParent) {
  //      let valData: any = (+(val * (+objectData.type)) / (+props.molWeightParent));
  //      setCfVal(valData.toFixed(8));
  //      setCFNew(true);
  //    }
  //  }, 3000);

  //}
  return (
    <MaterialTable
      title="Add Contribution Factors with Sub-components"
      options={{
        filtering: false
      }}
      columns={[
        {
          title: 'Name of the substance or sub-components expressed as', field: 'name',
          editComponent: (props) => (
            <div className="search-box-cf">
              {nameReq ? <div><SearchInputBox
                itemSelected={itemSelected}
                value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                filter="subcomponent"
                dataSubstance={dataSubstance} /><p className='validationErrorMsg'>* Required</p></div> :
                !molWeightReq ? <SearchInputBox
                  itemSelected={itemSelected}
                  value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                  filter="subcomponent"
                  dataSubstance={dataSubstance} />
                  : <SearchInputBox
                    itemSelected={itemSelected}
                    value={{ name: objectData.name ? objectData.name : props.value, type: "", subType: "", status: "", substanceId: "" }}
                    filter="subcomponent"
                    dataSubstance={dataSubstance}
                  />
              }
            </div>
          )
        },
        {
          title: 'Mol. Weight of Expressed as', field: 'molweight',
          editComponent: (props) => (
            molWeightReq ? <div>{objectData.type}</div> : <div>{props.value}</div>
          )
        },
        {
          title: 'Number of  Expressed as', field: 'numberof',
          editComponent: (props) => (
            numberOfReq ? <div> < TextField
              type="number"
              placeholder='Number of'
              value={props.value ? props.value : ''}
              onChange={e => { props.onChange(+e.target.value); calculateVal(+e.target.value) }}
            /><p className='validationErrorMsg'>* Enter a positive number</p></div> : < TextField
                type="number"
                placeholder='Number of'
                value={props.value ? props.value : ''}
                onChange={e => { props.onChange(+e.target.value); calculateVal(+e.target.value) }}
              />
          )
        },
        {
          title: 'Contribution Factor', field: 'contributionFactor',
          editComponent: (props) => (
            cFactor ? <div className='validationErrorMsg'>Please fill parent and subcomponent required details</div> :
              <div>{cfval}</div>
          )
        }
      ]}
      data={state.data}
      editable={{
        onRowUpdateCancelled: (rowData) => {
          setNumberOfReq(false);
          setNameReq(false);
          setCFactor(false);
          setMolWeightReq(false);
          setCfVal("");
          setCFNew(false);
          setobjectData("");
          setNoExpVal('');
          localStorage.removeItem('searchType');
        },
        onRowAddCancelled: (rowData) => {
          setNumberOfReq(false);
          setNameReq(false);
          setCFactor(false);
          setMolWeightReq(false);
          setobjectData("");
          setCfVal("");
          setCFNew(false);
          setNoExpVal('');
          localStorage.removeItem('searchType');
        },
        onRowAdd: (newData: any) =>
          new Promise<void>((resolve, reject) => {
            if (objectData) {
              newData.name = objectData.name
              newData.molweight = objectData.type;
              newData.contributionFactor = cfval;
            }
            localStorage.removeItem('searchType');
            setNumberOfReq(false);
            setNameReq(false);
            setCFactor(false);
            setMolWeightReq(false);
            if (newData.name === '' || newData.name === undefined || newData.contributionFactor === '' || newData.contributionFactor === undefined
              || newData.contributionFactor <= 0 || newData.numberof === '' || newData.numberof === undefined || newData.numberof <= 0) {
              if (newData.name === '' || newData.name === undefined) {
                setNameReq(true);
              }
              if (newData.contributionFactor === '' || newData.contributionFactor === undefined || newData.contributionFactor <= 0) {
                setCFactor(true);
              }
              if (newData.numberof === '' || newData.numberof === undefined || newData.numberof <= 0) {
                setNumberOfReq(true);
              }
              reject();
              return;
            }
            resolve();
            setState((prevState: any) => {
              setobjectData("");
              setMolWeightReq(false);
              setCfVal("");
              setNoExpVal('');
              setCFNew(false);
              const data = [...prevState.data];
              data.push(newData);
              return { ...prevState, data };
            });
          }),
        onRowDelete: (oldData: any) =>
          new Promise<void>((resolve) => {
            resolve();
            localStorage.removeItem('searchType');
            setState((prevState: any) => {
              const data = [...prevState.data];
              data.splice(data.indexOf(oldData), 1);
              return { ...prevState, data };
            });
          })
      }}
    />
  )


}
