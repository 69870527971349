import React, { useEffect, useCallback } from 'react';
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Tree from "../MuiTreeView";
import { CircularProgress } from '@material-ui/core';
import ItemIcon from '../CommonComponents/ItemIcon';
import { NodeData } from '../store/hierarchy/types';

const useStyles = makeStyles({
  container: {
    margin: 20,
    flex: 1,
  },
  icon: {
    fontSize: 20
  },
  node: {
    display: "flex",
    alignContent: "center"
  },
  inactiveNode: {
    display: "flex",
    alignContent: "center",
    color: 'grey',
    textDecoration: 'line-through'
  },
  itemLabel: {
    float: "left"
  },
  itemProgress: {
    float: "left",
    marginLeft: "4px"
  }
});

const TreeView = (props: any) => {
  const classes = useStyles();
  const { onSelectNode, onShowDetail } = props;
  useEffect(() => {
    onSelectNode();
  }, [onSelectNode]);

  const onItemClick = useCallback(
    (data: NodeData) => {
      onShowDetail(data);
      if (data.partial && data.children && data.children.length > 0) {
        // May be incomplete part of tree - force fetch.
        // This handles the case where tree was populated by searching for an item - you only get the part of the tree that is needed to reach that item
        // In "normal" browsing down the tree, the fetch is handled by "requestChildrenData"
        onSelectNode(data.name, data.type);
      }
    },
    [onShowDetail, onSelectNode]
  );

  const renderLabel = (data: any, unfoldStatus: boolean) => {
    return (
      data.name === 'All' ? null :
      <div>
        <div className={classes.itemLabel}>
          <Typography variant={data.selected ? "h6": "body1"} className={data.status === 'I' ? classes.inactiveNode: classes.node}>
            <ItemIcon type={data.type} subType={data.subType} />
            <span onClick={() => onItemClick(data)} >
              {data.name}
            </span>
          </Typography>
        </div>
        <div className={classes.itemProgress}>
          {props.loading === data.name && <CircularProgress size={24} />}
        </div>
      </div>
      )
    }

  const requestChildrenData = (data: any, path: any, toggleFoldStatus: any,) => {
    props.onSelectNode(data.name, data.type);
    toggleFoldStatus();
  }

  return (
    <Tree
      key={props.data.key}
      unfoldFirst={true}
      className={classes.container}
      data={props.data}
      labelKey="name"
      valueKey="key"
      childrenKey="children"
      foldIcon={<ArrowRight />}
      unfoldIcon={<ArrowDropDownIcon />}
      renderLabel={renderLabel}
      pageSize={100}
      requestChildrenData={requestChildrenData}
      actionsAlignRight={true}
    />
  );
};

export default TreeView;
