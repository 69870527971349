import { Dispatch } from 'redux';
import axios from 'axios';
import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import { Suggestion, SearchState } from './types';
import { AppState } from '../index';
import { transformSearchResult } from './utilities';
import { locateItem } from '../hierarchy/actions';
import { makeError } from '../error/actions';
import { getNameFlag } from '../flag/actions';
import { getSubComponentInfo, getMolWeightInfo } from '../subComponent/actions';

export const searchNotEnough = createStandardAction("SEARCH_NOT_ENOUGH")<boolean>();

export const searchRequest = createAsyncAction("SEARCH_REQUEST_START", "SEARCH_REQUEST_SUCCESS", "SEARCH_REQUEST_FAILURE")<string, Suggestion[], string>();

export const itemSelected = createStandardAction("SEARCH_ITEM_SELECTED")<Suggestion>();

export const searchStateChange = createStandardAction("SEARCH_STATE")<SearchState>();

// If input is big enough to search, start an API search, then put result in store.
// If input is too small for a worthwhile search, set state to indicate that.
export const search = (inputValue: string, maxResults: number = 20) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const current = getState().search.selectedItem;
    if (current && inputValue === current.name) {
      // No change
      return;
    }

    if (!inputValue || inputValue.length < 3) {
      dispatch(searchNotEnough(true));
      return;
    }

    dispatch(searchRequest.request(inputValue));

    axios.get(`/api/v1/entities?name=${encodeURIComponent(inputValue)}&maxResults=${maxResults}`)
      .then((response) => {
        dispatch(searchRequest.success(transformSearchResult(response.data.matches)));
      })
      .catch(function (error) {
        dispatch(makeError(error));
        dispatch(searchRequest.failure(error.message));
      });
  }
}

// If item is already in tree, set it as the selected item.
// Otherwise, query for tree to the selected item and merge into tree state. Also trigger a showDetail action.
export const onItemSelected = (item: Suggestion) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    localStorage.removeItem('compositepage');
    dispatch<any>(getMolWeightInfo(item.name));
    dispatch<any>(getSubComponentInfo(item.name));
    dispatch<any>(getNameFlag(item.name));
    locateItem(item)(dispatch, getState);
    dispatch(itemSelected(item));
  }
}
export const onItemSelectedPopUp = (item: Suggestion) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    //  locateItem(item)(dispatch, getState);
    localStorage.setItem('compositepage', "true");
    dispatch(itemSelected(item));
    //dispatch<any>(getSubComponentList(item.name));
  }
}
