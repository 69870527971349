import React, { useCallback } from 'react';
import MaterialTable from 'material-table';
import SearchInputBox from '../SearchBox/SearchInputBox';
import { useDispatch } from 'react-redux';
import { onItemSelectedPopUp } from '../store/search/actions';
import { Suggestion } from '../store/search/types';
import TextField from "@material-ui/core/TextField";
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { storeDeletedObject } from '../store/editItem/actions';

export default function AddSubstanceToCompositePanel(props: any) {
  let completed = false;
  let avgLevelVal = false;
  let minLevelVal = false;
  let maxLevelVal = false;
  let showInvalidMin = false;
  let showInvalidMax = false;
  let object: Suggestion;
  const isEdit = localStorage.getItem('editComposite');
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const itemDetail = detailData.itemDetail;
  const dataLoad: any = [{}];
  if (isEdit === 'true') {
    if (itemDetail.compositeNames && itemDetail.compositeNames.length !== undefined) {
      for (let i = 0; i < itemDetail.compositeNames.length; i++) {
        dataLoad.push({
          name: itemDetail.compositeNames[i].name,
          avglevel: itemDetail.compositeNames[i].avg ? +(itemDetail.compositeNames[i].avg) : undefined,
          minlevel: itemDetail.compositeNames[i].min ? +(itemDetail.compositeNames[i].min) : undefined,
          maxlevel: itemDetail.compositeNames[i].max ? +(itemDetail.compositeNames[i].max) : undefined
        });
      };
      dataLoad.shift();
    }
  } else {
    dataLoad.shift();
  }
  const [state, setState] = React.useState({
    data: dataLoad,
    newDataGlobal: {},
    editedData: {},
    //delArray: delArray
  });
  //const [delArray, setDelArray] = React.useState([
  //]);
  const dispatch = useDispatch();
  const itemSelected = useCallback(
    (item: Suggestion) => {
      //dispatch(onItemSelectedPopUp(item)),
    },
    [dispatch]
  );
  const dataSubstance = (item: Suggestion) => {
    object = item;
  }
  props.data(state.newDataGlobal);
  //props.editedData(delArray);
  if (dataLoad.length > 0) {
    props.data(state.data);
  } else {
    props.data(state.data);
  }
  if (isEdit && dataLoad[0] && dataLoad[0].name) {
    return (
      //<ThingsProvider value={ delArray }>
      <MaterialTable
        title="Add Components to Composite Substance"
        options={{
          //paging: false,
          //}
        }}
        columns={
          [
            {
              title: 'Name', field: 'name', editable: "onAdd",
              editComponent: (props) => (
                completed ?
                  <div className="search-box" id='compSearch1'><SearchInputBox
                    itemSelected={itemSelected}
                    value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                    filter="Substance,Substance name"
                    dataSubstance={dataSubstance}
                  /> <p className='validationErrorMsg'>* Required</p></div> :
                  <div className="search-box" id='compSearch2'>
                    <SearchInputBox
                      itemSelected={itemSelected}
                      value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                      filter="Substance,Substance name"
                      dataSubstance={dataSubstance}
                    /> </div>
              )
            },
            {
              title: 'Min. Level(%)', field: 'minlevel',
              editComponent: (props) => (
                minLevelVal ?
                  <div><TextField
                    type="number"
                    placeholder='Min. Level(%)'
                    className='text-strike'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)} />
                    {showInvalidMin ?
                      <p className='validationErrorMsg'>Enter a value lesser than or equal to Avg level.</p>
                      : <p className='validationErrorMsg'>Enter a value greater than 0 and lesser than 100.</p>
                    }
                  </div>
                  : <TextField
                    type="number"
                    placeholder='Min. Level(%)'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)}
                  />
              )
            },
            {
              title: 'Avg. Level(%)', field: 'avglevel',
              editComponent: (props) => (
                avgLevelVal ?
                  <div><TextField
                    type="number"
                    placeholder='Avg. Level(%)'
                    className='text-strike'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)} />
                    <p className='validationErrorMsg'>Enter a value greater than 0 and lesser than 100</p></div>
                  : <TextField
                    type="number"
                    placeholder='Avg. Level(%)'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)}
                  />
              )
            },
            {
              title: 'Max. Level(%)', field: 'maxlevel',
              editComponent: (props) => (
                maxLevelVal ?
                  <div><TextField
                    type="number"
                    placeholder='Max. Level(%)'
                    className='text-strike'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)} />
                    {showInvalidMax ?
                      <p className='validationErrorMsg'>Enter a value greater than or equal to Avg level.</p>
                      : <p className='validationErrorMsg'>Enter a value greater than 0 and lesser than 100.</p>
                    }

                  </div>
                  : <TextField
                    type="number"
                    placeholder='Max. Level(%)'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)}

                  />
              )
            }

          ]}
        data={state.data}
        editable={{
          onRowUpdateCancelled: (rowData) => {
            completed = false;
            avgLevelVal = false;
            minLevelVal = false;
            maxLevelVal = false;
          },
          onRowAddCancelled: (rowData) => {
            completed = false;
            avgLevelVal = false;
            minLevelVal = false;
            maxLevelVal = false;
          },
          onRowAdd: (newData: any) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                completed = false;
                avgLevelVal = false;
                avgLevelVal = false;
                minLevelVal = false;
                maxLevelVal = false;
                if (isEdit === 'true') {
                  localStorage.setItem('isEditCompositeSave', 'true')
                }
                else {
                  localStorage.setItem('isAddCompositeSave', 'true')
                }
                if (object) {
                  newData.name = object.substanceId;
                }
                if (newData.avglevel || newData.avglevel == '') {
                  newData.avglevel = +newData.avglevel;
                }
                if (newData.minlevel || newData.minlevel == '') {
                  newData.minlevel = +newData.minlevel;
                }
                if (newData.maxlevel || newData.maxlevel == '') {
                  newData.maxlevel = +newData.maxlevel;
                }
                if (newData.avglevel > 100 || newData.avglevel < 0 || newData.minlevel > 100 || newData.minlevel < 0
                  || newData.maxlevel > 100 || newData.maxlevel < 0 || newData.name === '' || newData.name === undefined
                  || ((newData.minlevel > newData.avglevel) && (newData.avglevel > 0) && (newData.minlevel > 0))
                  || ((newData.maxlevel < newData.avglevel) && (newData.avglevel > 0) && (newData.maxlevel > 0))) {
                  if (newData.avglevel > 100 || newData.avglevel < 0) {
                    avgLevelVal = true;
                  }
                  if (newData.minlevel > 100 || newData.minlevel < 0) {
                    minLevelVal = true;
                  }
                  if (newData.maxlevel > 100 || newData.maxlevel < 0) {
                    maxLevelVal = true;
                  }
                  if (newData.name === '' || newData.name === undefined) {
                    completed = true;
                  }
                  if ((newData.minlevel > newData.avglevel) && (newData.minlevel < 100) && (newData.minlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMin = true;
                    minLevelVal = true;
                  }
                  if ((newData.maxlevel < newData.avglevel) && (newData.maxlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMax = true;
                    maxLevelVal = true;
                  }
                  reject();
                  return;
                }

                resolve();
                setState((prevState) => {
                  if (newData.avglevel === 0) {
                    newData.avglevel = ''
                  }
                  if (newData.minlevel === 0) {
                    newData.minlevel = ''
                  }
                  if (newData.maxlevel === 0) {
                    newData.maxlevel = ''
                  }
                  const data = [...prevState.data];
                  newData['isEdited'] = false;
                  newData['isDeleted'] = false;
                  newData['isAdded'] = true;
                  data.push(newData);
                  const newDataGlobal = data;
                  return { ...prevState, data, newDataGlobal };
                });
              }, 600);
            }),
          onRowUpdate: (newData: any, oldData: any) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                completed = false;
                avgLevelVal = false;
                avgLevelVal = false;
                minLevelVal = false;
                maxLevelVal = false;
                showInvalidMax = false;
                showInvalidMin = false;
                if (object) {
                  newData.name = object.substanceId;
                }
                if (newData.avglevel || newData.avglevel == '') {
                  newData.avglevel = +newData.avglevel;
                }
                if (newData.minlevel || newData.minlevel == '') {
                  newData.minlevel = +newData.minlevel;
                }
                if (newData.maxlevel || newData.maxlevel == '') {
                  newData.maxlevel = +newData.maxlevel;
                }
                if (isEdit === 'true') {
                  localStorage.setItem('isEditCompositeSave', 'true')
                } else {
                  localStorage.setItem('isAddCompositeSave', 'true')
                }
                if (newData.avglevel > 100 || newData.avglevel < 0 || newData.minlevel > 100 || newData.minlevel < 0
                  || newData.maxlevel > 100 || newData.maxlevel < 0 || newData.name === ''
                  || ((newData.minlevel > newData.avglevel) && (newData.avglevel > 0) && (newData.minlevel > 0))
                  || ((newData.maxlevel < newData.avglevel) && (newData.avglevel > 0) && (newData.maxlevel > 0))) {
                  if (newData.avglevel > 100 || newData.avglevel < 0) {
                    avgLevelVal = true;
                  }
                  if (newData.minlevel > 100 || newData.minlevel < 0) {
                    minLevelVal = true;
                    showInvalidMin = false;
                  }
                  if (newData.maxlevel > 100 || newData.maxlevel < 0) {
                    maxLevelVal = true;
                  }
                  if (newData.name === '') {
                    completed = true;
                  }
                  if ((newData.minlevel > newData.avglevel) && (newData.minlevel < 100) && (newData.minlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMin = true;
                    minLevelVal = true;
                  }
                  if ((newData.maxlevel < newData.avglevel) && (newData.maxlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMax = true;
                    maxLevelVal = true;
                  }
                  reject();
                  return;
                }

                resolve();
                if (oldData) {
                  completed = false;
                  avgLevelVal = false;
                  minLevelVal = false;
                  maxLevelVal = false;
                  showInvalidMax = false;
                  showInvalidMin = false;
                  setState(prevState => {
                    if (newData.avglevel === 0) {
                      newData.avglevel = ''
                    }
                    if (newData.minlevel === 0) {
                      newData.minlevel = ''
                    }
                    if (newData.maxlevel === 0) {
                      newData.maxlevel = ''
                    }
                    const data = [...prevState.data];
                    newData['isEdited'] = true;
                    newData['isDeleted'] = false;
                    newData['isAdded'] = false;
                    data[data.indexOf(oldData)] = newData;
                    const newDataGlobal = data;
                    return { ...prevState, data, newDataGlobal };
                  });
                }
              }, 600);
            }),
          onRowDelete: (oldData: any) =>
            new Promise<void>((resolve) => {
              setTimeout(() => {
                resolve();
                if (isEdit === 'true') {
                  localStorage.setItem('isEditCompositeSave', 'true')
                }
                else {
                  localStorage.setItem('isAddCompositeSave', 'true')
                }
                setState((prevState) => {
                  const data = [...prevState.data];
                  oldData['isEdited'] = false;
                  oldData['isDeleted'] = true;
                  oldData['isAdded'] = false;
                  //data[data.indexOf(prevState.data)] = oldData;
                  localStorage.setItem('rowdelete', JSON.stringify(oldData));
                  const newDataGlobal = oldData;
                  //const temp: any = [];
                  //temp.push(newDataGlobal);
                  //setDelArray(temp);
                  storeDeletedObject(newDataGlobal);
                  data.splice(data.indexOf(oldData), 1);
                  return { ...prevState, data, newDataGlobal };
                  //return { ...prevState, data };
                });
              }, 600);
            })
        }}
      />
      //</ThingsProvider>  
    );
  }
  else if (!isEdit) {
    return (
      //<ThingsProvider value={ delArray }>
      <MaterialTable
        title="Add Components to Composite Substance"
        options={{
          //paging: false,
          //}
        }}
        columns={
          [
            {
              title: 'Name', field: 'name',
              editComponent: (props) => (
                completed ?
                  <div className="search-box" id='compSearch3'><SearchInputBox
                    itemSelected={itemSelected}
                    value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                    filter="Substance,Substance name"
                    dataSubstance={dataSubstance}
                  /> <p className='validationErrorMsg'>* Required</p></div> :
                  <div className="search-box" id='compSearch4'>
                    <SearchInputBox
                      itemSelected={itemSelected}
                      value={{ name: props.value, type: "", subType: "", status: "", substanceId: "" }}
                      filter="Substance,Substance name"
                      dataSubstance={dataSubstance}
                    /> </div>
              )
            },
            {
              title: 'Min. Level(%)', field: 'minlevel',
              editComponent: (props) => (
                minLevelVal ?
                  <div><TextField
                    type="number"                    
                    placeholder='Min. Level(%)'
                    className='text-strike'
                    value={props.value || (props.value === 0) ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)} />
                    {showInvalidMin ?
                      <p className='validationErrorMsg'>Enter a value lesser than or equal to Avg level.</p>
                      : <p className='validationErrorMsg'>Enter a value greater than 0 and lesser than 100.</p>
                    }
                  </div>
                  : <TextField
                    type="number"                    
                    placeholder='Min. Level(%)'
                    value={props.value || (props.value === 0 ) ? props.value : ''}
                    onChange={e => (props.onChange(e.target.value))}
                  />
              )
            },
            {
              title: 'Avg. Level(%)', field: 'avglevel',
              editComponent: (props) => (
                avgLevelVal ?
                  <div><TextField
                    type="number"                    
                    placeholder='Avg. Level(%)'
                    className='text-strike'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)} />
                    <p className='validationErrorMsg'>Enter a value greater than 0 and lesser than 100</p></div>
                  : <TextField
                    type="number"
                    
                    placeholder='Avg. Level(%)'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)}
                  />
              )
            },
            {
              title: 'Max. Level(%)', field: 'maxlevel',
              editComponent: (props) => (
                maxLevelVal ?
                  <div><TextField
                    type="number"
                    placeholder='Max. Level(%)'
                    className='text-strike'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)} />
                    {showInvalidMax ?
                      <p className='validationErrorMsg'>Enter a value greater than or equal to Avg level.</p>
                      : <p className='validationErrorMsg'>Enter a value greater than 0 and lesser than 100.</p>
                    }

                  </div>
                  : <TextField
                    type="number"
                    placeholder='Max. Level(%)'
                    value={props.value || props.value === 0 ? props.value : ''}
                    onChange={e => props.onChange(e.target.value)}

                  />
              )
            }

          ]}
        data={state.data}
        editable={{
          onRowUpdateCancelled: (rowData) => {
            completed = false;
            avgLevelVal = false;
            minLevelVal = false;
            maxLevelVal = false;
          },
          onRowAddCancelled: (rowData) => {
            completed = false;
            avgLevelVal = false;
            minLevelVal = false;
            maxLevelVal = false;
          },
          onRowAdd: (newData: any) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                completed = false;
                avgLevelVal = false;
                avgLevelVal = false;
                minLevelVal = false;
                maxLevelVal = false;
                if (newData.avglevel || newData.avglevel == '') {
                  newData.avglevel = +newData.avglevel;
                }
                if (newData.minlevel || newData.minlevel == '') {
                  newData.minlevel = +newData.minlevel;
                }
                if (newData.maxlevel || newData.maxlevel == '') {
                  newData.maxlevel = +newData.maxlevel;
                }
                if (isEdit === 'true') {
                  localStorage.setItem('isEditCompositeSave', 'true')
                }
                else {
                  localStorage.setItem('isAddCompositeSave', 'true')
                }
                if (object) {
                  newData.name = object.substanceId;
                }
                if (newData.avglevel > 100 || newData.avglevel < 0 || newData.minlevel > 100 || newData.minlevel < 0
                  || newData.maxlevel > 100 || newData.maxlevel < 0 || newData.name === '' || newData.name === undefined
                  || ((newData.minlevel > newData.avglevel) && (newData.avglevel > 0) && (newData.minlevel > 0))
                  || ((newData.maxlevel < newData.avglevel) && (newData.avglevel > 0) && (newData.maxlevel > 0))) {
                  if (newData.avglevel > 100 || newData.avglevel < 0) {
                    avgLevelVal = true;
                  }
                  if (newData.minlevel > 100 || newData.minlevel < 0) {
                    minLevelVal = true;
                  }
                  if (newData.maxlevel > 100 || newData.maxlevel < 0) {
                    maxLevelVal = true;
                  }
                  if (newData.name === '' || newData.name === undefined) {
                    completed = true;
                  }
                  if ((newData.minlevel > newData.avglevel) && (newData.minlevel < 100) && (newData.minlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMin = true;
                    minLevelVal = true;
                  }
                  if ((newData.maxlevel < newData.avglevel) && (newData.maxlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMax = true;
                    maxLevelVal = true;
                  }
                  reject();
                  return;
                }

                resolve();
                setState((prevState) => {
                  if (newData.avglevel === 0) {
                    newData.avglevel = ''
                  }
                  if (newData.minlevel === 0) {
                    newData.minlevel = ''
                  }
                  if (newData.maxlevel === 0) {
                    newData.maxlevel = ''
                  }
                  const data = [...prevState.data];
                  newData['isEdited'] = false;
                  newData['isDeleted'] = false;
                  newData['isAdded'] = true;
                  data.push(newData);
                  const newDataGlobal = data;
                  return { ...prevState, data, newDataGlobal };
                });
              }, 600);
            }),
          onRowUpdate: (newData: any, oldData: any) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                completed = false;
                avgLevelVal = false;
                avgLevelVal = false;
                minLevelVal = false;
                maxLevelVal = false;
                showInvalidMax = false;
                showInvalidMin = false;
                if (object) {
                  newData.name = object.substanceId
                }
                if (isEdit === 'true') {
                  localStorage.setItem('isEditCompositeSave', 'true')
                } else {
                  localStorage.setItem('isAddCompositeSave', 'true')
                }
                if (newData.avglevel || newData.avglevel == '') {
                  newData.avglevel = +newData.avglevel;
                }
                if (newData.minlevel || newData.minlevel == '') {
                  newData.minlevel = +newData.minlevel;
                }
                if (newData.maxlevel || newData.maxlevel == '') {
                  newData.maxlevel = +newData.maxlevel;
                }
                if (newData.avglevel > 100 || newData.avglevel < 0 || newData.minlevel > 100 || newData.minlevel < 0
                  || newData.maxlevel > 100 || newData.maxlevel < 0 || newData.name === ''
                  || ((newData.minlevel > newData.avglevel) && (newData.avglevel > 0) && (newData.minlevel > 0))
                  || ((newData.maxlevel < newData.avglevel) && (newData.avglevel > 0) && (newData.maxlevel > 0))) {
                  if (newData.avglevel > 100 || newData.avglevel < 0) {
                    avgLevelVal = true;
                  }
                  if (newData.minlevel > 100 || newData.minlevel < 0) {
                    minLevelVal = true;
                    showInvalidMin = false;
                  }
                  if (newData.maxlevel > 100 || newData.maxlevel < 0) {
                    maxLevelVal = true;
                  }
                  if (newData.name === '') {
                    completed = true;
                  }
                  if ((newData.minlevel > newData.avglevel) && (newData.minlevel < 100) && (newData.minlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMin = true;
                    minLevelVal = true;
                  }
                  if ((newData.maxlevel < newData.avglevel) && (newData.maxlevel > 0) && (newData.avglevel > 0)) {
                    showInvalidMax = true;
                    maxLevelVal = true;
                  }
                  reject();
                  return;
                }

                resolve();
                if (oldData) {
                  completed = false;
                  avgLevelVal = false;
                  minLevelVal = false;
                  maxLevelVal = false;
                  showInvalidMax = false;
                  showInvalidMin = false;                  
                  setState(prevState => {
                    if (newData.avglevel === 0) {
                      newData.avglevel = ''
                    }
                    if (newData.minlevel === 0) {
                      newData.minlevel = ''
                    }
                    if (newData.maxlevel === 0) {
                      newData.maxlevel = ''
                    }
                    const data = [...prevState.data];
                    newData['isEdited'] = true;
                    newData['isDeleted'] = false;
                    newData['isAdded'] = false;
                    data[data.indexOf(oldData)] = newData;
                    const newDataGlobal = data;
                    return { ...prevState, data, newDataGlobal };
                  });
                }
              }, 600);
            }),
          onRowDelete: (oldData: any) =>
            new Promise<void>((resolve) => {
              setTimeout(() => {
                resolve();
                if (isEdit === 'true') {
                  localStorage.setItem('isEditCompositeSave', 'true')
                }
                else {
                  localStorage.setItem('isAddCompositeSave', 'true')
                }
                setState((prevState) => {
                  const data = [...prevState.data];
                  oldData['isEdited'] = false;
                  oldData['isDeleted'] = true;
                  oldData['isAdded'] = false;
                  //data[data.indexOf(prevState.data)] = oldData;
                  localStorage.setItem('rowdelete', JSON.stringify(oldData));
                  const newDataGlobal = oldData;
                  //const temp: any = [];
                  //temp.push(newDataGlobal);
                  //setDelArray(temp);
                  storeDeletedObject(newDataGlobal);
                  data.splice(data.indexOf(oldData), 1);
                  return { ...prevState, data, newDataGlobal };
                  //return { ...prevState, data };
                });
              }, 600);
            })
        }}
      />
      //</ThingsProvider>  
    );
  }
  else {
    return <div></div>
  }
}
