import React from 'react';
import { Select, TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field, Form, FormikActions } from 'formik';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';
import { ItemData } from '../store/editItem/types';
import { LookupState } from '../store/lookupData/types';
import AddComponentToCompositePanel from '../AddDialog/AddCompToComposite';
import DomainEditField from '../DomainSelector/DomainEditField';
import { Typography } from '@material-ui/core';
import { setTimeout } from 'timers';
import { useSelector } from 'react-redux';
import { AppState } from '../store';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
   fieldName: {
    marginBottom: "20px",
    //pointerEvents: "none"
  },
  msgColorRed: {
    color: 'red',
    fontSize: '14px'
  },
  msgColorGreen: {
    color: 'green',
    fontSize: '14px'
  },
  none: {
    display:"none",
  }

});

interface LookupProps {
  lookup: LookupState;
}
const EditCompositeNameForm = (props: LookupProps) => {
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const itemDetail = detailData.itemDetail;

  const classes = useStyles();
  const [levelData, setlevelData] = React.useState([])
  const [, setSubData]: any = React.useState([]);
  const { lookup } = props;
  const [showAvgMsg, setShowAvgMsg] = React.useState(false);
  const [showMinMsg, setShowMinMsg] = React.useState(false);
  //const [showMaxMsg, setShowMaxMsg] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState(false);
  const validationMessageAvg = 'sum of Average value should not be greater than 100';
  const validationMessageMin = 'sum of Minimum value should not be greater than 100';
  //const validationMessageMax = 'sum of Maximum value should not be greater than 100';
  const validationSuccessMsg = "valid composition";
  const errorValidationOnload = "Please fill composite data before validating";
  const [validateClick, setValidateClick] = React.useState(false);
  const [showDomainError, setShowDomainError] = React.useState(false);
  localStorage.setItem('editComposite', 'true');
  let [count, setCount] = React.useState(0); 
  const getEnableSaveBtn = localStorage.getItem('isEditCompositeSave');
  const dataHolderFunction = (levelDataComp: any) => {
    setlevelData(levelDataComp);
    if (levelData.length > 0) {
      localStorage.setItem('tableEditData', JSON.stringify(levelDataComp));
      setValidateClick(false);

    } else {
      setValidateClick(true);
    } 
    if (getEnableSaveBtn === 'true') {
      setSuccessMsg(false);
    } else {
      if (showAvgMsg || showMinMsg ) {
        setSuccessMsg(false);
      } else {
        setSuccessMsg(true);
      }
    }
  }
  const [disableIfEmptyDomain, setdisableIfEmptyDomain] = React.useState(false);
  setTimeout(() => {
    const parsedDomain = localStorage.getItem('selectedDomainsForEdit');
    let domainWhileEdit: any = [];
    if (parsedDomain) {
      domainWhileEdit = JSON.parse(parsedDomain);
    }

    setdisableIfEmptyDomain(domainWhileEdit.length <= 0 ? true : false);
    setShowDomainError(domainWhileEdit.length <= 0 ? true : false)
  }, 1000)

  const ValidateComposition = (e: any) => {
     localStorage.removeItem('isEditCompositeSave');
    const parsedDomain = localStorage.getItem('selectedDomainsForEdit');
    let domainForEdit: any = [];
    if (parsedDomain) {
      domainForEdit = JSON.parse(parsedDomain);
    }
    if (domainForEdit.length === 0) {
      setShowDomainError(true);
      setSuccessMsg(false);
      setValidateClick(false);
    } else {
      setShowDomainError(false);
      if (Object.keys(e).length === 0) {
        setSuccessMsg(false);
        setValidateClick(true);
      } else {
        //   if (e.length > 0) {
        var avgTotal = 0;
        var minTotal = 0;
        //var maxTotal = 0;
        for (let i = 0; i < e.length; i++) {
          avgTotal += e[i].avglevel === "" ? 0 : e[i].avglevel ;
          minTotal += e[i].minlevel === "" ? 0 : e[i].minlevel;
         // maxTotal += e[i].maxlevel === undefined ? 0 : e[i].maxlevel;
        }
        if (avgTotal > 100) {
          setShowAvgMsg(true);
        } else {
          setShowAvgMsg(false);
        }
        if (minTotal > 100) {
          setShowMinMsg(true);
        } else {
          setShowMinMsg(false);
        }
        //if (maxTotal > 100) {
        //  setShowMaxMsg(true);
        //} else {
        //  setShowMaxMsg(false);
        //}
        if (((avgTotal <= 100) || (Number.isNaN(avgTotal))) && ((minTotal <= 100) || (Number.isNaN(minTotal))) ) {
          setSuccessMsg(true);
        } else {
          setSuccessMsg(false);
        }
      }
    }
  }
  const substanceData = (data: any) => {
    setSubData(data);
  }
  if (count === 0 && (levelData.length > 0)) { 
    ValidateComposition(levelData);
    setCount(1);
  }
  return (
   <Form>
      { // <InputLabel>Name</InputLabel>
        // <EnhancedTextInputField name="name" fullWidth margin="dense" className={classes.fieldName} disabled /> 
      }
      <InputLabel>Status</InputLabel>
      <Field
        className={classes.field}
        name="status"
        component={Select}
        margin="dense"
        fullWidth
        >
        { lookup.status.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
        </Field>
      <DomainEditField name="linkDomains" allDomains={lookup.domain.map(i => i.display)} />
      {showDomainError ? <div className={classes.msgColorRed} > Please select atleast one domain</div> : null
}
      <InputLabel>Audit Description</InputLabel>
      <Field
        className={classes.field}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth/>
      <AddComponentToCompositePanel {...lookup} dataFromParent={dataHolderFunction} substanceData={substanceData} />
      <Button type="button" color="primary" className={(itemDetail.compositeNames && itemDetail.compositeNames[0] && itemDetail.compositeNames[0].name) ? "" : classes.none} onClick={() => ValidateComposition(levelData)}>
        Validate Composition
          </Button>
      <Button type="submit" color="primary" disabled={!successMsg || disableIfEmptyDomain}>
        Save
      </Button>
      {showAvgMsg ?
        <Typography variant="caption" className={classes.msgColorRed} >
          {validationMessageAvg}</Typography>
        :
        showMinMsg ?
          <Typography variant="caption" className={classes.msgColorRed} >
            {validationMessageMin}</Typography>
          :
            successMsg ?
            <Typography variant="caption" className={(itemDetail.compositeNames && itemDetail.compositeNames[0] && itemDetail.compositeNames[0].name) ? classes.msgColorGreen : classes.none } >
                {validationSuccessMsg}</Typography>
              : validateClick ?
                <Typography variant="caption" className={classes.msgColorRed} >
                  {errorValidationOnload}</Typography> :
                null
      }
      </Form>
  )
}

interface FormProps {
  data: ItemData;
  lookup: LookupState;
  onSave: (values: any, formikActions: FormikActions<ItemData>) => void;
}

const EditCompositeNameFormik = (props: FormProps) => {
  const { data, lookup } = props;

  return (
  <div>
    <Formik
      initialValues={data}
      onSubmit={props.onSave}
      render={() => (
        <EditCompositeNameForm lookup={lookup} />
      )}
    />
  </div>
  );
};

export default EditCompositeNameFormik;
