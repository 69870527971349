import { FormikErrors } from "formik";
import { MergeItemsFormState } from "../store/mergeItems/types";
import { Suggestion } from "../store/search/types";

export const validateMerge = (values: MergeItemsFormState) => {
  const errors: FormikErrors<MergeItemsFormState> = {};
  const sourceError = validateEntity(values.source);
  const targetError = validateEntity(values.target);
  const commonError = validateUniqueness(values.source, values.target)
  errors.source = sourceError && sourceError.name ? sourceError : commonError;
  errors.target = targetError && targetError.name ? targetError : commonError;

  const hasErrors = Object.keys(errors.source!).length || Object.keys(errors.target!).length;
  return hasErrors ? errors : undefined;
}

const validateEntity = (values: Suggestion): FormikErrors<Suggestion> => {
  const errors = {} as FormikErrors<Suggestion>;
  errors.name = validateNotEmpty(values.name, "Name");

  return errors;
}

const validateUniqueness = (values: Suggestion, other: Suggestion): FormikErrors<Suggestion> => {
  const errors = {} as FormikErrors<Suggestion>;
  if (values.name === other.name) {
    errors.name = "Different entities should be selected";
  }

  return errors;
}

const validateNotEmpty = (value: string, name: string): string | undefined => {
  return value ? undefined : `${name} cannot be empty`;
}
