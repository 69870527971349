import { createAsyncAction, createStandardAction } from "typesafe-actions";
import { Dispatch } from "redux";
import axios from 'axios';
import { AppState } from "..";
import { makeSuccessMessage, makeError } from "../error/actions";
import { SubCompState } from "./types";
import { useSelector } from "react-redux";
import { locateItem } from "../hierarchy/actions";
import { showDetail } from "../itemDetail/actions";


export const closeSubcompEditRequest = createStandardAction("FLAG_CLOSE_STRUCT")<void>();
export const openSubCompEditRequest = createStandardAction("FLAG_OPEN_STRUCT")<any>();
export const getMolWeightRequest = createAsyncAction(
  "GET_MOL_WEIGH_START",
  "GET_MOL_WEIGH_SUCCESS",
  "GET_MOL_WEIGH_FAILURE")<any, any, any>();
export const getSubComponentRequest = createAsyncAction(
  "GET_SUBCOMPONENT_REQUEST_START",
  "GET_SUBCOMPONENT_REQUEST_SUCCESS",
  "GET_SUBCOMPONENT_REQUEST_FAILURE")<any, any, any>();
export const getSubComponentSearchRequest = createAsyncAction(
  "GET_SUBCOMPONENT_SEARCH_REQUEST_START",
  "GET_SUBCOMPONENT_SEARCH_REQUEST_SUCCESS",
  "GET_SUBCOMPONENT_SEARCH_REQUEST_FAILURE")<any, any, any>();

export const closeSubcompEdit = () => {
  return closeSubcompEditRequest();
}

export const openSubCompEdit = () => {
  return openSubCompEditRequest("openSubCompEditDialog");
}

//export const editSubComponentAction = createStandardAction("EDIT_SUBCOMPONENT")<any>();

//export const showEditSubComponent = (itemData: any) => {
//  return editSubComponentAction(itemData);
//};

export const savedSubCompEditAction = createAsyncAction(
  "SAVE_SUBCOMP_EDIT_REQUEST_START",
  "SAVE_SUBCOMP_EDIT_REQUEST_SUCCESS",
  "SAVE_SUBCOMP_EDIT_REQUEST_FAILURE")<any, any, any>();

//const payloadCreation = () => {
//  const subCompDetailsReducer = useSelector((state: any) => state.subComponentReducer);
//}

export const saveAddSubComponent = (values: any, formik: any) => {
  if (values.newEntity.name === "") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please enter name'));
      formik.setSubmitting(false);
    }
  } else if (values.molweight === undefined) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please enter mol weight'));
      formik.setSubmitting(false);
    }
  } else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      // dispatch(makeError('Please enter mol weight'));
      formik.setSubmitting(false);
    }
  }
}
export const saveSubComponentEdit = (subcompName: any, MolValue: any, NoExp: any, Cf: any) => {
  const re = /[0-9]+/g;
  if (MolValue === "") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please enter Mol Weight'));
    }
  }
  else if (NoExp === "") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please enter Number of Expressed as'));
    }
  }
  else if (Cf === "") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please enter Contribution Factor'));
    }
  }
  else if (+Cf < 0 || +NoExp < 0 || +MolValue < 0) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please enter a positive value'));
    }
  }
  //else if (re.test(+NoExp) ) {
  //  return (dispatch: Dispatch, getState: () => AppState) => {
  //    dispatch(makeError('Number of Expressed As should not contain decimal values'));
  //  }
  //}
  else {
    const reducerDataUnparsed = localStorage.getItem('reducerData');
    const parsedData = reducerDataUnparsed ? JSON.parse(reducerDataUnparsed) : '';
    let index = localStorage.getItem('index');
    const parentSubstance = parsedData[0].substanceName;
    const parentMol = parsedData[0].substanceMolWeight;
    let component: any = [];
    let tempobj: any = {};
    for (let i = 0; i < parsedData.length; i++) {
      if (index && i === +index) {
        tempobj = {
          subComponentName: subcompName,
          subComponentMolWeight: MolValue,
          numberOfExpressedAs: NoExp,
          contributionFactor: Cf,
          status: ""
        }
        component.push(tempobj);
      } else {
        tempobj = {
          subComponentName: parsedData[i].subComponentName,
          subComponentMolWeight: parsedData[i].subComponentMolWeight,
          numberOfExpressedAs: parsedData[i].numberOfExpressedAs,
          contributionFactor: parsedData[i].contributionFactor,
          status: ""
        }
        component.push(tempobj);
      }
    }
    let object = {
      substanceName: parentSubstance,
      substanceMolWeight: parentMol,
      status: "",
      "component": component,
      auditDescription: ""
    }
    return (dispatch: Dispatch, getState: () => AppState) => {
      //https://localhost:44388/api/v1/ContributionFactor/EditSubComponent
      axios.post(`/api/v1/ContributionFactor/EditSubComponent`, object)
        .then((response) => {
          dispatch(savedSubCompEditAction.success(response.data));
          dispatch(makeSuccessMessage("Save complete"));
          //dispatch<any>(locateItem({ name: parentSubstance, type: "Substance", subType: "", status: "", substanceId: "" }, true, parentSubstance))
          dispatch<any>(showDetail({ name: parentSubstance, type: "Substance", subType: "", status: "" }));
          dispatch(getSubComponentSearchRequest.request(""));
          //dispatch(getSubComponentRequest.success(response.data));
        })
        .catch((error: any) => {
          dispatch(makeError(error));
        });
    }
  }
}

//get all subcomponent api call
export const getSubComponentInfo = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    //const name = localStorage.getItem('name');
    dispatch(getSubComponentRequest.request(""));
    //https://localhost:44300/api/v1/ContributionFactor/GetSubComponent
    //https://localhost:44388/api/v1/ContributionFactor/GetSubComponent?substanceName=Aluminium%20sulphate
    axios.get(`/api/v1/ContributionFactor/GetSubComponent?substanceName=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(getSubComponentRequest.success(response.data));
      })
      .catch((error: any) => {

      });
  }
}
export const clearsubComponentData = () => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getSubComponentRequest.request(null));
  }
}
export const getMolWeightInfo = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    //const name = localStorage.getItem('name');
    // dispatch(getMolWeightRequest.request(""));
    axios.get(`/api/v1/ContributionFactor/SubComponentExactSearch?substanceName=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(getMolWeightRequest.success(response.data));
      })
      .catch((error: any) => {

      });
  }
}


//GET subcomponent as search results
export const getSubComponentList = (item: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    //const name = localStorage.getItem('name');
    // dispatch(getMolWeightRequest.request(""));
    axios.get(`/api/v1/ContributionFactor/SearchSubComponent?substanceName=${encodeURIComponent(item)}`)
      .then((response) => {
        dispatch(getSubComponentSearchRequest.success(response.data));
      })
      .catch((error: any) => {

      });
  }
}
