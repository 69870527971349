import React from 'react';
import { Select, TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field, Form, FormikActions } from 'formik';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';
import { ItemData } from '../store/editItem/types';
import { LookupState } from '../store/lookupData/types';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  hidetype: {
    display: "none"
  },
  red: {
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 500,
    color: "red",
    marginBottom: "15px",
    marginTop: "-15px"
  }
});

interface LookupProps {
  lookup: LookupState;
  data: ItemData;
}

const EditClassificationForm = (props: LookupProps) => {
  const classes = useStyles();
  const [inActiveMsg, setInActiveMsg] = React.useState(false);
  const change = (e: any) => {
    if (e === 'Inactive') {
      setInActiveMsg(true);
    } else {
      setInActiveMsg(false);
    }
  }
  const { lookup,data } = props;
  return (
   <Form>
      <InputLabel className={(data.type === 'Classification') ? classes.hidetype : ''}>Name</InputLabel>
      <EnhancedTextInputField name="name" fullWidth margin="dense" className={(data.type === 'Classification') ? [classes.hidetype, classes.field].join(" ") : classes.field} />

      <InputLabel className={(data.type === 'Classification') ? classes.hidetype : ''}
      >Type</InputLabel>
      <Field
        className={(data.type === 'Classification') ? [classes.hidetype, classes.field].join(" ") : classes.field}
        name="subType"
        component={Select}
        margin="dense"
        fullWidth
        >
        { lookup.classificationType.map((item) =>
          <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
        ) }
      </Field>

      <InputLabel>Status</InputLabel>
      <Field
        className={classes.field}
        name="status"
        component={Select}
        margin="dense"
        fullWidth
        >
        { lookup.status.map((item) =>
          <MenuItem key={item.key} value={item.key} onClick={(e) => change(item.display)} > {item.display}</MenuItem>
        )}
      </Field>
      {inActiveMsg ? <div className={classes.red}>
        Note: Changing the status of the Classification to INACTIVE will automatically inactivate all the names, synonyms and identifiers assigned.
        Click SAVE in case you wish to proceed. </div> : null}

      <InputLabel>Audit Description</InputLabel>
      <Field
        className={classes.field}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth/>

      <Button type="submit" color="primary">
        Save
      </Button>
  </Form>
  )
}

interface FormProps {
  data: ItemData;
  lookup: LookupState;
  onSave: (values: ItemData, formikActions: FormikActions<ItemData>) => void;
}

const EditClassificationFormik = (props: FormProps) => {
  const { data, lookup } = props;

  return (
  <div>
    <Formik
      initialValues={data}
      onSubmit={props.onSave}
      render={() => (
        <EditClassificationForm lookup={lookup} data={data} />
      )}
    />
  </div>
  );
};

export default EditClassificationFormik;
