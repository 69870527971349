import React from 'react';
import { Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field, Form, FormikActions } from 'formik';
import { ItemData } from '../store/editItem/types';
import { LookupState } from '../store/lookupData/types';
import { TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px"
  },
  red: {
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 500,
    color: "red",
    marginBottom: "15px",
    marginTop: "-15px"
  }
});

interface LookupProps {
  lookup: LookupState;
  data?: ItemData;
}

const EditSubstanceForm = (props: LookupProps) => {
  const classes = useStyles();
  const { lookup, data } = props;
  const getSubComponentreducer = useSelector((state: any) => state.subComponentReducer);
  lookup.molWeight = (getSubComponentreducer && getSubComponentreducer.getMolWeightData[0]) ? getSubComponentreducer.getMolWeightData[0].substanceMolWeight : null;
  const molWeightVal = (data: any) => {
    localStorage.setItem('editSubSMolWeight', data.target.value);
  }
  const auditDescVal = (data: any) => {
    localStorage.setItem('editSubSAuditDesc', data.target.value);
  }
  const initialStatus = data ? data.status : null;
  const [activeMsg, setActiveMsg] = React.useState(false);
  const [inActiveMsg, setInActiveMsg] = React.useState(false);
  const change = (e: any) => {
    if (e === 'Inactive') {
      setInActiveMsg(true);
      //setActiveMsg(false);
    } else {
      setInActiveMsg(false);
    }
    //else if (e === 'Active') {
    //  setActiveMsg(true);
    //  setInActiveMsg(false);
    //}
  }
  return (
    <Form>
      <InputLabel>Status</InputLabel>
      <Field
        className={classes.field}
        name="status"
        component={Select}
        margin="dense"
        fullWidth
      >
        {lookup.status.map((item) =>
          <MenuItem key={item.key} value={item.key} onClick={(e) => change(item.display)} > {item.display}</MenuItem>
        )}
      </Field>
      {inActiveMsg ? <div className={classes.red}>
        Note: Changing the status of the Substance to INACTIVE will automatically inactivate all the names, synonyms and identifiers assigned.
        Click SAVE in case you wish to proceed. </div> : null}
      {// activeMsg ? <div className= { classes.red }>active</div>: null
      }

      { // <InputLabel>Name</InputLabel>
        // <EnhancedTextInputField name="name" fullWidth margin="dense" className={classes.field} disabled />
      }
      <InputLabel>Mol. Weight</InputLabel>
      <Field
        type="text"
        className={classes.field}
        name="molWeight"
        //inputProps={inputProps}
        //step="0.01"
        component={TextField}
        margin="dense"
        defaultValue={lookup.molWeight}
        onKeyDown={(e: any) => /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?><,-\s]*$/i.test(e.key) && e.which !== 8 && e.preventDefault()}
        fullWidth
        onBlur={molWeightVal} />
      <InputLabel>Audit Description</InputLabel>
      <Field
        className={classes.field}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth
        onBlur={auditDescVal} />
      <Button type="submit" color="primary">
        Save
      </Button>
    </Form>
  )
}

interface FormProps {
  data: ItemData;
  lookup: LookupState;
  onSave: (values: ItemData, formikActions: FormikActions<ItemData>) => void;
}

const EditSubstanceFormik = (props: FormProps) => {
  const { data, lookup } = props;
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={props.onSave}
        render={() => (
          <EditSubstanceForm lookup={lookup} data={data} />
        )}
      />
    </div>
  );
};

export default EditSubstanceFormik;
