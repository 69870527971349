import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import HistoryView from './HistoryView';
import { closeHistoryView } from '../store/history/actions';

const HistoryDialog = () => {
  const dispatch = useDispatch();
  const itemHistory = useSelector((state: AppState) => {
    return state.itemHistory;
  });
  const onClose = useCallback(
    () => { dispatch(closeHistoryView()); },
    [dispatch]);

  const open = itemHistory.name.length > 0;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg" >
      <MuiDialogTitle >
        <Grid justify="space-between" container spacing={6}>
          <Grid item>
            {`History for ${itemHistory.name}`}
          </Grid>
          <Grid item>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent>
        <HistoryView historyData={itemHistory} />
      </DialogContent>
    </Dialog>
  );
}

export default HistoryDialog;
