import { PolymerData } from "./types";
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const initialState: PolymerData = {
    PolymerData: null,
    sruCheckerFixerData: null,
    sourceCheckerFixerData: null,
    ParentPolymerDetails: null,
    polymerFlag:null,
    editPolymer: null,
    ParentVariantDetails: null,
    ParentPolymerDetailsCOPY:null,
    confirm:null
  };

const emptyParentPolymerDetails = {
  "name": null,
  "substanceId": 0,
  "ispolymer": false,
  "isvariant": false,
  "structureId": 0,
  "backBoneNames": null,
  "sruSmiles": null,
  "sourceSmiles": null,
  "sruMolcontent": null,
  "sourceMolcontent": null,
  "molecularFormula": null,
  "prfSmiles": null,
  "substituentSmiles": null,
  "sourceFlag": false,
  "sruFlag": false,
  "type": null,
  "variantDetails": null,
  "structureStatus": null,
  "substanceStatus": null,
  "isRestricted": false,
  "ParentVariantDetails": null
}
/*export const polymerReducer = createReducer(initialState)

  .handleAction(actions.getPolymerstructureAction.request, (state, action) => ({ ...state, PolymerData: null}))
  .handleAction(actions.getPolymerstructureAction.success, (state, action) => ({ ...state, PolymerData:action.payload }))
  .handleAction(actions.getPolymerstructureAction.failure, (state, action) => ({ ...state, PolymerData: null }))

  .handleAction(actions.SRUCheckerFixerAction.request, (state, action) => ({ ...state, sruCheckerFixerData: null }))
  .handleAction(actions.SRUCheckerFixerAction.success, (state, action) => ({ ...state, sruCheckerFixerData: action.payload }))
  .handleAction(actions.SRUCheckerFixerAction.failure, (state, action) => ({ ...state, sruCheckerFixerData: null }))

  .handleAction(actions.sourceCheckerFixerAction.request, (state, action) => ({ ...state, sourceCheckerFixerData: null }))
  .handleAction(actions.sourceCheckerFixerAction.success, (state, action) => ({ ...state, sourceCheckerFixerData: action.payload }))
  .handleAction(actions.sourceCheckerFixerAction.failure, (state, action) => ({ ...state, sourceCheckerFixerData: null }))

  .handleAction(actions.getParentPolymerAction.request, (state, action) => ({ ...state, ParentPolymerDetails: emptyParentPolymerDetails }))
  .handleAction(actions.getParentPolymerAction.success, (state, action) => ({ ...state, ParentPolymerDetails: action.payload }))
  .handleAction(actions.getParentPolymerAction.failure, (state, action) => ({ ...state, ParentPolymerDetails: emptyParentPolymerDetails }))*/

export const polymerReducer = createReducer(initialState)

  .handleAction(actions.getPolymerstructureAction.request, (state, action) => ({ ...state, PolymerData: null }))
  .handleAction(actions.getPolymerstructureAction.success, (state, action) => ({ ...state, PolymerData: action.payload }))
  .handleAction(actions.getPolymerstructureAction.failure, (state, action) => ({ ...state, PolymerData: null }))

  .handleAction(actions.SRUCheckerFixerAction.request, (state, action) => ({ ...state, sruCheckerFixerData: null }))
  .handleAction(actions.SRUCheckerFixerAction.success, (state, action) => ({ ...state, sruCheckerFixerData: action.payload }))
  .handleAction(actions.SRUCheckerFixerAction.failure, (state, action) => ({ ...state, sruCheckerFixerData: null }))

  .handleAction(actions.sourceCheckerFixerAction.request, (state, action) => ({ ...state, sourceCheckerFixerData: null }))
  .handleAction(actions.sourceCheckerFixerAction.success, (state, action) => ({ ...state, sourceCheckerFixerData: action.payload }))
  .handleAction(actions.sourceCheckerFixerAction.failure, (state, action) => ({ ...state, sourceCheckerFixerData: null }))

  .handleAction(actions.getParentVariantDetailsAction.request, (state, action) => ({ ...state, ParentVariantDetails: null }))
  .handleAction(actions.getParentVariantDetailsAction.success, (state, action) => ({ ...state, ParentVariantDetails: action.payload }))
  .handleAction(actions.getParentVariantDetailsAction.failure, (state, action) => ({ ...state, ParentVariantDetails: null }))

  .handleAction(actions.getParentPolymerAction.request, (state, action) => ({ ...state, ParentPolymerDetails: null }))
  .handleAction(actions.getParentPolymerAction.success, (state, action) => ({ ...state, ParentPolymerDetails: action.payload }))
  .handleAction(actions.getParentPolymerAction.failure, (state, action) => ({
    ...state, ParentPolymerDetails: {
      "name": null,
      "substanceId": 0,
      "ispolymer": false,
      "isvariant": false,
      "structureId": 0,
      "backBoneNames": null,
      "sruSmiles": null,
      "sourceSmiles": null,
      "sruMolcontent": null,
      "sourceMolcontent": null,
      "molecularFormula": null,
      "prfSmiles": null,
      "substituentSmiles": null,
      "sourceFlag": false,
      "sruFlag": false,
      "type": null,
      "variantDetails": null,
      "structureStatus": null,
      "substanceStatus": null,
      "isRestricted": false
    }
  }))

  .handleAction(actions.getPolymerFlagAction.request, (state, action) => ({ ...state, polymerFlag: null }))
  .handleAction(actions.getPolymerFlagAction.success, (state, action) => ({ ...state, polymerFlag: action.payload }))
  .handleAction(actions.getPolymerFlagAction.failure, (state, action) => ({ ...state, polymerFlag: null }))
  .handleAction(actions.closeEditPolymerAction, (state, action) => ({ ...state, editPolymer: null }))
  .handleAction(actions.showEditPolymerAction, (state, action) => ({ ...state, editPolymer: action.payload }))

  .handleAction(actions.closeConfirmBoxAction, (state, action) => ({ ...state, confirm: null }))
  .handleAction(actions.showConfirmBoxAction, (state, action) => ({ ...state, confirm: action.payload }))

  
  .handleAction(actions.AddEditPolymerStructureAction.request, (state, action) => ({ ...state }))
  .handleAction(actions.AddEditPolymerStructureAction.success, (state, action) => ({ ...state }))
  .handleAction(actions.AddEditPolymerStructureAction.failure, (state, action) => ({ ...state }))

  .handleAction(actions.getParentPolymerActionCOPY.request, (state, action) => ({ ...state, ParentPolymerDetailsCOPY: emptyParentPolymerDetails }))
  .handleAction(actions.getParentPolymerActionCOPY.success, (state, action) => ({ ...state, ParentPolymerDetailsCOPY: action.payload }))
  .handleAction(actions.getParentPolymerActionCOPY.failure, (state, action) => ({ ...state, ParentPolymerDetailsCOPY: emptyParentPolymerDetails }))

  ;
