import { VariantState, Variant, addVariantObj, variantStrcutureInfo, editVariantObj, variantRestriction } from "./types";
import { Dispatch } from "redux";
import { AppState } from "..";
import axios from 'axios';
import { makeError, makeSuccessMessage } from "../error/actions";
import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import { showDetail } from "../itemDetail/actions";
import { locateItem } from '../hierarchy/actions';
import { getParentPolymer } from '../polymer/actions';
import { NodeResult } from '../hierarchy/types';

export const addVariantDialogAction = createStandardAction("ADD_VARIANT")<any>();

export const addVariantDialog = (a: any) => {  
  return addVariantDialogAction(a);
};

export const editVariantDialogAction = createStandardAction("EDIT_VARIANT")<any>();

export const editVariantDialog = (a: any) => {  
  return editVariantDialogAction(a);
};

export const viewVariantDialogAction = createStandardAction("VIEW_VARIANT")<any>();

export const viewVariantDialog = (a: any) => {
  return viewVariantDialogAction(a);
};

export const closeVariantDialogAction = createStandardAction("CLOSE_VARIANT")<any>();

export const closeVariantDialog = () => {
  return closeVariantDialogAction("");
};

export const closeViewVariantDialogAction = createStandardAction("CLOSE_VIEW_VARIANT")<any>();
export const closeAddVariantDialogAction = createStandardAction("CLOSE_ADD_VARIANT")<any>();

export const closeAddVariantDialog = () => {
  return closeAddVariantDialogAction("");
};
export const closeViewVariantDialog = () => {
  return closeViewVariantDialogAction("");
};


export const variantRequest = createAsyncAction(
    "VARIANT_REQUEST_START",
    "VARIANT_REQUEST_SUCCESS",
    "VARIANT_REQUEST_FAILURE")<string, string, string>();
export const getVariantRequest = createAsyncAction(
    "VARIANT_LIST_REQUEST_START",
    "VARIANT_LIST_REQUEST_SUCCESS",
    "VARIANT_LIST_REQUEST_FAILURE")<string, VariantState, string>();

export const getVariantList = (parentBackBoneName: string,parentSubstanceId:number) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(getVariantRequest.request(parentBackBoneName));
      axios.post(`/api/v1/entities/GetStructureVariantInfoByParentSubstance`, { parentBackBoneName: parentBackBoneName, parentSubstanceId :parentSubstanceId})
        .then((response) => {
          dispatch(getVariantRequest.success({ variants: response.data.reverse() ,isAdd:true}));
        }).catch((error) => {
            console.log('error in getting variants ==',error);
          dispatch(makeError(error));
      dispatch(getVariantRequest.failure(parentBackBoneName));

         });
    }
}


export const addVariantRequest = createAsyncAction(
  "ADDVARIANT_REQUEST_START",
  "ADDVARIANT_REQUEST_SUCCESS",
  "ADDVARIANT_REQUEST_FAILURE")<string, string, string>();

export const addVariant = (obj: addVariantObj, restrictObj: variantRestriction) => {
     return (dispatch: Dispatch, getState: () => AppState) => {
       dispatch(addVariantRequest.request("started"));
       axios.put('/api/v1/Entities/AddVariant', obj)
         .then((response) => {
           console.log('response ==', response)
           let restrictionData: any = {
             variantSubstanceName: obj.name,
             domains: restrictObj.domains ? restrictObj.domains : null,
             mailIds: restrictObj.mailIds ? restrictObj.mailIds : null
           }
           console.log('restrictionData ==', restrictionData)
           if (restrictionData.domains || restrictionData.mailIds) {
             axios.put('/api/v1/Entities/AddVariantRestrictions', restrictionData).then((response) => {
              console.log('add variant restriction ==',response)
             }).catch((error) => {
               console.log('err in variant add rest==',error)
               dispatch(makeError(error));
             });
           }
          dispatch(makeSuccessMessage('Variant Added Successfully !'));
          dispatch(closeAddVariantDialog());          
          showDetail({ name: obj.name, type: 'Structure Variant', subType: obj.subType, status: obj.status })(dispatch, getState);
          dispatch<any>(locateItem({ name: obj.name, type: obj.parentType, subType: obj.subType, status: obj.status, substanceId: obj.name, auditDescription: obj.auditDescription }, true, obj.name));
        })
        .catch((error: any) => {
            console.log('err saving variant ==',error);
          dispatch(makeError(error));
         });
    }
}


export const classificationNodeRequest = createAsyncAction(
  "CLASSIFICATION_NODE_REQUEST_START",
  "CLASSIFICATION_NODE_REQUEST_SUCCESS",
  "CLASSIFICATION_NODE_REQUEST_FAILURE")<string, NodeResult, string>();

export const editVariantInfo = (obj: editVariantObj, restrictObj: variantRestriction) => {
  
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(addVariantRequest.request("started"));
    axios.put('/api/v1/Entities/EditVariant', obj)
      .then((response) => {
        let restrictionData: any = {
          variantSubstanceName: obj.name,
          domains: restrictObj.domains ? restrictObj.domains : null,
          mailIds: restrictObj.mailIds ? restrictObj.mailIds : null
        }
        console.log('restrictionData edit here ==', restrictionData)
        if (restrictionData.domains || restrictionData.mailIds) {
          axios.put('/api/v1/Entities/EditVariantRestrictions', restrictionData).then((response) => {
            console.log('edit variant restriction ==', response)
            dispatch(makeSuccessMessage('Variant Updated Successfully !'));
          }).catch((error) => {
            console.log('err in variant add rest==', error)
            dispatch(makeError(error));
          });
        }
        dispatch(closeVariantDialog());

        // Calling the left panel ..
                        
        //dispatch<any>(locateItem({ name: obj.parentName, type: obj.parentType, subType: obj.subType, status: "A", substanceId: obj.name, auditDescription: obj.auditDescription }, true, obj.parentName));
        dispatch<any>(locateItem({ name: obj.name, type: obj.parentType, subType: obj.subType, status: "A", substanceId: obj.name, auditDescription: obj.auditDescription }, true, obj.currentName));        
        showDetail({ name: obj.name, type: 'Structure Variant', subType: obj.subType, status: obj.status })(dispatch, getState);
        
      })
      .catch((error: any) => {
        console.log('err saving variant ==', error);
        dispatch(makeError(error));
      });    
  }
}

//BELOW API NOT IN USE
export const editVariant = (parentBackBoneName: string, obj: Variant) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(variantRequest.request(parentBackBoneName));
      axios.put('/api/v1/entities/EditStructureVariant',  [obj] )
        .then((response) => {
          dispatch(makeSuccessMessage(response.data));
          axios.post(`/api/v1/entities/GetStructureVariantInfoByParentSubstance`, { parentBackBoneName: parentBackBoneName, parentSubstanceId: 0 })
            .then((response) => {
              dispatch(getVariantRequest.success({ variants: response.data.reverse(), isAdd: true }));
            }).catch((error) => {
              console.log('error in getting variants ==', error);
              dispatch(makeError(error));
              dispatch(getVariantRequest.failure(parentBackBoneName));
            });
        })
        .catch((error: any) => {
            console.log('err saving varaient ==',error);
          dispatch(makeError(error));
         });
    }
}

export const getTopologyRequest = createAsyncAction(
  "TOPOLOGY_REQUEST_START",
  "TOPOLOGY_REQUEST_SUCCESS",
  "TOPOLOGY_REQUEST_FAILURE")<string, [string], string>();

export const getTopology = () => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getTopologyRequest.request("started"));
    axios.get(`/api/v1/LookUp/Topology`)
      .then((response) => {
       // console.log('topology res =', response)
        dispatch(getTopologyRequest.success(response.data));
      }).catch((error) => {
        console.log('error in getting topology ==', error);
        dispatch(makeError(error));
        dispatch(getTopologyRequest.failure(error.message));
      });
  }
}

export const getChemicalMicrostructuresRequest = createAsyncAction(
  "CHEMICALMICROSTRUCTURE_REQUEST_START",
  "CHEMICALMICROSTRUCTURE_REQUEST_SUCCESS",
  "CHEMICALMICROSTRUCTURE_REQUEST_FAILURE") < string,[string] ,string>();

export const getChemicalMicrostructures = () => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getChemicalMicrostructuresRequest.request("started"));
    axios.get(`/api/v1/LookUp/Chemical microstructure`)
      .then((response) => {
       // console.log('chemical micro res =', transformLookupKeyResult(response.data))
        dispatch(getChemicalMicrostructuresRequest.success(response.data));
      }).catch((error) => {
        console.log('error in getting chemical micro ==', error);
        dispatch(makeError(error));
        dispatch(getChemicalMicrostructuresRequest.failure(error.message));
      });
  }
}

export const getVariantFlagAction = createAsyncAction("GET_VARIANTFLAG_START", "GET_VARIANTFLAG_SUCCESS", "GET_VARIANTFLAG_FAIL")<string, any, string>();

export const getVariantFlag = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getVariantFlagAction.request("started"));
    axios.get(`/api/v1/Entities/GetVariantFlag?name=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(getVariantFlagAction.success(response.data));
        if (response.data == true) {
          console.log('getting variant details since its a variant')
          dispatch<any>(getStructureVariantInfo(name));
        }
      }).catch((error) => {
        dispatch(getVariantFlagAction.failure(error));
      });
  }
}


export const getStructureVariantAction = createAsyncAction("GET_STRUCTUREVARIANT_START", "GET_STRUCTUREVARIANT_SUCCESS", "GET_STRUCTUREVARIANT_FAIL")<string, variantStrcutureInfo, string>();

export const getStructureVariantInfo = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(getStructureVariantAction.request("started"));
    axios.get(`api/v1/Entities/GetStructureVariantInfo?Name=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(getStructureVariantAction.success(response.data));
        if (response.data && response.data['parentName']) {
        dispatch<any>(getParentPolymer(response.data['parentName']))
        }
      }).catch((error) => {
        dispatch(getStructureVariantAction.failure(error));
      });

  }
}


export const addStructureAction = createAsyncAction("ADD_STRUCTURE_RESTRICTIONS_REQUEST","ADD_STRUCTURE_RESTRICTIONS_SUCCESS","ADD_STRUCTURE_RESTRICTIONS_FAILURE")<string, string, string>();
// Structure Restrictions
export const addStructureRestrictionsData = (object: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
      const restDomains = object.domains;
      const restEmail = object.mailIds;
      let restrictionData: any = {
          structureName: object.structureName,
          domains: restDomains ? restDomains : null,
          mailIds: restEmail ? restEmail : null
      }

      if (restrictionData.domains || restrictionData.mailIds) {
        axios.put('/api/v1/Structure/Restrictions', JSON.stringify(restrictionData), {
          headers: {
            'content-type': 'application/json;'
          }
        }).then((response) => {
            dispatch(makeSuccessMessage('Structure Restrictions Added Successfully.'));
            dispatch(addStructureAction.success(response.data));
            showDetail({ name: object.structureName, type: 'Substance', subType: '', status: '' })(dispatch, getState);
          }).catch((error) => {
            dispatch(addStructureAction.failure(error));
            //dispatch(makeError(error));
        });
      }    
  };
}

export const editStructureAction = createAsyncAction("EDIT_STRUCTURE_RESTRICTIONS_REQUEST", "EDIT_STRUCTURE_RESTRICTIONS_SUCCESS", "EDIT_STRUCTURE_RESTRICTIONS_FAILURE")<string, string, string>();
// Structure Restrictions
export const editStructureRestrictionsData = (object: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const restDomains = object.domains;
    const restEmail = object.mailIds;
    let restrictionData: any = {
      structureName: object.structureName,
      domains: restDomains ? restDomains : null,
      mailIds: restEmail ? restEmail : null
    }

    if (restrictionData.domains || restrictionData.mailIds) {
      axios.put('/api/v1/Structure/EditStructureRestriction', JSON.stringify(restrictionData), {
        headers: {
          'content-type': 'application/json;'
        }
      }).then((response) => {
        dispatch(makeSuccessMessage('Structure Restrictions Edited Successfully.'));
        dispatch(editStructureAction.success(response.data));
        showDetail({ name: object.structureName, type: 'Substance', subType: '', status: '' })(dispatch, getState);
      }).catch((error) => {
        dispatch(editStructureAction.failure(error));
        //dispatch(makeError(error));
      });
    }
  };
}

export const getVariantRestrictionAction = createAsyncAction("GET_VARIANTRESTRICTION_START", "GET_VARIANTRESTRICTION_SUCCESS", "GET_VARIANTRESTRICTION_FAIL")<string, any, string>();

export const getVariantRestrictions = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    axios.get(`/api/v1/Entities/GetVariantRestrictions?variantName=${encodeURIComponent(name)}`)
      .then((response) => {
        console.log('response HERE ==', response)
        dispatch(getVariantRestrictionAction.success(response.data));
      }).catch((error) => {
        dispatch(makeError(error));
      });

  }
}
