import React, { useEffect } from 'react';
import { TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFlaggedInfo } from '../store/flag/actions';
import { formatDateAndTime } from '../domain/Format';

//const useStyles = makeStyles({
//});
const FlagNotifications = () => {
  //const classes = useStyles();
  const dispatch = useDispatch();
  //const putCommentApi = useCallback(
  //  (name: string, commentType: string, description: string) => {
  //    const Name = name;
  //    const CommentType = commentType;
  //    const Description = description;
  //    dispatch(getAllFlaggedInfo())
  //  },
  //  [dispatch]
  //);
  //-----pagination changes-------//
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    dispatch(getAllFlaggedInfo())
  },
    [dispatch]);
  const flagList = useSelector((state: any) => state.flagReducer);
  const tableData = flagList.getallDetails;
  return (
    <Paper>
      {tableData.length > 0 ?
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>When</TableCell>
                  <TableCell>Flagged By</TableCell>
                  <TableCell>Substance/Composite Name</TableCell>
                  <TableCell>Flag Status</TableCell>
                  <TableCell>Flag Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, i: any) => (
                  <TableRow key={`item${i}`} >
                    <TableCell>{formatDateAndTime(row.flagDate)}</TableCell>
                    <TableCell>{row.flagName}</TableCell>
                    <TableCell>{row.sirIdentity}</TableCell>
                    <TableCell>{row.flagStatus}</TableCell>
                    <TableCell>{row.flagDescription}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div> : null}
      </Paper>
  )
}
export default FlagNotifications;
