import { createReducer } from "typesafe-actions";
import * as actions from './actions';


const initialState: any = {
      backboneIdentity: "POLYSILICONE",
      sirIdentity: "DEV-000-036-398",
      comments: [],
};
const combine = (state: any, action: any) => {
  return { ...state, backboneIdentity: action.payload.backboneIdentity, comments: action.payload.comments, sirIdentity: action.payload.sirIdentity }
}
export const commentReducer = createReducer(initialState)
  .handleAction(actions.commentRequest.request, (state, action) => combine(state, action))
  .handleAction(actions.commentRequest.success, (state, action) => combine(state, action))
  .handleAction(actions.commentRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.getCommentRequest.request, (state, action) => combine(state, action))
  .handleAction(actions.getCommentRequest.success, (state, action) => combine(state, action))
  .handleAction(actions.getCommentRequest.failure, (state, action) => ({ ...state }))

