export interface CharacterTranslation {
  inputSequence: string;
  translation: string;
};

export const translateText = (input: string, translations: CharacterTranslation[]) => {
  return translations.reduce(
    (a, i, n, x) => a.replace(i.inputSequence, i.translation), input
  );
}

export const defaultTranslations: CharacterTranslation[] = [
  {inputSequence: "\\O", translation: "\u03A9"},
  {inputSequence: "\\a", translation: "\u03B1"},
  {inputSequence: "\\b", translation: "\u03B2"},
  {inputSequence: "\\c", translation: "\u03B3"},
  {inputSequence: "\\z", translation: "\u03C9"},
];
