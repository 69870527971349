import React from 'react';
import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'formik';
import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import { showAddItem, showAddItemName } from '../store/addItem/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { ItemDetailState } from '../store/itemDetail/types';
import { NodeData } from '../store/hierarchy/types';

const useStyles = makeStyles({
  group: {
    flexDirection: "row"
  },
  label: {
    padding: "10px"
  }
});
const filterSelectedNode = (detailData: ItemDetailState): NodeData => {
  if (detailData.selectedNode &&
    detailData.selectedNode.name !== 'Classification' && detailData.selectedNode.name !== 'Orphans') {
    return detailData.selectedNode;
  }

  return {} as NodeData
}

const ItemTypeSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const detailData = useSelector((state: AppState) => state.itemDetail);

  const selectedClassification = (e: any) => {
    if (e.target.value) {
      const node = filterSelectedNode(detailData);
      localStorage.setItem('radioItemSelected', e.target.value);
      let v = (detailData.itemDetail['backboneIdentity'] == 'No item selected') ? "" : detailData.itemDetail['backboneIdentity'];
      let t = detailData.type;
      if (e.target.value === 'Substance name' || e.target.value === 'Classification') {
        localStorage.removeItem('nameComposite');
        dispatch(showAddItemName({ parentEntity: { name: v, type: t, subType: node.subType, status: node.status }, targetType: e.target.value }));
      } else if (e.target.value === "Sub component") {
        localStorage.removeItem('nameComposite');
        dispatch(showAddItem({ parentEntity: { name: node.name, type: "Sub component", subType: node.subType, status: node.status }, targetType: e.target.value }));

      } else {
        localStorage.removeItem('nameComposite');
        dispatch(showAddItem({ parentEntity: { name: v, type: t, subType: node.subType, status: node.status }, targetType: e.target.value }));
      }
    }   
  }
  return (
    <React.Fragment>
      <Field name="newEntity.type" label="New Item Type" component={RadioGroup} className={classes.group}>
        <Typography className={classes.label} variant="body1">Create a new: </Typography>
        <FormControlLabel
          value="Classification"
          control={<Radio />}
          label="Classification"
          onClick={selectedClassification}
        />
        <FormControlLabel
          value="Classification name"
          control={<Radio />}
          label="Classification name"
          onClick={selectedClassification}
        />
        <FormControlLabel
          value="Substance"
          control={<Radio />}
          label="Substance"
          onClick={selectedClassification}
        />
        <FormControlLabel
          value="Substance name"
          control={<Radio />}
          label="Substance name"
          onClick={selectedClassification}
        />
        <FormControlLabel 
          value="Composite substance"
          control={<Radio />}
          label="Composite substance"
          onClick={selectedClassification}
        />
        <FormControlLabel 
          value="Sub component"
          control={<Radio />}
          label="Sub component"
          onClick={selectedClassification}
        />
    </Field>
  </React.Fragment>
  )
}

export default ItemTypeSelector;
