import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const initialState: any = {
  data: '',
  loading: false,
  isLoading: true,
  imageData: '',
  hasMore: true,
  jChemData: [],
  inProgress: false,
};


const combine = (state: any, action: any) => {
  return { ...state, data: action.payload, jChemData: [...state.jChemData, ...action.payload.jChemData], hasMore: action.payload.hasMore, resultCount: action.payload.resultCount, loading: false }
}
const combineInitial = (state: any, action: any) => {
  return { ...state, data: action.payload, jChemData: action.payload.jChemData, hasMore: action.payload.hasMore, resultCount: action.payload.resultCount, loading: false }
}
export const structureSearchReducer = createReducer(initialState)
  .handleAction(actions.requestSearchStructure.request, (state, action) => ({ ...state,data: undefined, loading: true }))
  .handleAction(actions.requestSearchStructure.success, (state, action) => combine(state, action))
  .handleAction(actions.requestSearchStructure.failure, (state, action) => ({ ...state, loading: false }))
  .handleAction(actions.requestSearchStructureWithInitial.request, (state, action) => ({ ...state, data: undefined, jChemData: undefined, hasMore: true, loading: true }))
  .handleAction(actions.requestSearchStructureWithInitial.success, (state, action) => combineInitial(state, action))
  .handleAction(actions.requestSearchStructureWithInitial.failure, (state, action) => ({ ...state, loading: false, jChemData: undefined }))
  .handleAction(actions.loaderRequest.success, (state, action) => ({ ...state, isLoading: action.payload }))
  .handleAction(actions.getImageData.request, (state, action) => ({ ...state, imageData: undefined, loading: false }))
  .handleAction(actions.getImageData.success, (state, action) => ({ ...state, imageData: action.payload, loading: false }))
  .handleAction(actions.downloadSearchAction.request, (state, action) => ({ ...state, inProgress: true}))
  .handleAction(actions.downloadSearchAction.success, (state, action) => ({ ...state, inProgress: false }))
  .handleAction(actions.downloadSearchAction.failure, (state, action) => ({ ...state, inProgress: false }))

