import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  paper: {
    padding: 2,
    textAlign: 'center' as 'center',
    width: "max-content",
    margin: 8
  },
  table: {
    minWidth: "200px"
  },
  tableCell: {
    padding: "0px",
    borderBottom: "none"
  },
  label: {
    textAlign: "left"
  }
});

interface DomainEditEvents {
  onChange: (item: DomainItemEditState) => void;
}

export interface DomainItemEditState {
  name: string;
  selected: boolean;
}

interface DomainEditState {
  items: DomainItemEditState[];
  title: string;
}

type DomainItemEditProps = DomainItemEditState & DomainEditEvents
type DomainEditProps = DomainEditState & DomainEditEvents


const DomainItemEdit = (props: DomainItemEditProps) => {
  const { name, selected } = props;
  const { onChange } = props;
  const classes = useStyles();

  return (
    <TableRow key="name">
      <TableCell className={classes.tableCell} align="right"><Typography variant="caption" align="right">{name}</Typography></TableCell>
      <TableCell className={classes.tableCell} ><Checkbox checked={selected} onChange={(e, v) => onChange({ name, selected: v })} /></TableCell>
    </TableRow>
  )
}

const DomainEdit = (props: DomainEditProps) => {
  const classes = useStyles();
  const { items, title } = props;
  const { onChange } = props;

  return (
    <Paper className={classes.paper} >
      <Typography variant="body1" className={classes.label}>{title}</Typography>
      <Table className={[classes.table, "table"].join(" ")}>
        <TableBody className="domainClass">
          {items.map(item =>
            <DomainItemEdit key={item.name} name={item.name} selected={item.selected} onChange={onChange} />)
          }
        </TableBody>
      </Table>
    </Paper>
  );
}

export default DomainEdit;
