import { Input } from "@material-ui/icons";
import { format } from "date-fns";

export function formatDate(inputDate) {
  return inputDate && format(new Date(inputDate), "dd-MMM-yyyy");
}

export function formatDateAndTime(inputDate) {
  return inputDate && new Date(inputDate).toLocaleString("en-GB", {
    day: "2-digit",
    year: "numeric",
    month: "short",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
}

export function numberToString(inputNumber) {
  return inputNumber == null ? "" : inputNumber.toString();
}

export function round(inputNumber) {
  return typeof inputNumber == "number"
    ? inputNumber.toPrecision(4)
    : inputNumber == null
    ? ""
    : "Invalid input";
}
