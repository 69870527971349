import { createReducer } from "typesafe-actions";
import * as actions from './actions';

const initialState: any = {
  getallDetails:[],
  flagDetails: [],
  open: "",
  enable: "",
  disable: ""
};
const combine = (state: any, action: any) => {
  return { ...state, flagDetails: action.payload, }
}
export const flagReducer = createReducer(initialState)
  .handleAction(actions.getAllFlagRequest.request, (state, action) => combine(state, action))
  .handleAction(actions.getAllFlagRequest.success, (state, action) => ({ ...state, getallDetails: action.payload,}))
  .handleAction(actions.getAllFlagRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.nameFlagRequest.request, (state, action) => combine(state, action))
  .handleAction(actions.nameFlagRequest.success, (state, action) => combine(state, action))
  .handleAction(actions.nameFlagRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.enableFlagRequest.request, (state, action) => combine(state, action))
  .handleAction(actions.enableFlagRequest.success, (state, action) => ({ ...state, flagDetails: action.payload, open:"" }))
  .handleAction(actions.enableFlagRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.disableFlagRequest.request, (state, action) => combine(state, action))
  .handleAction(actions.disableFlagRequest.success, (state, action) => ({ ...state, flagDetails: action.payload, open: "" }))
  .handleAction(actions.disableFlagRequest.failure, (state, action) => ({ ...state }))
  .handleAction(actions.closeFlagRequest, (state, action) => ({ ...state, open: "" }))
  .handleAction(actions.openFlagRequest, (state, action) => ({ ...state, open: action.payload }))
