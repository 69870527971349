import { Dispatch } from 'redux';
import axios from 'axios';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NodeResult, ClassificationResponseNode } from './types';
import { AppState } from '../index';
import { transformHierarchyResult, buildTreeNodeData } from './utilities';
import { Suggestion } from '../search/types';
import { findNode, filterOutNames } from '../../domain/tree';
import { showDetail, substanceDetailNodeRequest, compositeTabContentRequest } from '../itemDetail/actions';
import { makeError, makeSuccessMessage } from '../error/actions';
import { getStructureRestrictionsList } from '../editItem/actions';

export const classificationNodeRequest = createAsyncAction(
  "CLASSIFICATION_NODE_REQUEST_START",
  "CLASSIFICATION_NODE_REQUEST_SUCCESS",
  "CLASSIFICATION_NODE_REQUEST_FAILURE")<string, NodeResult, string>();

export const mergeItemHierarchyRequest = createAsyncAction(
  "MERGE_ITEM_HIERARCHY_REQUEST_START",
  "MERGE_ITEM_HIERARCHY_REQUEST_SUCCESS",
  "MERGE_ITEM_HIERARCHY_REQUEST_FAILURE")<string, any, string>();

export const selectHierarchyBranches = createStandardAction("SELECT_HIERARCHY_BRANCHES")<string>();
export const removeItemAction = createStandardAction("REMOVE_ITEM")<string>();


// Get children of a node for population into tree
export const queryNode = (nodeName: string, nodeType: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    if (nodeName === 'Orphans') {
      queryOrphansNode(dispatch);
    } else if (nodeName === 'Classification') {
      queryRegularNode("", nodeType, dispatch);
    } else {
      queryRegularNode(nodeName, nodeType, dispatch);
    }
  }
}

const queryRegularNode = (nodeName: string, nodeType: string, dispatch: Dispatch) => {
  const queryName = nodeName || "";
  const queryType = nodeType || "Classification";
  dispatch(classificationNodeRequest.request(nodeName));
  axios.get(`/api/v1/entities/parentageandchildren?nodeName=${encodeURIComponent(queryName)}&nodeType=${queryType}`)
    .then((response) => {
      if (nodeType == undefined) {
        let topClassifications = ['CHEMICALS', 'COLOURS', 'CROSS CATEGORY', 'F&R', 'HBPC', 'HYDROCARBON BLENDS', 'NATURAL INORGANICS', 'NATURAL PRODUCTS', 'OIL AND FAT', 'PERFUMES', 'POLYMERS', 'SURFACTANT'];
        let res = response.data.filter((item: { [x: string]: string; }) => { return topClassifications.indexOf(item['entityName']) > -1 });
         dispatch(classificationNodeRequest.success({ parentName: nodeName, nodeType: queryType, data: filterOutNames(transformHierarchyResult(res)) }));
      } else {
        dispatch(classificationNodeRequest.success({ parentName: nodeName, nodeType: queryType, data: filterOutNames(transformHierarchyResult(response.data)) }));
      }

      if (nodeName && nodeName !== 'Orphans') {
        dispatch(selectHierarchyBranches(queryName));
      }
    })
    .catch((error) => {
      dispatch(makeError(error));
      dispatch(classificationNodeRequest.failure(error.message));
    });
}

const queryOrphansNode = (dispatch: Dispatch) => {
  dispatch(classificationNodeRequest.request("Orphans"));

  axios.get('/api/v1/entities/orphans')
    .then((response) => {
      dispatch(classificationNodeRequest.success({ parentName: 'Orphans', nodeType: 'Classification', data: transformHierarchyResult(response.data) }));
    })
    .catch((error) => {
      dispatch(makeError(error));
      dispatch(classificationNodeRequest.failure(error.message));
    });
}

// If item is already in tree, set it as the selected item.
// Otherwise, query for tree to the selected item and merge into tree state. Also trigger a showDetail action.
export const locateItem = (item: Suggestion, refresh: boolean = false, remove: string = "") => {
  if (item.type === "Composite" || item.type === "Composite name") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      showDetail(item)(dispatch, getState);
    }
  } else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const queryName = item.substanceId ? item.substanceId : item.name;
      //const queryName = item.name;
      const queryType = (item.substanceId && (item.type !== "Composite" && item.type !== "Classification" && item.type !== "Classification name")) ? "Substance" : (item.type === "Classification name") ? "Classification" : item.type;
      const treeState = getState().hierarchy.tree;
      if (refresh) {
        // TODO: is this the right place?
        dispatch(removeItemAction(remove || queryName));
      } else {
        const node = findNode(treeState, (i) => item.name === i.name && item.type === i.type);
        if (node) {
          dispatch(selectHierarchyBranches(queryName));
          showDetail(node)(dispatch, getState);
          return;
        }
      }

      dispatch(mergeItemHierarchyRequest.request(item.name));
      dispatch(substanceDetailNodeRequest.request(item.name));
      dispatch(compositeTabContentRequest.request(item.name));
      axios.get(`/api/v1/entities/parentageandchildren?nodeName=${encodeURIComponent(queryName)}&nodeType=${queryType}`)
        .then((response) => {
          const nodeTree = buildTreeNodeData(response.data);
          const responseItems = response.data as ClassificationResponseNode[];
          const itemMatch = responseItems.find(i => i.entityName.localeCompare(queryName) === 0);

          const highlightItem = (itemMatch && itemMatch.entityType === "Substance name") ?
            { name: itemMatch.parentName || itemMatch.entityName, type: itemMatch.parentType || itemMatch.entityType } : { name: queryName, type: queryType };
          dispatch(mergeItemHierarchyRequest.success({ itemName: highlightItem.name, data: nodeTree }));
          dispatch(selectHierarchyBranches(highlightItem.name));
          const node = findNode(nodeTree, (i) => i.name === highlightItem.name && i.type === highlightItem.type);
          if (node) {
            showDetail(node)(dispatch, getState);
            localStorage.removeItem('nonode');
          }
          else {
            dispatch(substanceDetailNodeRequest.success({ substanceName: queryName, data: response.data, type: "queryType" }));
            dispatch(compositeTabContentRequest.success({ data: response.data[0] }));

          }
          localStorage.removeItem('name');
        })
        .catch((error) => {
          dispatch(makeError(error));
          dispatch(mergeItemHierarchyRequest.failure(error.message));
        });
    }
  }
}
export const savedStructureAction = createStandardAction("SAVE_STRUCTURE_SUCCESS")<any>();

export const saveStructureData = () => {
  //debugger;
  return (dispatch: Dispatch, getState: () => AppState) => {
    //const structureSource = localStorage.getItem('marvinSmiles');
    const structureSource = localStorage.getItem('molcontent');
    //debugger;
    axios.post('/api/v1/Structure/CheckerFixer', null, { params: { structureSource } })
      .then((response) => {
        //debugger;
        dispatch(savedStructureAction(response));
      })
      .catch((error: any) => {
        //  localStorage.removeItem('marvinSmiles');
        //  dispatch(makeError("Invalid structure. Please review the sketch"));
      });
  };
}
export const getStructureRequest = createStandardAction("GET_STRUCTURE_SUCCESS")<any>();

//get all structure
export const getStructureList = (name: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    //  dispatch(commentRequest.request(name));
    axios.get(`/api/v1/Structure/GetStructure?substanceName=${encodeURIComponent(name)}`)
      .then((response) => {
        dispatch(getStructureRequest(response));
      }).catch((error) => {
        localStorage.removeItem('marvinSmiles');
        dispatch(getStructureRequest(undefined));
        //  dispatch(makeError(error));
        //dispatch(substanceDetailNodeRequest.failure(error.message));
      });
  }
}
export const editStructureAction = createStandardAction("EDIT_STRUCTURE_SUCCESS")<any>();
//edit structure
export const editStructureData = (object: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    axios.put('/api/v1/Structure/EditStructure', { name: object.name, molecule: object.molecule, status: object.status, auditDescription: object.auditDescription, verification: object.verification, molContent: object.molContent, molecularFormula: object.molecularFormula })
      .then((response) => {
        dispatch(editStructureAction(response));
        dispatch<any>(editStructureRestrictionData(object));
        axios.get(`/api/v1/Structure/GetStructure?substanceName=${encodeURIComponent(object.name)}`)
          .then((response) => {
            dispatch(getStructureRequest(response));
            dispatch<any>(showDetail({ name: object.name, type: 'Substance', subType: 'Substance', status: object.status }));
            //dispatch(showDetail({}))
          }).catch((error) => {
            localStorage.removeItem('marvinSmiles')
            dispatch(getStructureRequest(null));
          });
        if (response.data.isUniqueStructure) {
          dispatch(makeSuccessMessage("Structure data is updated successfully."));
        }
        else {
          if (response.data.isRestrictedStructure) {
            dispatch(makeSuccessMessage("Structure is updated successfully, please note that the same structure is already added"));
          }
          else {
            dispatch(makeSuccessMessage("Structure is updated successfully, please note that the same structure is already added to: " + response.data.duplicateStructureBackboneIdentity));
          }
        }
      })
      .catch((error: any) => {
        dispatch(makeError(error));
      });
  };
}
export const editStructureRestrictionData = (object: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const restDomains = localStorage.getItem('structurerestricteddomains');
    const restEmail = localStorage.getItem('structurerestrictedemails');
    const restrictionOnly = localStorage.getItem('restrictionOnly');
    let restrictionData: any = {
      structureName: object.name,
      domains: restDomains ? JSON.parse(restDomains) : null,
      mailIds: restEmail ? JSON.parse(restEmail) : null
    }
    if (restrictionData.domains || restrictionData.mailIds) {
      axios.put('/api/v1/Structure/EditStructureRestriction', restrictionData).then((response) => {
        localStorage.removeItem('structurerestricteddomains');
        localStorage.removeItem('structurerestrictedemails');
        dispatch(makeSuccessMessage("Structure Restriction is updated successfully."));
        //if (restrictionOnly === 'true') {
        dispatch<any>(showDetail({ name: object.name, type: 'Substance', subType: 'Substance', status: object.status }));
        localStorage.removeItem('restrictionOnly');
        //} else {

        //}
        dispatch<any>(getStructureRestrictionsList(object.name));
      }).catch((error) => {
        dispatch(makeError(error));
      });
    }
  };
}
export const addStructureAction = createStandardAction("ADD_STRUCTURE_SUCCESS")<any>();
//edit structure
export const addStructureData = (object: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    axios.put('/api/v1/Structure/AddStructure', { name: object.name, molecule: object.molecule, status: object.status, auditDescription: object.auditDescription, verification: object.verification, molContent: object.molContent, molecularFormula: object.molecularFormula })
      .then((response) => {
        dispatch(editStructureAction(response));
        const restDomains = localStorage.getItem('structurerestricteddomains');
        const restEmail = localStorage.getItem('structurerestrictedemails');
        let restrictionData: any = {
          structureName: object.name,
          domains: restDomains ? JSON.parse(restDomains) : null,
          mailIds: restEmail ? JSON.parse(restEmail) : null
        }
        if (restrictionData.domains || restrictionData.mailIds) {
          axios.put('/api/v1/Structure/Restrictions', restrictionData).then((response) => {
            localStorage.removeItem('structurerestricteddomains');
            localStorage.removeItem('structurerestrictedemails');
          }).catch((error) => {
            dispatch(makeError(error));
          });
        }
        if (response.data.isUniqueStructure) {
          dispatch(makeSuccessMessage("Structure data is added successfully."));
        }
        else {
          if (response.data.isRestrictedStructure) {
            dispatch(makeSuccessMessage("Structure is added successfully, please note that the same structure is already added"));
          }
          else {
            dispatch(makeSuccessMessage("Structure is added successfully, please note that the same structure is already added to: " + response.data.duplicateStructureBackboneIdentity));
          }
        }
        axios.get(`/api/v1/Structure/GetStructure?substanceName=${encodeURIComponent(object.name)}`)
          .then((response) => {
            dispatch(getStructureRequest(response));
          }).catch((error) => {
            localStorage.removeItem('marvinSmiles')
            dispatch(getStructureRequest(null));
            //  dispatch(makeError(error));
            //dispatch(substanceDetailNodeRequest.failure(error.message));
          });
        setTimeout(() => {
          dispatch<any>(getStructureRestrictionsList(object.name));
          dispatch<any>(showDetail({ name: object.name, type: 'Substance', subType: 'Substance', status: object.status }));
        }, 1000)
      })
      .catch((error: any) => {
        dispatch(makeError(error));
      });
  };
}

export const deleteStructureAction = createStandardAction("DELETE_STRUCTURE_SUCCESS")<any>();

export const deleteStructureData = (object: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    axios.post('/api/v1/Structure/DeleteStructure', { subName: object.name, molecule: object.molecule })
      .then((response) => {
        dispatch(makeSuccessMessage("Structure is deleted successfully."));
        dispatch<any>(showDetail({ name: object.name, type: 'Substance', subType: 'Substance', status: object.status }));
      })
      .catch((error: any) => {
        dispatch(makeError(error));
      });
  };
}
