export enum MessageSeverity {
  Info,
  Success,
  Warning,
  Error
};

export interface SirMessage {
  message: string;
  severity: MessageSeverity;
  time: number;
};

export interface SirMessageState {
  display: boolean;
  messages: SirMessage[];
};

export interface SirError {
  type: string;
  message: string;
}
