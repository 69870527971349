import { Dispatch } from 'redux';
import axios from 'axios';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {AppState} from '../index';
import { makeError } from '../error/actions';

export const requestHistoryStructure = createAsyncAction(
  "HISTORY_REQUEST_STRUCT_START",
  "HISTORY_REQUEST_STRUCT_SUCCESS",
  "HISTORY_REQUEST_STRUCT_FAILURE")<string, any, string>();

export const closeHistoryViewActionStruct = createStandardAction("HISTORY_CLOSE_STRUCT")<void>();

export const requestStructureHistory = (nodeName: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    axios.get(`/api/v1/Structure/GetStructureHistory?substanceName=${encodeURIComponent(nodeName)}`)
      .then((response) => {
        dispatch(requestHistoryStructure.success({ backboneIdentity: response.data[0].backboneIdentity, history: response.data[0].history, sirIdentity: response.data[0].sirIdentity }));
      })
      .catch((error) => {
        dispatch(makeError('No history data available'));
        //dispatch(requestHistoryStructure.success(null));
        dispatch(requestHistoryStructure.failure(error.message));
      });
  }
}

export const closeHistoryViewStructure = () => {
  return closeHistoryViewActionStruct();
}
