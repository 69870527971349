import { createReducer } from 'typesafe-actions';
import { ItemDetailState } from "./types";
import * as actions from './actions';

const initialState: ItemDetailState = {
  itemDetail: { backboneIdentity: "No item selected" },
  type: "",
  selectedNode: null,
  compositeTabDetail: { backboneIdentity: "No item selected" }
};

export const itemDetailReducer = createReducer(initialState)
  .handleAction(actions.substanceDetailNodeRequest.request, (state, action) => ({ ...state, itemDetail: { backboneIdentity: "Loading..." } }))
  .handleAction(actions.substanceDetailNodeRequest.success, (state, action) => ({ ...state, itemDetail: action.payload.data, type: action.payload.type }))
  .handleAction(actions.substanceDetailNodeRequest.failure, (state, action) => ({ ...state, itemDetail: { backboneIdentity: "" } }))
  .handleAction(actions.selectedNodeAction, (state, action) => ({ ...state, itemDetail: { backboneIdentity: "Loading..." }, selectedNode: action.payload }))
  .handleAction(actions.compositeTabContentRequest.request, (state, action) => ({ ...state, compositeTabDetail: { backboneIdentity: "Loading..." } }))
  .handleAction(actions.compositeTabContentRequest.success, (state, action) => ({ ...state, compositeTabDetail: action.payload.data }))
