import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { productionMiddleware, devMiddleware } from "./Middleware";
import { connectRouter, RouterState } from 'connected-react-router';
import { userReducer } from "./user/reducers";
import { UserState } from "./user/types";
import { hierarchyReducer } from "./hierarchy/reducers";
import { HierarchyState } from "./hierarchy/types";
import { searchReducer } from "./search/reducers";
import { SearchState } from "./search/types";
import { itemDetailReducer } from "./itemDetail/reducers";
import { ItemDetailState } from "./itemDetail/types";
import { messageReducer } from "./error/reducers";
import { SirMessageState } from "./error/types";
import { lookupReducer } from "./lookupData/reducers";
import { LookupState } from "./lookupData/types";
import { importExportReducer } from "./importExport/reducers";
import { ImportExportState } from "./importExport/types";
import { itemHistoryReducer } from "./history/reducers";
import { ItemHistoryState } from "./history/types";
import { editItemReducer } from "./editItem/reducers";
import { EditItemState } from "./editItem/types";
import { addItemReducer } from "./addItem/reducers";
import { AddItemState } from "./addItem/types";
import { mergeItemsReducer } from "./mergeItems/reducers";
import { MergeItemsState } from "./mergeItems/types";
import { StructureState } from "../store/structureInfo/types";
import { parentChildApiReducer } from '../store/structureInfo/reducers';
import { CommentState } from "./comment/types";
import { commentReducer } from "./comment/reducers";
import { structureHistoryReducer } from "./structurehistory/reducers";
import { structureSearchReducer } from "./searchStructure/reducers";
import { flagReducer } from "./flag/reducers";
import { subComponentReducer } from "./subComponent/reducers";
import { SubCompState } from "./subComponent/types";
import { swapReducer } from "./swapBackbone/reducers";
//import { validCompReducer } from '../store/validateComposition/reducers';
//import { DatatoValidateState } from '../store/validateComposition/types';
import { AddVariantState } from "./variant/types";
import { variantReducer } from "./variant/reducers"; 
import {polymerReducer} from "./polymer/reducers";
import {PolymerData} from "./polymer/types";

const rootReducer = (history: any) => combineReducers({
  parentChildApiReducer: parentChildApiReducer,
  user: userReducer,
  router: connectRouter(history),
  hierarchy: hierarchyReducer,
  search: searchReducer,
  itemDetail: itemDetailReducer,
  messages: messageReducer,
  lookup: lookupReducer,
  importExport: importExportReducer,
  itemHistory: itemHistoryReducer,
  editItem: editItemReducer,
  addItem: addItemReducer,
  addItemName: addItemReducer,
  mergeItems: mergeItemsReducer,
  commentReducer: commentReducer,
  structureHistoryReducer: structureHistoryReducer,
  structureSearchReducer: structureSearchReducer,
  flagReducer: flagReducer,
  subComponentReducer: subComponentReducer,
  swapReducer: swapReducer,
  //datatoValidate: validCompReducer
  variant:variantReducer, 
  polymersData:polymerReducer
});

export interface AppState {
  structureHistoryReducer: any;
  StructureState: StructureState,
  hierarchy: HierarchyState,
  user: UserState,
  router: RouterState,
  search: SearchState,
  itemDetail: ItemDetailState,
  messages: SirMessageState,
  lookup: LookupState,
  importExport: ImportExportState,
  itemHistory: ItemHistoryState,
  editItem: EditItemState,
  addItem: AddItemState,
  mergeItems: MergeItemsState,
  comment: CommentState,
  addItemName: AddItemState,
  subCompState: SubCompState,
  variant: AddVariantState,
  polymersData:PolymerData
  //datatoValidate: DatatoValidateState
  //variant:VariantState,
  //editVariant:EditVariant,
  //deleteVariant:DeleteVariant,
}

export default function configureStore(history: any) {

  if (process.env.NODE_ENV === 'production') {
    const store = createStore(
      rootReducer(history),
      applyMiddleware(...productionMiddleware(history))
    );
    return store;
  } else {
    const store = createStore(
      rootReducer(history),
      composeWithDevTools(applyMiddleware(...devMiddleware(history)))
    );
    return store;
  }
}
