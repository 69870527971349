import React from 'react';
import './TabStyle.css';
import { AppState } from '../store';
import TabForSubstance from './TabForSubstance';
import TabForCompositeSubstance from './TabForCompositeSubstance';
import { useSelector } from 'react-redux';

const StructureTabs = () => {
  //let selected: string = '';
  //SearchInProgress();
  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  let pageStatus = localStorage.getItem('compositepage');
  localStorage.removeItem('marvinSmiles');
  let displayUI = false;
  if (selectedItem === null || (selectedItem as any).type === "") {
    displayUI = false;
  } else {
    displayUI = true;
  }
  if (displayUI) {
    return (
      (((selectedItem as any).type !== "Composite") && ((selectedItem as any).type !== "Composite name")&& (pageStatus !== "true")) ?
        <div>
          <TabForSubstance />
    </div>
      :
        <div>
          <TabForCompositeSubstance />
        </div>
    );
  } else {
    return (
     <div></div>
    );
  }
}

export default StructureTabs;
