import { Dispatch } from 'redux';
import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import axios from 'axios';
import { ItemData } from './types';
import { AppState } from '../index';
import { makeSuccessMessage, makeError } from '../error/actions';
import { locateItem } from '../hierarchy/actions';
import { showDetail } from '../itemDetail/actions';
import { hasConsecutiveSpaces } from '../../utils/checkConsecutiveSpaces';

export const editItemAction = createStandardAction("EDIT_ITEM")<ItemData>();

export const showEditItem = (itemData: ItemData) => {
  return editItemAction(itemData);
};

export const closeEditAction = createStandardAction("CLOSE_EDIT")<void>();
export const editCompositeRestrictionRequest = createAsyncAction(
  "COMPOSITE_RESTRICTIONS_REQUEST_START",
  "COMPOSITE_RESTRICTIONS_REQUEST_SUCCESS",
  "COMPOSITE_RESTRICTIONS_REQUEST_FAILURE")<string, any, string>();
export const editStructureRestrictionRequest = createAsyncAction(
  "STRUCTURE_RESTRICTIONS_REQUEST_START",
  "STRUCTURE_RESTRICTIONS_REQUEST_SUCCESS",
  "STRUCTURE_RESTRICTIONS_REQUEST_FAILURE")<string, any, string>();
export const closeEdit = () => {
  localStorage.removeItem('editComposite');
  localStorage.removeItem('tableEditData');
  localStorage.removeItem('selectedDomainsForEdit');
  localStorage.removeItem('rowdelete');
  localStorage.removeItem('restricteddomains');
  localStorage.removeItem('restrictredemails');
  localStorage.removeItem('isEditCompositeSave');
  localStorage.removeItem('editSubName');
  localStorage.removeItem('editSubstance');
  localStorage.removeItem('editSubSMolWeight');
  localStorage.removeItem('editSubSAuditDesc');
  localStorage.setItem('isSubVsClass','false');
  return closeEditAction();
};

export const savedEditAction = createAsyncAction("SAVE_EDIT_START", "SAVE_EDIT_SUCCESS", "SAVE_EDIT_FAIL")<string, ItemData, string>();

export const saveEdit = (values: ItemData, formik: any) => {
  let isSub = localStorage.getItem('isSubVsClass');
  if (isSub === 'false') {
    switch (values.type) {
      case "Substance":
        return saveSubstanceEdit(values);
      case "Classification":
        return saveClassificationEdit(values, formik);
      case "Composite":
        return saveCompositeEdit(values);
      default:
        return saveNameEdit(values);
    }
  }
  else {
    switch (values.type) {
      case "Substance":
        return subVsClassific(values);
      case "Classification":
        return classificToSub(values);
    }
    
  }
}

let delArrayContainer: any = [];
//let reg = /^.*\s{2,}.*$/
//SonarQube warning for Denail of Service issue
// let reg = /(?!\S)\s{2}(?!$)/

export const storeDeletedObject = (obj: any) => {
  if (delArrayContainer.indexOf(obj) === -1) {
    delArrayContainer.push(obj);
  }
}

const saveNameEdit = (values: ItemData) => {
  if (hasConsecutiveSpaces(values.name)) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      //formik.setSubmitting(false);
    }
  } 
  else {
  return (dispatch: Dispatch, getState: () => AppState) => {
    let dataSelected: any = localStorage.getItem('name');
    let parentTypeWhileEdit: any = localStorage.getItem('parentTypeWhileEdit');
    const editState = getState().editItem;
    const currentName = editState.itemData ? editState.itemData.name : values.name;
    dispatch(savedEditAction.request(editState.itemData ? editState.itemData.name : values.name));
    const parsedDomain = localStorage.getItem('selectedDomainsForEdit');
    let domainWhileEdit: any = [];
    let primaryWhileEdit: any = [];
    if (parsedDomain) {
      domainWhileEdit = JSON.parse(parsedDomain);
    }
    let url = values.type !== 'Classification name' ? '/api/v1/SubstanceName/EditSubstanceNameDomains' : '/api/v1/Classification/EditClassificationName';
    axios.put(url, { currentName, name: values.name, status: values.status, nameType: values.subType, auditDescription: values.auditDescription, domains: domainWhileEdit, primaryDomains: values.primaryDomains })
      .then((response) => {
        dispatch(makeSuccessMessage("Save complete"));
        dispatch(savedEditAction.success(values));
        if (dataSelected !== currentName) {
          values.name = dataSelected;
        }
        if (values.type === 'Classification name') {
          dispatch<any>(locateItem({ name: values.name, type: "Classification name", subType: values.subType, status: values.status, substanceId: values.name, auditDescription: values.auditDescription }, true, currentName));
        } else if (parentTypeWhileEdit === "Substance") {
          dispatch<any>(locateItem({ name: values.name, type: "Substance name", subType: values.subType, status: values.status, substanceId: values.name, auditDescription: values.auditDescription }, true, currentName));
        } else {
          dispatch<any>(locateItem({ name: values.name, type: "Substance name", subType: values.subType, status: values.status, substanceId: values.name, auditDescription: values.auditDescription }, true, currentName));
          dispatch<any>(showDetail({ name: values.name, type: "Composite", subType: values.subType, status: "A" }));
        }
        localStorage.removeItem('editSubstance');
      })
      .catch((error: any) => {
        localStorage.removeItem('editSubName');
        localStorage.removeItem('editSubstance');
        localStorage.removeItem('selectedDomainsForEdit');
        dispatch(makeError(error));
        dispatch(savedEditAction.failure(error.message));
      });
  };
}
}

const saveSubstanceEdit = (values: ItemData) => {
  const valMolWeight = localStorage.getItem('editSubSMolWeight');
  const valAuditDesc = localStorage.getItem('editSubSAuditDesc');
  if (valMolWeight === "") {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Please enter Molar Weight'));
    }
  }
  else {
      return (dispatch: Dispatch, getState: () => AppState) => {
        const editState = getState().editItem;
        const valMolWeight = localStorage.getItem('editSubSMolWeight');
        const currentName = editState.itemData ? editState.itemData.name : values.name;

        if (values.name) {
          dispatch(savedEditAction.request(editState.itemData ? editState.itemData.name : values.name));
          axios.put('/api/v1/Substances/EditSubstance', { substanceName: currentName, editedSubstanceName: values.name, status: values.status, auditDescription: valAuditDesc ? valAuditDesc : '', molWeight: valMolWeight })
            .then((response) => {
              dispatch(makeSuccessMessage("Save complete"));
              localStorage.removeItem('editSubSMolWeight');
              localStorage.removeItem('editSubSAuditDesc');
              dispatch(savedEditAction.success(values));
              dispatch<any>(locateItem({ name: values.name, type: "Substance", subType: values.subType, status: values.status, substanceId: values.name, auditDescription: values.auditDescription }, true, currentName));
            })
            .catch((error: any) => {
              dispatch(makeError(error));
              dispatch(savedEditAction.failure(error.message));
            });
        } else {
          dispatch(makeError('Please enter a value for Name field'));
        }
      };
    
    }
}

const subVsClassific = (values: ItemData) => {
  if (hasConsecutiveSpaces(values.name)) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      //formik.setSubmitting(false);
    }
  } else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const editState = getState().editItem;
      const currentName = editState.itemData ? editState.itemData.name : values.name;
      dispatch(savedEditAction.request(editState.itemData ? editState.itemData.name : values.name));
      axios.put('/api/v1/Classification/SubstanceToClassification', { currentName, name: values.name, status: values.status, classificationType: values.subType, auditDescription: values.auditDescription })
        .then((response) => {
          dispatch(makeSuccessMessage("Save complete"));
          dispatch(savedEditAction.success(values));
          localStorage.setItem('isSubVsClass', 'false');
          dispatch<any>(locateItem({ name: values.name, type: "Classification", subType: values.subType, status: values.status, substanceId: "", auditDescription: values.auditDescription }, true, currentName));
        })
        .catch((error: any) => {
          dispatch(makeError(error));
          dispatch(savedEditAction.failure(error.message));
        });

    };
  }
}
const classificToSub = (values: ItemData) => {
  if (hasConsecutiveSpaces(values.name)) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      //formik.setSubmitting(false);
    }
  } else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const editState = getState().editItem;
      const currentName = editState.itemData ? editState.itemData.name : values.name;
      dispatch(savedEditAction.request(editState.itemData ? editState.itemData.name : values.name));
      axios.put('/api/v1/Classification/ClassificationToSubstance', { currentName, name: values.name, status: values.status, classificationType: values.subType, auditDescription: values.auditDescription })
        .then((response) => {
          dispatch(makeSuccessMessage("Save complete"));
          dispatch(savedEditAction.success(values));
          localStorage.setItem('isSubVsClass', 'false');
          dispatch<any>(locateItem({ name: values.name, type: "Substance", subType: values.subType, status: values.status, substanceId: "", auditDescription: values.auditDescription }, true, currentName));
        })
        .catch((error: any) => {
          dispatch(makeError(error));
          dispatch(savedEditAction.failure(error.message));
        });

    };
  }
}

const saveClassificationEdit = (values: ItemData, formik: any) => {
  if (hasConsecutiveSpaces(values.name)) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(makeError('Name contains more than one consecutive space or leading/trailing spaces - please correct'));
      //formik.setSubmitting(false);
    }
  } else {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const editState = getState().editItem;
      const currentName = editState.itemData ? editState.itemData.name : values.name;
      dispatch(savedEditAction.request(editState.itemData ? editState.itemData.name : values.name));
      axios.put('/api/v1/Classification/EditClassification', { currentName, name: values.name, status: values.status, classificationType: values.subType, auditDescription: values.auditDescription })
        .then((response) => {
          dispatch(makeSuccessMessage("Save complete"));
          dispatch(savedEditAction.success(values));
          dispatch<any>(locateItem({ name: values.name, type: "Classification", subType: values.subType, status: values.status, substanceId: "", auditDescription: values.auditDescription }, true, currentName));
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          dispatch(makeError(error));
          dispatch(savedEditAction.failure(error.message));
        });

    };
  }
}
const saveCompositeEdit = (values: ItemData) => {

  return (dispatch: Dispatch, getState: () => AppState) => {
    const editState = getState().editItem;
    let data = localStorage.getItem('tableEditData');
    const parsedDomain = localStorage.getItem('selectedDomainsForEdit');
    let domainWhileEdit: any = [];

    if (parsedDomain) {
      domainWhileEdit = JSON.parse(parsedDomain);
    }
    let compositionData: any = [];
    let updatedData: any = [];
    if (data) {
      compositionData = JSON.parse(data);
    }
    for (let i = 0; i < compositionData.length; i++) {
      let object: any = {
        isDeleted: compositionData[i].isDeleted,
        isEdited: compositionData[i].isEdited,
        isAdded: compositionData[i].isAdded,
        name: compositionData[i].name,
        min: compositionData[i].minlevel ? compositionData[i].minlevel.toString() : "",
        avg: compositionData[i].avglevel ? compositionData[i].avglevel.toString() : "",
        max: compositionData[i].maxlevel ? compositionData[i].maxlevel.toString() : ""
      }
      updatedData.push(
        object
      )
    }
    let delObj = localStorage.getItem('rowdelete');
    if (delObj) {
      //const parsedDelObj = JSON.parse(delObj);
      for (let i = 0; i < delArrayContainer.length; i++) {
        let object: any = {
          isDeleted: delArrayContainer[i].isDeleted,
          isEdited: delArrayContainer[i].isEdited,
          isAdded: delArrayContainer[i].isAdded,
          name: delArrayContainer[i].name,
          min: delArrayContainer[i].minlevel ? delArrayContainer[i].minlevel.toString() : "",
          avg: delArrayContainer[i].avglevel ? delArrayContainer[i].avglevel.toString() : "",
          max: delArrayContainer[i].maxlevel ? delArrayContainer[i].maxlevel.toString() : ""
        }
        updatedData.push(
          object
        )
      }
    }
    let finalObject = {
      compositeSubstanceName: editState.itemData ? editState.itemData.name : values.name,
      editedCompositeSubstanceName: values.name,
      status: values.status,
      auditDescription: values.auditDescription,
      domains: domainWhileEdit,
      components: updatedData
    }
    if (values.name) {
      dispatch(savedEditAction.request(editState.itemData ? editState.itemData.name : values.name));
      axios.put('/api/v1/CompositeSubstances/EditCompositeSubstance', finalObject)
        .then((response) => {
          localStorage.removeItem('tableEditData');
          localStorage.removeItem('selectedDomainsForEdit');
          localStorage.removeItem('isEditCompositeSave')
          localStorage.removeItem('editComposite');
          delArrayContainer = [];
          dispatch(makeSuccessMessage("Save complete"));
          localStorage.removeItem('rowdelete');
          dispatch(savedEditAction.success(values));
          const restDomains = localStorage.getItem('restricteddomains');
          const restEmail = localStorage.getItem('restrictredemails');
          let restrictionData: any = {
            compositeSubstanceName: values.name,
            domains: restDomains ? JSON.parse(restDomains) : null,
            mailIds: restEmail ? JSON.parse(restEmail) : null
          }
          if (restrictionData.domains || restrictionData.mailIds) {
            axios.put('/api/v1/CompositeSubstances/EditCompositeSubstanceRestriction', restrictionData).then((response) => {
              localStorage.removeItem('restricteddomains');
              localStorage.removeItem('restrictredemails');
            }).catch((error: any) => {
              localStorage.removeItem('restricteddomains');
              localStorage.removeItem('restrictredemails');
              dispatch(makeError(error));
            });
          }

          setTimeout(() => {
            dispatch<any>(showDetail({ name: values.name, type: "Composite", subType: values.subType, status: values.status }));
          }, 3000);
        })
        .catch((error: any) => {
          localStorage.removeItem('tableEditData');
          localStorage.removeItem('selectedDomainsForEdit');
          localStorage.removeItem('isEditCompositeSave')
          localStorage.removeItem('editComposite');
          dispatch(makeError(error));
          //dispatch(savedEditAction.failure(error.message));
        });
    }
    else {
      dispatch(makeError("Please enter the value for Name field!"));
    }

  };
}
export const getCompositeRestrictionsList = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
      axios.get(`/api/v1/CompositeSubstances/CompositeSubstanceRestrictions?compositeSubstanceName=${encodeURIComponent(name)}`)
        .then((response) => {
          dispatch(editCompositeRestrictionRequest.success(response));
        }).catch((error) => {
          dispatch(makeError(error));
        });
    
  }
}
export const getStructureRestrictionsList = (name: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    axios.get(`/api/v1/Structure/GetStructureRestrictions?StructureName=${encodeURIComponent(name)}`)
      .then((response) => {
        localStorage.removeItem('structurerestricteddomains');
        localStorage.removeItem('structurerestrictedemails');
        dispatch(editStructureRestrictionRequest.success(response));
      }).catch((error) => {
        dispatch(makeError(error));
      });

  }
}
