import { createReducer } from 'typesafe-actions';
import { HierarchyState, NodeData, NodeResult } from "./types";
import { produce } from 'immer';
import * as actions from './actions';
import { walkTree, findNodesInTreeByName, mergeTrees, setSelectedBranches, removeNodes, findNode } from '../../domain/tree';
import { v4 as uuidv4 } from 'uuid';

const initialState: HierarchyState = {
  tree: {
    name: 'All',
    type: 'Classification',
    subType: '',
    status: 'A',
    key: 'Root',
    selected: true,
    children: [ {
        name: 'Classification',
        type: 'Classification',
        subType: '',
        status: 'A',
        key: 'ClassRoot',
        selected: true
      },{
        name: 'Orphans',
        type: 'Classification',
        subType: '',
        status: 'A',
        key: 'OprhanRoot'
      },
    ]
  },
  loadingNode: null,
  structrueData: null,
  getStructureList: null,
  editStructureData: null,
  addstructrueData: null
};

const setSelectedBranchesImmutable = (current: NodeData, item: string) => {
  const newState = produce(current, draftState => {
    // Start with everything deselected...
    walkTree(draftState, () => true, (n => n.selected = false));
    // ... then select the branches with matching name
    setSelectedBranches(draftState, item);
    // Always keep the classification branch open (even if the selected item is in the orphans branch.)
    const classificationNode = findNode(draftState, i => i.name === 'Classification');
    if (classificationNode) {
      classificationNode.selected = true;
    }

    // Setting top-level key tells react to redraw everything below.
    //draftState.key = `Root-${Math.random()}`;
    //As per SonarQube Math.random() can provide repetitive and predictable value. So using UUID4
    draftState.key = `Root-${uuidv4()}`;
  });

  return newState;
}

const mergeTreesImmutable = (current: NodeData, payload: NodeData) : NodeData => {
  const newState = produce(current, draftState => {
    mergeTrees(draftState, payload);
    });

  return newState;
}

const mergeResultsImmutable = (current: NodeData, payload: NodeResult) : NodeData => {
  const newState = produce(current, draftState => {
    // Add the new results to all nodes that match.
    const parentNodes = findNodesInTreeByName(payload.parentName || 'Classification', payload.nodeType, draftState);

    parentNodes.forEach(i => {
      // Existing item might already have children, so use our merge util
      if (i.name === "Orphans" || i.name === "Classification") {
        i.children = [];
      }
      const c = { ...i, children: payload.data };
      mergeTrees(i, c);
      i.partial = false;
    });
  });

  return newState;
}

const removeItemImmutable = (current: NodeData, itemName: string) : NodeData => {
  const newState = produce(current, draftState => {
    removeNodes(draftState, itemName);
  });

  return newState;
}

export const hierarchyReducer = createReducer(initialState)
.handleAction(actions.classificationNodeRequest.success, (state, action) => ({...state, loadingNode: null, tree: mergeResultsImmutable(state.tree, action.payload) }))
.handleAction(actions.classificationNodeRequest.request, (state, action) => ({...state, loadingNode: action.payload }))
.handleAction(actions.classificationNodeRequest.failure, (state, action) => ({ ...state, loadingNode: null }))
.handleAction(actions.mergeItemHierarchyRequest.success, (state, action) => ({...state, tree: mergeTreesImmutable(state.tree, action.payload.data), loadingNode: null}))
.handleAction(actions.mergeItemHierarchyRequest.request, (state, action) => ({...state, loadingNode: 'Classification' }))
.handleAction(actions.mergeItemHierarchyRequest.failure, (state, action) => ({ ...state, loadingNode: null }))
.handleAction(actions.selectHierarchyBranches, (state, action) => ({ ...state, tree: setSelectedBranchesImmutable(state.tree, action.payload) }))
.handleAction(actions.removeItemAction, (state, action) => ({ ...state, tree: removeItemImmutable(state.tree, action.payload) }))
.handleAction(actions.savedStructureAction, (state, action) => ({ ...state, structrueData: action.payload }))
.handleAction(actions.getStructureRequest, (state, action) => ({ ...state, getStructureList: action.payload }))
  .handleAction(actions.editStructureAction, (state, action) => ({ ...state, editstructrueData: action.payload }))
  .handleAction(actions.addStructureAction, (state, action) => ({ ...state, addstructrueData: action.payload }))

;

